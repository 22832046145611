import {Injectable} from '@angular/core';
import {AutoOptions, LanguageFn, Options, Root} from 'lowlight/lib';
import {common, createLowlight} from 'lowlight';


interface LowlightInst {
  highlight: (language: string, value: string, options?: Readonly<Options> | null | undefined) => Root;
  highlightAuto: (value: string, options?: Readonly<AutoOptions> | null | undefined) => Root;
  listLanguages: () => Array<string>;
  register: {
    (grammars: Readonly<Record<string, LanguageFn>>): undefined;
    (name: string, grammar: LanguageFn): undefined;
  };
  registerAlias: {
    (aliases: Readonly<Record<string, ReadonlyArray<string> | string>>): undefined;
    (language: string, alias: ReadonlyArray<string> | string): undefined;
  };
  registered: (aliasOrName: string) => boolean;
}

@Injectable({providedIn: 'root'})
export class ReaderTiptapExtensionsService {
  private lowlightInstance: LowlightInst;

  getLowlightInstance(): LowlightInst {
    if (!this.lowlightInstance) {
      this.lowlightInstance = createLowlight(common);
    }

    return this.lowlightInstance;
  }
}
