// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  position: relative;
  border: 0;
  border-radius: 0;
  height: 5px;
  background-color: var(--bitmark-reader-secondary-color);
}
.progress .progress-bar {
  background-color: var(--bitmark-reader-primary-color);
}
.progress .animated-stripes {
  position: absolute;
  left: -46px;
  right: 0;
  top: 0;
  bottom: 0;
  background: repeating-linear-gradient(-55deg, var(--bitmark-reader-primary-color) 1px, var(--bitmark-reader-primary-color) 5px, #ffffff 20px);
  animation-name: move-animation;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes move-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(46px);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
