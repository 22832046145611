// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.bit-wrapper-header {
  display: flex;
  background-color: #9bd0ff;
  border-color: #9bd0ff;
  color: #ffffff;
}
.bit-wrapper-header:empty {
  display: none;
}

.bit-wrapper1 {
  position: relative;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
}
.bit-wrapper1 :not(.child-content) .bit-background1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.bit-wrapper1 .bit-wrapper-container {
  min-height: 25px;
}

.bit1 {
  contain: layout style;
}
.bit1.has-theme-width {
  max-width: 100%;
}
.bit1.is-message1 {
  border-radius: 0.3rem;
  align-self: flex-end;
  max-width: 755px;
  min-width: 100px;
  padding: 0;
  margin: 0;
}
.bit1.is-message1.not-in-timeline {
  width: -moz-fit-content;
  width: fit-content;
}
.bit1.is-module {
  width: var(--bitmark-module-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-module-size-height, var(--bitmark-x-size-height, inherit));
}
.bit1.is-module.has-margins {
  padding: var(--bitmark-module-size-margin, var(--bitmark-x-size-margin, 0));
}
.bit1.is-module > .bit-wrapper-container {
  border-top: var(--bitmark-module-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-module-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-module-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-module-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-module-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-module-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-module-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-module-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-module-border-radius, var(--bitmark-x-border-radius, y));
  padding: var(--bitmark-module-size-padding, var(--bitmark-x-size-padding, 0));
}
.bit1.is-module > .bit-wrapper-container > .bit-background1 {
  background-color: var(--bitmark-module-background-color, var(--bitmark-bit-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-reader-content-background-color)))));
  background-image: var(--bitmark-module-background-image, var(--bitmark-bit-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-module-background-size, var(--bitmark-bit-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-module-background-position, var(--bitmark-bit-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-module-background-repeat, var(--bitmark-bit-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-module-background-transform, var(--bitmark-bit-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-module-background-animation-name) var(--bitmark-module-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-module-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-module-background-backdrop-filter);
}
.bit1.is-learning-path {
  width: var(--bitmark-learning-path-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-learning-path-size-height, var(--bitmark-x-size-height, inherit));
}
.bit1.is-learning-path.has-margins {
  padding: var(--bitmark-learning-path-size-margin, var(--bitmark-x-size-margin, 0));
}
.bit1.is-learning-path > .bit-wrapper-container {
  border-top: var(--bitmark-learning-path-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-learning-path-border-style, var(--bitmark-x-border-style)) var(--bitmark-learning-path-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-learning-path-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-learning-path-border-style, var(--bitmark-x-border-style)) var(--bitmark-learning-path-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-learning-path-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-learning-path-border-style, var(--bitmark-x-border-style)) var(--bitmark-learning-path-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-learning-path-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-learning-path-border-style, var(--bitmark-x-border-style)) var(--bitmark-learning-path-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-learning-path-border-radius, var(--bitmark-x-border-radius, y));
  padding: var(--bitmark-learning-path-size-padding, var(--bitmark-x-size-padding, 0));
}
.bit1.is-learning-path > .bit-wrapper-container > .bit-background1 {
  background-color: var(--bitmark-learning-path-background-color, var(--bitmark-bit-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-reader-content-background-color)))));
  background-image: var(--bitmark-learning-path-background-image, var(--bitmark-bit-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-learning-path-background-size, var(--bitmark-bit-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-learning-path-background-position, var(--bitmark-bit-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-learning-path-background-repeat, var(--bitmark-bit-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-learning-path-background-transform, var(--bitmark-bit-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-learning-path-background-animation-name) var(--bitmark-learning-path-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-learning-path-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-learning-path-background-backdrop-filter);
}
.bit1.is-annotation-note {
  max-width: 480px;
}
.bit1.is-annotation-favorite {
  width: 370px;
  height: 80px;
}
.bit1.is-annotation-bookmark {
  width: 396px;
  height: 80px;
  align-self: initial;
}
@media (min-width: 992px) {
  .bit1.is-annotation-bookmark {
    left: -65px;
  }
}
@media (max-width: 992px) {
  .bit1.is-annotation-bookmark {
    left: -15px;
  }
}
.bit1.is-single-action {
  border-radius: 0.3rem;
  padding: 10px 10px 0 10px;
}
.bit1.is-single-action .bit-background1 {
  border-radius: 0.3rem;
}
.bit1.is-training-chapter {
  --bitmarkChapterScaleDownFactor: 1;
  --bitmark-chapter-l-1-font-family: Inter, sans-serif;
  --bitmark-chapter-l-1-font-size: 1.25rem;
  --bitmark-chapter-l-1-font-weight: 300;
  --bitmark-chapter-l-1-font-style: none;
  --bitmark-chapter-l-1-font-line-height: 1.3;
  --bitmark-chapter-l-1-font-color: #ffffff;
  --bitmark-chapter-l-1-size-padding: 0;
  --bitmark-chapter-l-1-background-color: transparent;
  --bitmark-chapter-l-1-size-margin: 0 30px;
  --bitmark-chapter-l-2-font-family: Inter, sans-serif;
  --bitmark-chapter-l-2-font-size: 1.25rem;
  --bitmark-chapter-l-2-font-weight: 300;
  --bitmark-chapter-l-2-font-style: none;
  --bitmark-chapter-l-2-font-line-height: 1.3;
  --bitmark-chapter-l-2-font-color: #ffffff;
  --bitmark-chapter-l-2-size-padding: 0;
  --bitmark-chapter-l-2-background-color: transparent;
  --bitmark-chapter-l-2-size-margin: 0 30px;
}
.bit1.is-training-chapter bitmark-bit {
  display: block;
  margin-left: -10px;
}
@media (min-width: 680px) {
  .bit1.is-training-chapter bitmark-bit {
    margin-left: -30px;
  }
}
.bit1.is-training-chapter .bit-background1 {
  background: transparent;
}
.bit1.bit-full-height bitmark-bit {
  height: 100%;
}

.bit-shadow1 {
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  transform: translateZ(0); /* added as a workaround for Safari performance issues https://stackoverflow.com/questions/10814178/css-performance-relative-to-translatez0 */
}

.bit-shadow-filler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
