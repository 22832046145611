import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {BitResource} from '../bits.models';
import {TakePictureBit} from './take-picture.models';
import {
  ResourceCameraPhotoEditComponent
} from '../resource/edit/resource-camera-photo-edit/resource-camera-photo-edit.component';
import {RecordedVideoOutput, VideoRecordingService} from '../../shared/recording/video-recording.service';
import {BitbookApiService} from '../../reader/bitbook-api.service';

@Component({
  selector: 'bitmark-take-picture',
  templateUrl: './take-picture.component.html',
  styleUrls: ['./take-picture.component.scss', '../bits.scss'],
})
export class TakePictureComponent implements OnInit {
  private _bit?: TakePictureBit;
  @Input() set bit(value: TakePictureBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.wordBankItems = [];
      this.computeWordBank();
    }
  }

  get bit(): TakePictureBit {
    return this._bit;
  }

  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<TakePictureBit>();

  @ViewChild(ResourceCameraPhotoEditComponent) resourceCameraPhotoEdit: ResourceCameraPhotoEditComponent;

  isTakingPicture = false;
  isUploadingPicture = false;
  wordBankItems: Array<{ text: string, used: boolean }> = [];

  private initialized = false;

  constructor(private bitBookApiService: BitbookApiService,
              private videoRecordingService: VideoRecordingService) {
  }

  ngOnInit() {
    this.computeWordBank();

    this.initialized = true;
  }

  private computeWordBank() {
    if (this.bit.additionalSolutions?.length) {
      this.bit.additionalSolutions.forEach(x => {
        this.wordBankItems.push({text: x, used: this.bit.answer?.usedSolutions?.includes(x)});
      });
    }

    this.wordBankItems.sort();
  }

  toggleWordBankSolution(item: { text: string, used: boolean }) {
    item.used = !item.used;

    if (!this.bit.answer?.usedSolutions) {
      this.bit.answer = {
        usedSolutions: [item.text]
      };
    } else {
      if (this.bit.answer.usedSolutions.includes(item.text)) {
        this.bit.answer.usedSolutions = this.bit.answer.usedSolutions.filter(x => x !== item.text);
      } else {
        this.bit.answer.usedSolutions = [...this.bit.answer.usedSolutions, item.text];
      }
    }

    this.changed.emit();
  }

  takePicture() {
    if (this.isTakingPicture) {
      this.resourceCameraPhotoEdit.takePicture();
    } else {
      this.videoRecordingService.hasPermissions().then((hasPermissions) => {
        if (hasPermissions) {
          this.isTakingPicture = true;
        } else {
          this.videoRecordingService.askForPermissions().then(() => {
            this.isTakingPicture = true;
          }, () => {
            this.isTakingPicture = false;
          });
        }
      });
    }
  }

  cancelTakePicture() {
    this.isTakingPicture = false;
  }

  pictureCaptured(data: RecordedVideoOutput) {
    this.isUploadingPicture = true;
    const file = data.file || new File([data.blob], data.title, {type: data.blob.type});

    this.delayRequest(
      this.bitBookApiService.uploadResource(file),
      (uploadData: { url: string }) => {
        this.isUploadingPicture = false;
        this.isTakingPicture = false;
        this.bit.answer = {
          ...this.bit.answer,
          image: {
            url: uploadData.url,
            provider: 'getmorebrain.com'
          }
        };
        this.changed.emit();
      },
      2000);
  }

  private delayRequest(request: Observable<any>, action: (uploadData: { url: string }) => void, minRequestTime: number) {
    const initialTime = new Date();

    request.subscribe(
      data => {
        const currentTime = new Date();
        const elapsedTime = currentTime.getTime() - initialTime.getTime();

        setTimeout(() => {
          action(data);
        }, Math.max(minRequestTime - elapsedTime, 0));
      },
      (err) => {
        console.error(err);
        window.alert(err);
      });
  }
}
