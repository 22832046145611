import {EventEmitter, Injectable, OnDestroy} from "@angular/core";

@Injectable({providedIn: "root"})
export class IdleService implements OnDestroy {
  private isInitialized = false;
  private idleTimeout;
  private resetFn;
  private events = [
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress'
  ];

  idle = new EventEmitter();

  constructor() {
  }

  ngOnDestroy() {
    this.stop();
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetFn);
    });
  }

  init() {
    if (this.isInitialized) {
      return;
    }
    this.resetFn = this.reset.bind(this);
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetFn);
    });

    this.start();
  }

  stop() {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }
  }

  start() {
    this.idleTimeout = setTimeout(this.emitIdle.bind(this), 500);
  }

  reset() {
    this.stop();
    this.start();
  }

  emitIdle() {
    console.log('idle');
    this.idle.emit();
  }
}

