import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject, Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DebounceService, DropdownItemModel, EventEmitter as EE, ScrollDirective, SubSink} from '../../../../shared';
import {
  BitmarkFormat,
  DefaultBitAnnotationColors,
  ProductFamily,
  ReaderHiddenElements
} from '../../../../shared/models';
import {BitbookApiService} from '../../../bitbook-api.service';
import {BitbookMqService} from '../../../bitbook-mq.service';
import {ReaderTocService} from '../../../reader-toc.service';
import {ReaderBasketService} from '../../../reader-basket.service';
import {BitApiWrapper, BitResource, BitType} from '../../../../bits/bits.models';
import {ReaderClipboardService} from '../../../reader-clipboard.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {BitmarkConfig} from '../../../../bitmark.module';
import {ReaderContentService} from '../reader-content.service';
import {ParserApiService} from '../../../parser-api.service';
import {ReaderContentCommonComponent} from '../reader-content-common.component';
import {BrandingRenderService} from '../../../branding-render.service';
import {ReaderBitsBinService} from '../../../reader-bits-bin.service';
import {ReaderTipTapTapService} from '../../../tiptap/reader-tiptap.service';
import {ArticleBit} from '../../../../bits/article/article.models';
import {ReaderTextEditorComponent} from '../../../reader-editor/reader-text-editor/reader-text-editor.component';
import {
  ReaderSimpleTextEditorComponent
} from '../../../reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {DomObserverService} from '../../../../shared/dom/dom-observer.service';
import {AnalyticsService} from '../../../../shared/analytics/analytics.service';
import {RolesApiService, TaughtClass} from '../../../roles-api.service';
import StringCaseService from '../../../../shared/utils/string-case.service';
import {ArrayService} from '../../../../shared/utils/array.service';
import {ReaderLeContentService} from '../reader-le-content.service';

@Component({
  selector: 'bitmark-reader-content-notebook',
  templateUrl: './reader-content-notebook.component.html',
  styleUrls: ['../../../reader-common.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('300ms ease-in', style({transform: 'translateY(0%)'})),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateY(100%)'})),
      ]),
    ]),
  ],
})
export class ReaderContentNotebookComponent extends ReaderContentCommonComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Input() hiddenElements: Array<ReaderHiddenElements> = [];

  BitmarkFormat = BitmarkFormat;

  bitActions: Array<DropdownItemModel>;

  private sub = new SubSink();
  private subImgClicked: string;
  private subBitmarkChanged: string;
  private subInternalLinkClicked: string;
  private subCrossLinkClicked: string;

  // @ViewChild('infiniteScroll') infiniteScroll: ElementRef;

  @HostListener('window:popstate', ['$event'])
  // this is used when using the browser back button after selecting bits in toc/using internal link
  onPopState() {
    this.popStateListenerAction();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.debounceService.debounce(500, () => {
      this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
    });
  }

  constructor(@Inject('BitmarkConfig') bitmarkConfig: BitmarkConfig,
              bitBookApiService: BitbookApiService,
              bitbookMqService: BitbookMqService,
              readerTocService: ReaderTocService,
              translate: TranslateService,
              router: Router,
              private route: ActivatedRoute,
              location: Location,
              parserApiService: ParserApiService,
              basketSvc: ReaderBasketService,
              readerBasketService: ReaderBasketService,
              readerClipboard: ReaderClipboardService,
              readerContentService: ReaderContentService,
              clipboardService: ClipboardService,
              brandingRenderService: BrandingRenderService,
              readerTipTapService: ReaderTipTapTapService,
              ngbModal: NgbModal,
              private readerBitsBinService: ReaderBitsBinService,
              scrollDirective: ScrollDirective,
              domUtilsService: DomUtilsService,
              domObserverService: DomObserverService,
              fileUtilsService: FileUtilsService,
              deviceDetectorService: DeviceDetectorService,
              analyticsService: AnalyticsService,
              rolesApiService: RolesApiService,
              stringCaseService: StringCaseService,
              arrayService: ArrayService,
              private ngZone: NgZone,
              private debounceService: DebounceService,
              private readerLeContentService: ReaderLeContentService) {
    super(bitmarkConfig,
      bitBookApiService,
      readerContentService,
      bitbookMqService,
      readerTocService,
      readerBasketService,
      readerClipboard,
      clipboardService,
      basketSvc,
      translate,
      parserApiService,
      router,
      location,
      brandingRenderService,
      readerTipTapService,
      ngbModal,
      scrollDirective,
      domUtilsService,
      domObserverService,
      fileUtilsService,
      deviceDetectorService,
      analyticsService,
      rolesApiService,
      stringCaseService,
      arrayService
    );
    this.context = 'notebook';
  }

  ngOnInit() {
    this.context = this.router.url.indexOf('/new-release/') !== -1 ? 'new-release' : 'notebook';
    if (this.context === 'new-release') {
      this.globalNotebookApiQueryParams = {
        space: this.bitmarkConfig.space
      };
    }
    const menuActions = [{
      label: this.translate.instant('Reader.Actions.CopyAllBitsToBasket'),
      handler: this.copyAllBitsToBasket.bind(this)
    }];

    this.bitbookMqService.notifyReaderMenuReady(this.bitmarkConfig.isProUser
      ? [{
        label: this.translate.instant('Edit'),
        handler: this.openEditBookEditor.bind(this),
      }, {
        label: this.translate.instant('JSON Editor'),
        handler: this.openJsonBookEditor.bind(this),
      }, {
        label: this.translate.instant('Bitmark Translator'),
        handler: this.openHtmlTranslatorEditor.bind(this),
      }, ...menuActions]
      : menuActions);

    this.rolesApiService.getTaughtClasses()
      .subscribe((classes: Array<TaughtClass>) => {
        this.bitActions = [{
          id: 'code-language',
          label: 'Language',
          isDisabled: true
        }, {
          id: 'code-language-change',
          label: 'Change Language',
          actions: [{
            label: 'plaintext',
            handler: this.changeCodeLanguage.bind(this)
          }]
        }, {
          id: 'code-language-separator',
          isSeparator: true
        }, {
          label: this.translate.instant('Reader.Basket.AddToBasket'),
          handler: this.addToBasket.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.SendContinue'),
          handler: this.sendBit.bind(this),
        }, classes?.length ? {
          label: this.translate.instant('Reader.Actions.SendToClass'),
          handler: this.sendBitToClass.bind(this)
        } : null, {
          label: this.translate.instant('Reader.Actions.SaveToAnotherNotebook'),
          handler: this.saveBit.bind(this),
        }, {id: 'replace-image', isSeparator: true}, {
          id: 'replace-image',
          label: this.translate.instant('Shared.ReplaceImageContinue'),
          handler: this.replaceImage.bind(this)
        }, {id: 'shuffle', isSeparator: true}, {
          id: 'shuffle',
          label: this.translate.instant('Reader.Actions.Shuffle'),
          handler: this.shuffle.bind(this)
        }, {id: 'reset-answer', isSeparator: true}, {
          id: 'reset-answer',
          label: this.translate.instant('Reader.Actions.ResetAnswer'),
          handler: this.resetAnswer.bind(this),
        }, {
          id: 'edit-bit-data-separator',
          isSeparator: true,
          isHidden: !this.bitmarkConfig.isProUser,
        }, {
          isHidden: !this.bitmarkConfig.isProUser,
          label: 'Reader.Actions.EditBitmarkType',
          handler: this.openEditBitEditor.bind(this),
        }, {
          id: 'show-hidden-fields',
          label: this.translate.instant('Shared.ShowHiddenFields'),
          icon: 'eye-fill',
          iconCssClass: 'size-18px',
          handler: this.showHiddenFields.bind(this)
        }, {
          id: 'hide-hidden-fields',
          label: this.translate.instant('Shared.HideHiddenFields'),
          icon: 'eye-slash-fill',
          iconCssClass: 'size-18px',
          handler: this.hideHiddenFields.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyBitmarkJSON'),
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkJsonToClipboard.bind(this),
        }, {
          label: 'Reader.Actions.BrandingPublisherTheme',
          isHidden: !this.bitmarkConfig.isProUser,
          isDisabled: true
        }, {
          isSeparator: true,
        }, {
          label: this.translate.instant('Reader.Actions.AddBookmark'),
          handler: this.createAnnotationBookmarkOnBit.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.AddFavorite'),
          handler: this.createAnnotationFavoriteOnBit.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.AddNote'),
          handler: this.createAnnotationNoteOnBit.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.AddHandwritten'),
          handler: this.createAnnotationHandwrittenOnBit.bind(this),
        }, {isSeparator: true}, {
          label: this.translate.instant('Shared.Copy'),
          handler: this.copyBitToClipboard.bind(this),
        }, {
          label: this.translate.instant('Shared.Cut'),
          handler: this.cutBitToClipboard.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.CopyLinkToBit'),
          handler: this.copyBitLink.bind(this),
        }, {
          id: 'find-in-book',
          label: this.translate.instant('Reader.Actions.FindInBook'),
          handler: this.findBitInBook.bind(this),
        }, {
          id: 'speech-to-text',
          label: 'Speech to Text',
          handler: this.transcribeMediaBit.bind(this),
        }, {
          id: 'translate',
          label: this.translate.instant('Reader.Translate.TranslateTitle'),
          handler: this.translateBit.bind(this),
        }, {isSeparator: true}, {
          id: 'move-to-bin',
          showOnAltKey: false,
          label: this.translate.instant('Reader.Bin.MoveToBin'),
          handler: this.moveBitToBin.bind(this),
        }, {
          id: 'delete-immediately',
          showOnAltKey: true,
          label: this.translate.instant('Shared.DeleteImmediately'),
          handler: this.deleteBit.bind(this)
        }].filter(x => !!x);
      });

    this.skeletonLoaderActions = [{
      id: 'delete-immediately',
      showOnAltKey: true,
      label: this.translate.instant('Shared.DeleteImmediately'),
      handler: this.stopBitGeneration.bind(this)
    }];

    this.bitAnnotationActions = [{
      id: 'color',
      colors: DefaultBitAnnotationColors,
      handler: this.selectAnnotationColor.bind(this),
    }, {isSeparator: true}, {
      label: this.translate.instant('Shared.Delete'),
      handler: this.deleteAnnotation.bind(this),
    }];

    this.bitEditorActions = [{
      label: this.translate.instant('Reader.Actions.AddChapter'),
      handler: this.createChapterAfterBit.bind(this),
    }, {
      label: this.translate.instant('Reader.Actions.AddBit'),
      isHidden: !this.bitmarkConfig.isProUser,
      handler: this.openBitEditor.bind(this),
    }, {
      id: 'branding-placeholder',
      isHidden: true
    }, {
      isSeparator: true
    }, {
      label: this.translate.instant('Shared.Paste'),
      handler: this.pasteFromClipboard.bind(this),
    }, {
      label: this.translate.instant('Reader.Actions.InsertBasket'),
      handler: this.onInsertBasket.bind(this),
    }, {
      label: this.translate.instant('Shared.PasteSpecial'),
      actions: [{
        label: this.translate.instant('Reader.Actions.PasteQuizletCards'),
        handler: this.pasteFlashcardsFromQuizlet.bind(this)
      }, {
        label: this.translate.instant('Reader.Actions.PasteStepsScribePro'),
        handler: this.pasteStepFromScribePro.bind(this)
      }]
    }, {
      isSeparator: true,
      isHidden: this.context !== 'new-release',
    }, {
      label: this.translate.instant('Reader.Actions.AddAuthorNote'),
      isHidden: this.context !== 'new-release',
      handler: this.createAuthorNote.bind(this)
    }, {
      label: this.translate.instant('Reader.Actions.AddReviewerNote'),
      isHidden: this.context !== 'new-release',
      handler: this.createReviewerNote.bind(this)
    }, {
      isSeparator: true,
      isHidden: !this.bitmarkConfig.isProUser || !this.bitmarkConfig.hasChatGpt,
    }, {
      label: 'ChatGPT',
      isHidden: !this.bitmarkConfig.isProUser || !this.bitmarkConfig.hasChatGpt,
      actions: [{
        label: 'Generate Quizzes for this Chapter',
        handler: this.generateQuizzesForBitChapter.bind(this),
      }, {
        label: 'Generate Quizzes for this Book',
        handler: this.generateQuizzesForBook.bind(this),
      }, {isSeparator: true}, {
        label: 'Generate Summary for this Chapter',
        handler: this.generateSummaryForChapterStream.bind(this),
      }, {
        label: 'Generate Summary for this Book',
        handler: this.generateSummaryForBookStream.bind(this),
      },],
    }, {
      isSeparator: true
    }, {
      label: 'More...',
      actions: [{
        label: this.translate.instant('Shared.UploadPDF'),
        handler: this.insertPdf.bind(this),
      }]
    }];

    this.sub.sink = this.bitbookMqService.onReaderBitSelected()
      .subscribe(this.handleBitSelected.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderRefreshNotebook()
      .subscribe(this.refreshNotebookIfNeeded.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe(this.handleBitsVisibilityChanged.bind(this));
    this.sub.sink = this.bitbookMqService.onInternalLinkClicked()
      .subscribe(this.handleInternalLink.bind(this));

    this.handleNewRelease();

    if (this.bitmarkConfig?.editorMenusFromTheme?.enabled) {
      this.populateAddBitsMenu();
    }

    this.readerLeContentService.loadExpandedBits(this.bitBook.externalId);

    this.subImgClicked = EE.default.on('imgClicked', (x: BitResource) => this.ngZone.run(() => this.onOpenResource(x)));
    this.subBitmarkChanged = EE.default.on('bitmark-changed', (props: { id: string, data: any }) => {
      this.ngZone.run(() => {
        this.saveBitBitmark(props.id, props.data);
      });
    });
    this.subInternalLinkClicked = EE.default.on('internalLinkClicked', ({reference, bitId}: { reference: string, bitId: string }) => {
      this.ngZone.run(() => {
        this.navigateToBitReference(reference, bitId);
      });
    });
    this.subCrossLinkClicked = EE.default.on('crossRefLinkClicked', ({bookId, reference}: { bookId: string, reference: string }) => {
      this.ngZone.run(() => {
        this.navigateToBookBitReference(bookId, reference);
      });
    });

    this.renderPage(this.route.snapshot.fragment);

    // this.idleService.init();
    // this.idleService.idle.subscribe(() => {
    //   this.onScrollDown();
    // this.onScrollUp();
    // });
  }

  ngAfterViewInit() {
    this.initializeMouseUpListener();
    this.initializeUserCopyProtection();
    // this.infiniteScroll.nativeElement.addEventListener('wheel', this.handleScroll.bind(this));
  }

  ngOnDestroy() {
    if (this.scrollEndUnlisten) {
      this.scrollEndUnlisten();
    }
    this.sub.unsubscribe();
    this.removeMouseUpListener();
    EE.default.clear(this.subImgClicked);
    EE.default.clear(this.subBitmarkChanged);
    EE.default.clear(this.subInternalLinkClicked);
    EE.default.clear(this.subCrossLinkClicked);
  }

  private moveBitToBin(dropdownItemModel: DropdownItemModel) {
    const isChapterBit = dropdownItemModel.data.bit.type === BitType.Chapter;
    this.readerBitsBinService.moveToBin(this.bitBook.id, dropdownItemModel.data.id)
      .subscribe(() => {
        this.deleteBitLocally(dropdownItemModel, isChapterBit);
        this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
      }, err => console.error(err));
  }

  private createAuthorNote(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.ReviewAuthorNote);
  }

  private createReviewerNote(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.ReviewReviewerNote);
  }

  private createInfoBit(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.Info);
  }

  private createSideNoteBit(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.SideNote);
  }

  private createRemarkBit(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.Remark);
  }

  private createExampleBit(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.Example);
  }

  private createNoteBit(dropdownItemModel: DropdownItemModel) {
    this.createArticleLikeBit(dropdownItemModel, BitType.Note);
  }

  private createArticleLikeBit(dropdownItemModel: DropdownItemModel, type: BitType) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: ArticleBit = {
      body: '',
      format: BitmarkFormat.PP,
      type: type
    };
    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapper?.id, null, this.globalNotebookApiQueryParams)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        res.bits[0].isBeingEdited = true;
        const editorCreatedSub = this.bitbookMqService.onEditorCreated()
          .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
            this.domUtilsService.waitForDifferentStyleValue(`#bit-${res.bits[0].id}`, 'visibility', 'hidden')
              .subscribe(() => {
                editor.focus();
              });
            editorCreatedSub.unsubscribe();
          });
        this.addBitsToBookContent(res.bits, bitWrapper?.id);
      }, err => console.error(err));
  }

  protected readonly ReaderHiddenElements = ReaderHiddenElements;
}
