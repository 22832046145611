import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../shared/api/api.service';
import {FileUtilsService} from '../shared/utils/file-utils.service';
import {ObjectService} from '../shared';

export interface TaughtClass {
  id: string;
  title: string;
  name: string;
  category: string;
  coverImage: string;
}

declare const randomAvatar: any;

@Injectable({providedIn: 'root'})
export class RolesApiService {
  constructor(private apiService: ApiService,
              private fileUtilsService: FileUtilsService,
              private objectService: ObjectService) {
  }

  getTaughtClasses(): Observable<Array<TaughtClass>> {
    return this.apiService.get('roles/taught-classes', {})
      .pipe(map(classes => classes.map(x => {
        const imageDataUrl = randomAvatar({
          text: '',
          style: 'hex',
          size: 200,
          colorSeed: this.objectService.getHashOfString(x.classId),
          outputType: 'base64',
        });

        return {
          id: x.classId,
          name: x.className,
          category: x.classCategory,
          title: `${x.classCategory}\n${x.className}`,
          coverImage: URL.createObjectURL(this.fileUtilsService.dataURItoBlob(imageDataUrl))
        } as TaughtClass;
      })));
  }
}
