import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {DebounceService} from '../utils/debounce.service';

@Injectable({providedIn: 'root'})
export class DomObserverService {
  constructor(private debounceService: DebounceService) {
  }

  observeMutations(elementSelector: string): Observable<void> {
    return new Observable(x => {
      const readerContentEl = document.querySelector(elementSelector);

      const m = new MutationObserver((mutations) => {
        let imgsToLoadCount = 0;
        let imgsLoadedCount = 0;

        let mutationImages = [];

        mutations?.forEach(mutation => {
          mutation?.addedNodes?.forEach((node: any) => {
            if (typeof node.getElementsByTagName !== 'function') {
              return;
            }

            const imgs = node.getElementsByTagName('img');
            mutationImages = [
              ...mutationImages,
              ...Array.prototype.slice
                .call(imgs)
                .filter(img => img.src && (!img.complete || img.naturalWidth === 0))
            ];
          });
        });
        imgsToLoadCount = mutationImages.length;

        const imgsFound = imgsToLoadCount > 0;

        mutationImages?.forEach((img) => {
          img.addEventListener('load', () => {
            imgsLoadedCount++;
            this.debounceService.debounce(1000, () => {
              if (imgsToLoadCount === imgsLoadedCount) {
                x.next();
                x.complete();
              }
            });
          });
          img.addEventListener('error', () => {
            imgsLoadedCount++;
            this.debounceService.debounce(1000, () => {
              if (imgsToLoadCount === imgsLoadedCount) {
                x.next();
                x.complete();
              }
            });
          });
        });

        if (!imgsFound) {
          x.next();
          x.complete();
        }

        m.disconnect();
      });

      m.observe(readerContentEl, {
        childList: true,
        subtree: true,
        characterData: true
      });
    });
  }

  observeResize(elementSelector: string): Observable<any> {
    return new Observable(x => {
      const readerContentEl = document.querySelector(elementSelector);
      let hasResized = false;
      let resizedTimes = 0;
      const r = new ResizeObserver(() => {
        x.next();
        hasResized = true;
        resizedTimes++;

        setTimeout(() => {
          resizedTimes--;
          if (resizedTimes <= 0) {
            r.disconnect();
          }
        }, 1000);

        setTimeout(() => {
          if (resizedTimes > 0) {
            r.disconnect();
          }
        }, 5000);
      });

      setTimeout(() => {
        if (!hasResized) {
          x.next();
          r.disconnect();
        }
      }, 5000);

      r.observe(readerContentEl, {
        box: 'content-box'
      });
    });
  }

  observeResize2(elementSelector: string): Observable<any> {
    return new Observable(x => {
      const readerContentEl = document.querySelector(elementSelector);
      let hasResized = false;
      let resizedTimes = 0;
      const r = new ResizeObserver(() => {
        hasResized = true;
        resizedTimes++;

        setTimeout(() => {
          resizedTimes--;
          if (resizedTimes <= 0) {
            x.next();
            x.complete();
            r.disconnect();
          }
        }, 1000);

        setTimeout(() => {
          if (resizedTimes > 0) {
            x.next();
            x.complete();
            r.disconnect();
          }
        }, 5000);
      });

      setTimeout(() => {
        if (!hasResized) {
          x.next();
          x.complete();
          r.disconnect();
        }
      }, 5000);

      r.observe(readerContentEl, {
        box: 'content-box'
      });
    });
  }
}
