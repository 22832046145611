import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {RecordAudioBit} from './record-audio.models';
import {BitResource} from '../bits.models';
import {RecordedAudioOutput} from '../../shared/recording/audio-recording.service';
import {BitbookApiService} from '../../reader/bitbook-api.service';
import {BitWordBankService} from '../bit-word-bank.service';

@Component({
  selector: 'bitmark-record-audio',
  templateUrl: './record-audio.component.html',
  styleUrls: ['../bits.scss']
})
export class RecordAudioComponent implements OnInit {
  private _bit?: RecordAudioBit;
  @Input() set bit(value: RecordAudioBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.wordBankItems = this.bitWordBankService.computeWordBank(value.additionalSolutions, value.answer?.usedSolutions);
    }
  }

  get bit(): RecordAudioBit {
    return this._bit;
  }

  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<RecordAudioBit>();

  isUploading = false;
  wordBankItems: Array<{ text: string, used: boolean }> = [];

  private initialized = false;

  constructor(private bitBookApiService: BitbookApiService,
              private bitWordBankService: BitWordBankService) {
  }

  ngOnInit() {
    this.wordBankItems = this.bitWordBankService.computeWordBank(this.bit.additionalSolutions, this.bit.answer?.usedSolutions);

    this.initialized = true;
  }

  toggleWordBankSolution(item: { text: string; used: boolean }) {
    item.used = !item.used;

    const newUsedSolutions = this.bitWordBankService.addOrRemoveSolution(this.bit.answer?.usedSolutions, item.text);
    if (this.bit.answer) {
      this.bit.answer.usedSolutions = newUsedSolutions;
    } else {
      this.bit.answer = {
        usedSolutions: newUsedSolutions
      };
    }

    this.changed.emit();
  }

  recordingStopped(data: RecordedAudioOutput) {
    this.isUploading = true;
    const file = new File([data.blob], data.title);

    this.delayRequest(
      this.bitBookApiService.uploadResource(file),
      (resourceUrl: { url: string }) => {
        this.isUploading = false;
        this.bit.answer = {
          ...this.bit.answer,
          audio: {
            src: resourceUrl.url
          }
        };
        this.changed.emit();
      },
      2000);
  }

  private delayRequest(request: Observable<any>, action: (uploadData: { url: string }) => void, minRequestTime: number) {
    const initialTime = new Date();

    request.subscribe(
      data => {
        const currentTime = new Date();
        const elapsedTime = currentTime.getTime() - initialTime.getTime();

        setTimeout(() => {
          action(data);
        }, Math.max(minRequestTime - elapsedTime, 0));
      },
      (err) => {
        console.error(err);
        window.alert(err);
      });
  }
}
