import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModuleRouted} from './app/app.module.routed';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModuleRouted)
  .catch(err => console.log(err));

// if ('serviceWorker' in navigator && environment.production) {
//   navigator.serviceWorker.register('ngsw-worker.js');
// }

fetch(`assets/svg/icon-sprites.svg?v=${environment.version}`).then();
