import {Observable, Subscriber} from "rxjs";
import {StorageMap} from "@ngx-pwa/local-storage";

export class IndexedDbStorage {
  constructor(private storage: StorageMap) {
  }

  add(key: string, value: any): Observable<any> {
    return new Observable(x => {
      this.getAsArray(key)
        .subscribe((array: Array<any>) => {
          array.push(value);
          this.store(key, array)
            .subscribe(() => {
              x.next();
              x.complete();
            }, err => {
              x.error(err);
              x.complete();
            });
        }, err => {
          x.error(err);
          x.complete();
        });
    });
  }

  remove(key: string, predicate: () => boolean): Observable<any> {
    return new Observable(x => {
      this.getAsArray(key)
        .subscribe((array: any) => {
          const idx = array.findIndex(predicate);
          if (idx !== -1) {
            array.splice(idx, 1);
          }
          this.store(key, array)
            .subscribe(() => {
              x.next();
              x.complete();
            }, err => {
              x.error(err);
              x.complete();
            });
        }, err => {
          x.error(err);
          x.complete();
        });
    });
  }

  store(key: string, value: any): Observable<any> {
    return this.storage.set(key, value);
  }

  get(key: string): Observable<any | Array<any>> {
    return this.storage.get(key);
  }

  getAsArray(key: string): Observable<Array<any>> {
    return new Observable((o: Subscriber<Array<any>>) => {
      this.storage.get(key)
        .subscribe((array: any | Array<any>) => {
          if (!array) {
            array = [];
          }
          if (!(array instanceof Array)) {
            array = [array];
          }
          o.next(array);
          o.complete();
        }, err => {
          o.error(err);
          o.complete();
        });
    });
  }

  count(key: string): Observable<number> {
    return new Observable(x => {
      this.getAsArray(key)
        .subscribe((array: Array<any>) => {
          x.next(array.length);
          x.complete();
        }, err => {
          x.error(err);
          x.complete();
        });
    });
  }

  clear(key?: string): Observable<any> {
    return new Observable(x => {
      if (!key) {
        this.storage.clear()
          .subscribe(() => {
            x.next();
            x.complete();
          }, err => {
            x.error(err);
            x.complete();
          });
      } else {
        this.storage.delete(key)
          .subscribe(() => {
            x.next();
            x.complete();
          }, err => {
            x.error(err);
            x.complete();
          });
      }
    });
  }

  check(): Observable<{ isSupported: boolean, quota?: number, usage?: number }> {
    return new Observable(x => {
      this.storage.set("test", "test")
        .subscribe(() => {
          this.storage.delete("test");
          if (navigator.storage && navigator.storage.estimate) {
            navigator.storage.estimate().then(quota => {
              x.next({isSupported: true, quota: quota.quota, usage: quota.usage});
            }, () => {
              x.next({isSupported: true});
            });
          } else {
            x.next({isSupported: true});
          }
          x.complete();
        }, () => {
          x.next({isSupported: false});
          x.complete();
        });
    });
  }
}
