import {Mark, mergeAttributes} from '@tiptap/core';
import {MarkedOptions} from '../marked/marked.node';

export const UserHighlight = Mark.create<MarkedOptions>({
  name: 'mark',

  addOptions() {
    return {
      HTMLAttributes: {
        style: '',
        'data-color': ''
      },
    };
  },

  addAttributes() {
    return {
      // color: {
      //   default: '#fffb81',
      //   parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
      //   renderHTML: attributes => {
      //     if (!attributes.color) {
      //       return {};
      //     }
      //
      //     return {
      //       'data-color': attributes.color,
      //       style: `background-color: ${attributes.color}`,
      //     };
      //   },
      // },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mark[class="user-highlight"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const customAttributes = {
      class: 'bitmark-text-user-highlight',
      'data-highlighted': true
    };
    return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
  }
});
