import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export default class UrlService {
  isValid(url: string): boolean {
    if (!url) {
      return false;
    }

    // take from https://github.com/adamgibbons/ics/blob/master/src/schema/index.js
    const urlRegex = /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

    return urlRegex.test(url);
  }

  isValidInternalOrExternal(link: string): boolean {
    if (link.startsWith('/')) {
      try {
        new URL(link, window.location.origin);
        return true;
      } catch {
        return false;
      }
    }

    const acceptedProtocols = ['http:', 'https:'];

    try {
      const url = new URL(link);

      return acceptedProtocols.includes(url.protocol);
    } catch {
      return false;
    }
  }

  getHostName(link: string): string {
    if (link.startsWith('/')) {
      try {
        const url = new URL(link, window.location.origin);
        return url.hostname;
      } catch {
        return null;
      }
    }

    try {
      const url = new URL(link);

      return url.hostname;
    } catch {
      return null;
    }
  }
}
