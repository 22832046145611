export * from './bit-wrapper/bit-wrapper.component';
import {BitWrapperComponent} from './bit-wrapper/bit-wrapper.component';

export * from './reader-editor/json-editor/json-editor.component';
import {ReaderJsonEditorComponent} from './reader-editor/json-editor/json-editor.component';

export * from './reader-editor/html-translator/html-translator.component';
import {ReaderHtmlTranslatorComponent} from './reader-editor/html-translator/html-translator.component';

export * from './reader-editor/bit-editor/bit-editor.component';
import {ReaderBitEditorComponent} from './reader-editor/bit-editor/bit-editor.component';

export * from './reader-book/reader-book.component';
import {ReaderBookComponent} from './reader-book/reader-book.component';

export * from './reader-book/reader-toc-sidebar/reader-toc-sidebar.component';
import {ReaderTocSidebarComponent} from './reader-book/reader-toc-sidebar/reader-toc-sidebar.component';

export * from './reader-book/reader-search-sidebar/reader-search-sidebar.component';
import {ReaderSearchSidebarComponent} from './reader-book/reader-search-sidebar/reader-search-sidebar.component';

export * from './reader-book/reader-header/reader-header.component';
import {ReaderHeaderComponent} from './reader-book/reader-header/reader-header.component';

export * from './reader-book/reader-search-header/reader-search-header.component';
import {ReaderSearchHeaderComponent} from './reader-book/reader-search-header/reader-search-header.component';

export * from './reader-book/reader-content/reader-content.component';
import {ReaderContentComponent} from './reader-book/reader-content/reader-content.component';

export * from './reader-basket/reader-basket.component';
import {ReaderBasketComponent} from './reader-basket/reader-basket.component';

export * from './reader-bin/reader-notebook-bin/reader-notebook-bin.component';
import {ReaderNotebookBinComponent} from './reader-bin/reader-notebook-bin/reader-notebook-bin.component';

export * from './reader-book-invite/reader-book-invite.component';
import {ReaderBookInviteComponent} from './reader-book-invite/reader-book-invite.component';

export * from './reader-book/reader-search-box/reader-search-box.component';
import {ReaderSearchBoxComponent} from './reader-book/reader-search-box/reader-search-box.component';

export * from './reader-book/reader-progress/reader-progress.component';
import {ReaderProgressComponent} from './reader-book/reader-progress/reader-progress.component';

export * from './reader-book/reader-content/reader-content-common.component';
import { ReaderContentCommonComponent } from './reader-book/reader-content/reader-content-common.component';

export * from './reader-book/reader-content/reader-content-learning-path-common.component';
import {ReaderContentLearningPathCommonComponent} from './reader-book/reader-content/reader-content-learning-path-common.component';

export * from './reader-book/reader-content/reader-content-book/reader-content-book.component';
import {ReaderContentBookComponent} from './reader-book/reader-content/reader-content-book/reader-content-book.component';

export * from './reader-book/reader-content/reader-content-notebook/reader-content-notebook.component';
import {ReaderContentNotebookComponent} from './reader-book/reader-content/reader-content-notebook/reader-content-notebook.component';

export * from './reader-book/reader-content/reader-content-self-learning/reader-content-self-learning.component';
import {ReaderContentSelfLearningComponent} from './reader-book/reader-content/reader-content-self-learning/reader-content-self-learning.component';

export * from './reader-book/reader-content/reader-content-shop/reader-content-shop.component';
import {ReaderContentShopComponent} from './reader-book/reader-content/reader-content-shop/reader-content-shop.component';

export * from './reader-book/reader-content/reader-content-search/reader-content-search.component';
import {ReaderContentSearchComponent} from './reader-book/reader-content/reader-content-search/reader-content-search.component';

export * from './reader-book/reader-content/reader-content-learning-events/reader-content-learning-events.component';
import {ReaderContentLearningEventsComponent} from './reader-book/reader-content/reader-content-learning-events/reader-content-learning-events.component';

export * from './reader-book/reader-content/reader-content-learning-paths/reader-content-learning-paths.component';
import {ReaderContentLearningPathsComponent} from './reader-book/reader-content/reader-content-learning-paths/reader-content-learning-paths.component';

export * from './reader-book/reader-content/reader-content-notebook-learning-path/reader-content-notebook-learning-path.component';
import {ReaderContentNotebookLearningPathComponent} from './reader-book/reader-content/reader-content-notebook-learning-path/reader-content-notebook-learning-path.component';

export * from './reader-book/reader-content/reader-content-draft-book/reader-content-draft-book.component';
import {ReaderContentDraftBookComponent} from './reader-book/reader-content/reader-content-draft-book/reader-content-draft-book.component';

export * from './reader-basket/reader-content-basket/reader-content-basket.component';
import {ReaderContentBasketComponent} from './reader-basket/reader-content-basket/reader-content-basket.component';

export * from './reader-book/reader-content/reader-content-flashcards/reader-content-flashcards.component';
import {ReaderContentFlashcardsComponent} from './reader-book/reader-content/reader-content-flashcards/reader-content-flashcards.component';

export * from './reader-editor/pdf-upload/pdf-upload.component';
import {ReaderPdfUploadComponent} from './reader-editor/pdf-upload/pdf-upload.component';

export * from './reader-editor/reader-text-editor/reader-text-editor.component';
import {ReaderTextEditorComponent} from './reader-editor/reader-text-editor/reader-text-editor.component';

export * from './reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';
import {ReaderSimpleTextEditorComponent} from './reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';

export * from './reader-editor/code-editor/reader-code-editor.component';
import {ReaderCodeEditorComponent} from './reader-editor/code-editor/reader-code-editor.component';

export * from './reader-editor/monaco-code-editor/monaco-code-editor-component';
import {ReaderMonacoCodeEditorComponent} from './reader-editor/monaco-code-editor/monaco-code-editor-component';

export const components = [
  BitWrapperComponent,
  ReaderBookComponent,
  ReaderContentComponent,
  ReaderBasketComponent,
  ReaderNotebookBinComponent,
  ReaderHeaderComponent,
  ReaderSearchHeaderComponent,
  ReaderContentCommonComponent,
  ReaderContentLearningPathCommonComponent,
  ReaderContentBookComponent,
  ReaderContentNotebookComponent,
  ReaderContentSelfLearningComponent,
  ReaderContentShopComponent,
  ReaderContentSearchComponent,
  ReaderContentLearningEventsComponent,
  ReaderContentLearningPathsComponent,
  ReaderContentNotebookLearningPathComponent,
  ReaderContentFlashcardsComponent,
  ReaderContentDraftBookComponent,
  ReaderContentBasketComponent,
  ReaderSearchSidebarComponent,
  ReaderTocSidebarComponent,
  ReaderBookInviteComponent,
  ReaderSearchBoxComponent,
  ReaderProgressComponent,
  ReaderPdfUploadComponent,
  ReaderTextEditorComponent,
  ReaderSimpleTextEditorComponent,
  ReaderCodeEditorComponent,
  ReaderMonacoCodeEditorComponent,
  ReaderBitEditorComponent,
  ReaderJsonEditorComponent,
  ReaderHtmlTranslatorComponent
];
