// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-loading {
  margin: 0 auto;
  width: 48px;
  height: 48px;
}

@keyframes spin {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes wax-and-wain {
  to {
    stroke-dashoffset: 15;
    transform: rotate(360deg);
  }
}
.svg-loading {
  cursor: default;
}
.svg-loading svg {
  transform-origin: center;
  animation-name: spin;
  animation-duration: 1925ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.svg-loading svg circle {
  fill: transparent;
  stroke: #543199;
  stroke-width: 3px;
  stroke-dasharray: 50, 1000;
  stroke-dashoffset: 15;
  transform-origin: center;
  animation-name: wax-and-wain;
  animation-duration: 4350ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
