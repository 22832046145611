import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ImageResponsiveBit} from './image-responsive.models';
import {BrandingRenderService} from '../../reader/branding-render.service';
import {BitApiWrapper, BitResource, BitResourceImage, BitResourceSize, BitResourceType} from '../bits.models';
import {SubSink} from '../../shared';

@Component({
  selector: 'bitmark-image-responsive',
  templateUrl: './image-responsive.component.html',
  styleUrls: ['../bits.scss']
})
export class ImageResponsiveComponent implements OnInit, OnDestroy {
  @Input() bit?: ImageResponsiveBit;
  @Input() bitWrapper?: BitApiWrapper;
  @Output() openResource = new EventEmitter<BitResource>();

  drawnImage: BitResourceImage;
  isImageZoomable = false;

  private sub = new SubSink();

  constructor(private brandingRenderService: BrandingRenderService) {
  }

  ngOnInit() {
    const imgLandscape = this.bit?.resource?.imageLandscape;
    const imgPortrait = this.bit?.resource?.imagePortrait;

    if (imgPortrait?.src && imgPortrait?.width && imgLandscape?.src && imgLandscape?.width) {
      this.sub.sink = this.brandingRenderService.getBitThemeBranding(this.bitWrapper).subscribe(data => {
        if (!data?.themeBranding && !data?.themeId) {
          return;
        }

        let bitWidthPx = data.themeBranding?.imageResponsive?.size?.width || data.themeBranding?.bit?.size?.width || data.themeBranding?.readerContentWidth || '';
        bitWidthPx = bitWidthPx.includes('%') ? '' : bitWidthPx.replace('px', '').trim();
        const bitWidth = bitWidthPx ? Number(bitWidthPx) : Infinity;

        const windowWidth = document.querySelector('.reader-content .bits-wrapper')?.clientWidth || window.innerWidth;

        this.drawnImage = this.findLeastScalingDown([imgLandscape, imgPortrait], Math.min(bitWidth, windowWidth));
      });
    } else {
      this.drawnImage = imgPortrait?.src ? imgPortrait : imgLandscape;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private findLeastScalingDown(imageList: BitResourceImage[], containerWidth: number): BitResourceImage | null {
    let minScalingFactor = Infinity;
    let maxImageWidth = 0;
    let bestFitImage: BitResourceImage | null = null;
    let largestImage: BitResourceImage | null = null;

    imageList.forEach(image => {
      const imageWidth = image.width;
      const scalingFactor = containerWidth / imageWidth;

      if (scalingFactor <= 1) { // scale down
        if (1 - scalingFactor < minScalingFactor) {
          minScalingFactor = 1 - scalingFactor;
          bestFitImage = image;
        }
      }

      if (imageWidth > maxImageWidth) {
        maxImageWidth = imageWidth;
        largestImage = image;
      }
    });

    return bestFitImage || largestImage;
  }

  onImageResourceClicked() {
    if (!this.isImageZoomable) {
      return;
    }
    this.openResource.emit({type: BitResourceType.Image, image: this.drawnImage});
  }

  onImageResourceMeasured(size: BitResourceSize) {
    this.isImageZoomable = !size.zoomDisabled;
  }
}
