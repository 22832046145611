import {Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {User} from 'core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContainerComponent} from 'shared';

@Component({
  selector: 'app-user-box-simple',
  templateUrl: './user-box-simple.component.html',
  styleUrls: ['./user-box-simple.component.scss']
})
export class UserBoxSimpleComponent extends ContainerComponent implements OnDestroy {
  @Input() user: User;
  @Input() photoSize;
  @Input() dotSize = '';
  @Input() canOpenAvatar?: boolean;
  private subSocket: Subscription;

  @ViewChild('avatar') avatarTemplate: ElementRef;

  constructor(private ngbModal: NgbModal) {
    super();
  }

  // ngOnInit() {
  //   if (this.subSocket) {
  //     return;
  //   }
  //   let heartbeatTimer = null;
  //   this.subSocket = this.socketIoService.event('timeline')
  //     .pipe(filter(socketEvt => socketEvt.senderUserId === this.user.id))
  //     .subscribe((socketEvt: SocketEvent) => {
  //       if (socketEvt.messageType === SocketMessageType.UserAvailability) {
  //         this.user.isAvailable = socketEvt.payload;
  //       } else if (socketEvt.messageType === SocketMessageType.UserHeartbeat) {
  //         this.user.isActive = true;
  //         if (heartbeatTimer) {
  //           clearTimeout(heartbeatTimer);
  //         }
  //         heartbeatTimer = setTimeout(() => {
  //           this.user.isActive = false;
  //           clearTimeout(heartbeatTimer);
  //           heartbeatTimer = null;
  //         }, environment.heartBeatInterval + 1000);
  //       }
  //     });
  // }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subSocket) {
      this.subSocket.unsubscribe();
    }
  }

  openAvatar() {
    this.ngbModal.open(this.avatarTemplate, {
      windowClass: 'dark-modal lg',
      backdropClass: 'violet-backdrop',
      backdrop: 'static',
      keyboard: false,
      animation: false
    });
  }
}
