import {Component, Input} from '@angular/core';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {BitmarkPipe} from '../bitmark.pipe';
import {BitmarkTextContentService, UrlRewriteService} from '../../shared';
import {ReaderTipTapTapService} from '../../reader/tiptap/reader-tiptap.service';
import {BaseBit} from '../bits.models';
import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from '@angular/animations';
import {ReaderLeContentService} from '../../reader/reader-book/reader-content/reader-le-content.service';

export const toggleAnimations: Array<AnimationTriggerMetadata> = [
  trigger('slideToggle', [
    state('collapsed', style({height: '0'})),
    state('expanded', style({height: '*'})),
    transition('collapsed => expanded', animate('500ms ease-in-out', style({height: '*'}))),
    transition('expanded => collapsed', animate('500ms ease-in-out', style({height: '0'})))
  ]),
  trigger('slideToggleIsDone', [
    state('collapsed', style({height: '0'})),
    state('expanded', style({height: '*'})),
    transition('collapsed => expanded', animate('500ms ease-in-out', style({height: '*'}))),
    transition('expanded => collapsed', animate('500ms ease-in-out', style({height: '0'})))
  ])
];

@Component({
  template: '',
})
export class LearningEventCommon {
  @Input() bookId?: string;

  isBodyCollapsed = true;
  toggleState: 'collapsed' | 'expanded' = 'collapsed';
  toggleStateIsDone: 'collapsed' | 'expanded' = 'expanded';
  imageExists = false;

  constructor(protected readerTipTapService: ReaderTipTapTapService,
                        protected bitmarkTextContentService: BitmarkTextContentService,
                        protected readerLeContentService: ReaderLeContentService) {
  }

  initExpandedBit(bitId: string, isDone = false) {
    if (this.readerLeContentService.isBitExpanded(this.bookId, bitId)) {
      this.toggleBodyLogic(isDone);
    }
  }

  handleImage(bit: BaseBit) {
    this.imageExists = !!(bit.resource && bit.resource.image && bit.resource.image.src);
  }

  handleImageLoadError() {
    this.imageExists = false;
  }

  toggleBody(bitId: string, isDone = false) {
    this.toggleBodyLogic(isDone);

    this.readerLeContentService.toggleBitExpandState(this.bookId, bitId, !this.isBodyCollapsed);
  }

  private toggleBodyLogic(isDone = false) {
    if (isDone) {
      this.toggleStateIsDone = this.toggleStateIsDone === 'collapsed' ? 'expanded' : 'collapsed';
      this.toggleState = 'expanded';
    } else {
      this.toggleStateIsDone = 'expanded';
      this.toggleState = this.toggleState === 'collapsed' ? 'expanded' : 'collapsed';
    }
    this.isBodyCollapsed = !this.isBodyCollapsed;
  }

  getTextFromJson(json: any, format: BitmarkFormat): string {
    if (typeof json !== 'object') {
      return json;
    }

    const html = new BitmarkPipe(this.readerTipTapService, new UrlRewriteService()).transform(json, this.bitmarkTextContentService.getBodyFormat(json, format), 'html') as string;

    const element = document.createElement('DIV');
    element.innerHTML = html;

    return element.innerText || element.textContent;
  }
}
