import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {interval, Observable, Subject, Subscription, switchMap} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {RecordVideoBit} from './record-video.models';
import {BitResource} from '../bits.models';
import {RecordedVideoOutput} from '../../shared/recording/video-recording.service';
import {BitbookApiService} from '../../reader/bitbook-api.service';
import {BitWordBankService} from '../bit-word-bank.service';

@Component({
  selector: 'bitmark-record-video',
  templateUrl: './record-video.component.html',
  styleUrls: ['../bits.scss']
})
export class RecordVideoComponent implements OnInit, OnDestroy {
  private _bit?: RecordVideoBit;
  @Input() set bit(value: RecordVideoBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.wordBankItems = this.bitWordBankService.computeWordBank(value.additionalSolutions, value.answer?.usedSolutions);
    }
  }
  get bit(): RecordVideoBit {
    return this._bit;
  }
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<Subject<RecordVideoBit>>();

  isUploading = false;
  isProcessingRequest: boolean;
  wordBankItems: Array<{ text: string, used: boolean }> = [];

  private initialized = false;
  private documentPollingRetries = 0;
  private stopPolling = new Subject();
  private timeIntervalDocumentPolling: Subscription;
  private videoSaved = new Subject<RecordVideoBit>();

  constructor(private bitBookApiService: BitbookApiService,
              private bitWordBankService: BitWordBankService) {
  }

  ngOnInit() {
    this.wordBankItems = this.bitWordBankService.computeWordBank(this.bit.additionalSolutions, this.bit.answer?.usedSolutions);

    this.videoSaved.subscribe((newBit) => {
      if (this.isUploading || this.isProcessingRequest) {
        this.isUploading = false;
        this.isProcessingRequest = false;

        this.bit.answer = {...newBit.answer};
      }
    });

    this.initialized = true;
  }

  ngOnDestroy() {
    this.timeIntervalDocumentPolling?.unsubscribe();
    this.videoSaved.unsubscribe();
  }

  toggleWordBankSolution(item: { text: string; used: boolean }) {
    item.used = !item.used;

    const newUsedSolutions = this.bitWordBankService.addOrRemoveSolution(this.bit.answer?.usedSolutions, item.text);
    if (this.bit.answer) {
      this.bit.answer.usedSolutions = newUsedSolutions;
    } else {
      this.bit.answer = {
        usedSolutions: newUsedSolutions
      };
    }

    this.changed.emit();
  }

  recordingStopped(data: RecordedVideoOutput) {
    this.isUploading = true;
    const file = data.file || new File([data.blob], data.title, {type: data.blob.type});

    this.delayRequest(
      this.bitBookApiService.uploadResource(file),
      (uploadData: { url: string, jobId: string }) => {
        if (uploadData.jobId) {
          this.pollServerForJob(uploadData.jobId, this.checkVideoConvertJobStatus.bind(this), 4000);
        } else {
          this.bit.answer = {
            ...this.bit.answer,
            video: {
              src: uploadData.url
            }
          };
          this.changed.emit(this.videoSaved);
        }
      },
      2000);
  }

  recordingCancelled() {
    this.isUploading = false;
    this.isProcessingRequest = false;
  }

  private checkVideoConvertJobStatus(jobId: string): Observable<any> {
    this.documentPollingRetries++;

    if (this.documentPollingRetries >= 75) { // wait for 5 minutes for the conversion
      this.isProcessingRequest = false;
      this.stopPolling.next(true);
    }

    return new Observable(x => {
      this.bitBookApiService.getResourceJobStatus(jobId).subscribe((jobData) => {
        if (jobData.status !== 'in-progress' && jobData.status !== 'created') {
          this.stopPolling.next(true);
          this.documentPollingRetries = 0;

          this.bit.answer = {
            ...this.bit.answer,
            video: {
              src: jobData.outcome?.link || jobData.link
            }
          };
          this.changed.emit(this.videoSaved);
        }
        x.next();
      }, (err) => x.error(err));
    });
  }

  private pollServerForJob(jobId: string, callback: (jobId: string) => Observable<any>, timeInterval = 2000) {
    this.timeIntervalDocumentPolling = interval(timeInterval)
      .pipe(
        startWith(0),
        switchMap(() => callback(jobId)),
        takeUntil(this.stopPolling)
      ).subscribe(() => {
      }, err => {
        console.log(err);
      });
  }

  private delayRequest(request: Observable<any>, action: (uploadData: { url: string }) => void, minRequestTime: number) {
    const initialTime = new Date();

    request.subscribe(
      data => {
        const currentTime = new Date();
        const elapsedTime = currentTime.getTime() - initialTime.getTime();

        setTimeout(() => {
          action(data);
        }, Math.max(minRequestTime - elapsedTime, 0));
      },
      (err) => {
        console.error(err);
        window.alert(err);
      });
  }
}
