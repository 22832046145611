import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, tap} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {BitbookMqService} from '../../bitbook-mq.service';
import {ReaderTocService} from '../../reader-toc.service';
import {SubSink} from "../../../shared";
import {ReaderContentService} from '../reader-content/reader-content.service';
import {BitsViewPortVisibility} from "../../../bits/bits.models";

@Component({
  selector: 'bitmark-reader-progress',
  templateUrl: './reader-progress.component.html',
  styleUrls: ['reader-progress.component.scss']
})
export class ReaderProgressComponent implements OnInit, OnDestroy {
  @Input() bookExternalId: string;
  @Input() isWorking: boolean;

  private sub = new SubSink();
  totalBits: number;
  private lastEnteredBitProgressPercent: number;
  progressPercent: number;

  constructor(private bitbookMqService: BitbookMqService,
              private readerTocService: ReaderTocService,
              private readerContentService: ReaderContentService) {
  }

  ngOnInit() {
    // this.sub.sink = this.bitbookMqService.onReaderTocHasReloaded()
    //   .subscribe(this.updateProgress.bind(this));
    // this.sub.sink = this.bitbookMqService.onBitVisibilityChanged()
    //   .subscribe(this.updateProgress.bind(this));
    // setTimeout(() => {
    //   this.readerTrackingService.getLastPositionForBook(this.bookExternalId)
    //     .subscribe((lastPos: BookPosition) => {
    //       if (lastPos?.index >= -1) {
    //         this.updateProgress({bitIndex: lastPos.index, bitId: lastPos.bitId, isInViewport: true});
    //       }
    //     });
    // }, 500);
    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .pipe(distinctUntilChanged())
      .subscribe(this.updateProgress1.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsScrollVisibility()
      .pipe(distinctUntilChanged())
      .subscribe(this.updateProgress1.bind(this));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateProgress(event: { bitIndex: number, bitId: string, isInViewport: boolean }) {
    this.getTotalBits()
      .subscribe((totalBits: number) => {
        if (event.isInViewport) {
          this.lastEnteredBitProgressPercent = Math.floor(((event.bitIndex + 1) / totalBits) * 100);
        }
        if (event.bitId === 'end' && !event.isInViewport) {
          this.progressPercent = this.lastEnteredBitProgressPercent;
          return;
        }
        if (event.bitId === 'start' && !event.isInViewport) {
          this.progressPercent = this.lastEnteredBitProgressPercent;
          return;
        }
        if (this.readerContentService.isBitInViewport('end')) {
          this.progressPercent = 100;
          return;
        }
        if (this.readerContentService.isBitInViewport('start')) {
          this.progressPercent = 0;
          return;
        }
        this.progressPercent = this.lastEnteredBitProgressPercent;
      });
  }

  private getTotalBits(): Observable<number> {
    return !this.bookExternalId
      ? of(0)
      : this.totalBits
        ? of(this.totalBits)
        : this.readerTocService.getTocForBook(this.bookExternalId)
          .pipe(map((bookData: Array<any>) => bookData?.length + 1))
          .pipe(tap((totalBits: number) => this.totalBits = totalBits));
  }

  updateProgress1(visibleBits: BitsViewPortVisibility) {
    const lastVisibleBit = visibleBits.bitsWithContentInViewPort[visibleBits.bitsWithContentInViewPort?.length - 1]
      || visibleBits.bitsTouchingViewPort[visibleBits.bitsTouchingViewPort.length - 1];
    if (!lastVisibleBit) {
      return;
    }
    this.getTotalBits()
      .subscribe((totalBits: number) => {
        if (lastVisibleBit.id === 'end') {
          this.progressPercent = 100;
          return;
        }
        if (lastVisibleBit.id === 'start') {
          this.progressPercent = 0;
          return;
        }
        this.lastEnteredBitProgressPercent = Math.floor(((lastVisibleBit.index + 1) / totalBits) * 100);
        this.progressPercent = this.lastEnteredBitProgressPercent;
      });
  }
}
