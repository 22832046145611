import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BitResource} from '../bits.models';
import {CorrectionBit} from './correction.models';

@Component({
    selector: 'bitmark-correction',
    templateUrl: './correction.component.html',
    styleUrls: ['../bits.scss']
})
export class CorrectionComponent {
    @Input() bit?: CorrectionBit;
    @Output() openResource = new EventEmitter<BitResource>();
}
