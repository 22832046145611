import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {iframely} from '@iframely/embed.js';
import {IFramelyEmbedBit} from './iframely-embed.models';

@Component({
  selector: 'bitmark-iframely-embed',
  templateUrl: './iframely-embed.component.html',
  styleUrls: ['./iframely-embed.component.scss']
})
export class IframelyEmbedComponent implements OnInit {
  @Input() bit?: IFramelyEmbedBit;
  @Output() onIsBeingEdited = new EventEmitter<any>();

  iframeSrc: SafeResourceUrl;

  constructor(private http: HttpClient,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (!this.bit.iframeData?.url) {
      this.http.get(`https://iframe.ly/api/iframely?url=${this.bit.vendorUrl}&api_key=5fcf9e2ff1344d18e11a17&omit_css=1&card=1&card=small&click_to_play=1`)
        .subscribe((data: any) => {
          if (data.html) {
            const iframelyIframeSrc = this.getIframeData(data.html);

            this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(iframelyIframeSrc);

            this.bit.iframeData = {
              url: iframelyIframeSrc
            };
            this.onIsBeingEdited.emit({id: this.bit.id, iframeData: this.bit.iframeData});
          }
        }, (error) => {
          console.error(error);
        });
    } else {
      this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.bit.iframeData.url);
    }

    if (!iframely._loaded) {
      iframely.load();
    }
  }

  private getIframeData(html: string): string {
    const el = document.createElement('html');
    el.innerHTML = `<html><body>${html}</body></html>`;

    this.detectIframelyHtmlChanges(el);

    const iframeElem = el.querySelector('iframe');
    if (iframeElem?.src) {
      return iframeElem.src;
    }

    const anchorElem = el.querySelector('a');
    if (anchorElem?.href) {
      return anchorElem.attributes['data-iframely-url']?.value;
    }

    return '';
  }

  private detectIframelyHtmlChanges(elem: HTMLElement) {
    if (!elem.querySelector('.iframely-card.iframely-embed')) {
      console.error('IFramely HTML change detected');
    }

    if (!elem.querySelector('.iframely-responsive')) {
      console.error('IFramely HTML change detected');
    }
  }
}
