import {Underline} from '@tiptap/extension-underline';
import {mergeAttributes} from '@tiptap/core';

export const UserUnderline = Underline.extend({
  name: 'userUnderline',
  renderHTML({HTMLAttributes}) {
    const customAttributes = {
      class: 'bitmark-text-user-underline'
    };
    return ['u', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
  },
});
