// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot-manage {
  background-color: #ff8000 !important;
}

.info-label {
  min-width: 130px;
  color: #656769;
}

.orange-editable-field {
  border: 1px solid #ff8000;
  border-radius: 8px;
  min-width: 250px;
  margin-left: -8px;
  padding: 0 7px 0 7px;
}

.translated-label {
  border: 1px solid #000000;
}

.translated-label-icon {
  height: 24px;
  cursor: pointer;
}

.orange {
  background-color: #ff8000;
  border: none;
}

.user-list-dot-dropdown {
  max-height: 30px;
  position: relative;
  top: -2px;
}

.user-dot-dropdown {
  max-height: 30px;
  align-items: center;
  display: flex !important;
  position: relative;
  top: -2px;
  left: 10px;
}

.avatar-photo {
  width: 22px;
  border-radius: 50%;
  height: 22px;
}

.emoji-menu {
  z-index: 1000;
  background-color: white;
  border-radius: 5px;
  padding: 6px 3px;
}
.emoji-menu .emoji-item {
  background-color: transparent;
  border-radius: 5px;
  font-size: 20px;
  line-height: 1;
  padding: 3px;
  margin: 0 3px;
  border: none;
}
.emoji-menu .emoji-item.is-active-emoji {
  background-color: #361f64 !important;
}
.emoji-menu .emoji-item.is-active-emoji {
  border: 2px solid #361f64 !important;
}

.text-green {
  color: #7ffd00 !important;
}

.text-red {
  color: #ff1644 !important;
}

.text-gradient {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  -webkit-background-clip: text;
  color: transparent;
  background-color: #AC0;
  background-image: linear-gradient(45deg, #81ff00 10.42%, #9b67ff 10.42%, #9b67ff 50%, #81ff00 50%, #81ff00 60.42%, #9b67ff 60.42%, #9b67ff 100%);
  background-size: 33.94px 33.94px;
}

[contenteditable=true]:empty:not(:focus)::before {
  content: attr(data-placeholder);
  color: #808080; /* Placeholder text color */
  pointer-events: none; /* Prevents the placeholder from being selectable */
  display: block; /* Make the pseudo-element behave like a block element */
}

::ng-deep .book-information .user-highlight {
  background-color: #777702;
}

@media (max-width: 680px) {
  .avatar-container {
    margin-right: 10px;
    overflow: hidden;
    width: 30px;
    display: inline-block;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
