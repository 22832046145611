// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  width: 50px;
  height: 50px;
  font-size: 27px;
  font-weight: 300;
  color: #000000;
  border-radius: 50% !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.is-group {
  width: 100%;
  aspect-ratio: 1;
  --g: / 20.56% 20.56% radial-gradient(#000 calc(71% - 1px), #0000 71%) no-repeat;
  -webkit-mask: 100% 50% var(--g), 93.301% 75% var(--g), 75% 93.301% var(--g), 50% 100% var(--g), 25% 93.301% var(--g), 6.699% 75% var(--g), 0% 50% var(--g), 6.699% 25% var(--g), 25% 6.699% var(--g), 50% 0% var(--g), 75% 6.699% var(--g), 93.301% 25% var(--g), radial-gradient(100% 100%, #000 38.366%, rgba(0, 0, 0, 0) calc(38.366% + 1px));
          mask: 100% 50% var(--g), 93.301% 75% var(--g), 75% 93.301% var(--g), 50% 100% var(--g), 25% 93.301% var(--g), 6.699% 75% var(--g), 0% 50% var(--g), 6.699% 25% var(--g), 25% 6.699% var(--g), 50% 0% var(--g), 75% 6.699% var(--g), 93.301% 25% var(--g), radial-gradient(100% 100%, #000 38.366%, rgba(0, 0, 0, 0) calc(38.366% + 1px));
}
.avatar.fit {
  width: 100%;
  height: 100%;
  font-size: 1em;
}
.avatar.sm {
  width: 25px;
  height: 25px;
  font-size: 16px;
}
.avatar.md {
  width: 50px;
  height: 50px;
}
.avatar.lg {
  width: 70px;
  height: 70px;
}
.avatar.xl {
  width: 110px;
  height: 110px;
}
.avatar.xxl {
  width: 320px;
  height: 320px;
}
.avatar .fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #9b9b9b;
}
.avatar img {
  text-indent: -10000px;
  width: 100%;
  height: 100%;
  margin: 3px;
}
.avatar.fit img {
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
