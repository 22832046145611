// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  border-radius: 0;
  box-shadow: 0 0 13px -2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.toc-header-gradient {
  background: #000000;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(52, 30, 95) 50%, rgb(0, 0, 0) 100%);
}

.toc-header {
  line-height: 1;
}
.toc-header .badge {
  background-color: #543199;
  color: #ffffff;
  border-radius: 20px;
}
.toc-header bitmark-icon {
  color: #a98ae6;
}

.toc-chapter-selector-section {
  box-shadow: 0 6px 7px -5px rgba(0, 0, 0, 0.1);
}
.toc-chapter-selector-section .toc-chapter-selector-wrapper {
  border: 1px solid #543199;
  border-radius: 0.25rem;
}
.toc-chapter-selector-section .toc-chapter-selector-wrapper .toc-chapter-selector {
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.toc-chapter-selector-section .toc-chapter-selector-wrapper .toc-chapter-selector:active, .toc-chapter-selector-section .toc-chapter-selector-wrapper .toc-chapter-selector:focus {
  box-shadow: none;
}
.toc-chapter-selector-section .toc-chapter-selector-wrapper .toc-chapter-selector:read-only {
  opacity: 1;
}
.toc-chapter-selector-section .toc-chapter-selector-wrapper bitmark-icon {
  color: #a98ae6;
}

::ng-deep .toc-chapter-selector-section .reader-toc-dropdown {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.reader-shadow {
  box-shadow: 0 0 13px -2px rgba(0, 0, 0, 0.2);
}

.h5 {
  font-weight: normal;
}

.toc-chapter > div {
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 1.5;
}
.toc-chapter:not(.lvl1) .toc-chapter-content {
  margin-bottom: 4px;
  border-top: 0.5px solid rgb(0, 0, 0);
  border-bottom: 0.5px solid rgb(0, 0, 0);
}

.toc-bit {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 1.2;
}
.toc-bit.toc-bit-margin {
  padding-bottom: 4px;
  padding-top: 4px;
}
.toc-bit.hidden-hack {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.toc-bit .toc-annotation-icon {
  margin-right: 4px;
  position: relative;
  height: 16px;
  top: -4px;
}

:host ::ng-deep .toc-element > p {
  display: inline;
}
:host ::ng-deep .line-clamp p, :host ::ng-deep .toc-bit-item p {
  margin: 0 !important;
}

.toc-selected {
  background-color: var(--bitmark-reader-secondary-color, #E2D3FF);
}

.toc-chapter-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #9b9b9b;
}

.toc-content {
  overflow-x: hidden;
  overflow-y: scroll;
}

input {
  border: none;
  width: 95%;
  padding-top: 3px;
  line-height: 1.5;
  padding-bottom: 3px;
  height: 30px;
  background: transparent !important;
}

.min-empty-height {
  min-height: 16.8px;
}

.toc-bit-item {
  color: var(--bitmark-reader-primary-color, #000000);
}

.lvl1, .lvl2, .lvl3, .lvl4, .lvl5, .lvl6, .lvl7 {
  margin-bottom: 0;
}
.lvl1.toc-chapter, .lvl2.toc-chapter, .lvl3.toc-chapter, .lvl4.toc-chapter, .lvl5.toc-chapter, .lvl6.toc-chapter, .lvl7.toc-chapter {
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.lvl1.toc-chapter .toc-chapter-content.generic-title-bit, .lvl2.toc-chapter .toc-chapter-content.generic-title-bit, .lvl3.toc-chapter .toc-chapter-content.generic-title-bit, .lvl4.toc-chapter .toc-chapter-content.generic-title-bit, .lvl5.toc-chapter .toc-chapter-content.generic-title-bit, .lvl6.toc-chapter .toc-chapter-content.generic-title-bit, .lvl7.toc-chapter .toc-chapter-content.generic-title-bit {
  color: rgba(0, 0, 0, 0.5);
  height: 0;
  border: none;
}
.lvl1 .toc-bit-item, .lvl2 .toc-bit-item, .lvl3 .toc-bit-item, .lvl4 .toc-bit-item, .lvl5 .toc-bit-item, .lvl6 .toc-bit-item, .lvl7 .toc-bit-item {
  color: var(--bitmark-reader-primary-color, #000000);
}

.lvl1.toc-chapter {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
  padding-top: 10px;
  padding-bottom: 40px;
}
.lvl1.toc-chapter > div:first-child {
  font-size: 90px;
  line-height: 1.1;
}
.lvl1.toc-chapter > div:last-child {
  padding: 0;
  margin-top: 0;
  font-size: 24px;
}

.lvl2.toc-chapter {
  padding-top: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 20px;
}

.lvl3.toc-chapter {
  padding-top: 40px;
  font-size: 18px;
  font-weight: 600;
}

.lvl4.toc-chapter {
  padding-top: 40px;
  font-size: 16px;
  font-weight: 600;
}

.lvl5.toc-chapter {
  padding-top: 40px;
  font-size: 14px;
  font-weight: 600;
}

.lvl6.toc-chapter {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.lvl6.toc-chapter > .toc-chapter-content {
  border: none;
}

.lvl7.toc-chapter {
  padding-top: 20px;
  font-weight: 200;
}

::ng-deep .app-dropdown-item {
  text-align: left !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp.line-clamp-single {
  -webkit-line-clamp: 1;
}

.book-title {
  font-family: "Inter", serif;
  font-size: 16px;
  height: 19px;
  line-height: 19px;
  color: #ffffff;
}

.dropdown-item-colors-container {
  display: flex;
}
.dropdown-item-colors-container .dropdown-item-color {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-item-colors-container .dropdown-item-color-selected {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
