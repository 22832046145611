import {Injectable} from '@angular/core';
import {concatMap, Observable, of, tap} from 'rxjs';
import {BrowserStorageService} from '../shared/browser-storage/browser-storage.service';
import {ReaderLocalContentService} from './reader-local-content.service';
import {BitbookMqService} from './bitbook-mq.service';
import {BitbookApiService} from './bitbook-api.service';
import {BitApiWrapper} from '../bits/bits.models';

@Injectable()
export class ReaderBitsBinService {
  private BITS_BIN_COUNT_KEY = 'reader-bits-book-{0}-bin-count';

  constructor(private browserStorageService: BrowserStorageService,
              private readerLocalContentService: ReaderLocalContentService,
              private bitBookMqService: BitbookMqService,
              private bitBookApiService: BitbookApiService) {
  }

  private getStorageKey(id: string): string {
    return this.BITS_BIN_COUNT_KEY.replace('{0}', id);
  }

  getBinItems(id: string): Observable<Array<BitApiWrapper>> {
    return new Observable<Array<BitApiWrapper>>(x => {
      this.bitBookApiService.getBookTrashedContent(id).subscribe((binContent: { content: Array<BitApiWrapper> }) => {
        this.bitBookMqService.notifyBookBinCountUpdated(id, binContent?.content.length);
        this.browserStorageService.idb.store(this.getStorageKey(id), binContent?.content.length)
          .subscribe(() => x.next(binContent.content));
      }, (err) => x.error(err));
    });
  }

  getBinCount(id: string): Observable<number> {
    return this.browserStorageService.idb.get(this.getStorageKey(id))
      .pipe(concatMap(cachedCount => cachedCount
        ? of(cachedCount)
        : this.getBinCountFromNetwork(id)))
      .pipe(tap(count => this.bitBookMqService.notifyBookBinCountUpdated(id, count)));
  }

  getBinCountFromNetwork(id: string): Observable<number> {
    return new Observable<number>(x => {
      this.bitBookApiService.getBookTrashedContentCount(id)
        .subscribe(({count}) => {
          this.bitBookMqService.notifyBookBinCountUpdated(id, count);
          this.browserStorageService.idb.store(this.getStorageKey(id), count)
            .subscribe(() => x.next(count));
        }, (err) => {
          x.error(err);
        });
    });
  }

  moveToBin(id: string, bitId: string): Observable<number> {
    return new Observable(x => {
      this.bitBookApiService.moveBitToBin(bitId).subscribe(() => {
        this.getBinCountFromNetwork(id).subscribe(count => {
          this.bitBookMqService.notifyBookBinCountUpdated(id, count);
          this.browserStorageService.idb.store(this.getStorageKey(id), count)
            .subscribe(() => x.next(count));
        }, (err) => {
          x.error(err);
        });
      }, (err) => {
        x.error(err);
      });
    });
  }

  deleteFromBin(id: string, bitId: string): Observable<number> {
    if (!bitId) {
      console.error('ERROR DELETING BIT...', id, bitId);
      return;
    }

    return new Observable(x => {
      this.bitBookApiService.removeBitFromNotebook(bitId).subscribe(() => {
        this.getBinCountFromNetwork(id).subscribe(count => {
          this.bitBookMqService.notifyBookBinCountUpdated(id, count);
          this.browserStorageService.idb.store(this.getStorageKey(id), count)
            .subscribe(() => x.next(count));
        }, (err) => {
          x.error(err);
        });
      }, (err) => {
        x.error(err);
      });
    });
  }

  restoreFromBin(id: string, bitId: string): Observable<number> {
    return new Observable(x => {
      this.bitBookApiService.restoreBitFromBin(bitId).subscribe(() => {
        this.getBinCountFromNetwork(id).subscribe(count => {
          this.bitBookMqService.notifyBookBinCountUpdated(id, count);
          this.browserStorageService.idb.store(this.getStorageKey(id), count)
            .subscribe(() => x.next(count));
        }, (err) => {
          x.error(err);
        });

        this.bitBookMqService.notifyRefreshNotebook(id);
        this.bitBookMqService.notifyRefreshToc({id});
      }, (err) => {
        x.error(err);
      });
    });
  }

  emptyBin(id: string): Observable<number> {
    return new Observable(x => {
      this.bitBookApiService.deleteBitsFromBin(id)
        .subscribe(() => {
          this.bitBookMqService.notifyBookBinChanged(id);
          this.bitBookMqService.notifyBookBinCountUpdated(id, 0);
          this.browserStorageService.idb.store(this.getStorageKey(id), 0)
            .subscribe(() => x.next(0));

          this.bitBookMqService.notifyRefreshToc({id});
        }, (err) => {
          x.error(err);
        });
    });
  }

  restoreAllFromBin(id: string, bitIds: Array<string>) {
    return new Observable(x => {
      this.bitBookApiService.restoreBitsFromBin(bitIds)
        .subscribe(() => {
          this.bitBookMqService.notifyBookBinChanged(id);
          this.bitBookMqService.notifyBookBinCountUpdated(id, 0);
          this.browserStorageService.idb.store(this.getStorageKey(id), 0)
            .subscribe(() => x.next(0));

          this.bitBookMqService.notifyRefreshNotebook(id);
          this.bitBookMqService.notifyRefreshToc({id});
        }, (err) => {
          x.error(err);
        });
    });
  }
}
