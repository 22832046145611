import {Injectable} from '@angular/core';
declare const GMBImageResize: any;

const gridBreakpoints = {
    sm: 680,
    md: 992,
    lg: 1024,
    xl: 1200
}

@Injectable({providedIn: 'root'})
export class UrlRewriteService {
  constructor() {
  }

  rewriteUrl(url: string) {
    return GMBImageResize.rewriteUrl(url);
  }

  rewriteUrlAndResize(url: string, imageContext: string, imageSize?: string){
    return GMBImageResize.rewriteUrlAndResize(url, imageContext, imageSize);
  }

  isMobile() {
    return window.innerWidth < gridBreakpoints.sm;
  }
}
