import {Component, OnInit} from '@angular/core';
import { GeneralLayoutService, ShopInfo } from 'core';
import {ContainerComponent} from '../container/container.component';
import { ShopBrandingService } from 'main/shop/shop-branding.service';

@Component({
  selector: 'app-toggle-side',
  templateUrl: './toggle-side.component.html'
})
export class ToggleSideComponent extends ContainerComponent implements OnInit {
  rightSectionClass: string;
  shopBranding: ShopInfo;

  constructor(private generalLayoutService: GeneralLayoutService,
              private shopBrandingService: ShopBrandingService,) {
    super();
  }

  ngOnInit() {
    this.rightSectionClass = this.generalLayoutService.getRightSectionClass();
    this.subSink.sink = this.generalLayoutService.getRightSectionClassObservable()
      .subscribe((rightSectionClass: string) => {
        this.rightSectionClass = rightSectionClass;
      });
    this.subSink.sink = this.shopBrandingService.config$.subscribe(shopBranding => this.shopBranding = shopBranding);
  }

  toggleSectionClass() {
    this.generalLayoutService.toggleSectionClass();
  }

  showBackButton() {
    return this.rightSectionClass === this.generalLayoutService.section_class.show;
  }
}
