import {EventEmitter, Renderer2} from '@angular/core';
import {fromEvent} from 'rxjs';
import {SubSink} from '../sub-sink/sub-sink';

export interface Coordinates {
  x: number;
  y: number;
}

export interface TouchSwipePayload {
  x: number;
  y: number;
  direction: 'left' | 'right' | 'up' | 'down';
  axis: 'horizontal' | 'vertical';
}

export class TouchSwipe {
  onPan = new EventEmitter<TouchSwipePayload>();
  onSwipe = new EventEmitter<TouchSwipePayload>();
  onSwipeAbort = new EventEmitter<TouchSwipePayload>();

  private subSink = new SubSink();

  private initialCoordinates?: Coordinates;

  left: number;
  top: number;
  currentTransition = 'none';
  swipeDistanceX = 50;
  swipeDistanceY = 100;

  constructor(private nativeElement: any,
              private renderer: Renderer2,
              private x = 0,
              private y = 0,
              private transition: string = 'all .1s ease-in-out',
              private axis: 'horizontal' | 'vertical' = 'vertical',
              private gripVerticalStart = 0,
              private gripVerticalEnd = 60) {
  }

  private getCoordinates(e): Coordinates {
    return {
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY
    };
  }

  applyTouchSwipe() {
    this.subSink.sink = fromEvent(this.nativeElement, 'touchstart')
      .subscribe((e) => this.touchStart(e));
    this.subSink.sink = fromEvent(this.nativeElement, 'touchend')
      .subscribe((e) => this.touchEnd(e));
    this.subSink.sink = fromEvent(this.nativeElement, 'touchmove')
      .subscribe((e) => this.touchMove(e));
  }

  destroy() {
    this.subSink.unsubscribe();
  }

  touchStart(e) {
    const coo = this.getCoordinates(e);
    this.initialCoordinates = (this.gripVerticalStart <= coo.y && coo.y <= this.gripVerticalEnd && this.axis === 'vertical' || this.axis === 'horizontal')
      ? coo
      : null;
  }

  touchMove(e) {
    if (!this.initialCoordinates) {
      return;
    }
    const coo = this.getCoordinates(e);
    const dX = coo.x - this.initialCoordinates.x;
    const dY = Math.max(0, coo.y - this.initialCoordinates.y);
    const axis = Math.abs(dX) > Math.abs(dY) ? 'horizontal' : 'vertical';
    const direction = axis === 'horizontal'
      ? dX < 0 ? 'left' : 'right'
      : dY < 0 ? 'up' : 'down';
    const vPan = this.axis === 'vertical' && dY > 5;
    const hPan = this.axis === 'horizontal' && Math.abs(dX) > 5;

    if (!vPan && !hPan) {
      return;
    }

    const evtPayload: TouchSwipePayload = {x: dX + this.x, y: dY + this.y, direction, axis};
    this.onPan.emit(evtPayload);
    this.renderPan(evtPayload);
  }

  touchEnd(e) {
    if (!this.initialCoordinates) {
      return;
    }
    const coo = this.getCoordinates(e);
    const dX = coo.x - this.initialCoordinates.x;
    const dY = Math.max(0, coo.y - this.initialCoordinates.y);
    const axis = Math.abs(dX) > Math.abs(dY) ? 'horizontal' : 'vertical';
    const direction = axis === 'horizontal'
      ? dX < 0 ? 'left' : 'right'
      : dY < 0 ? 'up' : 'down';
    const vPan = this.axis === 'vertical' && dY > this.swipeDistanceY;
    const hPan = this.axis === 'horizontal' && Math.abs(dX) > this.swipeDistanceX;

    const evtPayload: TouchSwipePayload = {x: dX + this.x, y: dY + this.y, direction, axis};
    if (vPan || hPan) {
      this.onSwipe.emit(evtPayload);
      this.renderSwipe(evtPayload);
    } else {
      this.onSwipeAbort.emit(evtPayload);
      this.renderSwipeAbort(evtPayload);
    }
  }

  private render() {
    this.renderer.setStyle(this.nativeElement, 'transition', this.currentTransition);
    this.renderer.setStyle(this.nativeElement, 'left', `${this.left}px`);
    // this.renderer.setStyle(this.nativeElement, 'top', `${this.top}px`);
    this.renderer.setStyle(this.nativeElement, 'transform', `translate3d(0, ${this.top}px, 0)`);
    this.renderer.setStyle(this.nativeElement, 'opacity', 1 - 0.1 * this.top / this.swipeDistanceY);
  }

  renderPan($event: TouchSwipePayload) {
    this.currentTransition = 'none';
    if (this.axis === 'horizontal') {
      this.left = $event.x;
    } else {
      this.top = $event.y;
    }
    this.render();
  }

  renderSwipe($event: TouchSwipePayload) {
    this.currentTransition = this.transition;
    if (this.axis === 'horizontal') {
      this.left = $event.direction === 'left' ? -1000 : 1000;
    } else {
      this.top = $event.direction === 'up' ? -1000 : 1000;
    }
    this.render();
  }

  renderSwipeAbort(event: TouchSwipePayload) {
    this.currentTransition = this.transition;
    this.left = this.top = 0;
    this.render();
  }
}
