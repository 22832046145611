// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #a98ae6;
  background-color: #000000;
  padding: 10px;
  z-index: 10001;
}
.button-bar.left {
  border-bottom-right-radius: 20px;
  left: 0;
}
.button-bar.right {
  right: 0;
  border-bottom-left-radius: 20px;
}
.button-bar .basket-counter {
  background-color: #543199;
  color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
