import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {editor} from 'monaco-editor';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import {DebounceService, SubSink} from '../../../shared';
import {Observable} from 'rxjs';

@Component({
  selector: 'bitmark-reader-monaco-code-editor',
  templateUrl: './monaco-code-editor.component.html',
  styleUrls: ['monaco-code-editor.component.scss']
})
export class ReaderMonacoCodeEditorComponent implements OnInit, OnDestroy {
  @Input() content?: string;
  @Input() language?: string;
  @Input() readonly?: boolean;
  @Input() showLineNumbers? = false;
  @Input() showMinimap? = false;
  @Input() updateLanguageEvent?: Observable<string>;
  @Output() contentChange = new EventEmitter<string>();
  @Output() updateContent = new EventEmitter<string>();

  @ViewChild('codeContainer') codeContainer: ElementRef;
  private initialContent: string;
  private subSink = new SubSink();
  editorOptions: IStandaloneEditorConstructionOptions;
  editor: any;
  private editorDisposables: Array<any> = [];

  constructor(private debounceService: DebounceService) {
  }

  ngOnInit() {
    this.initialContent = this.content;

    let monacoWidgetsElem: HTMLElement = document.querySelector('#monaco-widgets');
    if (!monacoWidgetsElem) {
      monacoWidgetsElem = document.createElement('div');
      monacoWidgetsElem.className = 'monaco-editor';
      monacoWidgetsElem.id = 'monaco-widgets';
      document.querySelector('body').appendChild(monacoWidgetsElem);
    }

    this.editorOptions = {
      theme: 'vs-dark',
      language: this.language,
      readOnly: this.readonly,
      domReadOnly: this.readonly,
      scrollBeyondLastLine: false,
      wordWrap: 'on',
      wrappingStrategy: 'advanced',
      minimap: {
        enabled: !!this.showMinimap
      },
      overviewRulerLanes: 0,
      contextmenu: false,
      fontSize: 16,
      overflowWidgetsDomNode: monacoWidgetsElem,
      fixedOverflowWidgets: true,
      lineNumbers: this.showLineNumbers ? 'on' : 'off',
      scrollbar: {
        alwaysConsumeMouseWheel: false
      }
    };
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    this.editorDisposables.forEach(d => d.dispose());
  }

  editorInit(editor: any) {
    if (this.updateLanguageEvent) {
      this.subSink.sink = this.updateLanguageEvent.subscribe(lang => {
        this.editorOptions = {...this.editorOptions, language: lang};
      });
    }

    this.editor = editor;
    this.editorDisposables.push(
      editor.onDidContentSizeChange(() => {
        this.updateHeight(editor);
      }));
    this.updateHeight(editor);

    if (this.readonly) {
      editor.getContribution('editor.contrib.readOnlyMessageController')?.dispose();
    }

    // const styles = editor.getDomNode().style;
    // styles.setProperty('--vscode-editor-background', 'inherit');
    // styles.setProperty('--vscode-editorGutter-background', 'inherit');
  }

  onUpdateContent(data: string) {
    if (this.initialContent === data) {
      return;
    }

    this.contentChange.emit(data);
    this.debounceService.debounce(500, () => {
      this.updateContent.emit(data);
    });
  }

  // https://github.com/microsoft/monaco-editor/issues/794
  private updateHeight(editor: any) {
    const contentHeight = Math.min(1000, editor.getContentHeight());
    this.codeContainer.nativeElement.style.height = `${contentHeight}px`;
    editor.layout({width: editor.getLayoutInfo().width, height: contentHeight});
  }
}
