import {OnDestroy, Output, EventEmitter, ElementRef, Directive, Input} from '@angular/core';

@Directive({selector: '[bitmarkResizeElementChange]'})
export class ResizeElementChangeDirective implements OnDestroy {
  @Input()
  disconnectTimeout?: number;

  @Output()
  changed = new EventEmitter<number>();

  private changes: ResizeObserver;

  constructor(private elementRef: ElementRef) {
    this.changes = new ResizeObserver(() => {
      this.changed.emit(elementRef?.nativeElement?.clientWidth || 0);
    });

    this.changes.observe(elementRef?.nativeElement);

    if (this.disconnectTimeout) {
      setTimeout(() => {
        this.changes.disconnect();
      }, this.disconnectTimeout);
    }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
