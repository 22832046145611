import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {AngularNodeViewComponent} from 'ngx-tiptap';

@Component({
  selector: 'bitmark-nodeview-gap',
  templateUrl: './video.component.html',
  styleUrls: ['../../../../bits/resource/resource-video/resource-video.component.scss']
})
export class NodeviewVideoComponent extends AngularNodeViewComponent implements AfterViewInit {
  @ViewChild('videoElement') videoElement: ElementRef;
  defaultText = '';

  ngAfterViewInit() {
    const videoEl = this.videoElement.nativeElement as HTMLVideoElement;
    if (videoEl.src) {
      return;
    }
    videoEl.src = this.node.attrs.videoSrc;
    videoEl.load();
  }

  onChange(event: Event): void {
    this.updateAttributes({});
  }
}
