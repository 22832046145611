import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {BitmarkSettings} from '../settings/bitmark.settings';

@Pipe({name: 'centCurrency'})
export class CentCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, locale?: string): string {
    if (value != null) {
      return this.currencyPipe.transform(value / 100, '', '', '1.2-2', locale || BitmarkSettings.locale);
    }
    return '0';
  }
}
