// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-circle {
  height: 2.2rem;
  width: 2.2rem;
  object-fit: cover;
  border-radius: 50%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton-header {
  width: 100%;
  height: 2.4rem;
  border-radius: 0.25rem;
}

.skeleton-text {
  width: 100%;
  height: 1.6rem;
  border-radius: 0.25rem;
}

.dot-wrapper {
  display: inline-flex;
  cursor: pointer;
  line-height: 0;
  padding: 5px;
}
.dot-wrapper .dot {
  background-color: var(--bitmark-reader-primary-color, #543199);
  border: 1px solid #ffffff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
