import {Injectable} from '@angular/core';
import {NgbTimeAdapter, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomTimeAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds()
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    const today = new Date();
    return time != null ? new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute, time.second).toISOString() : null;
  }
}
