import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbDateAdapter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {cloneDeep} from 'lodash';
import {BotActionSendBit} from './bot-action-send.models';
import {BitResource} from '../bits.models';
import {LearningPathBookBit} from '../learning-path/learning-path-book/learning-path-book.model';
import {CustomDateAdapter} from '../../shared/adapters/custom-date.adapter';
import {CustomDatepickerI18n} from '../../shared/i18n/custom-datepicker.i18n';

@Component({
  selector: 'bitmark-bot-action-send',
  templateUrl: './bot-action-send.component.html',
  styleUrls: ['./bot-action-send.component.scss', '../bits.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
  ]
})
export class BotActionSendComponent {
  @Input() bit?: BotActionSendBit;
  @Input() bitId?: string;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<BotActionSendBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      this.prevBotActionSendBit = cloneDeep(this.bit);
      this.botActionSendBitTime = this.bit.date || null;
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  private prevBotActionSendBit?: LearningPathBookBit;
  botActionSendBitTime?: string | null;

  cancelChanges() {
    this.bit = cloneDeep(this.prevBotActionSendBit);
    this.editClosed.emit();
  }

  saveChanges() {
    if (this.bit.date && this.botActionSendBitTime) {
      const newDate = new Date(this.bit.date);
      const newTime = new Date(this.botActionSendBitTime);
      this.bit.date = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newTime.getHours(), newTime.getMinutes(), newTime.getSeconds()).toISOString();
    }

    this.saveInlineBit.emit(this.bit);
  }
}
