import {Component, Input} from '@angular/core';
import {environment} from 'environment';

@Component({
  selector: 'app-icon',
  styleUrls: ['./icon.component.scss'],
  template: '<svg width="20" height="20"><use attr.href="assets/svg/icon-sprites.svg?v={{environment.version}}#{{name}}" [style.fill]="color"></use></svg>'
})
export class IconComponent {
  @Input() name: string;
  @Input() color?: string;
  protected readonly environment = environment;
}
