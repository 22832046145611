import {Component, Input} from '@angular/core';
import {BitResourceImageLink} from '../../bits.models';

@Component({
  selector: 'bitmark-resource-image-link',
  templateUrl: './resource-image-link.component.html',
  styleUrls: ['../../bits.scss']
})
export class ResourceImageLinkComponent {
  @Input() bitResourceImageLink?: BitResourceImageLink;
  @Input() fullWidth = false;

  onResourceLoaded($event: Event) {
    // console.log('resource loaded', $event);
  }

  onResourceLoadError($event: ErrorEvent) {
    ($event.target as HTMLElement).style.display = 'none';
  }
}
