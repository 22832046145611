import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

/*
 *ngFor="let c of oneDimArray | orderBy:'asc'"
 *ngFor="let c of arrayOfObjects | orderBy:'asc':'propertyName'"
*/
@Pipe({name: 'orderBy'})
export class OrderByPipe implements PipeTransform {
  transform(value: any[], order: 'asc' | 'desc' = 'asc', column = ''): any[] {
    if (!value || !order) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (!column) {
      if (order === 'asc') {
        return value.sort();
      } else {
        return value.sort().reverse();
      }
    }
    return orderBy(value, [column], [order]);
  }
}
