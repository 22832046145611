// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-upload-entry .notebooks-dropzone {
  background-color: #000000;
  border-radius: 9px;
  overflow: hidden;
  border: none;
  height: 200px;
  width: 100%;
}
.pdf-upload-entry .notebooks-dropzone .pdf-square-dropzone-small {
  text-align: center;
  display: inline-block;
  font-size: 2.25rem;
}
.pdf-upload-entry .notebooks-dropzone .pdf-square-dropzone-small img {
  background-color: #000000;
}
.pdf-upload-entry .dropzone-converting-label {
  font-size: 14px;
  color: #ffffff;
}
.pdf-upload-entry ngx-dropzone-preview {
  max-width: 116px !important;
  min-height: 170px !important;
  max-height: 170px !important;
  min-width: 116px !important;
  overflow-y: auto !important;
  align-items: flex-start !important;
}
.pdf-upload-entry .progress {
  border: 2px solid #a432ff;
  border-radius: 10px;
  height: 8px;
  background-color: #000000;
}
.pdf-upload-entry .progress .progress-bar {
  background-color: #a432ff;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
