export * from './add.pipe';
import {AddPipe} from './add.pipe';

export * from './date-format.pipe';
import {DateFormatPipe} from './date-format.pipe';

export * from './difference.pipe';
import {DifferencePipe} from './difference.pipe';

export * from './from-unix.pipe';
import {FromUnixPipe} from './from-unix.pipe';

export * from './parse.pipe';
import {ParsePipe} from './parse.pipe';

export * from './subtract.pipe';
import {SubtractPipe} from './subtract.pipe';

export const pipes = [
  AddPipe,
  DateFormatPipe,
  DifferencePipe,
  FromUnixPipe,
  ParsePipe,
  SubtractPipe
];
