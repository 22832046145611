// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-dialog {
  top: 0;
  bottom: 0;
  right: 10px;
  left: 10px;
  z-index: 1030;
}

.dialog-content {
  max-width: 355px;
  width: 100%;
  overflow: hidden;
  border-radius: 9px;
  margin: auto auto;
  box-shadow: 0 0 10px 0 #000000;
}

@media (min-width: 1200px) {
  .dialog-dialog {
    position: static !important;
    display: inline-block !important;
    height: auto !important;
    width: auto !important;
  }
  .dialog-content {
    width: 355px;
    position: static;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
