import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from 'environment';
import {AppErrorConverter} from './app-error';
import {IdentityService} from '../auth/identity.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse) {
    // const isDevEnvironment = environment.name === 'Development';
    console.error(error);
    const err = AppErrorConverter.ToAppError(error);
    if (/Loading chunk [\d]+ failed/.test(err.message)) {
      window.location.reload();
    }

    // if (!isDevEnvironment) {
    //   console.error(err);
    // }

    if (err.status === 403 || err.status === 404 || err.status >= 500) {
      return this.handleGenericError(err.status, err.message);
    }
  }

  private handleGenericError(errorStatusCode: number, errorMessage: string): Observable<any> | any {
    const identityService = this.injector.get(IdentityService);
    const router = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);

    identityService.settings?.clear();
    ngZone.run(() => {
      router.navigate(['generic-error'], {
        skipLocationChange: true,
        queryParams: {
          code: errorStatusCode,
          message: errorMessage
        }
      });
    });
  }
}

