import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {interval, Observable, Subject, Subscription, switchMap} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {HttpEventType} from '@angular/common/http';
import {BaseBit, BitResource, BitResourceDocument, BitResourceSize, BitResourceType} from '../bits.models';
import {DropdownItemModel, UrlRewriteService} from '../../shared';
import {ResourceVideoLinkEditComponent} from './edit/resource-video-edit/resource-video-link-edit.component';
import {BitbookApiService} from '../../reader/bitbook-api.service';
import {ResourceImageLinkEditComponent} from './edit/resource-image-link-edit/resource-image-link-edit.component';
import {ResourceWebsiteLinkEditComponent} from './edit/resource-website-link-edit/resource-website-link-edit.component';
import {
  ResourceDocumentLinkEditComponent
} from './edit/resource-document-link-edit/resource-document-link-edit.component';
import {AudioRecordingService, RecordedAudioOutput} from '../../shared/recording/audio-recording.service';
import {RecordedVideoOutput, VideoRecordingService} from '../../shared/recording/video-recording.service';

@Component({
  selector: 'bitmark-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss', '../bits.scss']
})
export class ResourceComponent implements OnInit {
  @Input() bitResource?: BitResource;
  @Input() hostBit: BaseBit;
  @Input() fillUp = false;
  @Input() showArticle = false;
  @Input() imageBit = false;
  @Input() ignoreActionsTypes: Array<string>;
  @Input() showReplaceButton = false;
  @Input() canBeEdited = false;
  @Output() open = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
  @Output() changed = new EventEmitter<{ resource: BitResource | undefined, instruction: string, body: string }>();

  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;

    if (value) {
      this.trimEditOptions();
    }
  }

  private _isBeingEditedByUser?: boolean;
  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  @ViewChild('fileImageInput') fileImageInput: ElementRef;
  @ViewChild('fileAudioInput') fileAudioInput: ElementRef;
  @ViewChild('fileVideoInput') fileVideoInput: ElementRef;
  @ViewChild('fileDocumentInput') fileDocumentInput: ElementRef;

  isImageZoomable = false;
  editOptions: Array<{ type: any; title: string; subtitle?: string; actions: Array<DropdownItemModel> }> = [];
  isUploadingVideo: boolean;
  isUploadingRequest: boolean;
  progressIsIndeterminate: boolean;
  progressPercentage: number;
  isProcessingRequest: boolean;
  processRequestEta: number;
  uploadPreviewDocument: BitResourceDocument;

  isRecordingAudio: boolean;
  isRecordingVideo: boolean;
  isUploadingAudioRecording: boolean;
  isUploadingVideoRecording: boolean;

  private stopProcessingRequestPolling = new Subject();
  private tempBitResource?: BitResource;
  private timeIntervalDocumentPolling: Subscription;
  private stopPolling = new Subject();
  private documentPollingRetries = 0;
  private uploadSub: Subscription;

  constructor(private ngbModal: NgbModal,
              private bitBookApiService: BitbookApiService,
              private translateService: TranslateService,
              private audioRecordingService: AudioRecordingService,
              private videoRecordingService: VideoRecordingService,
              private urlRewriteService: UrlRewriteService) {
  }

  ngOnInit() {
    this.rewriteResourceUrls();
    this.editOptions = [
      {
        type: 'video',
        title: this.translateService.instant('Shared.Video'),
        actions: [
          {
            id: 'videoinput',
            label: this.translateService.instant('Shared.CameraContinue'),
            handler: this.openVideoRecording.bind(this)
          },
          {
            label: this.translateService.instant('Shared.UploadContinue'),
            handler: () => {
              this.fileVideoInput.nativeElement.click();
            }
          },
          {
            label: this.translateService.instant('Shared.EmbedVideoContinue'),
            handler: this.openVideoLinkEditModal.bind(this)
          }
        ]
      },
      {
        type: 'audio',
        title: this.translateService.instant('Shared.Audio'),
        actions: [
          {
            id: 'audioinput',
            label: this.translateService.instant('Shared.Voice'),
            handler: this.openAudioRecording.bind(this)
          },
          {
            label: this.translateService.instant('Shared.UploadContinue'),
            handler: () => {
              this.fileAudioInput.nativeElement.click();
            }
          }
        ]
      },
      {
        type: 'image',
        title: this.translateService.instant('Shared.Image'),
        actions: [
          {
            label: this.translateService.instant('Shared.UploadContinue'),
            handler: () => {
              this.fileImageInput.nativeElement.click();
            }
          },
          {
            label: this.translateService.instant('Shared.UrlContinue'),
            handler: this.openImageLinkEditModal.bind(this)
          }
        ]
      },
      {
        type: 'documents',
        title: this.translateService.instant('Shared.Documents'),
        actions: [
          {
            label: this.translateService.instant('Shared.UploadContinue'),
            handler: () => {
              this.fileDocumentInput.nativeElement.click();
            }
          },
          {
            label: this.translateService.instant('Shared.LinkContinue'),
            handler: this.openDocumentLinkEditModal.bind(this)
          }
        ]
      },
      {
        type: 'website',
        title: this.translateService.instant('Shared.Website'),
        actions: [
          {
            label: this.translateService.instant('Shared.LinkContinue'),
            handler: this.openWebsiteLinkEditModal.bind(this)
          }
        ]
      }
    ];

    this.trimEditOptions();
  }

  rewriteResourceUrls() {
    if (this.bitResource?.image?.src) {
      this.bitResource.image.src = this.urlRewriteService.rewriteUrl(this.bitResource.image.src);
    }
    if (this.bitResource?.image?.src1x) {
      this.bitResource.image.src1x = this.urlRewriteService.rewriteUrl(this.bitResource.image.src1x);
    }
    if (this.bitResource?.image?.src2x) {
      this.bitResource.image.src2x = this.urlRewriteService.rewriteUrl(this.bitResource.image.src2x);
    }
    if (this.bitResource?.image?.src3x) {
      this.bitResource.image.src3x = this.urlRewriteService.rewriteUrl(this.bitResource.image.src3x);
    }
    if (this.bitResource?.imageLink?.url) {
      this.bitResource.imageLink.url = this.urlRewriteService.rewriteUrl(this.bitResource.imageLink.url);
    }
    if (this.bitResource?.audio?.src) {
      this.bitResource.audio.src = this.urlRewriteService.rewriteUrl(this.bitResource.audio.src);
    }
    if (this.bitResource?.video?.src) {
      this.bitResource.video.src = this.urlRewriteService.rewriteUrl(this.bitResource.video.src);
    }
    if (this.bitResource?.document?.url) {
      this.bitResource.document.url = this.urlRewriteService.rewriteUrl(this.bitResource.document.url);
    }
  }

  onImageResourceClicked() {
    if (!this.isImageZoomable) {
      return;
    }
    this.open.emit(this.bitResource);
  }

  onImageResourceMeasured(size: BitResourceSize) {
    this.isImageZoomable = !size.zoomDisabled && (size.shrinkFactor <= .9 || !!this.bitResource?.image?.src2x || !!this.bitResource?.image?.src3x);
    this.measureDisplaySize.emit(size);
  }

  onCaptionChanged(prop: string, resource: any) {
    this.changed.emit({resource: {...this.bitResource, [prop]: resource}, instruction: null, body: null});
  }

  openVideoLinkEditModal() {
    const modalRef = this.ngbModal.open(ResourceVideoLinkEditComponent, {
      windowClass: 'transparent-modal lg',
      animation: false
    });

    const sub = modalRef.componentInstance.onSave.subscribe(({resource, title, description}) => {
      this.bitResource = {...resource};
      this.changed.emit({resource: this.bitResource, instruction: title, body: description});
      sub.unsubscribe();
    });
  }

  openImageLinkEditModal() {
    const modalRef = this.ngbModal.open(ResourceImageLinkEditComponent, {
      windowClass: 'transparent-modal lg',
      animation: false
    });

    const sub = modalRef.componentInstance.onSave.subscribe(link => {
      this.bitResource = {...link};
      this.changed.emit({resource: this.bitResource, instruction: null, body: null});
      sub.unsubscribe();
    });
  }

  openWebsiteLinkEditModal() {
    const modalRef = this.ngbModal.open(ResourceWebsiteLinkEditComponent, {
      windowClass: 'transparent-modal lg',
      animation: false
    });

    const sub = modalRef.componentInstance.onSave.subscribe(link => {
      this.bitResource = {...link};
      this.changed.emit({resource: this.bitResource, instruction: null, body: null});
      sub.unsubscribe();
    });
  }

  openDocumentLinkEditModal() {
    const modalRef = this.ngbModal.open(ResourceDocumentLinkEditComponent, {
      windowClass: 'transparent-modal lg',
      animation: false
    });

    const sub = modalRef.componentInstance.onSave.subscribe(link => {
      this.bitResource = {...link};
      this.changed.emit({resource: this.bitResource, instruction: null, body: null});
      sub.unsubscribe();
    });
  }

  openVideoRecording() {
    this.videoRecordingService.hasPermissions().then((hasPermissions) => {
      if (hasPermissions) {
        this.isRecordingVideo = true;
      } else {
        this.videoRecordingService.askForPermissions().then(() => {
          this.isRecordingVideo = true;
        }, () => {
          this.isRecordingVideo = false;
        });
      }
    });
  }

  videoRecordingStopped(data: RecordedVideoOutput) {
    this.isUploadingVideoRecording = true;
    const file = data.file || new File([data.blob], data.title, {type: data.blob.type});

    this.delayRequest(
      this.bitBookApiService.uploadResource(file),
      (uploadData: { url: string, jobId: string }) => {
        if (uploadData.jobId) {
          this.pollServerForJob(uploadData.jobId, this.checkVideoConvertJobStatus.bind(this), 4000);
        } else {
          this.isRecordingVideo = false;
          this.isUploadingVideoRecording = false;
          this.bitResource = {
            ...this.bitResource,
            type: BitResourceType.Video,
            video: {
              src: uploadData.url
            }
          };
          this.changed.emit({resource: this.bitResource, instruction: null, body: null});
        }
      },
      2000);
  }

  cancelVideoRecording() {
    this.isRecordingVideo = false;
  }

  openAudioRecording() {
    this.audioRecordingService.hasPermissions().then((hasPermissions) => {
      if (hasPermissions) {
        this.isRecordingAudio = true;
      } else {
        this.audioRecordingService.startRecording();
        const subTime = this.audioRecordingService.getRecordedTime().subscribe(() => {
          this.audioRecordingService.abortRecording();
          this.isRecordingAudio = true;
          subTime?.unsubscribe();
          subFailed?.unsubscribe();
        });

        const subFailed = this.audioRecordingService.recordingFailed().subscribe(() => {
          this.isRecordingAudio = false;
          subTime?.unsubscribe();
          subFailed?.unsubscribe();
        });
      }
    });
  }

  audioRecordingStopped(data: RecordedAudioOutput) {
    this.isUploadingAudioRecording = true;
    const file = new File([data.blob], data.title);

    this.delayRequest(
      this.bitBookApiService.uploadResource(file),
      (resourceUrl: { url: string }) => {
        this.isRecordingAudio = false;
        this.isUploadingAudioRecording = false;
        this.bitResource = {
          ...this.bitResource,
          type: BitResourceType.Audio,
          audio: {
            src: resourceUrl.url
          }
        };
        this.changed.emit({resource: this.bitResource, instruction: null, body: null});
      },
      2000);
  }

  cancelAudioRecording() {
    this.isRecordingAudio = false;
  }

  uploadImage() {
    if (!this.fileImageInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileImageInput.nativeElement.files[0];

    this.isUploadingVideo = false;
    this.isUploadingRequest = true;
    this.progressPercentage = 0;
    this.uploadPreviewDocument = {
      url: file.name
    };

    this.bitBookApiService.uploadResourceWithProgress(file)
      .subscribe((event) => {
        this.handleUploadResponse(event,
          () => {
            this.tempBitResource = {
              type: BitResourceType.Image,
              image: {
                src: event.body.url,
                alt: '',
              }
            };
          },
          () => {
            this.bitResource = {...this.tempBitResource};
            this.changed.emit({resource: this.bitResource, instruction: null, body: null});
          });
      }, (err) => {
        this.fileImageInput.nativeElement.value = null;
        this.isProcessingRequest = false;
        this.isUploadingRequest = false;
        this.tempBitResource = undefined;
        console.error(err);
        alert('Could not upload image');
      });
  }

  uploadAudio(file?: File | undefined) {
    if (!file) {
      if (!this.fileAudioInput.nativeElement.files?.length) {
        return;
      }
      file = this.fileAudioInput.nativeElement.files[0];
    }

    this.isUploadingVideo = false;
    this.isUploadingRequest = true;
    this.progressIsIndeterminate = true;
    this.progressPercentage = 100;
    this.uploadPreviewDocument = {
      url: file.name
    };

    this.bitBookApiService.uploadResourceWithProgress(file)
      .subscribe((event) => {
        this.handleUploadResponse(event,
          () => {
            this.tempBitResource = {
              type: BitResourceType.Audio,
              audio: {
                src: event.body.url
              }
            };
          },
          () => {
            this.bitResource = {...this.tempBitResource};
            this.changed.emit({resource: this.bitResource, instruction: null, body: null});
          });
      }, (err) => {
        this.fileAudioInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload audio');
      });
  }

  uploadVideo() {
    if (!this.fileVideoInput.nativeElement.files?.length || !this.hostBit) {
      return;
    }
    const file = this.fileVideoInput.nativeElement.files[0];

    this.isUploadingVideo = true;
    this.isUploadingRequest = true;
    this.progressIsIndeterminate = true;
    this.progressPercentage = 100;
    this.uploadPreviewDocument = {
      url: file.name
    };

    this.uploadSub = this.bitBookApiService.uploadResourceWithProgress(file, this.hostBit.id)
      .subscribe((event) => {
        this.handleUploadResponse(event,
          () => {
            this.tempBitResource = {
              type: BitResourceType.Video,
              video: {
                src: event.body.url
              }
            };
          },
          () => {
            this.bitResource = {...this.tempBitResource};
            this.changed.emit({resource: this.bitResource, instruction: null, body: null});
          });
      }, (err) => {
        this.fileVideoInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload video');
      });
  }

  uploadDocument() {
    if (!this.fileDocumentInput.nativeElement.files?.length || !this.hostBit) {
      return;
    }
    const file = this.fileDocumentInput.nativeElement.files[0];

    this.isUploadingVideo = false;
    this.isUploadingRequest = true;
    this.progressIsIndeterminate = true;
    this.progressPercentage = 100;
    this.uploadPreviewDocument = {
      url: file.name
    };

    this.bitBookApiService.uploadResourceWithProgress(file, this.hostBit.id)
      .subscribe((event) => {
        this.handleUploadResponse(event,
          () => {
            this.tempBitResource = {
              type: BitResourceType.Document,
              document: {
                url: event.body.url
              }
            };
          },
          () => {
            this.bitResource = {...this.tempBitResource};
            this.changed.emit({resource: this.bitResource, instruction: null, body: null});
          });
      }, (err) => {
        this.fileDocumentInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload document');
      });
  }

  pollServerForJob(jobId: string, callback: (jobId: string) => Observable<any>, timeInterval = 2000) {
    this.timeIntervalDocumentPolling = interval(timeInterval)
      .pipe(
        startWith(0),
        switchMap(() => callback(jobId)),
        takeUntil(this.stopPolling)
      ).subscribe(() => {
      }, err => {
        console.log(err);
      });
  }

  deleteResource() {
    this.bitResource = undefined;

    this.changed.emit({resource: this.bitResource, instruction: null, body: null});
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.isUploadingVideo = false;
    this.isUploadingRequest = false;
    this.isProcessingRequest = false;
    this.tempBitResource = undefined;
  }

  private checkJobStatus(jobId: string): Observable<any> {
    this.documentPollingRetries++;

    if (this.documentPollingRetries >= 6) {
      this.isProcessingRequest = false;
      this.stopPolling.next(true);
    }

    return new Observable(x => {
      this.bitBookApiService.getResourceJobStatus(jobId).subscribe((jobData) => {
        if (jobData.status !== 'in-progress' && jobData.status !== 'created') {
          this.stopPolling.next(true);
          this.documentPollingRetries = 0;

          const vid = {
            ...this.tempBitResource?.video,
            ...jobData.outcome
          };

          this.bitResource = {
            ...this.tempBitResource,
            preview: jobData.details?.preview || jobData.outcome?.preview,
            video: vid
          };
          this.isProcessingRequest = false;
          this.changed.emit({resource: this.bitResource, instruction: null, body: null});
        }

        x.next();
      }, (err) => x.error(err));
    });
  }

  private checkVideoConvertJobStatus(jobId: string): Observable<any> {
    this.documentPollingRetries++;

    if (this.documentPollingRetries >= 75) { // wait for 5 minutes for the conversion
      this.isProcessingRequest = false;
      this.stopPolling.next(true);
    }

    return new Observable(x => {
      this.bitBookApiService.getResourceJobStatus(jobId).subscribe((jobData) => {
        if (jobData.status !== 'in-progress' && jobData.status !== 'created') {
          this.stopPolling.next(true);
          this.documentPollingRetries = 0;

          this.isRecordingVideo = false;
          this.isUploadingVideoRecording = false;
          this.isProcessingRequest = false;

          this.bitResource = {
            ...this.bitResource,
            type: BitResourceType.Video,
            video: {
              src: jobData.outcome?.link || jobData.link
            }
          };
          this.changed.emit({resource: this.bitResource, instruction: null, body: null});
        }
        x.next();
      }, (err) => x.error(err));
    });
  }

  private handleUploadResponse(event: any, createResource: () => void, onFinished: Function) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;

        if (this.progressIsIndeterminate && percentDone < 20) {
          this.progressIsIndeterminate = false;
        }

        if (!this.progressIsIndeterminate) {
          this.progressPercentage = percentDone;
        }

        break;

      case HttpEventType.Response:
        this.isUploadingRequest = false;
        this.progressIsIndeterminate = false;
        const data = event.body;

        if (event.body?.eta && event.body?.eta > 0) {
          this.isProcessingRequest = true;
          this.processRequestEta = event.body.eta;

          createResource();

          if (data.jobId) {
            this.progressIsIndeterminate = true;
            this.pollServerForJob(data.jobId, this.checkJobStatus.bind(this));
          } else {
            this.progressPercentage = 0;
            this.stopProcessingRequestPolling.next(false);
            interval(1000)
              .pipe(
                startWith(0),
                switchMap(() => {
                  return new Observable(x => {
                    this.progressPercentage += Math.round(100 / event.body.eta);

                    if (this.progressPercentage >= 100) {
                      this.isProcessingRequest = false;
                      this.stopProcessingRequestPolling.next(true);
                      onFinished();
                    }

                    x.next();
                  });
                }),
                takeUntil(this.stopProcessingRequestPolling)
              ).subscribe(() => {
            });
          }
          return;
        } else if (data.jobId) {
          this.isProcessingRequest = true;
          createResource();
          this.pollServerForJob(data.jobId, this.checkJobStatus.bind(this));
          return;
        } else {
          createResource();
        }

        this.isProcessingRequest = false;
        onFinished();

        break;

      default:
        break;
    }
  }

  private trimEditOptions() {
    if(this.ignoreActionsTypes?.length){
      this.editOptions = this.editOptions.filter((eo: any) => {
        return this.ignoreActionsTypes?.indexOf(eo.type) === -1;
      })
    }
    if (!navigator.mediaDevices?.enumerateDevices) {
      return;
    }

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        const deviceTypes = devices.map(x => x.kind.toString().toLowerCase());

        this.editOptions?.forEach(option => {
          option.actions = option.actions.filter(action => {
            if (!action.id) {
              return true;
            }

            return deviceTypes.includes(action.id.toLowerCase());
          });
        });
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });

  }

  private delayRequest(request: Observable<any>, action: Function, minRequestTime: number) {
    const initialTime = new Date();

    request.subscribe(
      data => {
        const currentTime = new Date();
        const elapsedTime = currentTime.getTime() - initialTime.getTime();

        setTimeout(() => {
          action(data);
        }, Math.max(minRequestTime - elapsedTime, 0));
      },
      (err) => {
        console.error(err);
        window.alert(err);
      });
  }
}
