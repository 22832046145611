import {Injectable} from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import {ApiService} from '../api/api.service';
import {Country, IdentityUser, SpacesConfig} from './account.models';
import {environment} from "environment";
import {AuthService} from "@faubulous/ng2-ui-auth";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AccountApiService {
  constructor(private authService: AuthService,
              private apiService: ApiService) {
  }

  getProfile(): Observable<IdentityUser> {
    return this.apiService.get('account/profile', null);
  }
  
  getCountries(): Observable<Array<Country>> {
    return this.apiService.get('countries', null);
  }

  getLanguages(): Observable<Array<Country>> {
    return this.apiService.get('countries/languages', null);
  }

  getSpacesConfig(): Observable<SpacesConfig> {
    return new Observable(x => {
      fetch(`${environment.bitbookApiEndpoint}/users/spaces/config`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.authService.getToken()}`,
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(json => {
          x.next(json);
          x.complete();
        })
        .catch(err => {
          x.error(err);
          x.complete();
        });
    });
  }

  subscribeToWebPushNotifications(subscription: PushSubscription): Observable<{ endpoint: string }> {
    return this.apiService.post('account/subscribe-web-push', null, {subscription});
  }

  updateProfile(user: any): Observable<IdentityUser> {
    return this.apiService.post('account/profile', null, user);
  }

  uploadProfilePhoto(file: File): Observable<any> {
    return this.apiService.s3Upload('storage/presigned', file)
      .pipe(concatMap((s3Url: string) => this.apiService.post('account/photo', null, {url: s3Url})));
  }

  // updateAvailability(isAvailable: boolean): Observable<any> {
  //   return this.apiService.patch('account/update-availability', null, {isAvailable});
  // }

  changePassword(userPasswords: any): Observable<any> {
    return this.apiService.post('account/change-password', null, userPasswords);
  }

  resendValidationLink(email: string): Observable<any> {
    return this.apiService.post('account/resend-link', null, {email});
  }

  getUserEmailsOrForceCreateAccount(registrationData: { company: string, emails: Array<string>, host: string }) {
    return this.apiService.post('account/get-user-emails-or-create-account', null, registrationData);
  }

  register(user: any) {
    return this.apiService.post('account/register', null, user);
  }

  getAiConfigValues(): any {
    return {
      'depth': [
        {'id': 1, 'name': 'Elementary (Grade 1-6)'},
        {'id': 2, 'name': 'Middle School (Grade 7-9)'},
        {'id': 3, 'name': 'High School (Grade 10-12)'},
        {'id': 4, 'name': 'College Prep'},
        {'id': 5, 'name': 'Undergraduate'},
        {'id': 6, 'name': 'Graduate'},
        {'id': 7, 'name': 'Master\'s'},
        {'id': 8, 'name': 'Doctoral Candidate'},
        {'id': 9, 'name': 'Postdoc'},
        {'id': 10, 'name': 'Ph.D'}
      ],
      'learning_style': [
        {'id': 1, 'name': 'Sensing'},
        {'id': 2, 'name': 'Visual'},
        {'id': 3, 'name': 'Inductive'},
        {'id': 4, 'name': 'Active'},
        {'id': 5, 'name': 'Sequential'},
        {'id': 6, 'name': 'Intuitive'},
        {'id': 7, 'name': 'Verbal'},
        {'id': 8, 'name': 'Deductive'},
        {'id': 9, 'name': 'Reflective'},
        {'id': 10, 'name': 'Global'}
      ],
      'communication_style': [
        {'id': 1, 'name': 'Stochastic'},
        {'id': 2, 'name': 'Formal'},
        {'id': 3, 'name': 'Textbook'},
        {'id': 4, 'name': 'Layman'},
        {'id': 5, 'name': 'Story Telling'},
        {'id': 6, 'name': 'Socratic'},
        {'id': 7, 'name': 'Humorous'}
      ],
      'tone_style': [
        {'id': 1, 'name': 'Debate'},
        {'id': 2, 'name': 'Encouraging'},
        {'id': 3, 'name': 'Neutral'},
        {'id': 4, 'name': 'Informative'},
        {'id': 5, 'name': 'Friendly'}
      ],
      'reasoning_framework': [
        {'id': 1, 'name': 'Deductive'},
        {'id': 2, 'name': 'Inductive'},
        {'id': 3, 'name': 'Abductive'},
        {'id': 4, 'name': 'Analogical'},
        {'id': 5, 'name': 'Causal'}
      ],
      'use_emojis': [
        {'id': 1, 'name': 'Yes'},
        {'id': 2, 'name': 'No'}
      ]
    };
  }
}
