import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BitBrandingJsCustomizerService {
  setNegativePadding(bitWrapper: HTMLElement) {
    const wrapperContainer = bitWrapper.querySelector('.bit-wrapper-container') as HTMLElement;
    const wrapperPadding = window.getComputedStyle(wrapperContainer, null).getPropertyValue('padding');
    const padding = wrapperPadding.split(' ').map(x => `-${x}`).join(' ');
    wrapperContainer.style.setProperty('--bitmark-bit-negative-padding', padding);
  }

  // #4448
  removeBitShadowIfBackdrop(bitWrapper: HTMLElement) {
    const hasShadow = bitWrapper.classList.contains('bit-shadow1');
    if (!hasShadow) {
      return;
    }
    const wrapperBackground = bitWrapper.querySelector('.bit-background1');
    if (wrapperBackground) {
      const backdropFilterValue = window.getComputedStyle(wrapperBackground, null).getPropertyValue('backdrop-filter');
      if (backdropFilterValue && backdropFilterValue !== 'none') {
        bitWrapper.classList.remove('bit-shadow1');
      }
    }
  }

  compensateBitIconMargins(bitWrapper: HTMLElement) {
    const hasMarginAsPadding = bitWrapper.classList.contains('has-margins');
    if (hasMarginAsPadding) {
      return;
    }

    const iconEl = bitWrapper.getElementsByClassName('bit-icon')[0] as HTMLElement;
    if (!iconEl) {
      return;
    }
    const wrapperPaddings = window.getComputedStyle(bitWrapper, null)
      .getPropertyValue('--padding-theme')
      .split(' ')
      .map(x => x.trim());
    const iconMargins = window.getComputedStyle(iconEl, null).getPropertyValue('margin')
      .split(' ')
      .map(x => x.trim());

    const diff = this.diffBoxValues(iconMargins, wrapperPaddings);
    const diffStr = diff.join(' ');
    iconEl.style.setProperty('margin', diffStr);
    bitWrapper.style.setProperty('overflow', 'visible');
  }

  positionBitIcon(bitWrapper: HTMLElement) {
    const iconElements = Array.from(bitWrapper.querySelectorAll('.bit-icon,.bit-inner-icon'));

    iconElements?.forEach(iconElem => {
      const iconPosition = window.getComputedStyle(iconElem).getPropertyValue('--bit-icon-position');
      if (iconPosition === 'top-center') {
        iconElem.classList.add('icon-top-center');
      } else if (iconPosition === 'bottom-center') {
        iconElem.classList.add('icon-bottom-center');
      }
    });
  }

  alignByContext(bitWrapper: HTMLElement) {
    if (bitWrapper.classList.contains('context-timeline')) {
      if (bitWrapper.closest('.bit-entry-bubble')?.classList.contains('is-mine')) {
        bitWrapper.style.setProperty('align-self', 'flex-end');
      } else {
        bitWrapper.style.setProperty('align-self', 'flex-start');
      }
    }
  }

  setCustomIcon(bitWrapper: HTMLElement, iconUrl: string) {
    const isImageCss = iconUrl.indexOf('-gradient') !== -1;
    const actualIconUrl = isImageCss
      ? iconUrl
      : `url(${iconUrl})`;
    bitWrapper.style.setProperty('--bitmark-custom-icon-image', actualIconUrl);
  }

  adjustForBitWallpaper(bitWrapper: HTMLElement) {
    const computedStyles = window.getComputedStyle(bitWrapper, ':before');
    const backColor = computedStyles.getPropertyValue('background-color');
    const backImage = computedStyles.getPropertyValue('background-image');
    if (backColor !== 'rgba(0, 0, 0, 0)' || backImage !== 'none') {
      bitWrapper.style.setProperty('overflow', 'visible');
    }

    const bitWrapperAttachedTo = window.getComputedStyle(bitWrapper).getPropertyValue('--bit-wallpaper-attached-to');
    if (bitWrapperAttachedTo === 'bit') {
      bitWrapper.classList.add('wallpaper-attached-to-bit');
    } else if (bitWrapperAttachedTo === 'full') {
      bitWrapper.classList.add('wallpaper-attached-to-full');
    }
  }

  applyFontGradient(bitWrapper: HTMLElement) {
    const propertyValue = window.getComputedStyle(bitWrapper).getPropertyValue('--bit-font-gradient-background');

    if (propertyValue) {
      bitWrapper.classList.add('font-gradient');
    } else {
      bitWrapper.classList.remove('font-gradient');
    }
  }

  private normalizeBoxValues(x: Array<string>): Array<string> {
    if (!x?.length) {
      return ['0px', '0px', '0px', '0px'];
    }
    return (x.length === 1
      ? [x[0], x[0], x[0], x[0]]
      : x.length === 2
        ? [x[0], x[1], x[0], x[1]]
        : x.length === 3
          ? [x[0], x[1], x[2], '0px']
          : [x[0], x[1], x[2], x[3]])
      .map(v => v || '0px');
  }

  private diffBoxValues(a1: Array<string>, a2: Array<string>): Array<string> {
    const na1 = this.normalizeBoxValues(a1);
    const na2 = this.normalizeBoxValues(a2);
    return na1.map((v1: string, idx: number) => {
      const num1 = parseInt(v1) || 0;
      const um = v1.substr(-2);
      const num2 = parseInt(na2[idx]) || 0;
      return `${num1 - num2}${um}`;
    });
  }
}
