import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BookCloseBit} from './book-close.models';

@Component({
  selector: 'bitmark-book-close',
  templateUrl: './book-close.component.html',
  styleUrls: ['../bits.scss']
})
export class BookCloseComponent {
  @Input() bit: BookCloseBit;
  @Output() closeBook = new EventEmitter<any>();
}
