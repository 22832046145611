import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookEntity} from '../../reader/reader.models';
import {DropdownItemModel} from '../../shared';
import {BookType} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-bit-book-summary',
  templateUrl: './bit-book-summary.component.html',
  styleUrls: ['./bit-book-summary.component.scss']
})
export class BitBookSummaryComponent implements OnInit {
  @Input() bitBook: BookEntity;
  @Input() actions: Array<DropdownItemModel>;
  @Output() sendBook = new EventEmitter<any>();

  bookTypes = BookType;
  isMenuHidden = false;

  ngOnInit() {
    this.isMenuHidden = !this.actions?.length || this.actions.every((a) => a.isHidden);
  }
}
