// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `bitmark-icon {
  color: var(--bitmark-quote-sign-color);
}

p.quote {
  margin-top: 30px;
}

p.quoted-person {
  font-size: calc(var(--bitmarkChapterScaleDownFactor) * var(--bitmark-quote-font-size));
  font-weight: 400;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
