// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-logo-grave {
  width: var(--bitmark-images-logo-grave-logo-grave-image-size-width, 25%);
  height: var(--bitmark-images-logo-grave-logo-grave-image-size-height, auto);
  padding: var(--bitmark-images-logo-grave-logo-grave-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-images-logo-grave-logo-grave-image-size-margin, var(--bitmark-x-size-margin, 0));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
