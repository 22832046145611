import {Conversation1Bit} from './conversation.models';
import {ElementRef} from '@angular/core';

export class ConversationCommonComponent {
  hasPartner = false;
  hasAvatarIcon = false;

  computePartnerData(bit: Conversation1Bit) {
    this.hasAvatarIcon = !!(bit?.partner?.avatarImage?.src
      || bit?.partner?.avatarImage?.alt);
    this.hasPartner = !!(bit?.partner?.name || this.hasAvatarIcon);
  }

  adjustLeftMargin(bubbleElem: ElementRef) {
    if (bubbleElem?.nativeElement?.offsetLeft < 0) {
      const elemMargin = getComputedStyle(bubbleElem.nativeElement)?.marginLeft;
      if (elemMargin) {
        bubbleElem.nativeElement.style.marginLeft = `calc(${elemMargin} - ${bubbleElem?.nativeElement?.offsetLeft}px)`;
      }
    }
  }

  adjustRightMargin(bubbleElem: ElementRef) {
    const widthDiff = bubbleElem?.nativeElement?.offsetWidth - bubbleElem?.nativeElement?.offsetParent?.clientWidth;

    if (widthDiff > 0) {
      const elemMargin = getComputedStyle(bubbleElem.nativeElement)?.marginRight;
      if (elemMargin) {
        bubbleElem.nativeElement.style.marginRight = `calc(${elemMargin} + ${widthDiff}px)`;
      }
    }
  }
}
