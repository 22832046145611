import {Injectable} from "@angular/core";
import {HandInStatus} from "../shared/models/bitmark.models";

@Injectable({providedIn: 'root'})
export class BitProductService {
  getProductsFromEvent(event: any, existingProducts: Array<any>, bitProductIds: Array<string>){
    if(!existingProducts?.filter((p) => p).length){
      const tempProducts = existingProducts?.length ? existingProducts : bitProductIds.map((p) => {
        return {_id: p}
      });
      for (let i = 0; i < tempProducts?.length; i++) {
        const product = event.detail?.products.find((p) => tempProducts[i]._id === p._id);
        if(product){
          product.meta = product.meta || {};
          tempProducts[i] = Object.assign({}, product);
          tempProducts[i].publisher = tempProducts[i] && tempProducts[i]?.meta?.publisher?.length ? tempProducts[i].meta.publisher[0].data : '';
          tempProducts[i].title = tempProducts[i] && tempProducts[i]?.texts?.title ? tempProducts[i].texts.title : '';
          tempProducts[i].bitbookDoesNotExist = !tempProducts[i]?.bookExists && tempProducts[i]?.meta?.family !== 'xmodule';
          tempProducts[i].productDoesNotExist = !tempProducts[i]?.texts?.title && !tempProducts[i]?.bookExists && tempProducts[i]?.meta?.family !== 'xmodule';
          tempProducts[i].isInWrongSpace = tempProducts[i]?.spacePermissions && Object.keys(tempProducts[i]?.spacePermissions)?.length && !tempProducts[i].spacePermissions.sameSpace && tempProducts[i]?.meta?.family !== 'xmodule';
          tempProducts[i].publisherAvatar = this.getPropertyValue(tempProducts[i]?.onlyPublisherAvatar)
          tempProducts[i].videoLength = this.getPropertyValue(tempProducts[i]?.meta?.video_duration)
          tempProducts[i].meta.handInStatus = tempProducts[i].meta.handInStatus || (tempProducts[i].spacePermissions?.processHandIn ? HandInStatus?.NotHandedIn : null);
          tempProducts[i].title = tempProducts[i]?.isInWrongSpace ? "🚫 Product in the wrong space" : tempProducts[i]?.productDoesNotExist ? "🤔" : tempProducts[i].title;
        } else{
          tempProducts[i].bitbookDoesNotExist = !tempProducts[i]?.bookExists && tempProducts[i]?.meta?.family !== 'xmodule';
          tempProducts[i].productDoesNotExist = !tempProducts[i]?.texts?.title && !tempProducts[i]?.bookExists && tempProducts[i]?.meta?.family !== 'xmodule';
          tempProducts[i].isInWrongSpace = tempProducts[i]?.spacePermissions && Object.keys(tempProducts[i]?.spacePermissions)?.length && !tempProducts[i].spacePermissions.sameSpace && tempProducts[i]?.meta?.family !== 'xmodule';
          tempProducts[i].title = tempProducts[i]?.isInWrongSpace ? "🚫 Product in the wrong space" : tempProducts[i]?.productDoesNotExist ? "🤔" : tempProducts[i].title;
        }
      }
      return tempProducts;
    } else{
      for (let i = 0; i < event?.detail?.products?.length; i++) {
        let idx = existingProducts?.findIndex((p) => p._id === event?.detail?.products[i]._id);
        if(idx > -1){
          existingProducts[idx] = Object.assign({}, event?.detail?.products[i]);
          existingProducts[idx].meta = existingProducts[idx].meta || {};
          existingProducts[idx].publisher = existingProducts[idx] && existingProducts[idx]?.meta?.publisher?.length ? existingProducts[idx].meta.publisher[0].data : '';
          existingProducts[idx].title = existingProducts[idx] && existingProducts[idx]?.texts?.title ? existingProducts[idx].texts.title : '';
          existingProducts[idx].bitbookDoesNotExist = !existingProducts[idx]?.bookExists && existingProducts[idx]?.meta?.family !== 'xmodule';
          existingProducts[idx].productDoesNotExist = !existingProducts[idx]?.texts?.title && !existingProducts[idx]?.bookExists && existingProducts[idx]?.meta?.family !== 'xmodule';
          existingProducts[idx].isInWrongSpace = existingProducts[idx]?.spacePermissions && Object.keys(existingProducts[idx]?.spacePermissions)?.length && !existingProducts[idx].spacePermissions.sameSpace && existingProducts[idx]?.meta?.family !== 'xmodule';
          existingProducts[idx].publisherAvatar = this.getPropertyValue(existingProducts[idx]?.onlyPublisherAvatar)
          existingProducts[idx].videoLength = this.getPropertyValue(existingProducts[idx]?.meta?.video_duration)
          existingProducts[idx].meta.handInStatus = existingProducts[idx].meta.handInStatus || (existingProducts[idx].spacePermissions?.processHandIn ? HandInStatus?.NotHandedIn : null);
          existingProducts[idx].title = existingProducts[idx]?.isInWrongSpace ? "🚫 Product in the wrong space" : existingProducts[idx]?.productDoesNotExist ? "🤔" : existingProducts[idx].title;
        }
      }
      return existingProducts;
    }
  }

  getPropertyValue(arrayPropertyValue: Array<any>){
    if(arrayPropertyValue?.length){
      return arrayPropertyValue[0]?.data || arrayPropertyValue[0]?.file?.url;
    }
    return null;
  }
}
