// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-editor-action {
  border: 1px solid #543199;
  background-color: #ffffff;
  border-radius: 5px;
  min-width: 30px;
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 3px;
}
.btn-editor-action.is-active {
  background-color: gold;
}

.reader-advanced-bubble-menu {
  padding: 1px 0;
}

::ng-deep .selected-context-menu .tiptap-bubble-item {
  border: 1px solid #7b7b7b;
  width: 34px;
  border-radius: 5px;
  text-align: center;
  margin: 0 !important;
  background-color: white;
}
::ng-deep .selected-context-menu .menu-bubble-item {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  padding: 2px;
  width: 70px;
  text-align: left;
  padding-left: 7px;
}

::ng-deep .reader-text-editor mark {
  background-color: transparent;
  padding: 0;
}
::ng-deep .reader-text-editor .tippy-box {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 5px;
}
::ng-deep .reader-text-editor .tiptap-bubble-menu-list {
  border: none;
  box-shadow: none;
}
::ng-deep .reader-text-editor .contextual-menu-absolute {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  right: 0;
  z-index: 100;
}

::ng-deep .task-list > li > div > p {
  min-width: 5px;
}
::ng-deep .contextual-menu-absolute {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 3px;
  background-color: #543199;
  border-radius: 6px;
  position: fixed;
}
::ng-deep #contextual-menu-general {
  z-index: 1000;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
::ng-deep #contextual-menu-general .menu-bubble-item {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  padding: 2px;
  width: 70px;
  text-align: left;
  padding-left: 7px;
}
::ng-deep #contextual-menu-general .menu-bubble-item.is-active-bubble {
  border: 2px solid #361f64 !important;
}
::ng-deep #contextual-menu-general .tiptap-bubble-item.is-active-bubble {
  border: 2px solid #361f64 !important;
}
::ng-deep .reader-note-editor {
  z-index: 1049;
}
::ng-deep .reader-note-editor .contextual-menu-absolute {
  right: 30px;
}
::ng-deep .reader-note-editor .ProseMirror {
  max-height: 96px;
  overflow: auto;
}
@media (max-width: 1024px) {
  ::ng-deep .reader-note-editor .contextual-menu-absolute {
    right: 0;
  }
}
::ng-deep .reader-text-editor .dropdown-menu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
::ng-deep .d-hidden {
  visibility: hidden;
}
::ng-deep .text-editor-actions .popover-body, ::ng-deep .no-pad-popover .popover-body {
  padding: 0;
}
::ng-deep .text-editor-actions .popover-body label, ::ng-deep .no-pad-popover .popover-body label {
  margin-bottom: 0;
}
::ng-deep .has-focus {
  border-radius: 3px;
  box-shadow: 0 0 0 3px #68cef8;
}
::ng-deep .tippy-content {
  padding: 0;
}
::ng-deep .tippy-content input[type=checkbox] {
  -webkit-appearance: checkbox;
}
::ng-deep .tippy-content .popover-body {
  padding: 0;
}
::ng-deep .tippy-content label {
  margin-bottom: 0;
}
::ng-deep .tiptap-menu-separator {
  border-top: 1px solid #c8c8c8;
}
::ng-deep #tiptap-buble-menu-text-format-item:hover + #tiptap-text-bubble-menu {
  display: block;
}
::ng-deep .tippy-box {
  border-radius: 10px;
  box-shadow: none !important;
  background-color: #fff;
  padding: 10px;
}
::ng-deep #tippy-4 .tippy-box {
  padding: 0;
}
::ng-deep #tippy-2 .tippy-box {
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  border: none;
}
::ng-deep #tippy-2 .tippy-box .tiptap-menu {
  margin-top: 16px;
  position: absolute;
  right: 0;
}
::ng-deep .tiptap-menu {
  min-width: 200px;
}
::ng-deep .tiptap-bubble-menu-text {
  border-radius: 20px;
}
::ng-deep .tiptap-text-bubble-menu-top {
  height: 40px;
  padding: 6px 4px;
}
::ng-deep .tiptap-text-bubble-menu-top .tiptap-bubble-item {
  width: 34px;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  text-align: center;
  margin: 0 2px;
}
::ng-deep .tiptap-bubble-item-container {
  text-align: center;
}
::ng-deep .tiptap-bubble-item {
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  border-left: 5px solid transparent;
}
::ng-deep .tiptap-bubble-item.tiptap-bubble-item-block {
  display: block;
  width: 120px;
}
::ng-deep .text-decoration-link {
  text-decoration: underline;
  text-decoration-color: #0067ff;
  text-decoration-thickness: 2px;
}
::ng-deep .text-decoration-marked {
  padding: 3px 5px;
  background-color: #a6c4f8;
  border-radius: 5px;
}
::ng-deep .text-decoration-note-highlight {
  padding: 3px 5px;
  background-color: #f6f2ac;
  border-radius: 5px;
}
::ng-deep .text-decoration-inserted {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #97d789;
  text-decoration-thickness: 2px;
}
::ng-deep .text-decoration-deleted {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #dfb5a2;
  text-decoration-thickness: 2px;
}
::ng-deep .color-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}
::ng-deep .lettered-list {
  list-style-type: upper-latin !important;
}
::ng-deep .lettered-list-lower {
  list-style-type: lower-latin !important;
}
::ng-deep .ordered-list-roman {
  list-style-type: upper-roman !important;
}
::ng-deep .ordered-list-roman-lower {
  list-style-type: lower-roman !important;
}
::ng-deep .no-bullet-list {
  list-style-type: none !important;
}
::ng-deep .tiptap-bubble-menu-icon {
  height: 15px;
}
::ng-deep .dropdown__mobile .tiptap-bubble-item {
  width: 34px;
  text-align: center;
  margin: 3px 0;
  font-size: 22px;
}
::ng-deep .dropdown__mobile .tiptap-small-bubble-item {
  border-radius: 5px;
  border: 1px solid #7b7b7b;
}
::ng-deep .dropdown__mobile .menu-bubble-item {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  padding: 2px;
  width: 70px;
  text-align: left;
  padding-left: 7px;
  min-width: 90px;
}
::ng-deep .dropdown__mobile .menu-bubble-item.is-active-bubble {
  border: 2px solid #361f64 !important;
}
::ng-deep .tiptap-bubble-item.is-active-bubble {
  border: 2px solid #361f64 !important;
}

::ng-deep .tiptap-section {
  font-weight: bold;
}

::ng-deep .reader-content-new-release .focused-editor {
  outline: 2px solid #ff5500 !important;
  outline-offset: 3px !important;
  outline-style: dotted !important;
  border-radius: 1px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
