import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {BaseUser, MqService, ShopInfo, UsersApiService} from 'core';
import {DropdownItemModel} from 'shared';
import {TranslateService} from '@ngx-translate/core';
import {ShopListUser} from 'main/shop/shop.models';
import {ClipboardService} from 'ngx-clipboard';
import {ShopBrandingService} from 'main/shop/shop-branding.service';
import {Subscription} from 'rxjs';
import {BitbookApiService} from "bitmark";

@Component({
  selector: 'app-learning-experience-user-list',
  templateUrl: './learning-experience-user-list.component.html',
  styleUrls: ['./learning-experience-user-list.component.scss'],
})
export class LearningExperienceUserListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() users: Array<ShopListUser> = [];
  @Input() course: any;
  @Input() courseSession: any;
  @Input() displayedUsers: Array<ShopListUser>;
  @Input() label: string;
  @Input() isOrganizer: boolean;
  @Input() isParticipantsList: boolean;
  @Input() showEmails: boolean;
  @Input() isExpertsList: boolean;
  @Input() filterText: string;
  @Input() usersWhoBoughtCourse: Array<number>;
  @Input() sortOrder: { key: string, order: number };
  @Input() listActions: Array<DropdownItemModel>;
  @Input() notBranded: boolean;
  @Input() theme: any;
  @Output() onEditEmail = new EventEmitter<{ oldEmail: string, newEmail: string }>();
  @Output() onDeleteEmail = new EventEmitter<string>();
  @Output() onJoinParticipantsForFree = new EventEmitter<Array<ShopListUser>>();
  @Output() onBuyForParticipantsPaid = new EventEmitter<Array<ShopListUser>>();
  @Output() onInviteUsersToJoinOrBuy = new EventEmitter<Array<ShopListUser>>();
  @Output() onRemovedParticipants = new EventEmitter<Array<string>>();
  @Output() onResendInvitation = new EventEmitter<ShopListUser>();
  @Output() onSetUserFlagEmojis = new EventEmitter<ShopListUser>();
  @Output() onUpdateUsers = new EventEmitter<Array<ShopListUser>>();
  @ViewChild('editingLabel') editingLabelEl: ElementRef;
  shopBrandingSubscription: Subscription;
  isEditing: boolean;
  operation: string;
  orderActions: any;
  removedUsers: Array<string>;
  redundantUsers: Array<string>;
  shopBranding: ShopInfo;

  constructor(private translate: TranslateService,
              private clipboardService: ClipboardService,
              private shopBrandingService: ShopBrandingService,
              private mqService: MqService,
              private bitbookApiService: BitbookApiService,
              private usersApiService: UsersApiService) {
  }

  ngOnInit() {
    this.orderActions = {
      'firstName': this.getFirstNameInitial,
      'lastName': this.getLastNameInitial,
      'email': this.getEmail,
    };

    this.listActions = this.isExpertsList ? [{
      label: this.translate.instant('Shop.Editor.CopyAll'),
      handler: this.copyAll.bind(this),
    }, {
      label: this.translate.instant('Shop.Editor.AddSeveral'),
      handler: this.addSeveral.bind(this),
    }] : [{
      label: this.translate.instant('Shop.Editor.CopyAll'),
      handler: this.copyAll.bind(this),
    }, {
      isSeparator: true,
    }, this.isParticipantsList ? {
      label: this.translate.instant('Shop.Editor.InviteUsersToBuy'),
      isHidden: this?.course?.simulatedPrice?.amount <= 0,
      handler: this.addSeveral.bind(this),
    } : {
      label: this.translate.instant('Shop.Editor.AddSeveral'),
      handler: this.addSeveral.bind(this),
    },
    {
      label: this.translate.instant('Shop.Editor.JoinForUsers'),
      handler: this.addSeveralAndJoinForFree.bind(this),
      isHidden: ( !this?.course?.simulatedPrice || this?.course?.simulatedPrice?.amount > 0 ) || !this.isParticipantsList
    },
    {
      label: this.translate.instant('Shop.Editor.BuyForUsers'),
      handler: this.addSeveralAndPay.bind(this),
      isHidden: (!this?.course?.simulatedPrice || this?.course?.simulatedPrice?.amount <= 0) || !this.isParticipantsList
    },
    {
      label: this.translate.instant('Shop.Editor.ReplaceAll'),
      handler: this.replaceAll.bind(this),
    }, {
      isSeparator: true,
    }, {
      label: this.translate.instant('Shop.Editor.DeleteAll'),
      handler: this.deleteAll.bind(this),
    }];
    if (!this.sortOrder) {
      this.sortOrder = {
        key: 'email',
        order: 1,
      };
    }
    this.shopBrandingSubscription = this.shopBrandingService.config$.subscribe(shopBranding => this.shopBranding = shopBranding);
    this.getOrganizersData();

    this.mqService.sub('shop-course-session-joined').subscribe((data: {
      sessionId: string,
      userIds: Array<number>
    }) => {
      if (this.courseSession.externalId === data?.sessionId && this.isOrganizer) {
        this.onSomeoneBoughtSession(data);
      }
    });
  }

  onSomeoneBoughtSession(data: { sessionId: string, userIds: Array<number> }) {
    this.usersWhoBoughtCourse = this.usersWhoBoughtCourse.concat(data?.userIds);
    this.displayedUsers.forEach((u) => {
      if (this.usersWhoBoughtCourse?.indexOf(+u.id) !== -1) {
        u.isCourseSessionJoined = true;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.users) {
      return;
    }
    if (changes?.filterText) {
      this.displayedUsers = this.filterUsers(changes?.filterText?.currentValue);
    }
    if (changes?.sortOrder && this.orderActions) {
      this.users = this.users?.sort(this.sortUsersByOrder.bind(this)) || [];
      this.displayedUsers = Object.assign([], this.users);
    }
  }

  filterUsers(filterText: string): Array<ShopListUser> {
    return Object.assign([], this.users.filter((u) => {
      return u?.email?.toLowerCase()?.indexOf(filterText?.toLowerCase()) !== -1
        || (u?.fullName && u?.fullName?.toLowerCase()?.indexOf(filterText?.toLowerCase()) !== -1)
        || (u?.emoji?.length && u?.emoji?.indexOf(filterText) !== -1);
    }));
  }

  ngOnDestroy() {
    this.shopBrandingSubscription.unsubscribe();
  }

  handleRemovedParticipants(participantEmails: Array<string>){
    if(participantEmails?.length && this.isParticipantsList){
      this.onRemovedParticipants.emit(participantEmails);
    }
  }

  getOrganizersData(oldOrganizers?: Array<ShopListUser>, newOrganizers?: Array<string>) {
    this.users = this.users?.length ? this.users : [];
    this.users?.forEach((u) => u.status = null);
    this.usersApiService.getUsersByMails(this.users.map((u) => u.email))
      .subscribe((users: Array<BaseUser>) => {
        if (this.isOrganizer) {
          const userList = this.users?.filter((o) => o?.email).reduce((prev, curr) => {
            const user = users?.find((u) => u.email?.toLowerCase() === curr?.email?.toLowerCase() || u.ssoEmail?.toLowerCase() === curr?.email?.toLowerCase());
            if (user) {
              const {id, email, ssoEmail, fullName, photoUrl} = user;
              return prev.concat([Object.assign({isCourseSessionJoined: this.usersWhoBoughtCourse?.indexOf(user.id) !== -1}, curr, {
                id,
                email,
                ssoEmail,
                fullName,
                photoUrl,
              })]);
            }
            return prev.concat(curr);
          }, []).sort(this.sortUsersByOrder.bind(this));
          if (oldOrganizers) {
            userList.forEach((u) => {
              if (oldOrganizers.findIndex((o) => o.email === u.email) === -1) {
                (u as any).status = 'new';
              }
            });
            this.removedUsers = [];
            oldOrganizers?.forEach((o) => {
              if (userList?.every((u) => u.email !== o?.email)) {
                this.removedUsers.push(o.email);
              }
            });
            this.handleRemovedParticipants(this.removedUsers);
            this.redundantUsers = [];
            newOrganizers?.forEach((o) => {
              if (oldOrganizers.findIndex((ol) => ol.email === o) !== -1) {
                this.redundantUsers.push(o);
              }
            });
            userList.forEach((e) => {
              if (this.redundantUsers.indexOf(e.email) !== -1) {
                e.status = 'redundant';
              }
            });
          }
          this.users = userList || [];
          if (!this.isParticipantsList) {
            this.operation = '';
            this.displayedUsers = Object.assign([], this.users);
            return;
          }
          if (this.operation === 'add') {
            const usersToJoinFor = this.users.filter((u) => u.status === 'redundant' || u.status === 'new').filter((u) => {
              return !this.usersWhoBoughtCourse.find((uId) => +u.id === uId);
            })
            if (usersToJoinFor?.length) {
              this.onInviteUsersToJoinOrBuy.emit(usersToJoinFor);
            } else {
              alert('All the new users have already joined the course');
            }
          }
          if (this.operation === 'add-paid') {
            const usersToBuyFor = this.users.filter((u) => u.status === 'redundant' || u.status === 'new').filter((u) => {
              return !this.usersWhoBoughtCourse.find((uId) => +u.id === uId);
            })
            if (usersToBuyFor?.length) {
              this.buyForParticipantsPaid(usersToBuyFor);
            } else {
              alert('All the new users have already joined the course');
            }
          }
          if (this.operation === 'add-free') {
            const usersToJoinFor = this.users.filter((u) => u.status === 'redundant' || u.status === 'new').filter((u) => {
              return !this.usersWhoBoughtCourse.find((uId) => +u.id === uId);
            })
            if (usersToJoinFor?.length) {
              this.joinParticipantsForFree(usersToJoinFor);
            } else {
              alert('All the new users have already joined the course');
            }
          }
        } else {
          const userList = this.users?.filter((o) => o?.email).reduce((prev, curr) => {
            const user = users.find((u) => u.email?.toLowerCase() === curr?.email?.toLowerCase() || u.ssoEmail?.toLowerCase() === curr?.email?.toLowerCase());
            if (user) {
              const {id, email, ssoEmail, fullName, photoUrl} = user;
              return prev.concat([Object.assign({}, curr, {id, email, ssoEmail, fullName, photoUrl})]);
            }
            return prev.concat(curr);
          }, []).sort((x, y) => {
            const xPropValue = this.getLastNameInitial(x)?.toLowerCase();
            const yPropValue = this.getLastNameInitial(y)?.toLowerCase();

            if (xPropValue < yPropValue) {
              return -1;
            } else if (xPropValue > yPropValue) {
              return 1;
            }
            return 0;
          });
          this.users = userList?.filter((u) => u.fullName) || [];
        }
        this.operation = '';
        this.displayedUsers = Object.assign([], this.users);
      });
  }

  sortUsersByOrder(x, y) {
    const xPropValue = this.orderActions[this.sortOrder?.key](x)?.toLowerCase();
    const yPropValue = this.orderActions[this.sortOrder?.key](y)?.toLowerCase();

    if (xPropValue < yPropValue) {
      return -1;
    } else if (xPropValue > yPropValue) {
      return 1;
    }
    return 0;
  }

  saveChanges() {
    this.isEditing = false;
    if (this.operation === 'add') {
      const oldOrganizers = JSON.parse(JSON.stringify(this.users));
      const newOrganizers = this.extractEmails(this.editingLabelEl.nativeElement.innerText.toLowerCase());
      this.users = (this.users || []).concat(newOrganizers.map((e) => {
        return {email: e};
      }));
      this.users = this.users.filter((item, index) => this.users.findIndex((i) => i.email === item.email) === index);
      this.getOrganizersData(oldOrganizers, newOrganizers);
      this.emitChanges(this.users.map((s) => {
        const existingUser = this.users.find((u) => u.email === s);
        return existingUser ? (existingUser as any) : s;
      }));
    } else if (this.operation === 'add-paid') {
      const oldOrganizers = JSON.parse(JSON.stringify(this.users));
      const newOrganizers = this.extractEmails(this.editingLabelEl.nativeElement.innerText.toLowerCase());
      this.users = (this.users || []).concat(newOrganizers.map((e) => {
        return {email: e};
      }));
      this.users = this.users.filter((item, index) => this.users.findIndex((i) => i.email === item.email) === index);
      this.getOrganizersData(oldOrganizers, newOrganizers);
      this.emitChanges(this.users.map((s) => {
        const existingUser = this.users.find((u) => u.email === s);
        return existingUser ? (existingUser as any) : s;
      }));
    } else if (this.operation === 'add-free') {
      const oldOrganizers = JSON.parse(JSON.stringify(this.users));
      const newOrganizers = this.extractEmails(this.editingLabelEl.nativeElement.innerText.toLowerCase());
      this.users = (this.users || []).concat(newOrganizers.map((e) => {
        return {email: e};
      }));
      this.users = this.users.filter((item, index) => this.users.findIndex((i) => i.email === item.email) === index);
      this.getOrganizersData(oldOrganizers, newOrganizers);
      this.emitChanges(this.users.map((s) => {
        const existingUser = this.users.find((u) => u.email === s);
        return existingUser ? (existingUser as any) : s;
      }));
    } else {
      const oldOrganizers = JSON.parse(JSON.stringify(this.users));
      const newOrganizers = this.extractEmails(this.editingLabelEl.nativeElement.innerText.toLowerCase());
      this.emitChanges(newOrganizers.map((s) => {
        const existingUser = this.users.find((u) => u.email === s);
        return existingUser ? (existingUser as any) : {email: s};
      }));
      this.users = JSON.parse(JSON.stringify(newOrganizers.map((e) => {
        return {email: e};
      })));
      this.users = (this.users || []).filter((item, index) => this.users.findIndex((i) => i.email === item.email) === index);
      this.getOrganizersData(oldOrganizers, newOrganizers);
    }
  }

  emitChanges(organizers: Array<ShopListUser>) {
    this.onUpdateUsers.emit(organizers.map((u) => {
      const {email, ssoEmail, emoji, id} = u;
      return Object.assign({}, {email, ssoEmail, emoji, id});
    }));
  }

  cancelEditing() {
    this.isEditing = false;
    this.operation = '';
    this.editingLabelEl.nativeElement.innerHTML = '';
  }

  addSeveral() {
    this.operation = 'add';
    this.isEditing = true;
  }

  addSeveralAndPay() {
    this.operation = 'add-paid';
    this.isEditing = true;
  }

  addSeveralAndJoinForFree() {
    this.operation = 'add-free';
    this.isEditing = true;
  }

  replaceAll() {
    this.operation = 'replace';
    this.isEditing = true;
  }

  deleteAll() {
    this.handleRemovedParticipants(this.users?.map((u) => u.email));
    this.users = [];
    this.displayedUsers = [];
    this.emitChanges([]);
    this.getOrganizersData();
  }

  copyAll() {
    this.clipboardService.copy(this.displayedUsers.map((u) => u?.email).join('\n'));
  }

  joinParticipantsForFree(users: Array<ShopListUser>) {
    this.onJoinParticipantsForFree.emit(users);
  }

  buyForParticipantsPaid(participants: Array<ShopListUser>) {
    this.onBuyForParticipantsPaid.emit(participants);
  }

  buyForUser(user: ShopListUser) {
    if (this?.course?.simulatedPrice?.amount <= 0) {
      this.onJoinParticipantsForFree.emit([user]);
    } else {
      this.onBuyForParticipantsPaid.emit([user]);
    }
  }

  resendInvitation(user: ShopListUser) {
    this.onResendInvitation.emit(user);
  }

  deleteEmail(email: string) {
    this.handleRemovedParticipants([email]);
    this.users = this.users.filter((i) => {
      return i.email?.trim()?.toLowerCase() !== email.trim().toLowerCase();
    });
    this.displayedUsers = Object.assign([], this.users);
    this.emitChanges(this.users);
  }

  editEmail(data: { oldEmail: string, newEmail: string }) {
    this.handleRemovedParticipants([data?.oldEmail]);
    const idx = this.users.findIndex((u) => u.email === data.oldEmail);
    this.users.splice(idx, 1, {email: data.newEmail});
    this.displayedUsers = Object.assign([], this.users);
    this.getOrganizersData();
    this.emitChanges(this.users);
  }

  editUser(data: ShopListUser) {
    this.emitChanges(this.users);
  }

  getLastNameInitial(user) {
    if (user.lastName?.length) {
      return user.lastName;
    }
    if (user.email?.indexOf('@') !== -1 && user.email?.split('@')[0].indexOf('.') !== -1) {
      return user.email.split('.')[1];
    }
    if (user.fullName?.indexOf(' ') !== -1 && user.fullName?.split(' ')[1].length > 0) {
      return user.fullName?.split(' ')[1];
    }
    return user.fullName || user.email;
  }

  getFirstNameInitial(user) {
    if (user.firstName?.length) {
      return user.firstName;
    }
    if (user.email?.indexOf('@') !== -1 && user.email?.split('@')[0].indexOf('.') !== -1) {
      return user.email.split('.')[0];
    }
    if (user.fullName?.indexOf(' ') !== -1 && user.fullName?.split(' ')[0].length > 0) {
      return user.fullName?.split(' ')[0];
    }
    return user.fullName || user.email;
  }

  getEmail(user) {
    return user?.email;
  }

  extractEmails(text) {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
  }
}
