import {Blockquote} from '@tiptap/extension-blockquote';

export const BitmarkQuote = Blockquote.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: 'simple-quote',
      },
      quoteType: {
        default: 'simple-quote'
      }
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setQuoteClass: (quoteType) => ({ commands }) => {
        return commands.updateAttributes('blockquote', { class: quoteType, quoteType: quoteType});
      },
    };
  },

  // addCommands(): any {
  //   return {
  //     setScientificQuote: attributes => ({ commands }) => {
  //       return commands.setNode(this.name, attributes);
  //     },
  //     toggleScientificQuote: attributes => ({ commands }) => {
  //       return commands.toggleNode(this.name, attributes);
  //     },
  //     // unsetScientificQuote: () => ({ commands }) => {
  //     //   return commands.unsetNode(this.name);
  //     // },
  //   };
  // },
});
