import {AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, Output,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DropdownItemModel, EventEmitter as EE, ScrollDirective, SubSink} from '../../../../shared';
import {BitmarkFormat, DefaultBitAnnotationColors, ProductFamily} from '../../../../shared/models';
import {ReaderModes} from '../../../reader.models';
import {BitbookApiService} from '../../../bitbook-api.service';
import {BitbookMqService} from '../../../bitbook-mq.service';
import {ReaderTocService} from '../../../reader-toc.service';
import {ReaderBasketService} from '../../../reader-basket.service';
import {BitApiAnnotation, BitApiWrapper, BitResource, BitType} from '../../../../bits/bits.models';
import {ReaderClipboardService} from '../../../reader-clipboard.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {BitmarkConfig} from '../../../../bitmark.module';
import {ReaderContentService} from '../reader-content.service';
import {ParserApiService} from '../../../parser-api.service';
import {ReaderContentCommonComponent} from '../reader-content-common.component';
import {BrandingRenderService} from '../../../branding-render.service';
import {ReaderBitsBinService} from '../../../reader-bits-bin.service';
import {ReaderTipTapTapService} from '../../../tiptap/reader-tiptap.service';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {DomObserverService} from '../../../../shared/dom/dom-observer.service';
import {AnalyticsService} from '../../../../shared/analytics/analytics.service';
import {RolesApiService, TaughtClass} from '../../../roles-api.service';
import StringCaseService from '../../../../shared/utils/string-case.service';
import {ArrayService} from '../../../../shared/utils/array.service';

@Component({
  selector: 'bitmark-reader-content-self-learning',
  templateUrl: './reader-content-self-learning.component.html',
  styleUrls: ['../../../reader-common.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('300ms ease-in', style({transform: 'translateY(0%)'})),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateY(100%)'})),
      ]),
    ]),
  ],
})
export class ReaderContentSelfLearningComponent extends ReaderContentCommonComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isEditable: boolean;
  @Input() readerMode: ReaderModes;
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();

  ReaderModes = ReaderModes;
  BitType = BitType;
  BitmarkFormat = BitmarkFormat;

  bitActions: Array<DropdownItemModel>;
  bitEditorActions: Array<DropdownItemModel> = [];

  private sub = new SubSink();
  private subImgClicked: string;
  private subBitmarkChanged: string;
  private subInternalLinkClicked: string;
  private subCrossLinkClicked: string;

  // @ViewChild('infiniteScroll') infiniteScroll: ElementRef;

  @HostListener('window:popstate', ['$event'])
  // this is used when using the browser back button after selecting bits in toc/using internal link
  onPopState() {
    this.popStateListenerAction();
  }

  constructor(@Inject('BitmarkConfig') bitmarkConfig: BitmarkConfig,
              bitBookApiService: BitbookApiService,
              bitbookMqService: BitbookMqService,
              readerTocService: ReaderTocService,
              translate: TranslateService,
              router: Router,
              private route: ActivatedRoute,
              location: Location,
              parserApiService: ParserApiService,
              basketSvc: ReaderBasketService,
              readerBasketService: ReaderBasketService,
              readerClipboard: ReaderClipboardService,
              readerContentService: ReaderContentService,
              clipboardService: ClipboardService,
              brandingRenderService: BrandingRenderService,
              readerTipTapService: ReaderTipTapTapService,
              ngbModal: NgbModal,
              private readerBitsBinService: ReaderBitsBinService,
              scrollDirective: ScrollDirective,
              domUtilsService: DomUtilsService,
              domObserverService: DomObserverService,
              fileUtilsService: FileUtilsService,
              deviceDetectorService: DeviceDetectorService,
              analyticsService: AnalyticsService,
              rolesApiService: RolesApiService,
              stringCaseService: StringCaseService,
              arrayService: ArrayService,
              private ngZone: NgZone) {
    super(bitmarkConfig,
      bitBookApiService,
      readerContentService,
      bitbookMqService,
      readerTocService,
      readerBasketService,
      readerClipboard,
      clipboardService,
      basketSvc,
      translate,
      parserApiService,
      router,
      location,
      brandingRenderService,
      readerTipTapService,
      ngbModal,
      scrollDirective,
      domUtilsService,
      domObserverService,
      fileUtilsService,
      deviceDetectorService,
      analyticsService,
      rolesApiService,
      stringCaseService,
      arrayService
    );
  }

  ngOnInit() {
    this.pagination = {
      pageSize: 1000,
      pageNumber: 1,
      startBitId: null,
    };
    this.context = ReaderModes.SelfLearningReadonly;
    this.globalNotebookApiQueryParams = {
      space: this.bitmarkConfig.space,
    };
    // console.log('READER MODE: ', this.readerMode);
    // console.log('CONTEXT: ', this.context);
    const menuActions = [{
      label: this.translate.instant('Reader.Actions.CopyAllBitsToBasket'),
      handler: this.copyAllBitsToBasket.bind(this),
    }];
    this.bitbookMqService.notifyReaderMenuReady(this.bitmarkConfig.isProUser
      ? [{
        label: this.translate.instant('Edit'),
        handler: this.openEditBookEditor.bind(this),
      }, {
        label: this.translate.instant('JSON Editor'),
        handler: this.openJsonBookEditor.bind(this),
      }, ...menuActions]
      : menuActions);

    this.rolesApiService.getTaughtClasses()
      .subscribe((classes: Array<TaughtClass>) => {
        this.bitActions = [{
          label: this.translate.instant('Reader.Basket.AddToBasket'),
          handler: this.addToBasket.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.SendContinue'),
          handler: this.sendBit.bind(this),
        }, classes?.length ? {
          label: this.translate.instant('Reader.Actions.SendToClass'),
          handler: this.sendBitToClass.bind(this)
        } : null, {
          label: this.translate.instant('Reader.Actions.SaveToAnotherNotebook'),
          handler: this.saveBit.bind(this),
          // }, {id: 'reset-answer', isSeparator: true}, {
          //   id: 'reset-answer',
          //   label: this.translate.instant('Reader.Actions.ResetAnswer'),
          //   handler: this.resetAnswer.bind(this),
        }, {
          isSeparator: true,
          isHidden: !this.bitmarkConfig.isProUser,
        }, {
          label: this.translate.instant('Reader.Actions.CopyBitmarkJSON'),
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkJsonToClipboard.bind(this),
        }, {
          label: 'Reader.Actions.BrandingPublisherTheme',
          isHidden: !this.bitmarkConfig.isProUser,
          isDisabled: true
        }, {
          isSeparator: true,
        }, {
          label: this.translate.instant('Reader.Actions.AddBookmark'),
          handler: this.createAnnotationBookmarkOnBit.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.AddFavorite'),
          handler: this.createAnnotationFavoriteOnBit.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.AddNote'),
          handler: this.createAnnotationNoteOnBit.bind(this),
        }, {
          label: this.translate.instant('Reader.Actions.AddHandwritten'),
          handler: this.createAnnotationHandwrittenOnBit.bind(this),
        }].filter(x => !!x);
      });

    this.skeletonLoaderActions = [{
      id: 'delete-immediately',
      showOnAltKey: true,
      label: this.translate.instant('Shared.DeleteImmediately'),
      isHidden: this.readerMode === ReaderModes.SelfLearningReadonly,
      handler: this.stopBitGeneration.bind(this),
    }];

    this.bitAnnotationActions = [{
      id: 'color',
      colors: DefaultBitAnnotationColors,
      handler: this.selectAnnotationColor.bind(this),
    }, {isSeparator: true}, {
      label: this.translate.instant('Shared.Delete'),
      handler: this.deleteAnnotation.bind(this),
    }];

    if (this.readerMode === ReaderModes.SelfLearningReadonly) {
      this.bitEditorActions = [];
    } else {
      this.bitEditorActions = [{
        label: this.translate.instant('Reader.Actions.AddChapter'),
        handler: this.createChapterAfterBit.bind(this),
      }, {
        label: this.translate.instant('Reader.Actions.AddBit'),
        isHidden: !this.bitmarkConfig.isProUser,
        handler: this.openBitEditor.bind(this),
      }, {
        id: 'branding-placeholder',
        isHidden: true
      }, {
        isSeparator: true
      }, {
        label: this.translate.instant('Shared.Paste'),
        handler: this.pasteFromClipboard.bind(this),
      }, {
        label: this.translate.instant('Reader.Actions.InsertBasket'),
        handler: this.onInsertBasket.bind(this),
      }, {
        isSeparator: true
      }, {
        label: 'More...',
        actions: [{
          label: this.translate.instant('Shared.UploadPDF'),
          handler: this.insertPdf.bind(this),
        }]
      }];

      if (this.bitmarkConfig?.editorMenusFromTheme?.enabled) {
        this.populateAddBitsMenu();
      }
    }

    this.sub.sink = this.bitbookMqService.onReaderBitSelected()
      .subscribe(this.handleBitSelected.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderRefreshNotebook()
      .subscribe(this.refreshNotebookIfNeeded.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe(this.handleBitsVisibilityChanged.bind(this));
    this.sub.sink = this.bitbookMqService.onInternalLinkClicked()
      .subscribe(this.handleInternalLink.bind(this));

    this.subImgClicked = EE.default.on('imgClicked', (x: BitResource) => this.ngZone.run(() => this.onOpenResource(x)));

    this.subBitmarkChanged = EE.default.on('bitmark-changed', (props: { id: string, data: any }) => {
      this.ngZone.run(() => {
        this.saveBitBitmark(props.id, props.data);
      });
    });
    this.subInternalLinkClicked = EE.default.on('internalLinkClicked', ({reference, bitId}: {reference: string, bitId: string}) => {
      this.ngZone.run(() => {
        this.navigateToBitReference(reference, bitId);
      });
    });
    this.subCrossLinkClicked = EE.default.on('crossRefLinkClicked', ({bookId, reference}: {bookId: string, reference: string}) => {
      this.ngZone.run(() => {
        this.navigateToBookBitReference(bookId, reference);
      });
    });

    this.renderPage(this.route.snapshot.fragment, false, {
      readerMode: this.readerMode,
      ignoreStartBit: false
    })
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    // if (this.readerMode !== 'shop-section' && this.readerMode !== 'shop-section-readonly') {
    //   const scrollContainerElem: HTMLElement = document.querySelector('.infinite-scroll-container');
    //   if (scrollContainerElem) {
    //     scrollContainerElem.scrollTop = 0;
    //   }
    // }
    // });
  }

  ngOnDestroy() {
    if (this.scrollEndUnlisten) {
      this.scrollEndUnlisten();
    }
    this.sub.unsubscribe();
    EE.default.clear(this.subImgClicked);
    EE.default.clear(this.subBitmarkChanged);
    EE.default.clear(this.subInternalLinkClicked);
    EE.default.clear(this.subCrossLinkClicked);
  }

  private moveBitToBin(dropdownItemModel: DropdownItemModel) {
    const isChapterBit = dropdownItemModel.data.bit.type === BitType.Chapter;
    this.readerBitsBinService.moveToBin(this.bitBook.id, dropdownItemModel.data.id)
      .subscribe(() => {
        this.deleteBitLocally(dropdownItemModel, isChapterBit);
        setTimeout(() => {
          const visibleBits = this.readerContentService.computeBitsVisibility(() => this.bitBookContent);
          this.bitbookMqService.notifyBitsVisibility(visibleBits);
        });
      }, err => console.error(err));
  }

  isBitActionsShown(index: number) {
    return this.bitBookContent[index]?.showBitActions || this.bitBookContent[index + 1]?.showBitActions;
  }

  showBitActions(index: number) {
    if (this.bitBookContent[index]) {
      this.bitBookContent[index].showBitActions = true;
      this.bitBookContent[index]?.annotations?.forEach((a) => {
        a.showBitActions = true;
      })
    }
  }

  hideBitActions(index: number) {
    if (this.bitBookContent[index]) {
      this.bitBookContent[index].showBitActions = false;
      this.bitBookContent[index]?.annotations?.forEach((a) => {
        a.showBitActions = false;
      })
    }
  }

  getShopApiWrapperFromAnnotation(bitApiWrapper: BitApiWrapper, annotation: BitApiAnnotation): BitApiWrapper {
    return {
      id: annotation.id.toString(),
      bit: {id: annotation.id.toString(), ...annotation.data},
      meta: bitApiWrapper.meta,
      showBitActions: bitApiWrapper?.showBitActions
    };
  }
}
