import {
  Component,
  EventEmitter, HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DropdownItemModel, SubSink} from '../../shared';
import {BitbookMqService} from '../bitbook-mq.service';
import {BitApiWrapper} from '../../bits/bits.models';
import {ReaderLocalContentService} from '../reader-local-content.service';
import {ReaderBasketService} from '../reader-basket.service';
import {BrandingRenderService} from "../branding-render.service";

@Component({
  selector: 'bitmark-reader-basket',
  templateUrl: './reader-basket.component.html',
  styleUrls: ['./reader-basket.component.scss']
})
export class ReaderBasketComponent implements OnInit, OnDestroy, OnChanges {
  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() findInBook = new EventEmitter<BitApiWrapper>();
  basketModalRef;
  basketCount = -1;
  actions: Array<DropdownItemModel> = [];
  brandingClass = '';

  private sub = new SubSink();

  // this is needed when there are items added to the cart in a different tab
  @HostListener('document:visibilitychange', ['$event'])
  synchroniseBasketCount() {
    if (!document.hidden) {
      this.readerBasketService.getBasketCount()
        .subscribe(this.updateBasketCount.bind(this));
    }
  }

  constructor(private router: Router,
              private ngbModal: NgbModal,
              private activatedRoute: ActivatedRoute,
              private readerBasketService: ReaderBasketService,
              private readerLocalContentService: ReaderLocalContentService,
              private bitbookMqService: BitbookMqService,
              private basketSvc: ReaderBasketService,
              private ngbActiveModal: NgbActiveModal,
              private renderer: Renderer2,
              private translate: TranslateService,
              private brandingRenderService: BrandingRenderService) {
  }

  ngOnInit() {
    this.sub.sink = this.readerBasketService.getBasketCount()
      .subscribe(this.updateBasketCount.bind(this));
    this.sub.sink = this.bitbookMqService.onBasketCountUpdated()
      .subscribe(this.updateBasketCount.bind(this));

    this.actions = [{
      label: this.translate.instant('Reader.Basket.SendAll'),
      handler: this.onSendAll.bind(this)
    }, {
      label: this.translate.instant('Reader.Basket.SaveAll'),
      handler: this.onSaveAll.bind(this)
    }, {
      isSeparator: true
    }, {
      label: this.translate.instant('Reader.Basket.EmptyBasket'),
      handler: this.clearBasket.bind(this)
    }];
    this.brandingRenderService.applyBookBranding({})
      .subscribe((res: { themeClass: string }) => {
        this.brandingClass = res?.themeClass;
        this.bitbookMqService.notifyReaderBrandingSet(null);
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnChanges() {
    if (this.basketModalRef && this.basketModalRef._windowCmptRef) {
      const ngbModalWindowNativeElement = (this.basketModalRef as any)._windowCmptRef.instance._elRef.nativeElement;
      this.renderer.removeClass(ngbModalWindowNativeElement, 'col-md-9');
    }
  }

  private updateBasketCount(basketCount: number) {
    this.basketCount = basketCount;
  }

  close() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: '',
      replaceUrl: false
    });
    this.ngbActiveModal.close();
  }

  onSendAll() {
    this.basketSvc.getBasketItems()
      .subscribe((basketItems: Array<BitApiWrapper>) => basketItems?.length && this.sendBits.emit(basketItems));
  }

  onSaveAll() {
    this.basketSvc.getBasketItems()
      .subscribe((basketItems: Array<BitApiWrapper>) => basketItems?.length && this.saveBits.emit(basketItems));
  }

  clearBasket() {
    this.basketSvc.clearBasket().subscribe();
  }
}
