import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ScrollbarService} from 'core';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html'
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<any>();
  window: any = window;
  document: any = document;

  constructor(private activeModal: NgbActiveModal,
              private scrollbarService: ScrollbarService) {
  }

  ngOnInit() {
    this.scrollbarService.lockBodyScrollbar();
  }

  ngOnDestroy() {
    this.scrollbarService.unLockBodyScrollbar();
  }

  close() {
    this.closed.emit();
  }
}
