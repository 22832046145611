export * from './autofocus/autofocus.directive';
import {AutofocusDirective} from './autofocus/autofocus.directive';

export * from './autofocus/autoselect.directive';
import {AutoSelectDirective} from './autofocus/autoselect.directive';

export * from './back/back.component';
import {BackComponent} from './back/back.component';

export * from './container/container.component';
import {ContainerComponent} from './container/container.component';

export * from './dialog/dialog.component';
import {DialogComponent} from './dialog/dialog.component';

export * from './dom/dom-change.directive';
import {DomChangeDirective} from './dom/dom-change.directive';

export * from './dropdown';
import {DropdownComponent, DropdownDesktopComponent, DropdownMobileComponent} from './dropdown';

export * from './emoticons';
import {EmoticonsComponent} from './emoticons';

export * from './error-pane/error-pane.component';
import {ErrorPaneComponent} from './error-pane/error-pane.component';

export * from 'shared/editable-label/editable-label.component';
import {EditableLabelComponent} from 'shared/editable-label/editable-label.component';

export * from 'shared/icon/icon.component';
import {IconComponent} from 'shared/icon/icon.component';

export * from './img/img.directive';
import {ImgDirective} from './img/img.directive';

export * from './logo/logo.component';
import {LogoComponent} from './logo/logo.component';

export * from './organizer-list/organizer-list.component';
import {OrganizerListComponent} from './organizer-list/organizer-list.component';

export * from './organizer-list/organizer-list-item/organizer-list-item.component';
import {OrganizerListItemComponent} from './organizer-list/organizer-list-item/organizer-list-item.component';

export * from './course-user-list/course-user-list.component';
import {CourseUserListComponent} from './course-user-list/course-user-list.component';

export * from './course-user-list/course-user-list-item/course-user-list-item.component';
import {CourseUserListItemComponent} from './course-user-list/course-user-list-item/course-user-list-item.component';

export * from './learning-experience-user-list/learning-experience-user-list.component';
import {LearningExperienceUserListComponent} from './learning-experience-user-list/learning-experience-user-list.component';

export * from './learning-experience-user-list/learning-experience-user-list-item/learning-experience-user-list-item.component';
import {LearningExperienceUserListItemComponent} from './learning-experience-user-list/learning-experience-user-list-item/learning-experience-user-list-item.component';

export * from './modal-wrapper/modal-wrapper.component';
import {ModalWrapperComponent} from './modal-wrapper/modal-wrapper.component';

export * from './ordering-animation/ordering-animation.directive';
import {OrderingAnimationDirective} from './ordering-animation/ordering-animation.directive';

export * from './pagination/pagination.component';
import {PaginationComponent} from './pagination/pagination.component';

export * from './pagination/pagination-label/pagination-label.component';
import {PaginationLabelComponent} from './pagination/pagination-label/pagination-label.component';

export * from './pipes';
import {FileSizePipe, SafeHtmlPipe, SafeStylePipe, OrderByPipe, SanitizePipe} from './pipes';

export * from './profile-photo-upload/profile-photo-upload.component';
import {ProfilePhotoUploadComponent} from './profile-photo-upload/profile-photo-upload.component';

export * from './progess-spinner/progess-spinner.component';
import {ProgessSpinnerComponent} from './progess-spinner/progess-spinner.component';

export * from './scroll-anti-block';
import {ScrollAntiBlockDirective, ScrollMemoDirective} from './scroll-anti-block';

export * from './search-box/search-box.component';
import {SearchBoxComponent} from './search-box/search-box.component';

export * from './search-suggestion-item/search-suggestion-item.component';
import {SearchSuggestionItemComponent} from './search-suggestion-item/search-suggestion-item.component';

export * from './swipe-to-close';
import {TouchSwipeDirective} from './swipe-to-close';

export * from './text-context-menu/text-context-menu.component';
import {TextContextMenuComponent} from './text-context-menu/text-context-menu.component';

export * from './text-context-menu-desktop/text-context-menu-desktop.component';
import {TextContextMenuDesktopComponent} from './text-context-menu-desktop/text-context-menu-desktop.component';

export * from './transition-group/transition-group.component';
import {TransitionGroupComponent} from './transition-group/transition-group.component';

export * from './transition-group/transition-group-item.directive';
import {TransitionGroupItemDirective} from 'shared/transition-group/transition-group-item.directive';

export * from './toggle-side/toggle-side.component';
import {ToggleSideComponent} from './toggle-side/toggle-side.component';

export * from './user-box';
import {
  UserBoxPeerComponent,
  UserBoxPersonalComponent,
  UserBoxSimpleComponent
} from './user-box';

export * from './user-status-dropdown/user-status-dropdown.component';
import {UserStatusDropdownComponent} from './user-status-dropdown/user-status-dropdown.component';

export * from './verify-email/verify-email.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component';

export * from './prevent-default/prevent-default.directive';
import {PreventDefaultDirective} from './prevent-default/prevent-default.directive';

export const components = [
  AutofocusDirective,
  AutoSelectDirective,
  BackComponent,
  ContainerComponent,
  DialogComponent,
  EditableLabelComponent,
  DomChangeDirective,
  DropdownComponent, DropdownDesktopComponent, DropdownMobileComponent,
  EmoticonsComponent,
  ErrorPaneComponent,
  IconComponent,
  ImgDirective,
  LogoComponent,
  ModalWrapperComponent,
  OrderingAnimationDirective,
  OrganizerListComponent,
  OrganizerListItemComponent,
  CourseUserListComponent,
  CourseUserListItemComponent,
  LearningExperienceUserListComponent,
  LearningExperienceUserListItemComponent,
  PaginationComponent, PaginationLabelComponent,
  FileSizePipe, SanitizePipe, SafeHtmlPipe, SafeStylePipe, OrderByPipe,
  ProfilePhotoUploadComponent,
  ProgessSpinnerComponent,
  ScrollAntiBlockDirective, ScrollMemoDirective,
  SearchBoxComponent,
  SearchSuggestionItemComponent,
  TouchSwipeDirective,
  TextContextMenuComponent, TextContextMenuDesktopComponent,
  ToggleSideComponent,
  TransitionGroupComponent, TransitionGroupItemDirective,
  UserBoxPeerComponent, UserBoxPersonalComponent, UserBoxSimpleComponent,
  UserStatusDropdownComponent,
  VerifyEmailComponent,
  PreventDefaultDirective
];
