import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {cloneDeep} from 'lodash';
import {LearningPathLtiBit} from './learning-path-lti.models';
import {BitResource, BitResourceType} from '../../bits.models';
import {ApiRouterService} from '../../../shared/api/api-router.service';
import {BitbookApiService} from '../../../reader/bitbook-api.service';
import {ApiService} from '../../../shared/api/api.service';
import {ParserApiService} from '../../../reader/parser-api.service';
import {LearningPathCommon} from '../learning-path.common';
import {BitmarkTextContentService} from '../../../shared';
import {ReaderTipTapTapService} from "../../../reader/tiptap/reader-tiptap.service";

@Component({
  selector: 'bitmark-learning-path-lti',
  templateUrl: './learning-path-lti.component.html',
  styleUrls: ['./learning-path-lti.component.scss', '../learning-path.common.scss', '../../bits.scss']
})
export class LearningPathLtiComponent extends LearningPathCommon implements OnInit, OnDestroy {
  @Input() bit?: LearningPathLtiBit;
  @Input() bitId?: string;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningPathLtiBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    this.trimHeader(this.bit);

    if (value) {
      this.isContentVisible = true;
      this.prevLearningPathBit = cloneDeep(this.bit);
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  public isContentVisible = true;
  private prevLearningPathBit?: LearningPathLtiBit;
  private deepLinkIntervalId: any | undefined;
  selectedResource: any;

  get isHeaderMinimized(): boolean {
    return !this.isContentVisible ||
      (this.bit.isInfoOnly && !this.bit.body);
  }

  get isHeaderOnly(): boolean {
    if (this.bit.isInfoOnly && !this.bit.body && !this.isBeingEditedByUser) {
      return true;
    }

    return !this.bit.body && !this.isBeingEditedByUser && !this.bit.deepLink;
  }

  get isTracked(): boolean {
    return this.bit.isTracked ?? true;
  }

  constructor(private apiRouterService: ApiRouterService,
              private apiService: ApiService,
              private parserApiService: ParserApiService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              private authService: AuthService,
              private bitBookApiService: BitbookApiService) {
    super(readerTipTapService, bitmarkTextContentService);
  }

  ngOnInit() {
    this.handleImage(this.bit);
  }

  ngOnDestroy() {
    if (this.deepLinkIntervalId) {
      clearInterval(this.deepLinkIntervalId);
    }
  }

  openDeepLink() {
    const bitId = this.bit.id || this.bitId;

    if (this.deepLinkIntervalId) {
      clearInterval(this.deepLinkIntervalId);
    }

    this.deepLinkIntervalId = setInterval(() => {
      this.apiService.get('lti/resource/bit/{id}', {id: bitId}).subscribe(result => {
        if (result) {
          console.log(result);
          this.selectedResource = result;
          clearInterval(this.deepLinkIntervalId);
          this.deepLinkIntervalId = undefined;

          this.bit.lineItem = {...result.lineItem};
          this.bit.instruction = this.selectedResource.title;
          this.bit.body = this.selectedResource.text;
          this.bit.deepLink = this.selectedResource.url;
        }
      });
    }, 2000);

    const apiRoute = this.apiRouterService.computeRouteById(
      'lti/deeplink/open',
      null,
      {bit: bitId, jwt: this.authService.getToken()});
    window.open(apiRoute, '_blank');
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevLearningPathBit);
    clearInterval(this.deepLinkIntervalId);
    this.deepLinkIntervalId = undefined;
    this.editClosed.emit();
  }

  saveChanges() {
    // if (this.selectedResource) {
    //   this.learningPathLtiBit.instruction = this.selectedResource.title;
    //   this.learningPathLtiBit.body = this.selectedResource.text;
    //   this.learningPathLtiBit.deepLink = this.selectedResource.url;
    // }

    this.trimHeader(this.bit);
    this.saveInlineBit.emit(this.bit);
  }

  uploadImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitBookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        if (!this.bit.resource) {
          this.bit.resource = {
            type: BitResourceType.Image
          };
        }
        this.bit.resource.image = {
          src: resourceUrl.url,
          alt: this.bit.instruction
        };
        this.imageExists = true;
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  removeImage() {
    if (this.bit.resource) {
      this.bit.resource = null;
    }
    this.imageExists = false;
  }

  open(event) {
    event.stopPropagation();

    const bitId = this.bit.id || this.bitId;

    if (!this.bit.answer?.hasOpened) {
      this.bit.answer = {
        ...this.bit.answer,
        hasOpened: true
      };
      this.changed.emit();
    }

    if (this.bit.deepLink) {
      const apiRoute = this.apiRouterService.computeRouteById(
        'lti/resource/open',
        null,
        {bit: bitId, link: this.bit.deepLink, jwt: this.authService.getToken()});
      window.open(apiRoute, '_blank');
    }
  }

  toggleContent() {
    if (this.bit.isInfoOnly || this.isBeingEditedByUser) {
      return;
    }

    this.isContentVisible = !this.isContentVisible;
  }

  markAsDone() {
    this.bit.answer = {
      ...this.bit.answer,
      isDone: true
    };
    this.changed.emit();
  }

  reset(event) {
    event.stopPropagation();
    this.bit.answer = {
      hasOpened: false,
      isDone: false
    };
    this.changed.emit();
  }
}
