import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BrowserStorageService} from '../shared/browser-storage/browser-storage.service';

export interface BookPosition {
  bitId: any;
  index: number;
  distance: number;
}

@Injectable({providedIn: 'root'})
export class ReaderTrackingService {
  private TRACKING_KEY = 'reader-last-position-';

  constructor(private browserStorageService: BrowserStorageService) {
  }

  getLastPositionForBook(bookExternalId: string): Observable<BookPosition> {
    return this.browserStorageService.idb.get(`${this.TRACKING_KEY}${bookExternalId}`);
    // .pipe(tap((x) => console.log('getting last pos for', bookExternalId, x)));
  }

  storeLastPositionForBook(bookExternalId: string, bookPos: BookPosition): Observable<any> {
    // console.log('storing last pos', bookPos);
    return this.browserStorageService.idb.store(`${this.TRACKING_KEY}${bookExternalId}`,
      Object.assign({}, bookPos, {index: bookPos.index || -1}));
  }

  clearLastPositionForBook(bookExternalId: string): Observable<any> {
    return this.browserStorageService.idb.clear(`${this.TRACKING_KEY}${bookExternalId}`);
  }
}
