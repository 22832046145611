import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PageBit} from './page.models';
import {BitResource, BitResourceSize} from '../bits.models';

@Component({
  selector: 'bitmark-page',
  templateUrl: './page.component.html',
  styleUrls: ['../bits.scss']
})
export class PageComponent {
  @Input() bit?: PageBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
}
