import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BitmarkFormat} from '../../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-app-flashcards-card',
  templateUrl: './app-flashcards-card.component.html',
  styleUrls: ['./app-flashcards-card.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('front', style({transform: 'rotateY(0deg)'})),
      state('back', style({transform: 'rotateY(-180deg)'})),
      state('front-no', style({transform: 'rotateY(0deg)'})),
      state('back-no', style({transform: 'rotateY(-180deg)'})),
      transition('back => front', animate('500ms ease-out')),
      transition('front => back', animate('500ms ease-in')),
      transition('back => front-no', animate('0s ease-out')),
      transition('front-no => back', animate('500ms ease-in'))
    ])
  ]
})
export class AppFlashcardsCardComponent {
  @Input() cardState: string;
  @Input() frontCard: { text: any, format: BitmarkFormat } = null;
  @Input() backCard: { text: any, format: BitmarkFormat } = null;
  @Input() showActionButtons = true;
  @Input() showCardIndex = true;
  @Input() currentCardIndex: number;
  @Input() cardsCount: number;
  @Input() markedAsRight = false;
  @Input() markedAsWrong = false;
  @Output() markAsWrong = new EventEmitter<any>();
  @Output() markAsRight = new EventEmitter<any>();
  @Output() goToNext = new EventEmitter<any>();

  cardClick() {
    const selection = window.getSelection();
    if (!selection?.toString()) {
      this.goToNext.emit();
    }
  }
}
