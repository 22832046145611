import {Component, Input} from '@angular/core';
import {ClipboardService} from 'ngx-clipboard';
import {ButtonCopyTextBit} from './button-copy-text.models';

@Component({
  selector: 'bitmark-button-copy-text',
  templateUrl: './button-copy-text.component.html',
  styleUrls: ['../bits.scss', '../bits.scss']
})
export class ButtonCopyTextComponent {
  @Input() bit?: ButtonCopyTextBit;

  constructor(private clipboardService: ClipboardService) {
  }

  copyText() {
    if (this.bit.body) {
      this.clipboardService.copy(this.bit.body);
    }
  }
}
