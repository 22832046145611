import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environment';
import {datadogRum} from '@datadog/browser-rum';

declare var DD_LOGS: any;
const hostName = window.location.hostname;
const currentSubdomain = window.location.hostname.toLowerCase().replace('.getmorebrain.com', '');
const env = hostName.includes('localhost') || hostName.includes('192.')
  ? 'local'
  : currentSubdomain || 'unknown';
const logsProxyUrl = `https://${currentSubdomain}.getmorebrain.com/browser-logs`;
let rumProxyUrl = `https://${currentSubdomain}.getmorebrain.com/rum`;
let ddRUMInitialized = false;

@Injectable({providedIn: 'root'})
export class DDService {
  init(enableRum: boolean, sampleRate: number, defaultPrivacyLevel: string) {
    try {
      if (!datadogRum?.getInternalContext()) {
        this.initDD(enableRum, sampleRate, defaultPrivacyLevel);
      }
    } catch (e) {
      console.error(e);
    }

    if (typeof DD_LOGS !== 'undefined' && DD_LOGS) {
      try {
        DD_LOGS.init({
          clientToken: 'pubea7096dd5c9fa75efaab389a8cbdb2ba',
          forwardErrorsToLogs: true,
          site: 'datadoghq.eu',
          service: 'gmb-client',
          sessionSampleRate: 100,
          env: env,
          version: environment.version,
          proxyUrl: logsProxyUrl
        });

        if (window.localStorage) {
          const volatileLocalStorage = window.localStorage['gmb-cosmic.volatile'];
          if (volatileLocalStorage) {
            try {
              const volatileData = JSON.parse(volatileLocalStorage);
              if (volatileData) {
                if (!volatileData.user) {
                  DD_LOGS.clearUser();
                } else {
                  DD_LOGS.setUser({
                    id: volatileData.user.id,
                    ddUuid: volatileData.user.ddUuid,
                    // name: volatileData.user.fullName,
                    // email: volatileData.user.email || volatileData.user.ssoEmail,
                  });
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  private initDD(enableRum: boolean, sampleRate: number, defaultPrivacyLevel: string) {
    try {
      if (currentSubdomain === 'localhost') {
        rumProxyUrl = `https://app.getmorebrain.com/rum`;
      }
      this.initializeDatadogRUM(enableRum, sampleRate, defaultPrivacyLevel);
    } catch (ex) {
      console.error(`Error initializing RUM: ${ex}`);
    }
  }

  private initializeDatadogRUM(enableRum: boolean, sampleRate: number, defaultPrivacyLevel: any) {
    if (enableRum && !ddRUMInitialized) {
      ddRUMInitialized = true;
      datadogRum.init({
        applicationId: 'd846f740-aeb9-40fb-8e04-537413432040',
        clientToken: 'pubd3f250013e2d48dfc0c8862a2558737c',
        site: 'datadoghq.eu',
        service: 'gmb-client',
        env: env,
        version: '1.0.0',
        sessionSampleRate: sampleRate,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: defaultPrivacyLevel,
        allowFallbackToLocalStorage: true,
        proxy: rumProxyUrl
      });

      datadogRum.onReady(() => {

      });
    }
  }

  isDDReady() {
    return new Observable((x: any) => {
      if (typeof DD_LOGS !== 'undefined' && DD_LOGS) {
        DD_LOGS.onReady(() => {
          x.next();
          x.complete();
        });
      }
    });
  }

  logInfo(infoText: string, payload: any) {
    this.isDDReady().subscribe(() => {
      DD_LOGS.logger.info(infoText, payload);
    });
  }
}
