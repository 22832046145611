import memoizee from 'memoizee';

// https://medium.com/angular-in-depth/how-to-improve-angular-performance-by-just-adding-just-8-characters-877bde708ddd
export function memoize(config?) {
  return function(target, key, descriptor) {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction, config);
    descriptor.value = function () {
      return newFunction.apply(this, arguments);
    };
  };
}
