import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {LearningEventExternalLinkBit} from './learning-event-external-link.models';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import UrlService from '../../../shared/utils/url.service';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';

@Component({
  selector: 'bitmark-learning-event-external-link',
  templateUrl: './learning-event-external-link.component.html',
  styleUrls: ['./learning-event-external-link.component.scss', '../learning-event.common.scss'],
  animations: toggleAnimations
})
export class LearningEventExternalLinkComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventExternalLinkBit;
  @Output() changed = new EventEmitter<any>();

  isLeadEmpty = false;
  isItemEmpty = false;
  imageExists = false;
  externalLinkHost = '';

  protected readonly BitmarkFormat = BitmarkFormat;

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService,
              private urlService: UrlService,
              private router: Router) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.toggleStateIsDone = this.bit?.answer?.isDone ? 'collapsed' : 'expanded';
    this.handleImage(this.bit);

    this.initExpandedBit(this.bit.id, this.bit.answer?.isDone);

    if (this.bit.externalLink) {
      this.externalLinkHost = this.urlService.getHostName(this.bit.externalLink);
    }
  }

  toggleCheckbox() {
    if (!this.bit.answer) {
      this.bit.answer = {};
    }

    this.bit.answer.isDone = !this.bit.answer.isDone;

    if (this.isBodyCollapsed) {
      if (this.bit.answer?.isDone) {
        this.toggleStateIsDone = 'collapsed';
      } else {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'expanded';
      }
    } else {
      if (this.bit.answer?.isDone) {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'collapsed';
        this.isBodyCollapsed = true;
      }
    }

    this.changed.emit();
  }

  open() {
    if (!this.bit.externalLink || !this.urlService.isValidInternalOrExternal(this.bit.externalLink)) {
      return;
    }

    let target = '_blank';
    let isRelative = false;
    if (this.bit.externalLink.startsWith('/')) {
      target = '_top';
      isRelative = true;
    } else {
      const linkUrl = new URL(this.bit.externalLink);
      if (window.location.host === linkUrl.host) {
        target = '_top';
      }
    }

    if (target === '_top') {
      let navRoute = this.bit.externalLink;
      if (!isRelative) {
        const navRouteUrl = new URL(navRoute);
        navRoute = navRouteUrl.pathname + navRouteUrl.search + navRouteUrl.hash;
      }

      if (navRoute.endsWith('/')) {
        this.router.navigate([navRoute]);
      } else {
        this.router.navigateByUrl(navRoute);
      }
    } else {
      window.open(this.bit.externalLink, target);
    }
  }
}
