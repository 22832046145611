import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IdentityService} from "core";
import {environment} from 'environment';


@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {
  code: string;
  space: string;
  buttonTitle: string;
  backgroundColor: string;
  messages: Array<{ content: string, cssClass?: string }>;
  showDontPanicImg: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private identityService: IdentityService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params.code || '404';
      this.space = params.space || 'personal';
      this.buttonTitle = this.code === '401'
        ? 'Shared.Retry'
        : 'Shared.Continue';
      this.backgroundColor = params.color
        || (this.code.startsWith('5') ? '#000' : '#e6137a');
      this.showDontPanicImg = ['Cosmic', 'Development'].includes(environment.name) && this.code.startsWith('5');
      this.messages = [{
        content: this.code === '403'
          ? `${this.code} Logged out`
          : this.code === '401'
            ? `${this.space.toUpperCase()}.${this.code} Access Denied`
            : this.code === '404'
              ? `${this.code} Not found`
              : `${this.code} Ups`,
        cssClass: 'h1'
      }]
        .concat(Object.keys(params)
          .filter(k => ['reason'].includes(k))
          .map(k => {
            return {
              content: params[k],
              cssClass: 'h4'
            }
          }))
        .concat(Object.keys(params)
          .filter(k => !['color', 'code', 'space', 'reason'].includes(k))
          .map(k => {
            return {
              content: params[k],
              cssClass: 'font-weight-bold'
            }
          }))
        .concat([{
          content: `Get More Brain v.${environment.version}`,
          cssClass: 'small mt-6'
        }]);
    });
  }

  handleContinue() {
    if (this.code === '403') {
      this.router.navigateByUrl('/logout');
    } else if (this.code === '401') {
      this.router.navigateByUrl(`/space/${this.space}/login`);
    } else if (this.code.startsWith('5')) {
      this.router.navigateByUrl(`/space/${this.identityService.getSpace()}/workspace`);
    } else {
      this.router.navigateByUrl(`/space/${this.identityService.getSpace()}/my-library`);
    }
  }
}

