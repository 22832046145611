import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BitResource, BitResourceSize} from '../bits.models';
import {TableImageBit} from './table-image.models';

@Component({
  selector: 'bitmark-table-image',
  templateUrl: './table-image.component.html'
})
export class TableImageComponent {
  @Input() bit?: TableImageBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
}
