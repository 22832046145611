import {Directive, ElementRef} from '@angular/core';

// Inspiration from: https://www.neoito.com/blog/how-to-maintain-scroll-position-in-angular-chat-app/
@Directive({
  selector: '[bitmarkScrollDirective]'
})
export class ScrollDirective {
  previousScrollHeightMinusTop: number; // the variable which stores the distance
  readyFor: string;
  toReset = false;

  constructor(public elementRef: ElementRef) {
    this.previousScrollHeightMinusTop = 0;
    this.readyFor = 'down';
    // this.restore();
  }

  reset() {
    this.previousScrollHeightMinusTop = 0;
    this.readyFor = 'up';
    this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight;
    // resetting the scroll position to bottom because that is where chats start.
  }

  restore() {
    if (this.toReset) {
      if (this.readyFor === 'up') {
        this.elementRef.nativeElement.scrollTop =
          this.elementRef.nativeElement.scrollHeight -
          this.previousScrollHeightMinusTop;
        // restoring the scroll position to the one stored earlier
      } else {
        if (this.elementRef.nativeElement.scrollTop < this.previousScrollHeightMinusTop) {
          this.elementRef.nativeElement.scrollTop = this.previousScrollHeightMinusTop;
        }
      }
      this.toReset = false;
    }
  }

  prepareFor(direction) {
    this.toReset = true;
    this.readyFor = direction || 'up';
    if (this.readyFor === 'up') {
      // this.elementRef.nativeElement.scrollTop = !this.elementRef.nativeElement.scrollTop // check for scrollTop is zero or not
      //   ? this.elementRef.nativeElement.scrollTop + 1
      //   : this.elementRef.nativeElement.scrollTop;
      this.previousScrollHeightMinusTop =
        this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.scrollTop;
    } else {
      this.previousScrollHeightMinusTop = this.elementRef.nativeElement.scrollTop;
    }
    // the current position is stored before new messages are loaded
  }
}
