import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormatWidth} from '@angular/common';
import {NgbTimeAdapter, NgbTimepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {BitmarkSettings} from '../settings/bitmark.settings';
import {TimeService} from '../utils/time.service';
import {CustomTimepickerI18n} from '../i18n/custom-timepicker.i18n';
import {CustomTimeAdapter} from '../adapters/custom-time.adapter';

@Component({
  selector: 'bitmark-time-editor',
  templateUrl: './time-editor.component.html',
  styleUrls: ['./time-editor.component.scss'],
  providers: [
    {provide: NgbTimeAdapter, useClass: CustomTimeAdapter},
    {provide: NgbTimepickerI18n, useClass: CustomTimepickerI18n}
  ]
})
export class TimeEditorComponent {
  @Input() placeholder: string;

  _ngModel: string;
  @Input()
  set ngModel(value: string) {
    if (!this._ngModel) {
      this._ngModel = value || this.defaultDate.toISOString();
      this.modelHasValue = !!value;
    }
  }

  get ngModel(): string {
    return this._ngModel;
  }

  defaultDate: Date;
  modelHasValue = false;

  @Output() ngModelChange = new EventEmitter<string>();

  get is24HourTimeFormat(): boolean {
    return this.timeService.is24HourTimeFormat(BitmarkSettings.locale, FormatWidth.Short);
  }

  modelChanged(event) {
    this.ngModelChange.emit(event);
    this.modelHasValue = !!event;
  }

  constructor(private timeService: TimeService) {
    this.defaultDate = new Date();
    this.defaultDate.setHours(9, 0, 0, 0);
  }
}
