import {Routes} from '@angular/router';
import {AuthGuard} from 'core';
import * as barrel from '.';

export const routes: Routes = [{
  path: 'public',
  redirectTo: '',
  pathMatch: 'prefix'
}, {
  path: '',
  redirectTo: 'shop',
  pathMatch: 'full'
}, {
  path: '',
  canActivate: [AuthGuard],
  loadChildren: () => import('./auth/auth.module.routed').then(m => m.AuthModuleRouted)
}, {
  path: '',
  // canActivate: [AuthGuard],
  loadChildren: () => import('./public-shop/public-shop.module.routed').then(m => m.PublicShopModuleRouted),
  title: 'Get More Brain - Shop'
}, {
  path: 'bo',
  canActivate: [AuthGuard],
  loadChildren: () => import('./bo/bo.module.routed').then(m => m.BoModuleRouted)
}, {
  path: 'space',
  canActivate: [AuthGuard],
  loadChildren: () => import('./main/main.module.routed').then(m => m.MainModuleRouted),
  data: {claims: [{logged: 'true'}]}
}, {
  path: 'generic-error',
  component: barrel.GenericErrorComponent
}, {
  path: '404',
  component: barrel.Page404Component,
  title: '404'
}, {
  path: ':page',
  canActivate: [AuthGuard],
  loadChildren: () => import('./main/main.module.routed').then(m => m.MainModuleRouted),
  data: {claims: [{logged: 'true'}]}
}, {
  path: '**',
  component: barrel.Page404Component
}];
