export class BitmarkSettings {
  private static _locale: string;
  public static get locale() {
    return this._locale;
  }

  public static set locale(value: string) {
    this._locale = value;
  }

  public static setLocale(language: string, countryCode: string) {
    this._locale = `${language || 'en'}${countryCode ? '-' + countryCode : ''}`;
  }
}
