import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ArticleBit} from './card-1.models';
import {BitResource, BitType} from '../bits.models';

@Component({
  selector: 'bitmark-card-1',
  templateUrl: './card-1.component.html'
})
export class Card1Component {
  @Input() bit?: ArticleBit;
  @Input() bitSubtype: BitType = BitType.Card1;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() onIsBeingEdited = new EventEmitter<{ title: any, content: any }>();
}
