import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-editable-label',
  templateUrl: './editable-label.component.html',
  styleUrls: ['./editable-label.component.scss']
})
export class EditableLabelComponent {
  @Input() label: string;
  @Input() isOrganizer: boolean;
  @Input() placeholder: string;
  @Input() isLargeField: boolean;
  @Input() firstRowOnly: boolean;
  @Input() cssClass: string;
  @Output() onChanges = new EventEmitter<string>();
  @ViewChild('editableLabel') editableLabelEl: ElementRef;
  isEditing = false;

  ngOnInit() {
  }

  startEditing(){
    this.isEditing = true;
    setTimeout(() => {
      this.editableLabelEl.nativeElement.focus();
      const range = document.createRange();
      range.selectNodeContents(this.editableLabelEl.nativeElement);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }, 200);
  }

  saveChanges() {
    this.isEditing = false;
    if(this.firstRowOnly){
      this.editableLabelEl.nativeElement.scrollTop = 0;
    }
    this.onChanges.emit(this.editableLabelEl.nativeElement.innerText?.trim());
    this.label = this.editableLabelEl.nativeElement.innerText;
  }

  onCancel() {
    this.isEditing = false;
    if(this.firstRowOnly){
      this.editableLabelEl.nativeElement.scrollTop = 0;
    }
    this.editableLabelEl.nativeElement.innerText = this.label;
  }
}
