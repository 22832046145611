import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import confetti from 'canvas-confetti';
import {BotActionResponseBit, BotActionResponseOption} from './bot-action-response.models';
import {MessageBit} from '../message/message.models';
import {BitType} from '../bits.models';
import {BitmarkConfig} from '../../bitmark.module';
import {BitmarkFormat} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-bot-action-response',
  templateUrl: './bot-action-response.component.html',
  styleUrls: ['./bot-action-response.component.scss']
})
export class BotActionResponseComponent implements OnInit, OnDestroy {
  private _bit?: BotActionResponseBit;
  @Input()
  set bit(value: BotActionResponseBit) {
    this._bit = value;

    if ((this.animationActions.showAnswerAnimation || this.animationActions.hasAnswered) && !value.answer) {
      this.animationActions = {
        start: true,
        hasAnswered: false,
        showAnswerAnimation: false,
      };
      this.answerMessage = null;
      this.feedbackMessage = null;
      this.footerMessage = null;
    }
  }

  get bit(): BotActionResponseBit {
    return this._bit;
  }

  @Output() changed = new EventEmitter<any>();

  showFirstTimeAnimation = false;
  animationActions: any = {
    start: false,
    hasAnswered: false,
    showAnswerAnimation: false,
    showFooterAnimation: false,
    showFooterWithAnswerAnimation: false,
  };
  instructionMessage?: MessageBit;
  bodyMessage?: MessageBit;
  answerMessage?: MessageBit;
  feedbackMessage?: MessageBit;
  footerMessage?: MessageBit;
  responsesTransitionClass = 'animation-animate ';
  currentUserDetails: {
    icon: {
      photoUrl: string;
      fullName: string;
    };
    emails: { email: string; ssoEmail: string; };
  };
  inViewportChange: Subject<boolean> = new Subject<boolean>();

  private viewportSubscription: Subscription;
  private viewportSaveShowAnimationSubscription: Subscription;

  constructor(@Inject('BitmarkConfig') bitmarkConfig: BitmarkConfig) {
    this.currentUserDetails = {icon: bitmarkConfig.userIcon, emails: bitmarkConfig.userEmails};

    this.viewportSubscription = this.inViewportChange
      .pipe(debounceTime(500))
      .subscribe((inViewport: boolean) => {
        if (!inViewport) {
          return;
        }

        if (!this.showFirstTimeAnimation) {
          this.viewportSubscription.unsubscribe();
          return;
        }

        if (this.animationActions.start) {
          this.viewportSubscription.unsubscribe();
          return;
        }

        this.animationActions.start = true;
        this.viewportSubscription.unsubscribe();
      });

    this.viewportSaveShowAnimationSubscription = this.inViewportChange
      .pipe(debounceTime(6000))
      .subscribe((inViewport: boolean) => {
        if (inViewport && this.animationActions.start && !this.bit?.answer?.hasBeenShown) {
          this.bit.feedback = [];
          this.bit.answer = {
            ...this.bit.answer,
            hasBeenShown: true
          };
          this.changed.emit();
          this.viewportSaveShowAnimationSubscription.unsubscribe();
        }

        if (this.bit?.answer?.hasBeenShown) {
          this.viewportSaveShowAnimationSubscription.unsubscribe();
        }
      });
  }

  ngOnInit() {
    if (!this.bit?.answer?.hasBeenShown && !this.bit?.answer?.choice) {
      this.showFirstTimeAnimation = true;
    }

    if (this.bit?.answer?.choice) {
      this.animationActions.hasAnswered = true;
    }

    if (this.bit?.instruction) {
      this.instructionMessage = {
        type: BitType.Article,
        body: this.bit.instruction,
        format: BitmarkFormat.MM
      };

      if (this.bit?.body) {
        this.bodyMessage = {
          type: BitType.Article,
          body: this.bit.body,
          format: BitmarkFormat.MM
        };

        this.responsesTransitionClass += 'transition-delay-5s';
      } else {
        this.responsesTransitionClass += 'transition-delay-3s';
      }
    } else if (this.bit?.body) {
      this.instructionMessage = {
        type: BitType.Article,
        body: this.bit.body,
        format: BitmarkFormat.MM
      };

      this.responsesTransitionClass += 'transition-delay-3s';
    } else {
      this.responsesTransitionClass += 'transition-delay-1s';
    }

    this.createAnswerMessage(false);
  }

  ngOnDestroy() {
    this.viewportSubscription?.unsubscribe();
    this.viewportSaveShowAnimationSubscription?.unsubscribe();
  }

  onInViewportChange(evt: { target: Element, visible: boolean }) {
    this.inViewportChange.next(evt.visible);
  }

  selectAnswer(option: BotActionResponseOption) {
    this.bit.feedback = [];
    this.bit.answer = {
      ...this.bit.answer,
      choice: option.item || option.response,
      isCompleted: true
    };

    this.createAnswerMessage(true);

    setTimeout(() => {
      this.animationActions.showAnswerAnimation = true;
      if (this.footerMessage) {
        if (this.feedbackMessage) {
          this.animationActions.showFooterWithAnswerAnimation = true;
        } else {
          this.animationActions.showFooterAnimation = true;
        }
      }
    }, 500);
    setTimeout(() => this.changed.emit(), this.computeBitCompletedAnimationDelay());
  }

  private createAnswerMessage(shouldReact: boolean) {
    if (!this.bit?.answer?.choice) {
      return;
    }

    const choiceResponse = this.bit.responses?.filter(x => (x.item || x.response) === this.bit.answer?.choice);

    if (choiceResponse?.length) {
      this.answerMessage = {
        type: BitType.Article,
        body: choiceResponse[0].response,
        format: BitmarkFormat.MM
      };

      if (choiceResponse[0].feedback) {
        this.feedbackMessage = {
          type: BitType.Article,
          body: choiceResponse[0].feedback,
          format: BitmarkFormat.MM
        };
      }

      if (shouldReact) {
        if (choiceResponse[0].reaction === 'celebrate') {
          setTimeout(() => {
            confetti({
              particleCount: 500,
              spread: 170,
              origin: {y: 0.7},
              disableForReducedMotion: false
            });
          }, 3500);
        }
      }
    }

    if (this.bit?.footer) {
      this.footerMessage = {
        type: BitType.Article,
        body: this.bit.footer,
        format: BitmarkFormat.MM
      };
    }
  }

  private computeBitCompletedAnimationDelay(): number {
    let delay = 1500;

    if (this.feedbackMessage) {
      delay += 3000;
    }

    if (this.footerMessage) {
      delay += 3000;
    }

    return delay;
  }
}
