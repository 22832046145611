import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {startWith, switchMap, takeUntil} from 'rxjs/operators';
import {BitbookApiService} from '../../bitbook-api.service';
import {ReaderPdfLocalJobsService} from './pdf-local-jobs.service';

enum PdfUploadJobStatus {
  Uploading = 1,
  Converting = 2,
  Finished = 3
}

@Component({
  selector: 'bitmark-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss']
})
export class ReaderPdfUploadComponent implements OnInit, OnDestroy {
  @Input() bookExternalId: string;
  @Input() afterBitId: string;
  @Input() file: File;
  @Input() job: { jobId?: string, fileName: string, status: number, coverSrc?: any };
  @Output() dropzoneSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobFinished: EventEmitter<any> = new EventEmitter<any>();

  timeIntervalPdfPolling: Subscription;
  stopPolling = new Subject();
  progressPercentage = 0;
  uploadEta = 0;
  fileName = '';

  constructor(private bitbookApiService: BitbookApiService,
              private notebooksLocalJobsService: ReaderPdfLocalJobsService) {
  }

  ngOnInit() {
    if (this.file) {
      this.job = {
        fileName: this.file.name,
        status: PdfUploadJobStatus.Uploading
      };
      this.uploadPdf(this.file);
    } else {
      this.job.coverSrc = null;
      this.pollServerForJob(this.job.jobId);
    }
  }

  checkPdfJobStatus(jobId: string): Observable<any> {
    return new Observable(x => {
      this.bitbookApiService.getResourceJobStatus(jobId)
        .subscribe((jobData) => {
          if (!this.uploadEta) {
            this.uploadEta = +jobData.details.ETA;
            const interv = setInterval(() => {
              this.progressPercentage += 100 / this.uploadEta;
              if (this.progressPercentage >= 100) {
                clearInterval(interv);
              }
            }, 1000);
          }
          if (jobData.status === 'book-updated') {
            this.stopPolling.next(true);
            this.uploadEta = 0;
            this.progressPercentage = 0;
            this.job.status = PdfUploadJobStatus.Finished;
            this.notebooksLocalJobsService.removeJob(jobId).subscribe();
            this.jobFinished.emit(this.job.fileName);
          }
          x.next();
        }, (err) => x.error(err));
    });
  }

  uploadPdf(file: File) {
    // this.job.coverSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file) + '#zoom=0&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&view=Fit&page=1');
    this.bitbookApiService.uploadPdfContent(file, this.bookExternalId, this.afterBitId)
      .subscribe((f) => {
        const jobId = f?.url ? f.url.split('/')[f.url.split('/').length - 1] : 0;
        this.notebooksLocalJobsService.addJob({
          jobId: jobId,
          status: PdfUploadJobStatus.Converting,
          fileName: file.name,
          coverSrc: URL.createObjectURL(file)
        }).subscribe();
        this.job.status = PdfUploadJobStatus.Converting;
        this.pollServerForJob(jobId);
      }, (err) => {
        console.log(err);
      });
  }

  pollServerForJob(jobId: string) {
    let timeoutTime;
    if (this.uploadEta) {
      timeoutTime = this.uploadEta > 15 ? this.uploadEta / 5 : 3000;
    } else {
      timeoutTime = 3000;
    }
    this.timeIntervalPdfPolling = interval(timeoutTime)
      .pipe(
        startWith(0),
        switchMap(() => this.checkPdfJobStatus(jobId)),
        takeUntil(this.stopPolling)
      ).subscribe(() => {
      }, err => {
        console.log(err);
      });
  }

  // onDropzoneSelect(event) {
  //   console.log(event);
  //   this.dropzoneSelect.emit(event);
  // }

  ngOnDestroy() {
    this.stopPolling.next(true);
  }
}
