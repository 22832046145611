// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.triangle {
  z-index: -1;
  width: 16px;
  height: 16px;
  border: 0.2px solid #ffffff;
  transform: rotate(45deg);
  position: fixed;
  top: 8px;
  background-color: #543199;
}

.context-menu {
  min-width: 85px;
  height: 32px;
  margin: 8px 0 0 0;
}
.context-menu button {
  z-index: 1000;
  color: #ffffff;
  background-color: #543199;
  border-radius: 0;
}
.context-menu button:focus {
  outline: none;
  box-shadow: none;
}
.context-menu button:hover {
  background-color: #3f2572;
}
.context-menu button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.context-menu button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
