import {ConversationCommonComponent} from '../conversation-common.component';
import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Conversation1Bit} from '../conversation.models';

@Component({
  selector: 'bitmark-conversation-left-1-thought',
  templateUrl: './conversation-left-1-thought.component.html',
  styleUrls: ['../../bits.scss', '../conversation.common.scss', './conversation-left-1-thought.component.scss']
})
export class ConversationLeft1ThoughtComponent extends ConversationCommonComponent implements OnInit, AfterViewInit {
  @Input() bit: Conversation1Bit;

  @ViewChild('bubble') bubbleElem: ElementRef;

  ngOnInit() {
    this.computePartnerData(this.bit);
  }

  ngAfterViewInit() {
    this.adjustLeftMargin(this.bubbleElem);
  }
}
