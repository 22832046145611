import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BrowserStorageService} from '../shared/browser-storage/browser-storage.service';
import {BookEntityToc} from '../shared/models/bitmark.models';

@Injectable({providedIn: 'root'})
export class ReaderTocService {
  private TOC_KEY = 'reader-toc-';

  constructor(private browserStorageService: BrowserStorageService) {
  }

  getIsTocReloadNeeded(bookExternalId: string): Observable<Array<any>> {
    return this.browserStorageService.idb.get(`${this.TOC_KEY}${bookExternalId}-toc-reload-needed`);
  }

  setIsTocReloadNeeded(bookExternalId: string, isNeeded: boolean): Observable<any> {
    return this.browserStorageService.idb.store(`${this.TOC_KEY}${bookExternalId}-toc-reload-needed`, isNeeded);
  }

  getTocForBook(bookExternalId: string): Observable<Array<BookEntityToc>> {
    return this.browserStorageService.idb.get(`${this.TOC_KEY}${bookExternalId}`);
  }

  storeTocForBook(bookExternalId: string, toc: Array<BookEntityToc>): Observable<any> {
    return this.browserStorageService.idb.store(`${this.TOC_KEY}${bookExternalId}`, toc);
  }

  getBitByReferenceAnchor(bookExternalId: string, reference: string): Observable<BookEntityToc> {
    return this.browserStorageService.idb.get(`${this.TOC_KEY}${bookExternalId}`)
      .pipe(map((toc: Array<any>) => {
        return toc?.length
          ? toc.find((b) => b.anchor === reference)
          : null;
      }));
  }

  getBitIndexById(bookExternalId: string, bitId: string): Observable<number> {
    return this.browserStorageService.idb.get(`${this.TOC_KEY}${bookExternalId}`)
      .pipe(map((toc: Array<any>) => {
        return toc?.length
          ? toc.findIndex((b) => b.ref == bitId)
          : null;
      }));
  }

  storeTocStateForBook(bookExternalId: string, isOpened: boolean): Observable<any> {
    if (isOpened) {
      return this.browserStorageService.idb.store(`${this.TOC_KEY}${bookExternalId}-is-opened`, isOpened);
    } else {
      return this.browserStorageService.idb.clear(`${this.TOC_KEY}${bookExternalId}-is-opened`);
    }
  }

  getTocStateForBook(bookExternalId: string): Observable<boolean> {
    return this.browserStorageService.idb.get(`${this.TOC_KEY}${bookExternalId}-is-opened`);
  }
}
