import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {Subject} from 'rxjs';
import {JSONContent} from '@tiptap/core';
import {cloneDeep} from 'lodash';
import {ArticleBit} from './article.models';
import {BitResource, BitResourceType, BitType} from '../bits.models';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import {BitmarkTextContentService} from '../../shared';
import {BitUtilsService} from '../../shared/utils/bit-utils.service';
import {BitWordBankService} from '../bit-word-bank.service';

@Component({
  selector: 'bitmark-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss', '../bits.scss']
})
export class ArticleComponent implements OnInit, OnChanges {
  private _bit?: ArticleBit;
  @Input() set bit(value: ArticleBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.wordBankItems = this.bitWordBankService.computeWordBank(value.additionalSolutions, value.answer?.usedSolutions);
    }
  }
  get bit(): ArticleBit {
    return this._bit;
  }
  @Input() bitSubtype: BitType = BitType.Article;
  @Input() canBeEdited = false;
  @Input() isEditable = false;
  @Input() isResourceType = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() cancelBitChanges = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<ArticleBit>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() instructionChanged = new EventEmitter<any>();
  @Output() changed = new EventEmitter<Subject<ArticleBit>>();
  @ViewChild('editorComponent') editorComponentRef: ReaderTextEditorComponent;

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      this.prevArticleBit = cloneDeep(this.bit);
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;
  updateContentEventSubject: Subject<any> = new Subject<any>();

  bodyFormat: BitmarkFormat;
  isBodyEmpty = true;
  isBitEmpty = false;
  hasBitResource = false;
  wordBankItems: Array<{ text: string, used: boolean }> = [];

  private initialized = false;
  private prevArticleBit?: ArticleBit;
  protected readonly BitResourceType = BitResourceType;

  constructor(private bitmarkTextContentService: BitmarkTextContentService,
              private bitUtilsService: BitUtilsService,
              private bitWordBankService: BitWordBankService) {
  }

  ngOnInit() {
    this.bodyFormat = this.bitmarkTextContentService.getBodyFormat(this.bit.body, this.bit.format);
    this.computeBitVisibility();

    this.wordBankItems = this.bitWordBankService.computeWordBank(this.bit.additionalSolutions, this.bit.answer?.usedSolutions);

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.bit?.currentValue?.body && (this.bit as any)?.isBeingGenerated) {
      this.computeBitVisibility();
      if (this.editorComponentRef) {
        if (changes?.bit?.currentValue?.body?.toString().indexOf('[object Object]') !== -1) {
          console.log(1);
        }
        this.editorComponentRef.editor.commands.setContent(changes?.bit?.currentValue?.body);
      }
    }
  }

  toggleWordBankSolution(item: { text: string; used: boolean }) {
    item.used = !item.used;

    const newUsedSolutions = this.bitWordBankService.addOrRemoveSolution(this.bit.answer?.usedSolutions, item.text);
    if (this.bit.answer) {
      this.bit.answer.usedSolutions = newUsedSolutions;
    } else {
      this.bit.answer = {
        usedSolutions: newUsedSolutions
      };
    }

    this.changed.emit();
  }

  saveChanges() {
    this.computeBitVisibility();
    this.saveInlineBit.emit(this.bit);
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevArticleBit);
    this.instructionChanged.next(this.bit.instruction);
    this.updateContentEventSubject.next(this.bit.body);
    this.computeBitVisibility();
    this.cancelBitChanges.emit();
  }

  onUpdateContent(bit: ArticleBit, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;

    if (this.canBeEdited && !this.isBeingEditedByUser) {
      this.saveChanges();
    }
  }

  updateBitResource(data: { resource: BitResource | undefined, instruction: string, body: string }) {
    this.bit.resource = data.resource;
    if (data.instruction && this.bitmarkTextContentService.isElementEmpty(this.bit.instruction, BitmarkFormat.MM)) {
      this.bit.instruction = data.instruction;
      this.instructionChanged.next(data.instruction);
    }
    if (data.body) {
      const isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit.body, this.bit.format);
      if (!isBodyEmpty && this.bit.body.content) {
        this.bit.body.content.push(...this.bitmarkTextContentService.getBodyContent(data.body, this.bodyFormat)?.content);
        this.updateContentEventSubject.next(this.bit.body.content);
      } else if (!isBodyEmpty && Array.isArray(this.bit.body)) {
        this.bit.body.push(...this.bitmarkTextContentService.getBodyContent(data.body, this.bodyFormat)?.content);
        this.updateContentEventSubject.next(this.bit.body);
      }
      else if (!isBodyEmpty) {
        this.bit.body += '\n' + data.body;
        this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.bit.body, this.bodyFormat));
      } else {
        this.bit.body = data.body;
        this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.bit.body, this.bodyFormat));
      }
    }

    this.computeBitVisibility();
  }

  enterEditMode() {
    if (this.canBeEdited) {
      this.editorComponent.enterEditMode();
    }
  }

  private computeBitVisibility() {
    this.hasBitResource = this.bitUtilsService.hasBitResource(this.bit);
    this.isBitEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(this.bit?.instruction, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format)
      && !this.hasBitResource;

    const isBodyElContent = this.bitmarkTextContentService.isElementEmpty(this.bit.body, this.bit.format, false);
    this.isBodyEmpty = this.bitSubtype == BitType.Article
      ? isBodyElContent
      : this.isEditable && this.canBeEdited
        ? false
        : isBodyElContent;
  }
}
