// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host:has(.survey-matrix-container-modal) {
  flex: 1;
}

.survey-matrix-container-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-matrix-close-modal {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #543199;
}

.image-editing {
  cursor: crosshair;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
