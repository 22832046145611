export * from './widgets/generic-error/generic-error.component';
import {GenericErrorComponent} from './widgets/generic-error/generic-error.component';

export * from './widgets/page404/page404.component';
import {Page404Component} from './widgets/page404/page404.component';

export const components = [
  GenericErrorComponent,
  Page404Component
];
