import {Injectable} from '@angular/core';
import {NgbTimeAdapter, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs_ from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {TimeService} from '../utils/time.service';

const dayjs = dayjs_;
dayjs.extend(duration);

@Injectable()
export class TimeDurationAdapter extends NgbTimeAdapter<string> {
  constructor(private timeService: TimeService) {
    super();
  }

  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }

    const durationObj = this.timeService.parseDuration(value);

    return {
      hour: durationObj.hours(),
      minute: durationObj.minutes(),
      second: durationObj.seconds()
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return dayjs.duration({
      hours: time?.hour,
      minutes: time?.minute,
      seconds: time?.second
    }).toISOString();
  }
}
