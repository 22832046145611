import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {BitResource, BitResourceSize} from '../bits.models';
import {ProsemirrorBit} from './prosemirror.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {BitmarkTextContentService} from '../../shared';

@Component({
  selector: 'bitmark-prosemirror',
  templateUrl: './prosemirror.component.html',
  styleUrls: ['../bits.scss', '../../reader/reader-editor/reader-text-editor/reader-text-editor.component.scss', './prosemirror.component.scss']
})
export class ProsemirrorBitComponent implements OnInit {
  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;
  @Input() bit?: ProsemirrorBit;
  @Input() isBeingEdited = true;
  @Input() canBeEdited = false;
  @Input() disableKeyboardInput = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
  @Output() onIsBeingEdited = new EventEmitter<Array<JSONContent>>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  bitFormat: BitmarkFormat;
  isBodyEmpty = true;

  constructor(private bitmarkTextContentService: BitmarkTextContentService) {
  }

  ngOnInit() {
    this.bitFormat = this.bit?.format || BitmarkFormat.PP;
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit.bit || this.bit.content, this.bitFormat);
  }

  onUpdate(bitJson: JSONContent) {
    this.onIsBeingEdited.emit(bitJson.content);
  }

  enterEditMode() {
    if (this.canBeEdited) {
      this.isBeingEdited = true;
      this.editorComponent.enterEditMode();
    }
  }
}
