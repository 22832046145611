import {Component, Input, OnInit} from '@angular/core';
import {
  AccountApiService, BaseUser,
  GeneralLayoutService,
  IdentityService,
  SocketEvent,
  SocketIoService,
  SocketMessageType
} from 'core';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {ContainerComponent} from '../../container/container.component';
import {AccountMqService} from 'main/account/account-mq.service';
import {BitmarkSettings} from 'bitmark';
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-user-box-personal',
  templateUrl: './user-box-personal.component.html'
})
export class UserBoxPersonalComponent extends ContainerComponent implements OnInit {
  @Input() user: BaseUser;

  constructor(private router: Router,
              private generalLayoutService: GeneralLayoutService,
              private identityService: IdentityService,
              private accountMqService: AccountMqService,
              private accountApiService: AccountApiService,
              private socketIoService: SocketIoService) {
    super();
  }

  ngOnInit() {
    this.subscribeToProfileChanges();
  }

  goToProfile() {
    this.router.navigate(['profile'])
      .then(() => this.generalLayoutService.showRightSection());
  }

  subscribeToProfileChanges() {
    this.subSink.sink = this.socketIoService.event('account')
      .pipe(filter(x => x.messageType === SocketMessageType.AccountProfileUpdated))
      .subscribe((socketEvt: SocketEvent) => {
        this.accountApiService.getProfile()
          .subscribe((userEntity) => {
            const usr = Object.assign(this.identityService.user);
            this.identityService.user = Object.assign(usr, userEntity);
            BitmarkSettings.setLocale(this.identityService.user.language, this.identityService.user.countryCode);
            this.accountMqService.notifyProfileUpdate();
          }, (err: HttpErrorResponse) => {
            console.error(err);
          });
      });
  }
}
