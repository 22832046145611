// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-input {
  visibility: hidden;
  position: fixed;
  left: -9999px;
  opacity: 0;
}

.ingredient-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.ingredient-checkbox input[type=checkbox] + label::after {
  content: none;
}
.ingredient-checkbox label {
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-word;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ingredient-checkbox label .label-content {
  padding-left: 10px;
}
.ingredient-checkbox label span {
  padding-left: 10px;
}
.ingredient-checkbox label::before {
  position: static;
  content: "";
  align-self: flex-start;
}
.ingredient-checkbox label::after {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.ingredient-grid .ingredient-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ingredient-grid .ingredient-content-full-row {
  grid-column: span 4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
