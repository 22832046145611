import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Editor} from '@tiptap/core';
import {DropdownItemModel} from '../../../../shared';

@Component({
  selector: 'bitmark-contextual-menu-image',
  templateUrl: './contextual-menu-image.component.html'
})
export class ContextualMenuImageComponent implements OnInit {
  @Input() editor: Editor;
  @Output() onDeleteNode: EventEmitter<string> = new EventEmitter<any>();
  @Output() onReplaceImage: EventEmitter<boolean> = new EventEmitter<any>();
  menuActions: Array<DropdownItemModel> = [];

  constructor(private translationService: TranslateService) {

  }

  ngOnInit() {
    this.menuActions = [{
      label: this.translationService.instant('Reader.Editor.ImageLeftAligned'),
      handler: this.alignLeft.bind(this)
    }, {
      label: this.translationService.instant('Reader.Editor.ImageRightAligned'),
      handler: this.alignRight.bind(this)
    }, {
      label: this.translationService.instant('Reader.Editor.ImageCenterAligned'),
      handler: this.alignCenter.bind(this)
    }, {isSeparator: true}, {
      label: this.translationService.instant('Shared.Replace'),
      handler: this.replaceImage.bind(this)
    }, {isSeparator: true}, {
      label: this.translationService.instant('Shared.Delete'),
      handler: this.deleteNode.bind(this)
    }];
  }

  alignLeft() {
    (this.editor.chain().focus() as any).setImageAlignment('left').run();
  }

  alignRight() {
    (this.editor.chain().focus() as any).setImageAlignment('right').run();
  }

  alignCenter() {
    (this.editor.chain().focus() as any).setImageAlignment('center').run();
  }

  deleteNode() {
    this.editor.commands.deleteSelection();
  }

  replaceImage(){
    this.onReplaceImage.emit(true);
  }
}
