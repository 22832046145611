import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {DropdownItemModel} from '../dropdown/dropdown.model';

@Component({
  selector: 'bitmark-dropdown-desktop',
  templateUrl: 'dropdown-desktop.component.html',
  styleUrls: ['../dropdown.scss']
})
export class DropdownDesktopComponent {
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() additionalContent: TemplateRef<any> = null;

  @Output() selected = new EventEmitter<DropdownItemModel>();
  @Output() closed = new EventEmitter<DropdownItemModel>();

  selectedSubAction: DropdownItemModel;

  onOptionSelected(action: DropdownItemModel) {
    this.selected.emit(action);
  }

  onColorOptionSelected(action: DropdownItemModel, color: { primary: string, secondary: string }) {
    this.selected.emit({...action, selectedColor: color});
  }
}
