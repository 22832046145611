import {Pipe, PipeTransform} from '@angular/core';
import {UrlRewriteService} from '../url-rewrites/url-rewrite.service';

@Pipe({name: 'rewriteUrlResize'})
export class ReplaceUrlResizePipe implements PipeTransform {
  constructor(private urlRewriteService: UrlRewriteService) {
  }

  transform(value: string, imageContext: string, imageSize?: string): string {
    return this.urlRewriteService.rewriteUrlAndResize(value, imageContext, imageSize);
  }
}
