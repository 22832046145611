import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ConversationCommonComponent} from '../conversation-common.component';
import {Conversation1Bit} from '../conversation.models';

@Component({
  selector: 'bitmark-conversation-right-1-thought',
  templateUrl: './conversation-right-1-thought.component.html',
  styleUrls: ['../conversation.common.scss', './conversation-right-1-thought.component.scss', '../../bits.scss']
})
export class ConversationRight1ThoughtComponent extends ConversationCommonComponent implements OnInit, AfterViewInit {
  @Input() bit: Conversation1Bit;

  @ViewChild('bubble') bubbleElem: ElementRef;

  ngOnInit() {
    this.computePartnerData(this.bit);
  }

  ngAfterViewInit() {
    this.adjustRightMargin(this.bubbleElem);
  }
}
