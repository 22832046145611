import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrototypeImagesModel} from './prototypes-images.models';


@Component({
  selector: 'bitmark-prototype-images',
  templateUrl: './prototype-images.component.html',
  styleUrls: []
})
export class PrototypeImagesComponent implements OnInit {
  private _bit?: PrototypeImagesModel;
  @Input() set bit(value: PrototypeImagesModel) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.activeImageIndex = 0;
    }
  }

  get bit(): PrototypeImagesModel {
    return this._bit;
  }
  @Output() changed = new EventEmitter<any>();

  activeImageIndex = 0;
  private initialized = false;

  ngOnInit() {
    if (this.bit?.answer) {
      this.activeImageIndex = this.bit.answer.index || 0;
    }
    this.initialized = true;
  }

  onImageClicked() {
    if (this.activeImageIndex < this.bit.images?.length - 1) {
      this.activeImageIndex++;
      this.bit.answer = {
        index: this.activeImageIndex
      };
      this.changed.emit();
    }
  }
}
