import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'bitmark-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss'],
})
export class TocComponent {
  @Input() bit;
  @Output() toggleTocEvent = new EventEmitter();

  toggleToc() {
    this.toggleTocEvent.emit();
  }
}
