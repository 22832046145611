import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BitmarkFormat} from '../../shared/models';

@Component({
  selector: 'bitmark-editable-text',
  templateUrl: './bitmark-editable-text.component.html',
  styleUrls: ['./bitmark-editable-text.component.scss', '../bits.scss', '../bitmark-text/bitmark-text.component.scss']
})
export class BitmarkEditableTextComponent {
  @Input() text?: string;
  @Input() format?: BitmarkFormat;
  @Input() defaultText = '';
  @Input() placeholder = '';
  @Input() cssClass = '';
  @Input() allowNewline?: boolean;
  @Output() onChange = new EventEmitter();
  @Output() onEnterPress = new EventEmitter();
  lastPopulatedTitle = '';
  isSpellcheckOn = false;

  setToDefault() {
    if (!this.text) {
      this.text = this.lastPopulatedTitle;
    }
  }

  onEnterPressed() {
    this.onEnterPress.emit();
  }

  onTextChange($event: any) {
    this.text = $event;
    this.onChange.emit($event);
    if ($event.length === 1) {
      this.lastPopulatedTitle = $event;
    }
  }
}
