import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NotebookCoverType, NotebookTypes} from '../../models/bitmark.models';
import {BookEntity} from '../../../reader/reader.models';

@Component({
  selector: 'bitmark-notebook-standard-cover',
  templateUrl: './notebook-standard-cover.component.html',
  styleUrls: ['./notebook-standard-cover.component.scss']
})
export class NotebookStandardCoverComponent implements OnInit, AfterViewInit {
  @Input()
  set notebook(value: BookEntity) {
    this._notebook = value;
    this.computeCoverType();
    this.setBackground();
  }

  @Input() coverImageWebp?: string;
  @Input() coverImageJpg?: string;
  @Input() showcaseLetter: string;
  @Input() isEmoji: boolean;
  @Input() isImageLoadError: boolean;
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';
  @Input() adaptSize = false;
  @Output() onClick = new EventEmitter<any>();
  @Output() fallbackToSupportedCover = new EventEmitter<any>();
  @Output() onImageLoaded = new EventEmitter<{ imageElem: any, checkIfWide: boolean }>();

  private _notebook: BookEntity;
  displayHeight: any = 200;
  displayWidth: any = 140;
  background = 'none';

  get notebook(): BookEntity {
    return this._notebook;
  }

  @ViewChild('notebookDefaultLetter') notebookDefaultLetter: ElementRef;
  @ViewChild('imageElement') imageElement: ElementRef;

  NotebookCoverTypes = NotebookCoverType;
  appliedCoverType: NotebookCoverType = NotebookCoverType.FullCover;
  notebookCoverTypesWithMask = [NotebookCoverType.Standard, NotebookCoverType.FullCover];

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.adaptSize) {
      this.resizeDefaultLetter();
    }
  }

  ngOnInit() {
    this.computeCoverType();
    this.setBackground();
    if(this.size !== 'md'){
      this.displayWidth = 'auto';
      this.displayHeight = 'auto';
    }
    else if(this.appliedCoverType === NotebookCoverType.CustomCover && this.notebook?.coverImage?.width && this.notebook?.coverImage?.height){
      if(this.notebook?.coverImage.width / this.notebook.coverImage.height > 1.2) {
        this.displayHeight = 120;
        this.displayWidth = 210;
      }
    }
  }

  ngAfterViewInit() {
    if (this.adaptSize) {
      this.resizeDefaultLetter();
    }
  }

  onImageIsLoaded(){
    if (this.appliedCoverType === NotebookCoverType.CustomCover && this.imageElement?.nativeElement?.naturalWidth && this.imageElement?.nativeElement?.naturalHeight) {
      if (this.imageElement?.nativeElement?.naturalWidth / this.imageElement?.nativeElement?.naturalHeight > 1.2) {
        this.displayWidth = 'auto';
        this.displayHeight = 'auto';
      }
    }
    this.onImageLoaded.emit({ imageElem: this.imageElement?.nativeElement, checkIfWide: this.appliedCoverType === this.NotebookCoverTypes.CustomCover})
  }

  fallbackCover($event: any){
    this.fallbackToSupportedCover.emit($event);
    this.isImageLoadError = true;
  }

  computeCoverType() {
    if (this.notebook.subtype === NotebookTypes.PDF) {
      this.appliedCoverType = NotebookCoverType.CustomCover;
      return;
    }
    if (!this.notebook.coverImage) {
      this.appliedCoverType = NotebookCoverType.Standard;
      return;
    }
    this.appliedCoverType = this.notebook?.branding?.coverType || NotebookCoverType.FullCover;
  }

  setBackground(){
    if(this.appliedCoverType === 'custom-cover' && !this.notebook.coverImage) {
      this.isImageLoadError = true;
    }
    this.background = (this.isImageLoadError || (this.appliedCoverType !== NotebookCoverType.CustomCover)) ? 'linear-gradient(rgba(255,255,255,0),rgba(0,0,0,0.5)),' + (this.notebook?.branding?.primaryColor || this.notebook?.coverColor || 'red') : 'none';
    console.log('got background: ', this.background);
  }

  resizeDefaultLetter() {
    if (this.isEmoji) {
      return;
    }

    if (!this.notebookDefaultLetter?.nativeElement) {
      return;
    }

    const viewportWidth = document.body.clientWidth;
    const width = Math.min(viewportWidth - 100, 375);

    if (this.notebookDefaultLetter?.nativeElement) {
      this.notebookDefaultLetter.nativeElement.style.fontSize = width * 0.72 + 'px';
    }
  }
}
