// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .learning-event-header {
  --bitmark-le-learning-step-default-item-font-color: #543199;
  --bitmark-le-learning-step-default-item-font-size: 12px;
  --bitmark-le-learning-step-default-lead-font-color: #543199;
  --bitmark-le-learning-step-default-lead-font-size: 12px;
  --bitmark-le-learning-step-default-lead-content-before-string: "-";
  --bitmark-le-learning-step-default-lead-content-before-font-color: #543199;
  --bitmark-le-learning-step-default-lead-content-before-font-size: 12px;
  --bitmark-le-learning-step-default-lead-content-before-margin: 0 5px;
  --bitmark-le-learning-step-default-lead-content-after-font-color: #543199;
  --bitmark-le-learning-step-default-lead-content-after-font-size: 12px;
  --bitmark-le-learning-step-default-lead-content-after-margin: 0 5px;
  --bitmark-le-learning-step-default-instruction-font-color: #000;
  --bitmark-le-learning-step-default-instruction-font-size: 24px;
}
::ng-deep .learning-event-header .instruction-positioning-le-learning-step {
  flex-direction: var(--bitmark-le-learning-step-instruction-position, var(--bitmark-le-learning-step-default-instruction-position, column));
}
::ng-deep .learning-event-header .item-positioning-le-learning-step {
  flex-direction: var(--bitmark-le-learning-step-item-position, var(--bitmark-le-learning-step-default-item-position, row));
}
::ng-deep .learning-event-header .item-positioning-le-learning-step .item-wrapper {
  display: var(--bitmark-le-learning-step-item-display, inline-flex);
}
::ng-deep .learning-event-header .item-lead-bit-wrapper-le-learning-step:not(:empty):before {
  content: var(--bitmark-le-learning-step-item-lead-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-le-learning-step-item-lead-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-le-learning-step-item-lead-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-item-lead-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-item-lead-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-item-lead-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-item-lead-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-item-lead-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-item-lead-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-item-lead-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-item-lead-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-item-lead-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .item-lead-bit-wrapper-le-learning-step:not(:empty):after {
  content: var(--bitmark-le-learning-step-item-lead-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-le-learning-step-item-lead-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-le-learning-step-item-lead-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-item-lead-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-item-lead-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-item-lead-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-item-lead-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-item-lead-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-item-lead-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-item-lead-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-item-lead-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-item-lead-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .instruction-bit-le-learning-step:not(:empty):before {
  content: var(--bitmark-le-learning-step-instruction-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-le-learning-step-instruction-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-le-learning-step-instruction-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-instruction-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-instruction-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-instruction-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-instruction-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-instruction-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-instruction-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-instruction-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-instruction-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-instruction-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .instruction-bit-le-learning-step:not(:empty):after {
  content: var(--bitmark-le-learning-step-instruction-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-le-learning-step-instruction-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-le-learning-step-instruction-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-instruction-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-instruction-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-instruction-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-instruction-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-instruction-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-instruction-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-instruction-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-instruction-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-instruction-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .technical-term-positioning-le-learning-step {
  flex-direction: var(--bitmark-le-learning-step-technical-term-position, var(--bitmark-le-learning-step-default-technical-term-position, column));
}
::ng-deep .learning-event-header .item-wrapper .item-bit-le-learning-step {
  padding: var(--bitmark-le-learning-step-item-size-padding, var(--bitmark-le-learning-step-default-item-size-padding, 0));
  margin: var(--bitmark-le-learning-step-item-size-margin, var(--bitmark-le-learning-step-default-item-size-margin, 0));
  background-color: var(--bitmark-le-learning-step-item-background-color, var(--bitmark-le-learning-step-default-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-le-learning-step-item-background-image, var(--bitmark-le-learning-step-default-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-le-learning-step-item-background-size, var(--bitmark-le-learning-step-default-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-le-learning-step-item-background-position, var(--bitmark-le-learning-step-default-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-le-learning-step-item-background-repeat, var(--bitmark-le-learning-step-default-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-le-learning-step-item-background-transform, var(--bitmark-le-learning-step-default-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-le-learning-step-item-background-animation-name) var(--bitmark-le-learning-step-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-le-learning-step-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-le-learning-step-item-background-backdrop-filter);
  font-family: var(--bitmark-le-learning-step-item-font-family, var(--bitmark-le-learning-step-default-item-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-item-font-size, var(--bitmark-le-learning-step-default-item-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-item-font-weight, var(--bitmark-le-learning-step-default-item-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-item-font-style, var(--bitmark-le-learning-step-default-item-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-item-font-text-decoration, var(--bitmark-le-learning-step-default-item-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-item-font-text-decoration, var(--bitmark-le-learning-step-default-item-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-item-font-line-height, var(--bitmark-le-learning-step-default-item-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-item-font-color, var(--bitmark-le-learning-step-default-item-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-item-font-align, var(--bitmark-le-learning-step-default-item-font-align, var(--bitmark-le-learning-step-default-item-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-item-font-align, var(--bitmark-le-learning-step-default-item-font-align, var(--bitmark-le-learning-step-default-item-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-le-learning-step-item-border-top-width, var(--bitmark-le-learning-step-default-item-border-top-width)) var(--bitmark-le-learning-step-item-border-style, var(--bitmark-le-learning-step-default-item-border-style)) var(--bitmark-le-learning-step-item-border-color, var(--bitmark-le-learning-step-default-item-border-color));
  border-right: var(--bitmark-le-learning-step-item-border-right-width, var(--bitmark-le-learning-step-default-item-border-right-width)) var(--bitmark-le-learning-step-item-border-style, var(--bitmark-le-learning-step-default-item-border-style)) var(--bitmark-le-learning-step-item-border-color, var(--bitmark-le-learning-step-default-item-border-color));
  border-bottom: var(--bitmark-le-learning-step-item-border-bottom-width, var(--bitmark-le-learning-step-default-item-border-bottom-width)) var(--bitmark-le-learning-step-item-border-style, var(--bitmark-le-learning-step-default-item-border-style)) var(--bitmark-le-learning-step-item-border-color, var(--bitmark-le-learning-step-default-item-border-color));
  border-left: var(--bitmark-le-learning-step-item-border-left-width, var(--bitmark-le-learning-step-default-item-border-left-width)) var(--bitmark-le-learning-step-item-border-style, var(--bitmark-le-learning-step-default-item-border-style)) var(--bitmark-le-learning-step-item-border-color, var(--bitmark-le-learning-step-default-item-border-color));
  border-radius: var(--bitmark-le-learning-step-item-border-radius, var(--bitmark-le-learning-step-default-item-border-radius, y));
  width: var(--bitmark-le-learning-step-item-size-width, var(--bitmark-le-learning-step-default-item-size-width, inherit));
  height: var(--bitmark-le-learning-step-item-size-height, var(--bitmark-le-learning-step-default-item-size-height, inherit));
}
::ng-deep .learning-event-header .item-wrapper .item-icon-le-learning-step {
  --bit-icon-position: var(--bitmark-le-learning-step-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-le-learning-step-item-icon-width, var(--bitmark-le-learning-step-default-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-le-learning-step-item-icon-height, var(--bitmark-le-learning-step-default-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-le-learning-step-item-icon-image, var(--bitmark-le-learning-step-default-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-le-learning-step-item-icon-width, var(--bitmark-le-learning-step-default-item-icon-width, inherit));
  height: var(--bitmark-le-learning-step-item-icon-height, var(--bitmark-le-learning-step-default-item-icon-height, inherit));
  inset: var(--bitmark-le-learning-step-item-icon-location, var(--bitmark-le-learning-step-default-item-icon-location, inherit));
  margin: var(--bitmark-le-learning-step-item-icon-margin, var(--bitmark-le-learning-step-default-item-icon-margin, 0));
}
::ng-deep .learning-event-header .item-wrapper .item-icon-le-learning-step.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
::ng-deep .learning-event-header .item-wrapper .item-icon-le-learning-step.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
::ng-deep .learning-event-header .lead-bit-le-learning-step {
  display: var(--bitmark-le-learning-step-lead-display, inline-flex);
  font-family: var(--bitmark-le-learning-step-lead-font-family, var(--bitmark-le-learning-step-default-lead-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-lead-font-size, var(--bitmark-le-learning-step-default-lead-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-lead-font-weight, var(--bitmark-le-learning-step-default-lead-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-lead-font-style, var(--bitmark-le-learning-step-default-lead-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-lead-font-text-decoration, var(--bitmark-le-learning-step-default-lead-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-lead-font-text-decoration, var(--bitmark-le-learning-step-default-lead-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-lead-font-line-height, var(--bitmark-le-learning-step-default-lead-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-lead-font-color, var(--bitmark-le-learning-step-default-lead-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-lead-font-align, var(--bitmark-le-learning-step-default-lead-font-align, var(--bitmark-le-learning-step-default-lead-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-lead-font-align, var(--bitmark-le-learning-step-default-lead-font-align, var(--bitmark-le-learning-step-default-lead-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-le-learning-step-lead-border-top-width, var(--bitmark-le-learning-step-default-lead-border-top-width)) var(--bitmark-le-learning-step-lead-border-style, var(--bitmark-le-learning-step-default-lead-border-style)) var(--bitmark-le-learning-step-lead-border-color, var(--bitmark-le-learning-step-default-lead-border-color));
  border-right: var(--bitmark-le-learning-step-lead-border-right-width, var(--bitmark-le-learning-step-default-lead-border-right-width)) var(--bitmark-le-learning-step-lead-border-style, var(--bitmark-le-learning-step-default-lead-border-style)) var(--bitmark-le-learning-step-lead-border-color, var(--bitmark-le-learning-step-default-lead-border-color));
  border-bottom: var(--bitmark-le-learning-step-lead-border-bottom-width, var(--bitmark-le-learning-step-default-lead-border-bottom-width)) var(--bitmark-le-learning-step-lead-border-style, var(--bitmark-le-learning-step-default-lead-border-style)) var(--bitmark-le-learning-step-lead-border-color, var(--bitmark-le-learning-step-default-lead-border-color));
  border-left: var(--bitmark-le-learning-step-lead-border-left-width, var(--bitmark-le-learning-step-default-lead-border-left-width)) var(--bitmark-le-learning-step-lead-border-style, var(--bitmark-le-learning-step-default-lead-border-style)) var(--bitmark-le-learning-step-lead-border-color, var(--bitmark-le-learning-step-default-lead-border-color));
  border-radius: var(--bitmark-le-learning-step-lead-border-radius, var(--bitmark-le-learning-step-default-lead-border-radius, y));
  width: var(--bitmark-le-learning-step-lead-size-width, var(--bitmark-le-learning-step-default-lead-size-width, inherit));
  height: var(--bitmark-le-learning-step-lead-size-height, var(--bitmark-le-learning-step-default-lead-size-height, inherit));
  padding: var(--bitmark-le-learning-step-lead-size-padding, var(--bitmark-le-learning-step-default-lead-size-padding, 0));
  margin: var(--bitmark-le-learning-step-lead-size-margin, var(--bitmark-le-learning-step-default-lead-size-margin, 0));
}
::ng-deep .learning-event-header .lead-bit-le-learning-step:before {
  content: var(--bitmark-le-learning-step-lead-perm-content-before-string, var(--bitmark-le-learning-step-default-lead-perm-content-before-string));
  margin: var(--bitmark-le-learning-step-lead-perm-content-before-margin, var(--bitmark-le-learning-step-default-lead-perm-content-before-margin));
  font-family: var(--bitmark-le-learning-step-lead-perm-content-before-font-family, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-lead-perm-content-before-font-size, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-lead-perm-content-before-font-weight, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-lead-perm-content-before-font-style, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-lead-perm-content-before-font-text-decoration, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-lead-perm-content-before-font-text-decoration, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-lead-perm-content-before-font-line-height, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-lead-perm-content-before-font-color, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-lead-perm-content-before-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-lead-perm-content-before-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-before-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .lead-bit-le-learning-step:after {
  content: var(--bitmark-le-learning-step-lead-perm-content-after-string, var(--bitmark-le-learning-step-default-lead-perm-content-after-string));
  margin: var(--bitmark-le-learning-step-lead-perm-content-after-margin, var(--bitmark-le-learning-step-default-lead-perm-content-after-margin));
  font-family: var(--bitmark-le-learning-step-lead-perm-content-after-font-family, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-lead-perm-content-after-font-size, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-lead-perm-content-after-font-weight, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-lead-perm-content-after-font-style, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-lead-perm-content-after-font-text-decoration, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-lead-perm-content-after-font-text-decoration, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-lead-perm-content-after-font-line-height, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-lead-perm-content-after-font-color, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-lead-perm-content-after-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-lead-perm-content-after-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-align, var(--bitmark-le-learning-step-default-lead-perm-content-after-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .lead-dash-before-le-learning-step {
  display: var(--bitmark-le-learning-step-lead-display, inline-flex);
}
::ng-deep .learning-event-header .lead-dash-before-le-learning-step:before {
  content: var(--bitmark-le-learning-step-lead-content-before-string, var(--bitmark-le-learning-step-default-lead-content-before-string, var(--bitmark-y-string)));
  margin: var(--bitmark-le-learning-step-lead-content-before-margin, var(--bitmark-le-learning-step-default-lead-content-before-margin, var(--bitmark-y-margin)));
  font-family: var(--bitmark-le-learning-step-lead-content-before-font-family, var(--bitmark-le-learning-step-default-lead-content-before-font-family, var(--bitmark-y-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-lead-content-before-font-size, var(--bitmark-le-learning-step-default-lead-content-before-font-size, var(--bitmark-y-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-lead-content-before-font-weight, var(--bitmark-le-learning-step-default-lead-content-before-font-weight, var(--bitmark-y-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-lead-content-before-font-style, var(--bitmark-le-learning-step-default-lead-content-before-font-style, var(--bitmark-y-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-lead-content-before-font-text-decoration, var(--bitmark-le-learning-step-default-lead-content-before-font-text-decoration, var(--bitmark-y-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-lead-content-before-font-text-decoration, var(--bitmark-le-learning-step-default-lead-content-before-font-text-decoration, var(--bitmark-y-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-lead-content-before-font-line-height, var(--bitmark-le-learning-step-default-lead-content-before-font-line-height, var(--bitmark-y-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-lead-content-before-font-color, var(--bitmark-le-learning-step-default-lead-content-before-font-color, var(--bitmark-y-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-lead-content-before-font-align, var(--bitmark-le-learning-step-default-lead-content-before-font-align, var(--bitmark-le-learning-step-default-lead-content-before-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-lead-content-before-font-align, var(--bitmark-le-learning-step-default-lead-content-before-font-align, var(--bitmark-le-learning-step-default-lead-content-before-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .lead-dash-after-le-learning-step {
  display: var(--bitmark-le-learning-step-lead-display, inline-flex);
}
::ng-deep .learning-event-header .lead-dash-after-le-learning-step:after {
  content: var(--bitmark-le-learning-step-lead-content-after-string, var(--bitmark-le-learning-step-default-lead-content-after-string, var(--bitmark-y-string)));
  margin: var(--bitmark-le-learning-step-lead-content-after-margin, var(--bitmark-le-learning-step-default-lead-content-after-margin, var(--bitmark-y-margin)));
  font-family: var(--bitmark-le-learning-step-lead-content-after-font-family, var(--bitmark-le-learning-step-default-lead-content-after-font-family, var(--bitmark-y-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-lead-content-after-font-size, var(--bitmark-le-learning-step-default-lead-content-after-font-size, var(--bitmark-y-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-lead-content-after-font-weight, var(--bitmark-le-learning-step-default-lead-content-after-font-weight, var(--bitmark-y-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-lead-content-after-font-style, var(--bitmark-le-learning-step-default-lead-content-after-font-style, var(--bitmark-y-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-lead-content-after-font-text-decoration, var(--bitmark-le-learning-step-default-lead-content-after-font-text-decoration, var(--bitmark-y-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-lead-content-after-font-text-decoration, var(--bitmark-le-learning-step-default-lead-content-after-font-text-decoration, var(--bitmark-y-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-lead-content-after-font-line-height, var(--bitmark-le-learning-step-default-lead-content-after-font-line-height, var(--bitmark-y-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-lead-content-after-font-color, var(--bitmark-le-learning-step-default-lead-content-after-font-color, var(--bitmark-y-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-lead-content-after-font-align, var(--bitmark-le-learning-step-default-lead-content-after-font-align, var(--bitmark-le-learning-step-default-lead-content-after-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-lead-content-after-font-align, var(--bitmark-le-learning-step-default-lead-content-after-font-align, var(--bitmark-le-learning-step-default-lead-content-after-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .instruction-bit-wrapper-le-learning-step {
  padding: var(--bitmark-le-learning-step-instruction-size-padding, var(--bitmark-le-learning-step-default-instruction-size-padding, 0));
  margin: var(--bitmark-le-learning-step-instruction-size-margin, var(--bitmark-le-learning-step-default-instruction-size-margin, 0));
}
::ng-deep .learning-event-header .instruction-bit-le-learning-step {
  font-family: var(--bitmark-le-learning-step-instruction-font-family, var(--bitmark-le-learning-step-default-instruction-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-instruction-font-size, var(--bitmark-le-learning-step-default-instruction-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-instruction-font-weight, var(--bitmark-le-learning-step-default-instruction-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-instruction-font-style, var(--bitmark-le-learning-step-default-instruction-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-instruction-font-text-decoration, var(--bitmark-le-learning-step-default-instruction-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-instruction-font-text-decoration, var(--bitmark-le-learning-step-default-instruction-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-instruction-font-line-height, var(--bitmark-le-learning-step-default-instruction-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-instruction-font-color, var(--bitmark-le-learning-step-default-instruction-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-instruction-font-align, var(--bitmark-le-learning-step-default-instruction-font-align, var(--bitmark-le-learning-step-default-instruction-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-instruction-font-align, var(--bitmark-le-learning-step-default-instruction-font-align, var(--bitmark-le-learning-step-default-instruction-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-le-learning-step-instruction-border-top-width, var(--bitmark-le-learning-step-default-instruction-border-top-width)) var(--bitmark-le-learning-step-instruction-border-style, var(--bitmark-le-learning-step-default-instruction-border-style)) var(--bitmark-le-learning-step-instruction-border-color, var(--bitmark-le-learning-step-default-instruction-border-color));
  border-right: var(--bitmark-le-learning-step-instruction-border-right-width, var(--bitmark-le-learning-step-default-instruction-border-right-width)) var(--bitmark-le-learning-step-instruction-border-style, var(--bitmark-le-learning-step-default-instruction-border-style)) var(--bitmark-le-learning-step-instruction-border-color, var(--bitmark-le-learning-step-default-instruction-border-color));
  border-bottom: var(--bitmark-le-learning-step-instruction-border-bottom-width, var(--bitmark-le-learning-step-default-instruction-border-bottom-width)) var(--bitmark-le-learning-step-instruction-border-style, var(--bitmark-le-learning-step-default-instruction-border-style)) var(--bitmark-le-learning-step-instruction-border-color, var(--bitmark-le-learning-step-default-instruction-border-color));
  border-left: var(--bitmark-le-learning-step-instruction-border-left-width, var(--bitmark-le-learning-step-default-instruction-border-left-width)) var(--bitmark-le-learning-step-instruction-border-style, var(--bitmark-le-learning-step-default-instruction-border-style)) var(--bitmark-le-learning-step-instruction-border-color, var(--bitmark-le-learning-step-default-instruction-border-color));
  border-radius: var(--bitmark-le-learning-step-instruction-border-radius, var(--bitmark-le-learning-step-default-instruction-border-radius, y));
  width: var(--bitmark-le-learning-step-instruction-size-width, var(--bitmark-le-learning-step-default-instruction-size-width, inherit));
  height: var(--bitmark-le-learning-step-instruction-size-height, var(--bitmark-le-learning-step-default-instruction-size-height, inherit));
}
::ng-deep .learning-event-header .technical-term-bit-le-learning-step {
  font-family: var(--bitmark-le-learning-step-technical-term-font-family, var(--bitmark-le-learning-step-default-technical-term-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-technical-term-font-size, var(--bitmark-le-learning-step-default-technical-term-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-technical-term-font-weight, var(--bitmark-le-learning-step-default-technical-term-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-technical-term-font-style, var(--bitmark-le-learning-step-default-technical-term-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-technical-term-font-text-decoration, var(--bitmark-le-learning-step-default-technical-term-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-technical-term-font-text-decoration, var(--bitmark-le-learning-step-default-technical-term-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-technical-term-font-line-height, var(--bitmark-le-learning-step-default-technical-term-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-technical-term-font-color, var(--bitmark-le-learning-step-default-technical-term-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-technical-term-font-align, var(--bitmark-le-learning-step-default-technical-term-font-align, var(--bitmark-le-learning-step-default-technical-term-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-technical-term-font-align, var(--bitmark-le-learning-step-default-technical-term-font-align, var(--bitmark-le-learning-step-default-technical-term-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-le-learning-step-technical-term-border-top-width, var(--bitmark-le-learning-step-default-technical-term-border-top-width)) var(--bitmark-le-learning-step-technical-term-border-style, var(--bitmark-le-learning-step-default-technical-term-border-style)) var(--bitmark-le-learning-step-technical-term-border-color, var(--bitmark-le-learning-step-default-technical-term-border-color));
  border-right: var(--bitmark-le-learning-step-technical-term-border-right-width, var(--bitmark-le-learning-step-default-technical-term-border-right-width)) var(--bitmark-le-learning-step-technical-term-border-style, var(--bitmark-le-learning-step-default-technical-term-border-style)) var(--bitmark-le-learning-step-technical-term-border-color, var(--bitmark-le-learning-step-default-technical-term-border-color));
  border-bottom: var(--bitmark-le-learning-step-technical-term-border-bottom-width, var(--bitmark-le-learning-step-default-technical-term-border-bottom-width)) var(--bitmark-le-learning-step-technical-term-border-style, var(--bitmark-le-learning-step-default-technical-term-border-style)) var(--bitmark-le-learning-step-technical-term-border-color, var(--bitmark-le-learning-step-default-technical-term-border-color));
  border-left: var(--bitmark-le-learning-step-technical-term-border-left-width, var(--bitmark-le-learning-step-default-technical-term-border-left-width)) var(--bitmark-le-learning-step-technical-term-border-style, var(--bitmark-le-learning-step-default-technical-term-border-style)) var(--bitmark-le-learning-step-technical-term-border-color, var(--bitmark-le-learning-step-default-technical-term-border-color));
  border-radius: var(--bitmark-le-learning-step-technical-term-border-radius, var(--bitmark-le-learning-step-default-technical-term-border-radius, y));
  width: var(--bitmark-le-learning-step-technical-term-size-width, var(--bitmark-le-learning-step-default-technical-term-size-width, inherit));
  height: var(--bitmark-le-learning-step-technical-term-size-height, var(--bitmark-le-learning-step-default-technical-term-size-height, inherit));
  padding: var(--bitmark-le-learning-step-technical-term-size-padding, var(--bitmark-le-learning-step-default-technical-term-size-padding, 0));
  margin: var(--bitmark-le-learning-step-technical-term-size-margin, var(--bitmark-le-learning-step-default-technical-term-size-margin, 0));
}
::ng-deep .learning-event-header .technical-term-bit-le-learning-step:before {
  content: var(--bitmark-le-learning-step-technical-term-content-before-string, var(--bitmark-le-learning-step-default-technical-term-content-before-string));
  margin: var(--bitmark-le-learning-step-technical-term-content-before-margin, var(--bitmark-le-learning-step-default-technical-term-content-before-margin));
  font-family: var(--bitmark-le-learning-step-technical-term-content-before-font-family, var(--bitmark-le-learning-step-default-technical-term-content-before-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-technical-term-content-before-font-size, var(--bitmark-le-learning-step-default-technical-term-content-before-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-technical-term-content-before-font-weight, var(--bitmark-le-learning-step-default-technical-term-content-before-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-technical-term-content-before-font-style, var(--bitmark-le-learning-step-default-technical-term-content-before-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-technical-term-content-before-font-text-decoration, var(--bitmark-le-learning-step-default-technical-term-content-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-technical-term-content-before-font-text-decoration, var(--bitmark-le-learning-step-default-technical-term-content-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-technical-term-content-before-font-line-height, var(--bitmark-le-learning-step-default-technical-term-content-before-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-technical-term-content-before-font-color, var(--bitmark-le-learning-step-default-technical-term-content-before-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-technical-term-content-before-font-align, var(--bitmark-le-learning-step-default-technical-term-content-before-font-align, var(--bitmark-le-learning-step-default-technical-term-content-before-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-technical-term-content-before-font-align, var(--bitmark-le-learning-step-default-technical-term-content-before-font-align, var(--bitmark-le-learning-step-default-technical-term-content-before-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .learning-event-header .technical-term-bit-le-learning-step:after {
  content: var(--bitmark-le-learning-step-technical-term-content-after-string, var(--bitmark-le-learning-step-default-technical-term-content-after-string));
  margin: var(--bitmark-le-learning-step-technical-term-content-after-margin, var(--bitmark-le-learning-step-default-technical-term-content-after-margin));
  font-family: var(--bitmark-le-learning-step-technical-term-content-after-font-family, var(--bitmark-le-learning-step-default-technical-term-content-after-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-technical-term-content-after-font-size, var(--bitmark-le-learning-step-default-technical-term-content-after-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-technical-term-content-after-font-weight, var(--bitmark-le-learning-step-default-technical-term-content-after-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-technical-term-content-after-font-style, var(--bitmark-le-learning-step-default-technical-term-content-after-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-technical-term-content-after-font-text-decoration, var(--bitmark-le-learning-step-default-technical-term-content-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-technical-term-content-after-font-text-decoration, var(--bitmark-le-learning-step-default-technical-term-content-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-technical-term-content-after-font-line-height, var(--bitmark-le-learning-step-default-technical-term-content-after-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-technical-term-content-after-font-color, var(--bitmark-le-learning-step-default-technical-term-content-after-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-technical-term-content-after-font-align, var(--bitmark-le-learning-step-default-technical-term-content-after-font-align, var(--bitmark-le-learning-step-default-technical-term-content-after-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-technical-term-content-after-font-align, var(--bitmark-le-learning-step-default-technical-term-content-after-font-align, var(--bitmark-le-learning-step-default-technical-term-content-after-font-align, var(--bitmark-main-font-alignt, left))));
}

.learning-event-activity-type {
  --bitmark-le-learning-step-activity-type-default-font-color: #000;
  --bitmark-le-learning-step-activity-type-default-font-size: 12px;
  --bitmark-le-learning-step-activity-type-default-font-weight: bold;
  --bitmark-le-learning-step-activity-type-default-font-line-height: 1.3;
  --bitmark-le-learning-step-activity-type-default-border-top-width: 2px;
  --bitmark-le-learning-step-activity-type-default-border-right-width: 2px;
  --bitmark-le-learning-step-activity-type-default-border-bottom-width: 2px;
  --bitmark-le-learning-step-activity-type-default-border-left-width: 2px;
  --bitmark-le-learning-step-activity-type-default-border-style: solid;
  --bitmark-le-learning-step-activity-type-default-border-color: #543199;
  --bitmark-le-learning-step-activity-type-default-border-radius: 5px;
  font-family: var(--bitmark-le-learning-step-activity-type-font-family, var(--bitmark-le-learning-step-activity-type-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-activity-type-font-size, var(--bitmark-le-learning-step-activity-type-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-activity-type-font-weight, var(--bitmark-le-learning-step-activity-type-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-activity-type-font-style, var(--bitmark-le-learning-step-activity-type-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-activity-type-font-text-decoration, var(--bitmark-le-learning-step-activity-type-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-activity-type-font-text-decoration, var(--bitmark-le-learning-step-activity-type-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-activity-type-font-line-height, var(--bitmark-le-learning-step-activity-type-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-activity-type-font-color, var(--bitmark-le-learning-step-activity-type-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-activity-type-font-align, var(--bitmark-le-learning-step-activity-type-default-font-align, var(--bitmark-le-learning-step-activity-type-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-activity-type-font-align, var(--bitmark-le-learning-step-activity-type-default-font-align, var(--bitmark-le-learning-step-activity-type-default-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-le-learning-step-activity-type-border-top-width, var(--bitmark-le-learning-step-activity-type-default-border-top-width)) var(--bitmark-le-learning-step-activity-type-border-style, var(--bitmark-le-learning-step-activity-type-default-border-style)) var(--bitmark-le-learning-step-activity-type-border-color, var(--bitmark-le-learning-step-activity-type-default-border-color));
  border-right: var(--bitmark-le-learning-step-activity-type-border-right-width, var(--bitmark-le-learning-step-activity-type-default-border-right-width)) var(--bitmark-le-learning-step-activity-type-border-style, var(--bitmark-le-learning-step-activity-type-default-border-style)) var(--bitmark-le-learning-step-activity-type-border-color, var(--bitmark-le-learning-step-activity-type-default-border-color));
  border-bottom: var(--bitmark-le-learning-step-activity-type-border-bottom-width, var(--bitmark-le-learning-step-activity-type-default-border-bottom-width)) var(--bitmark-le-learning-step-activity-type-border-style, var(--bitmark-le-learning-step-activity-type-default-border-style)) var(--bitmark-le-learning-step-activity-type-border-color, var(--bitmark-le-learning-step-activity-type-default-border-color));
  border-left: var(--bitmark-le-learning-step-activity-type-border-left-width, var(--bitmark-le-learning-step-activity-type-default-border-left-width)) var(--bitmark-le-learning-step-activity-type-border-style, var(--bitmark-le-learning-step-activity-type-default-border-style)) var(--bitmark-le-learning-step-activity-type-border-color, var(--bitmark-le-learning-step-activity-type-default-border-color));
  border-radius: var(--bitmark-le-learning-step-activity-type-border-radius, var(--bitmark-le-learning-step-activity-type-default-border-radius, y));
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  color: var(--bitmark-le-learning-step-toggle-btn-color, #543199);
  transition: transform 500ms ease;
}
.toggle-btn.rotate {
  transform: translate(0%, -50%) rotate(90deg);
}

.learning-event-duration {
  --bitmark-le-learning-step-duration-default-font-color: #7A7A7A;
  --bitmark-le-learning-step-duration-default-font-size: 12px;
  font-family: var(--bitmark-le-learning-step-duration-font-family, var(--bitmark-le-learning-step-duration-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-duration-font-size, var(--bitmark-le-learning-step-duration-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-duration-font-weight, var(--bitmark-le-learning-step-duration-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-duration-font-style, var(--bitmark-le-learning-step-duration-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-duration-font-text-decoration, var(--bitmark-le-learning-step-duration-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-duration-font-text-decoration, var(--bitmark-le-learning-step-duration-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-duration-font-line-height, var(--bitmark-le-learning-step-duration-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-duration-font-color, var(--bitmark-le-learning-step-duration-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-duration-font-align, var(--bitmark-le-learning-step-duration-default-font-align, var(--bitmark-le-learning-step-duration-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-duration-font-align, var(--bitmark-le-learning-step-duration-default-font-align, var(--bitmark-le-learning-step-duration-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.learning-event-location {
  --bitmark-le-learning-step-location-default-font-color: #7A7A7A;
  --bitmark-le-learning-step-location-default-font-size: 12px;
  font-family: var(--bitmark-le-learning-step-location-font-family, var(--bitmark-le-learning-step-location-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-learning-step-location-font-size, var(--bitmark-le-learning-step-location-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-learning-step-location-font-weight, var(--bitmark-le-learning-step-location-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-learning-step-location-font-style, var(--bitmark-le-learning-step-location-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-learning-step-location-font-text-decoration, var(--bitmark-le-learning-step-location-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-learning-step-location-font-text-decoration, var(--bitmark-le-learning-step-location-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-learning-step-location-font-line-height, var(--bitmark-le-learning-step-location-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-learning-step-location-font-color, var(--bitmark-le-learning-step-location-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-learning-step-location-font-align, var(--bitmark-le-learning-step-location-default-font-align, var(--bitmark-le-learning-step-location-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-learning-step-location-font-align, var(--bitmark-le-learning-step-location-default-font-align, var(--bitmark-le-learning-step-location-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.hidden-input {
  visibility: hidden;
  height: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
