import {HttpClientModule, HttpHeaders} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {environment} from 'environment';
import {appHost} from 'app-host';

const corsFixer = (shopUrl: string) => {
  const appSubdomainCfg = appHost.getSubdomainConfig();
  const subDomain = appSubdomainCfg.subDomain === 'localhost'
    ? 'cosmic'
    : appSubdomainCfg.subDomain;
  return shopUrl?.replace('unchained-test-2.bitmark.cloud/graphql', `${subDomain}.getmorebrain.com/unchained-api/graphql`)
    .replace('unchained-swisslife-test.bitmark.cloud/graphql', 'cosmic.getmorebrain.com/unchained-api-swisslife/graphql')
    .replace('unchained-swisslife.bitmark.cloud/graphql', 'app.getmorebrain.com/unchained-api-swisslife/graphql')
    .replace('unchained-swisslife.getmorebrain.com/graphql', 'app.getmorebrain.com/unchained-api-swisslife/graphql')
    .replace('unchained-iffp.bitmark.cloud/graphql', 'app.getmorebrain.com/unchained-api-iffp/graphql')
    .replace('unchained-goldstuck.bitmark.cloud/graphql', 'goldstuck.getmorebrain.com/unchained-api-goldstuck/graphql')
    .replace('unchained-goldstuck.getmorebrain.com/graphql', 'goldstuck.getmorebrain.com/unchained-api-goldstuck/graphql')
    // .replace('unchained-electrosuisse.bitmark.cloud/graphql', 'electrosuisse.getmorebrain.com/unchained-api-electrosuisse/graphql')
    // .replace('unchained-electrosuisse.getmorebrain.com/graphql', 'electrosuisse.getmorebrain.com/unchained-api-electrosuisse/graphql')
    .replace('unchained.getmorebrain.com/graphql', 'app.getmorebrain.com/unchained-api/graphql')
    .replace('unchained.bitmark.cloud/graphql', `${subDomain}.getmorebrain.com/unchained-api/graphql`);
};

const getBaseUrl = (): string => {
  const port = [80, undefined, ""].indexOf(window.location.port) !== -1 ? "" : `:${window.location.port}`;
  return `${window.location.protocol}//${window.location.hostname}${port}`;
}

const getPersonalShopUrl = (shopConfigs: any) => {
  if (shopConfigs?.personal?.shops?.master?.shopUrl) {
    return corsFixer(shopConfigs?.personal?.shops?.master?.shopUrl);
  }
  if (!shopConfigs?.personal || !shopConfigs?.personal?.shops) {
    return '';
  }
  const keys = Object.keys(shopConfigs?.personal?.shops);
  if (keys?.length) {
    return corsFixer(shopConfigs?.personal?.shops[keys[0]]?.shopUrl);
  }
  return '';
};

const providers = [{
  provide: APOLLO_NAMED_OPTIONS,
  deps: [HttpLink],
  useFactory: (httpLink: HttpLink) => {
    try {
      const volatileString = window.localStorage.getItem('gmb-cosmic.volatile')?.trim();
      const volatileJson = JSON.parse(volatileString || '{}');
      const user = volatileJson.user;
      const shopConfigs = volatileJson.shopData;
      const loggedSpace = user?.claims?.loginSpace || user?.claims?.spaces[0] || 'personal';
      let shopUrl;
      if (user) {
        const shopConfig = shopConfigs[loggedSpace]?.shops;
        shopUrl = loggedSpace && shopConfig
          ? corsFixer(shopConfig[Object.keys(shopConfig)[0]].shopUrl)
          : corsFixer(environment.graphQlEndpoint);
      } else {
        //for public shop
        shopUrl = corsFixer(environment.graphQlEndpoint);
      }
      return {
        default: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: shopUrl,
            headers: new HttpHeaders({
              'Accept-Language': 'de-ch',
              'X-Shop-Country': 'CH',
            }),
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
            },
          },
        }, personal: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: getPersonalShopUrl(shopConfigs),
          }),
        },
      };
    } catch (ex) {
      console.error(ex);
      window.location.href = `${getBaseUrl()}/generic-error?code=500&msg=${ex.message}`;
    }
  },
}];

@NgModule({
  imports: [
    HttpClientModule,
    ApolloModule,
  ],
  providers
})
export class GraphQLModule {
}

