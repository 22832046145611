import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, skip} from 'rxjs/operators';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {IdentityService} from "core";

@Injectable({providedIn: 'root'})
export class GaRouterService {
  private isInitialized = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private gaService: GoogleAnalyticsService,
              private identityService: IdentityService) {
  }

  init() {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;
    const include: Array<string | RegExp> = [];
    const exclude: Array<string | RegExp> = [];
    const includeRules = this.normalizePathRules(include);
    const excludeRules = this.normalizePathRules(exclude);
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd), skip(1),
        filter(event => includeRules.length > 0 ? includeRules.some(rule => rule.test(event.urlAfterRedirects)) : true),
        filter(event => excludeRules.length > 0 ? !excludeRules.some(rule => rule.test(event.urlAfterRedirects)) : true))
      .subscribe((event: any) => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data: any) => {
          const title = data?.title || 'Get More Brain';
          const options = this.identityService.user && {
            user_id: this.identityService.user.gaUuid
          };
          this.gaService.pageView(event.urlAfterRedirects, title, undefined, options);
        });
      });
  }

  getChild(activatedRoute: ActivatedRoute) {
    return activatedRoute.firstChild
      ? this.getChild(activatedRoute.firstChild)
      : activatedRoute;
  }

  normalizePathRules(rules: Array<string | RegExp>) {
    return rules.map(rule => (rule instanceof RegExp)
      ? rule
      : new RegExp(`^${rule.replace('*', '.*')}$`, 'i'));
  }
}
