// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-container {
  gap: 10px;
  max-width: var(--bitmark-reader-content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
@media (max-width: 680px) {
  .filters-container {
    padding: 0 10px;
  }
}
.filters-container .bit-button {
  display: flex;
  justify-content: center !important;
  --bitmark-reader-le-filter-button-default-font-color: #543199;
  --bitmark-reader-le-filter-button-default-background-color: #fff;
  --bitmark-reader-le-filter-button-default-border-top-width: 1px;
  --bitmark-reader-le-filter-button-default-border-right-width: 1px;
  --bitmark-reader-le-filter-button-default-border-bottom-width: 1px;
  --bitmark-reader-le-filter-button-default-border-left-width: 1px;
  --bitmark-reader-le-filter-button-default-border-radius: 25px;
  --bitmark-reader-le-filter-button-default-border-style: solid;
  --bitmark-reader-le-filter-button-default-border-color: #543199;
  --bitmark-reader-le-filter-button-default-size-padding: 0 10px;
  --bitmark-reader-le-filter-selected-button-default-font-color: #fff;
  --bitmark-reader-le-filter-selected-button-default-background-color: #543199;
  --bitmark-reader-le-filter-selected-button-default-border-top-width: 1px;
  --bitmark-reader-le-filter-selected-button-default-border-right-width: 1px;
  --bitmark-reader-le-filter-selected-button-default-border-bottom-width: 1px;
  --bitmark-reader-le-filter-selected-button-default-border-left-width: 1px;
  --bitmark-reader-le-filter-selected-button-default-border-radius: 25px;
  --bitmark-reader-le-filter-selected-button-default-border-style: solid;
  --bitmark-reader-le-filter-selected-button-default-border-color: transparent;
  --bitmark-reader-le-filter-selected-button-default-size-padding: 0 10px;
  font-family: var(--bitmark-reader-le-filter-button-font-family, var(--bitmark-reader-le-filter-button-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-reader-le-filter-button-font-size, var(--bitmark-reader-le-filter-button-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-reader-le-filter-button-font-weight, var(--bitmark-reader-le-filter-button-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-reader-le-filter-button-font-style, var(--bitmark-reader-le-filter-button-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-reader-le-filter-button-font-text-decoration, var(--bitmark-reader-le-filter-button-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-reader-le-filter-button-font-text-decoration, var(--bitmark-reader-le-filter-button-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-reader-le-filter-button-font-line-height, var(--bitmark-reader-le-filter-button-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-reader-le-filter-button-font-color, var(--bitmark-reader-le-filter-button-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-reader-le-filter-button-font-align, var(--bitmark-reader-le-filter-button-default-font-align, var(--bitmark-reader-le-filter-button-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-reader-le-filter-button-font-align, var(--bitmark-reader-le-filter-button-default-font-align, var(--bitmark-reader-le-filter-button-default-font-align, var(--bitmark-main-font-alignt, left))));
  --bitmark-reader-le-filter-button-background-shorthand-composed: var(--bitmark-reader-le-filter-button-background, var(--bitmark-reader-le-filter-button-default-background-color)) var(--bitmark-reader-le-filter-button-background-image, var(--bitmark-reader-le-filter-button-default-background-image, none)) var(--bitmark-reader-le-filter-button-background-repeat, var(--bitmark-reader-le-filter-button-default-background-repeat, no-repeat)) var(--bitmark-reader-le-filter-button-background-position, var(--bitmark-reader-le-filter-button-default-background-position, center)) / var(--bitmark-reader-le-filter-button-background-size, var(--bitmark-reader-le-filter-button-default-background-size, cover));
  background: var(--bitmark-reader-le-filter-button-background-shorthand, var(--bitmark-reader-le-filter-button-background-shorthand-composed));
  transform: var(--bitmark-reader-le-filter-button-background-transform, var(--bitmark-reader-le-filter-button-default-background-transform, none));
  animation: var(--bitmark-reader-le-filter-button-background-animation-name) var(--bitmark-reader-le-filter-button-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-reader-le-filter-button-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-reader-le-filter-button-background-backdrop-filter);
  border-top: var(--bitmark-reader-le-filter-button-border-top-width, var(--bitmark-reader-le-filter-button-default-border-top-width)) var(--bitmark-reader-le-filter-button-border-style, var(--bitmark-reader-le-filter-button-default-border-style)) var(--bitmark-reader-le-filter-button-border-color, var(--bitmark-reader-le-filter-button-default-border-color));
  border-right: var(--bitmark-reader-le-filter-button-border-right-width, var(--bitmark-reader-le-filter-button-default-border-right-width)) var(--bitmark-reader-le-filter-button-border-style, var(--bitmark-reader-le-filter-button-default-border-style)) var(--bitmark-reader-le-filter-button-border-color, var(--bitmark-reader-le-filter-button-default-border-color));
  border-bottom: var(--bitmark-reader-le-filter-button-border-bottom-width, var(--bitmark-reader-le-filter-button-default-border-bottom-width)) var(--bitmark-reader-le-filter-button-border-style, var(--bitmark-reader-le-filter-button-default-border-style)) var(--bitmark-reader-le-filter-button-border-color, var(--bitmark-reader-le-filter-button-default-border-color));
  border-left: var(--bitmark-reader-le-filter-button-border-left-width, var(--bitmark-reader-le-filter-button-default-border-left-width)) var(--bitmark-reader-le-filter-button-border-style, var(--bitmark-reader-le-filter-button-default-border-style)) var(--bitmark-reader-le-filter-button-border-color, var(--bitmark-reader-le-filter-button-default-border-color));
  border-radius: var(--bitmark-reader-le-filter-button-border-radius, var(--bitmark-reader-le-filter-button-default-border-radius, y));
  margin: var(--bitmark-reader-le-filter-button-size-margin, var(--bitmark-reader-le-filter-button-default-size-margin, 0));
  padding: var(--bitmark-reader-le-filter-button-size-padding, var(--bitmark-reader-le-filter-button-default-size-padding, 0));
}
.filters-container .bit-button.selected {
  font-family: var(--bitmark-reader-le-filter-selected-button-font-family, var(--bitmark-reader-le-filter-selected-button-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-reader-le-filter-selected-button-font-size, var(--bitmark-reader-le-filter-selected-button-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-reader-le-filter-selected-button-font-weight, var(--bitmark-reader-le-filter-selected-button-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-reader-le-filter-selected-button-font-style, var(--bitmark-reader-le-filter-selected-button-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-reader-le-filter-selected-button-font-text-decoration, var(--bitmark-reader-le-filter-selected-button-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-reader-le-filter-selected-button-font-text-decoration, var(--bitmark-reader-le-filter-selected-button-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-reader-le-filter-selected-button-font-line-height, var(--bitmark-reader-le-filter-selected-button-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-reader-le-filter-selected-button-font-color, var(--bitmark-reader-le-filter-selected-button-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-reader-le-filter-selected-button-font-align, var(--bitmark-reader-le-filter-selected-button-default-font-align, var(--bitmark-reader-le-filter-selected-button-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-reader-le-filter-selected-button-font-align, var(--bitmark-reader-le-filter-selected-button-default-font-align, var(--bitmark-reader-le-filter-selected-button-default-font-align, var(--bitmark-main-font-alignt, left))));
  --bitmark-reader-le-filter-selected-button-background-shorthand-composed: var(--bitmark-reader-le-filter-selected-button-background, var(--bitmark-reader-le-filter-selected-button-default-background-color)) var(--bitmark-reader-le-filter-selected-button-background-image, var(--bitmark-reader-le-filter-selected-button-default-background-image, none)) var(--bitmark-reader-le-filter-selected-button-background-repeat, var(--bitmark-reader-le-filter-selected-button-default-background-repeat, no-repeat)) var(--bitmark-reader-le-filter-selected-button-background-position, var(--bitmark-reader-le-filter-selected-button-default-background-position, center)) / var(--bitmark-reader-le-filter-selected-button-background-size, var(--bitmark-reader-le-filter-selected-button-default-background-size, cover));
  background: var(--bitmark-reader-le-filter-selected-button-background-shorthand, var(--bitmark-reader-le-filter-selected-button-background-shorthand-composed));
  transform: var(--bitmark-reader-le-filter-selected-button-background-transform, var(--bitmark-reader-le-filter-selected-button-default-background-transform, none));
  animation: var(--bitmark-reader-le-filter-selected-button-background-animation-name) var(--bitmark-reader-le-filter-selected-button-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-reader-le-filter-selected-button-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-reader-le-filter-selected-button-background-backdrop-filter);
  border-top: var(--bitmark-reader-le-filter-selected-button-border-top-width, var(--bitmark-reader-le-filter-selected-button-default-border-top-width)) var(--bitmark-reader-le-filter-selected-button-border-style, var(--bitmark-reader-le-filter-selected-button-default-border-style)) var(--bitmark-reader-le-filter-selected-button-border-color, var(--bitmark-reader-le-filter-selected-button-default-border-color));
  border-right: var(--bitmark-reader-le-filter-selected-button-border-right-width, var(--bitmark-reader-le-filter-selected-button-default-border-right-width)) var(--bitmark-reader-le-filter-selected-button-border-style, var(--bitmark-reader-le-filter-selected-button-default-border-style)) var(--bitmark-reader-le-filter-selected-button-border-color, var(--bitmark-reader-le-filter-selected-button-default-border-color));
  border-bottom: var(--bitmark-reader-le-filter-selected-button-border-bottom-width, var(--bitmark-reader-le-filter-selected-button-default-border-bottom-width)) var(--bitmark-reader-le-filter-selected-button-border-style, var(--bitmark-reader-le-filter-selected-button-default-border-style)) var(--bitmark-reader-le-filter-selected-button-border-color, var(--bitmark-reader-le-filter-selected-button-default-border-color));
  border-left: var(--bitmark-reader-le-filter-selected-button-border-left-width, var(--bitmark-reader-le-filter-selected-button-default-border-left-width)) var(--bitmark-reader-le-filter-selected-button-border-style, var(--bitmark-reader-le-filter-selected-button-default-border-style)) var(--bitmark-reader-le-filter-selected-button-border-color, var(--bitmark-reader-le-filter-selected-button-default-border-color));
  border-radius: var(--bitmark-reader-le-filter-selected-button-border-radius, var(--bitmark-reader-le-filter-selected-button-default-border-radius, y));
  margin: var(--bitmark-reader-le-filter-selected-button-size-margin, var(--bitmark-reader-le-filter-selected-button-default-size-margin, 0));
  padding: var(--bitmark-reader-le-filter-selected-button-size-padding, var(--bitmark-reader-le-filter-selected-button-default-size-padding, 0));
}

.entry-date-title {
  --bitmark-reader-le-date-toggle-title-default-font-size: 28px;
  --bitmark-reader-le-date-toggle-title-default-font-weight: bold;
  font-family: var(--bitmark-reader-le-date-toggle-title-font-family, var(--bitmark-reader-le-date-toggle-title-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-reader-le-date-toggle-title-font-size, var(--bitmark-reader-le-date-toggle-title-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-reader-le-date-toggle-title-font-weight, var(--bitmark-reader-le-date-toggle-title-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-reader-le-date-toggle-title-font-style, var(--bitmark-reader-le-date-toggle-title-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-reader-le-date-toggle-title-font-text-decoration, var(--bitmark-reader-le-date-toggle-title-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-reader-le-date-toggle-title-font-text-decoration, var(--bitmark-reader-le-date-toggle-title-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-reader-le-date-toggle-title-font-line-height, var(--bitmark-reader-le-date-toggle-title-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-reader-le-date-toggle-title-font-color, var(--bitmark-reader-le-date-toggle-title-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-reader-le-date-toggle-title-font-align, var(--bitmark-reader-le-date-toggle-title-default-font-align, var(--bitmark-reader-le-date-toggle-title-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-reader-le-date-toggle-title-font-align, var(--bitmark-reader-le-date-toggle-title-default-font-align, var(--bitmark-reader-le-date-toggle-title-default-font-align, var(--bitmark-main-font-alignt, left))));
}
.entry-date-title .today {
  color: var(--bitmark-reader-interactivity-primary-color, #543199);
}

.toggle-btn {
  color: var(--bitmark-reader-interactivity-primary-color, #543199);
}
.toggle-btn.rotate {
  transform: rotate(90deg);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
