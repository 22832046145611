import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DropdownItemModel, SubSink} from "../../../shared";
import {BitbookMqService} from "../../bitbook-mq.service";
import {ReaderBasketService} from "../../reader-basket.service";
import {ReaderHiddenElements} from "../../../shared/models/bitmark.models";

@Component({
  selector: 'bitmark-reader-header',
  templateUrl: './reader-header.component.html',
  styleUrls: ['./reader-header.component.scss'],
})
export class ReaderHeaderComponent implements OnInit, OnDestroy {
  @Input() hideRightSide;
  @Input() actions: Array<DropdownItemModel>;
  @Input() hiddenElements: Array<ReaderHiddenElements> = [];
  @Output() close = new EventEmitter<any>();
  @Output() viewBasket = new EventEmitter<any>();
  @Output() toggleToc = new EventEmitter<any>();
  @Output() toggleSearch = new EventEmitter<any>();

  private sub = new SubSink();
  basketCount = 0;

  constructor(private bitbookMqService: BitbookMqService,
              private readerBasketService: ReaderBasketService) {
  }

  ngOnInit() {
    this.sub.sink = this.bitbookMqService.onBasketCountUpdated()
      .subscribe((count: number) => this.basketCount = count);
    this.sub.sink = this.readerBasketService.getBasketCount()
      .subscribe((count: number) => this.basketCount = count);
    this.sub.sink = this.bitbookMqService.onReaderMenuReady()
      .subscribe((actions: Array<DropdownItemModel>) => this.actions = actions);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  protected readonly ReaderHiddenElements = ReaderHiddenElements;
}
