import {Injectable} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {BitmarkFormat} from '../models/bitmark.models';
import {BitmarkPipe} from '../../bits';
import {ReaderTipTapTapService} from '../../reader/tiptap/reader-tiptap.service';
import {UrlRewriteService} from '../url-rewrites/url-rewrite.service';

@Injectable()
export class BitmarkTextContentService {
  constructor(private readerTipTapService: ReaderTipTapTapService) {
  }

  getBodyContent(body: any, format: BitmarkFormat): JSONContent {
    return new BitmarkPipe(this.readerTipTapService, new UrlRewriteService()).transform(body, this.getBodyFormat(body, format), 'json') as JSONContent;
  }

  getTextFromJson(json: any, format: BitmarkFormat): string {
    if (typeof json !== 'object') {
      return json;
    }

    const html = new BitmarkPipe(this.readerTipTapService, new UrlRewriteService()).transform(json, this.getBodyFormat(json, format), 'html') as string;

    const element = document.createElement('DIV');
    element.innerHTML = html;

    return element.innerText || element.textContent;
  }

  onUpdateContent(bit: any, bitJson: any) {
    bit.body = {
      type: 'doc',
      content: bitJson
    };
  }

  getBodyFormat(body: any, format: BitmarkFormat) {
    if (format === BitmarkFormat.Text && typeof body === 'object') {
      return BitmarkFormat.PP;
    }

    return format;
  }

  isElementEmpty(element: any, format: BitmarkFormat, ignoreNewline = true): boolean {
    if (typeof element === 'string') {
      return !element?.trim().length;
    } else if (typeof element === 'object') {
      const html = new BitmarkPipe(this.readerTipTapService, new UrlRewriteService()).transform(element, format, 'html') as string;
      const divElem = document.createElement('div');
      divElem.innerHTML = html;

      const searchedElementsSelector =  ignoreNewline ? 'img,audio,video' : 'img,audio,video,p';

      // if ([BitmarkFormat.PP, BitmarkFormat.Prosemirror].includes(format)) {
      if ([BitmarkFormat.PP].includes(format)) {
        return !(divElem.innerText
          || divElem.textContent
          || divElem.querySelectorAll(searchedElementsSelector)?.length);
      }

      return !(divElem.innerText || divElem.textContent);
    }

    return true;
  }
}
