import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountApiService, IdentityService} from 'core';
import {DropdownItemModel} from '../dropdown/dropdown/dropdown.model';

@Component({
  selector: 'app-user-status-dropdown',
  templateUrl: './user-status-dropdown.component.html'
})
export class UserStatusDropdownComponent implements OnInit {
  selectedStatus: DropdownItemModel;
  statuses: Array<DropdownItemModel> = [
    {
      label: 'Timeline.Available',
      data: 'available',
      icon: 'circle',
      iconCssClass: 'size-11px text-balanced',
      // handler: () => {
      //   this.accountApiService.updateAvailability(true)
      //     .subscribe(() => {
      //       this.selectedStatus = this.statuses[0];
      //       this.identityService.setAvailability(true);
      //     }, (err: HttpErrorResponse) => console.error(err));
      // }
    },
    {
      label: 'Timeline.NotAvailable',
      data: 'notAvailable',
      icon: 'circle',
      iconCssClass: 'size-11px text-gray',
      // handler: () => {
      //   this.accountApiService.updateAvailability(false)
      //     .subscribe(() => {
      //       this.selectedStatus = this.statuses[1];
      //       this.identityService.setAvailability(false);
      //     }, (err: HttpErrorResponse) => console.error(err));
      // }
    }
  ];

  constructor(private accountApiService: AccountApiService,
              private identityService: IdentityService) {
    this.selectedStatus = this.statuses[0];
  }

  ngOnInit() {
    this.selectedStatus = this.statuses[this.identityService.user.isAvailable ? 0 : 1];
  }
}
