import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MqService} from 'core';

@Injectable({providedIn: 'root'})
export class AccountMqService {
  constructor(private mqService: MqService) {
  }

  notifyProfileUpdate() {
    return this.mqService.pub('profile-update', null);
  }

  onProfileUpdated(): Observable<any> {
    return this.mqService.sub('profile-update');
  }
}
