// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-pointer {
  width: var(--bitmark-step-image-screenshot-with-pointer-pointer-diameter, 20px);
  height: var(--bitmark-step-image-screenshot-with-pointer-pointer-diameter, 20px);
  transform: translate(calc(var(--bitmark-step-image-screenshot-with-pointer-pointer-diameter, 20px) / 2 * -1), calc(var(--bitmark-step-image-screenshot-with-pointer-pointer-diameter, 20px) / 2 * -1)) !important;
  background-color: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, rgba(255, 214, 182, 0.7)))));
  background-image: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-animation-name) var(--bitmark-step-image-screenshot-with-pointer-pointer-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-step-image-screenshot-with-pointer-pointer-background-backdrop-filter);
  --bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-top-width: 1px;
  --bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-right-width: 1px;
  --bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-bottom-width: 1px;
  --bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-left-width: 1px;
  --bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-style: solid;
  --bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-color: rgb(255,193,142);
  border-top: var(--bitmark-step-image-screenshot-with-pointer-pointer-border-top-width, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-top-width)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-style, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-style)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-color, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-color));
  border-right: var(--bitmark-step-image-screenshot-with-pointer-pointer-border-right-width, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-right-width)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-style, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-style)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-color, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-color));
  border-bottom: var(--bitmark-step-image-screenshot-with-pointer-pointer-border-bottom-width, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-bottom-width)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-style, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-style)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-color, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-color));
  border-left: var(--bitmark-step-image-screenshot-with-pointer-pointer-border-left-width, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-left-width)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-style, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-style)) var(--bitmark-step-image-screenshot-with-pointer-pointer-border-color, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-color));
  border-radius: var(--bitmark-step-image-screenshot-with-pointer-pointer-border-radius, var(--bitmark-step-image-screenshot-with-pointer-fallback-pointer-border-radius, 50%));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
