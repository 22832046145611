// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown__mobile {
  font-size: 1.2rem;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1020;
}
.dropdown__mobile .content {
  border-radius: 15px;
  max-height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;
}
.dropdown__mobile .footer {
  border-radius: 15px;
  font-weight: bold;
  background-color: #ffffff;
  color: #543199;
}
.dropdown__mobile .footer:hover {
  background-color: #a98ae6;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
