import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {EvtUtilsService} from 'core';
import {SubSink} from 'core/sub-sink/sub-sink';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() placeholder = '';
  @Input() size = '';
  @Input() text = '';
  @Input() searchInputId = 'search';
  @Input() debounceTime = 0;
  @Output() onSearch = new EventEmitter<string>();
  @Output() close = new EventEmitter();
  @Output() onFocus = new EventEmitter();

  @ViewChild('inputEl') private inputElement: ElementRef<HTMLInputElement>;
  private sub = new SubSink();

  constructor(private evtUtilsService: EvtUtilsService) {
  }

  ngAfterViewInit() {
    this.setValue(this.text);
  }

  searchHandler(event: any) {
    if (typeof event.target?.value !== 'string') {
      return;
    }
    this.onSearch.emit(event.target.value);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.text) {
      this.setValue(changes.text.currentValue);
    }
  }

  setFocus() {
    this.inputElement.nativeElement.focus();
  }

  onFocusIn($event: any) {
    this.inputElement.nativeElement.setSelectionRange(0, $event.target.value.length);
    this.onFocus.emit();
  }

  onKeydownEscape($event) {
    this.evtUtilsService.cancelEvt($event);
    const value = this.inputElement.nativeElement.value.trim();
    if (value) {
      this.setValue('');
      this.onSearch.emit('');
      return;
    }

    this.close.emit();
  }

  private setValue(value: string) {
    if (!this.inputElement) {
      return;
    }
    this.inputElement.nativeElement.value = value;
  }
}
