import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {DropdownMobileComponent} from '../dropdown-mobile/dropdown-mobile.component';
import {ModalScrollAntiBlockService} from '../../scroll-anti-block/modal-scroll-anti-block.service';
import {DropdownItemModel} from './dropdown.model';

@Component({
  selector: 'bitmark-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['../dropdown.scss', './dropdown.component.scss']
})
export class DropdownComponent implements OnChanges {
  @Input() id;
  @Input() placement: PlacementArray = ['bottom-right', 'bottom-left', 'top-right', 'top-left'];
  @Input() disableMqEvents = false;
  @Input() autoClose: boolean | string = true;
  @Input() dropdownClass = '';
  @Input() scrollableSelectors = [];
  @Input() scrollAntiBlockStopPropagation = false;
  @Input() container = null;
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() additionalContent: TemplateRef<any> = null;
  @Input() dropdownButton: TemplateRef<any> = null;
  @Output() openEvent = new EventEmitter<any>();
  @Output() selected = new EventEmitter<DropdownItemModel>();
  @ViewChild('appDropdown') appDropdown: NgbDropdown;
  window = window;
  dropdownStyle = null;
  dropdownMobileModalRef: any = null;
  gridBreakpoints = {
    sm: 680,
    md: 992,
    lg: 1024,
    xl: 1200
  };
  activeElement: any;

  constructor(private modalScrollAntiBlockService: ModalScrollAntiBlockService,
              private ngbModal: NgbModal) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.additionalContent &&
      changes.additionalContent?.previousValue !== changes.additionalContent?.currentValue &&
      this.dropdownMobileModalRef) {
      this.dropdownMobileModalRef.componentInstance.additionalContent = this.additionalContent;
      this.modalScrollAntiBlockService.applyAntiBlock(this.dropdownMobileModalRef);
    }
  }

  onOptionSelected(action: DropdownItemModel) {
    this.selected.emit(action);
    if (action.handler) {
      action.handler.apply(null, [action]);
    }
    if (this.autoClose && !action.keepOpenAfterClick) {
      this.appDropdown.close();
    }
  }

  openDropdownMobile(actions: Array<DropdownItemModel>) {
    if (this.gridBreakpoints.md > window.innerWidth) {
      this.dropdownMobileModalRef = this.ngbModal.open(DropdownMobileComponent,
        {
          windowClass: 'dropdown-modal',
          backdropClass: 'd-none',
          backdrop: 'static',
          keyboard: false,
          animation: false
        });
      this.dropdownMobileModalRef.componentInstance.actions = actions;
      this.dropdownMobileModalRef.componentInstance.additionalContent = this.additionalContent;
      this.dropdownMobileModalRef.componentInstance.selected.subscribe((actions: Array<DropdownItemModel>) => {
        this.closeDropdownMobile();
        this.openDropdownMobile(actions);
      });
      this.dropdownMobileModalRef.componentInstance.canceled.subscribe(() => {
        this.closeDropdownMobile();
      });
      this.modalScrollAntiBlockService.applyAntiBlock(this.dropdownMobileModalRef);
      this.openEvent.emit(true);
    }
  }

  close() {
    if (!this.appDropdown) {
      return;
    }

    this.appDropdown.close();
    this.closeDropdownMobile();
  }

  onOpenDesktopDropdownChange(isOpen) {
    this.openEvent.emit(isOpen);

    if (isOpen) {
      this.activeElement = document.activeElement;
      this.dropdownStyle = {
        'z-index': 1030
      };
    }

    if (!isOpen) {
      this.dropdownStyle = {};
      this.activeElement.focus();
    }
  }

  private closeDropdownMobile() {
    if (this.dropdownMobileModalRef) {
      this.openEvent.emit(false);
      this.dropdownMobileModalRef.close();
      this.dropdownMobileModalRef = null;
    }
  }
}
