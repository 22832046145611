// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-wrapper.is-edit {
  background-color: var(--bitmark-edit-settings-color);
}
.message-wrapper.is-edit > div {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  background-color: #b1cfeb;
}
.message-wrapper .is-text-only {
  padding-top: 10px;
  padding-bottom: 15px;
}
.message-wrapper .is-emoji {
  min-height: 135px;
  flex-direction: row;
}
.message-wrapper .is-emoji .bit-body-text {
  font-size: 100px;
  margin-right: 0;
}
.message-wrapper .image-editable {
  cursor: pointer;
  opacity: 0.5;
}
.message-wrapper .image-placeholder {
  width: 100px;
  height: 100px;
  background-color: white;
  opacity: 0.7;
  cursor: pointer;
  color: var(--bitmark-reader-primary-color);
}
.message-wrapper .remove-image {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--bitmark-reader-primary-color);
  cursor: pointer;
}
.message-wrapper .bit-body-text {
  width: calc(100% - 45px);
  margin-right: 45px;
  margin-bottom: -1px;
  white-space: pre-wrap;
}
.message-wrapper .text-body-full-width {
  width: 100%;
  margin-right: 0;
}

.edit-container {
  background-color: var(--bitmark-edit-settings-color);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.edit-container .state-button {
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  min-height: 30px;
  font-size: 16px;
  min-width: 90px;
  font-weight: 500;
}
.edit-container .state-save-button {
  background-color: var(--bitmark-reader-primary-color) !important;
  color: #ffffff;
}
.edit-container .state-cancel-button {
  color: var(--bitmark-reader-primary-color);
}

.bit-button {
  justify-content: center !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
