import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {BitmarkConfig} from '../../bitmark.module';
import {SupportedTranslationLanguage} from '../models/bitmark.models';
import {BitbookApiService} from '../../reader/bitbook-api.service';
import {BrowserStorageService} from '../browser-storage/browser-storage.service';

@Component({
  selector: 'bitmark-bit-translate-language-picker',
  templateUrl: './bit-translate-language-picker.component.html',
  styleUrls: ['./bit-translate-language-picker.component.scss']
})
export class BitTranslateLanguagePickerComponent implements OnInit {
  @Output() languageChosen: EventEmitter<string> = new EventEmitter<string>();

  languages: Array<SupportedTranslationLanguage>;
  displayLanguages: Array<SupportedTranslationLanguage>;
  recentLanguages: Array<SupportedTranslationLanguage>;
  language: string;
  isSearching = false;
  customTitle : string;

  private RECENT_LANGUAGES_KEY = 'recent-translation-languages';

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private translate: TranslateService,
              private ngbActiveModal: NgbActiveModal,
              private bitbookApiService: BitbookApiService,
              private browserStorageService: BrowserStorageService) {
  }

  ngOnInit() {
    this.bitbookApiService.getSupportedLanguages(this.translate.currentLang)
      .subscribe(data => {
        const filteredLanguages = data.filter(x => x.supportSource && x.supportTarget);
        this.languages = filteredLanguages;
        this.displayLanguages = filteredLanguages;

        this.browserStorageService.idb.get(this.RECENT_LANGUAGES_KEY).subscribe((storedRecentLanguages: Array<string> | undefined) => {
          if (storedRecentLanguages?.length) {
            this.recentLanguages = [];
            storedRecentLanguages?.forEach(x => {
              const lang = filteredLanguages.find(l => l.languageCode === x);
              if (lang) {
                this.recentLanguages.push(lang);
              }
            });
          } else {
            this.recentLanguages = [];
          }
        });
      }, (err) => console.error(err));
  }

  closeModal() {
    if (!this.language?.length) {
      this.languageChosen.emit(null);
    }
    this.ngbActiveModal.close();
  }

  searchLanguage(evt: Event) {
    const value = (evt.target as any)?.value;
    if (!value) {
      this.displayLanguages = [...this.languages];
      this.isSearching = false;
      return;
    }

    this.isSearching = true;

    this.displayLanguages = this.languages.filter(language => {
      return language.displayName.toLowerCase().includes(value.toLowerCase()) ||
        language.languageCode.toLowerCase().includes(value.toLowerCase());
    });
  }

  chooseLanguage(language: SupportedTranslationLanguage) {
    this.language = language.languageCode;
    this.languageChosen.emit(language.languageCode);

    let storageRecentLanguages: Array<string> = [];
    if (this.recentLanguages?.length) {
      storageRecentLanguages = [
        language.languageCode,
        ...this.recentLanguages
          .filter(x => x.languageCode.toLowerCase() !== language.languageCode.toLowerCase())
          .map(x => x.languageCode)
      ].slice(0, 3);
    } else {
      storageRecentLanguages = [language.languageCode];
    }
    this.browserStorageService.idb.store(this.RECENT_LANGUAGES_KEY, storageRecentLanguages).subscribe();

    this.closeModal();
  }
}
