// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-subscribe-container input {
  max-width: 300px;
}
.page-subscribe-container .page-subscribe-icon {
  font-size: 52px;
  margin-left: 4px;
}
.page-subscribe-container .page-subscribe-instruction {
  margin-bottom: 10px;
  color: #656769;
}
.page-subscribe-container .page-subscribe-lead {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}
.page-subscribe-container .subscribe-button {
  margin-top: 20px;
  padding: 5px 15px;
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
