import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ArticleBit} from '../article/article.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import { BitType } from '../bits.models';
import {StepImageScreenshotWithPointerBit} from './step-image-screenshot-with-pointer.models';

@Component({
  selector: 'bitmark-step-image-screenshot-with-pointer',
  templateUrl: './step-image-screenshot-with-pointer.component.html',
  styleUrls: ['./step-image-screenshot-with-pointer.component.scss']
})
export class StepImageScreenshotWithPointerComponent {
  @Input() bit?: StepImageScreenshotWithPointerBit;
  @Input() isBeingEditedByUser: boolean;
  @Input() canBeEdited = false;
  @Output() cancelBitChanges = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<ArticleBit>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();

  readonly BitType = BitType;
}
