import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MultipleResponse1Bit} from './multiple-response-1.models';
import {BitResource} from '../bits.models';
import {MultipleResponseOption, MultipleResponseQuiz} from '../multiple-response/multiple-response.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-multiple-response-1',
  templateUrl: './multiple-response-1.component.html',
  styleUrls: ['../bits.scss']
})
export class MultipleResponse1Component implements OnInit, OnChanges {
  @Input() bit?: MultipleResponse1Bit;
  @Input() bitId: string;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Input() allowAnswerSubmit = true;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  answersCss = {};

  ngOnInit() {
    this.initializeExample();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.doRefresh();
    }
  }

  setQuizAnswer($event: Event, selectedResponse: string) {
    if (!this.bit.answer) {
      this.bit.answer = {responses: []};
    }
    const isChecked = ($event.target as any).checked;
    const idx = this.bit.answer.responses.indexOf(selectedResponse);

    if (isChecked && idx === -1) {
      this.bit.answer.responses.push(selectedResponse);
    }
    if (!isChecked && idx !== -1) {
      this.bit.answer.responses.splice(idx, 1);
    }

    this.changed.emit();
  }

  isResponseChecked(response: string): boolean {
    if (!this.bit.answer?.responses) {
      return false;
    }
    const idx = this.bit.answer.responses.indexOf(response);
    return idx !== -1;
  }

  private initializeExample() {
    if (!this.bit.isExample) {
      return;
    }

    this.bit.responses?.forEach((c: MultipleResponseOption, idx: number) => {
      if (c.example) {
        if (!this.bit.answer?.responses?.length) {
          this.bit.answer = {responses: []};
        }
        this.bit.answer.responses.push(c.response);
      }

      this.answersCss[idx] = this.handleFeedback(this.bit.answer?.responses || [], c, c.isExample);
    });
  }

  private handleFeedback(quizResponses: string[], c: MultipleResponseOption, responseHasFeedback: boolean) {
    return !responseHasFeedback
      ? ''
      : ((c.isCorrect && quizResponses.includes(c.response)) || (!c.isCorrect && !quizResponses.includes(c.response)))
        ? 'correct'
        : 'incorrect';
  }

  static evalAnswer(bit: MultipleResponse1Bit): BitEvalResult {
    const ret = {
      totalAnswers: bit.responses.length,
      answeredAnswers: (bit.answer?.responses || []).length,
      notAnsweredAnswers: (bit.responses.length - (bit.answer?.responses || []).length),
      correctAnswers: 0,
      incorrectAnswers: 0
    } as BitEvalResult;

    bit.responses.forEach(r => {
      const answerUsed = bit.answer?.responses.includes(r.response);
      if ((r.isCorrect && answerUsed) || (!r.isCorrect && !answerUsed)) {
        ret.correctAnswers++;
      } else {
        ret.incorrectAnswers++;
      }
    });

    console.log(ret);

    return ret;
  }

  doRefresh() {
    this.bit.responses?.forEach((c: MultipleResponseOption, idx: number) => {
      this.answersCss[idx] = this.handleFeedback(this.bit.answer?.responses || [], c, this.hasFeedback);
    });
  }
}
