// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-to-library {
  padding: 5px 0 0 0;
  border-radius: 100%;
  min-width: inherit;
  width: 40px;
  height: 40px;
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
