// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose-book-container {
  border-radius: 10px;
}
.choose-book-container .search-input {
  font-size: 14px;
}
.choose-book-container .books-list {
  max-height: 50vh;
  overflow: auto;
}
.choose-book-container .books-list .book-details {
  flex: 1;
  min-width: 0;
}
.choose-book-container .books-list .book-title, .choose-book-container .books-list .book-subtitle {
  font-family: Inter, sans-serif;
  font-size: 14px;
}
.choose-book-container .books-list .book-title {
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
