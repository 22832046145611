import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ArticleAltBit} from './article-alt.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-article-alt',
  templateUrl: './article-alt.component.html'
})
export class ArticleAltComponent {
  @Input() bit?: ArticleAltBit;
  @Output() openResource = new EventEmitter<BitResource>();
}
