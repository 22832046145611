import {Component, Input} from '@angular/core';
import {AboutBit} from './about.models';

@Component({
  selector: 'bitmark-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss', '../bits.scss']
})
export class AboutComponent {
  @Input() bit?: AboutBit;
}
