import {Component} from '@angular/core';
import {AudioRecordingService} from '../../../../shared/recording/audio-recording.service';
import {AudioRecordingBaseComponent} from '../../../../shared/recording/audio-recording-base/audio-recording-base.component';

@Component({
  selector: 'bitmark-resource-audio-recording-edit',
  templateUrl: './resource-audio-recording-edit.component.html',
  styleUrls: ['./resource-audio-recording-edit.component.scss']
})
export class ResourceAudioRecordingEditComponent extends AudioRecordingBaseComponent {
  constructor(audioRecordingService: AudioRecordingService) {
    super(audioRecordingService);
    this.cssFeedbackBackgroundColor = '--bitmark-resource-audio-recording-background-color';
    this.cssFeedbackAudioWaveColor = '--bitmark-resource-audio-recording-audio-wave-color';
  }
}
