import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ApiService} from '../api/api.service';

@Component({
  selector: 'bitmark-scorm-player',
  templateUrl: './scorm-player.component.html',
  styleUrls: ['./scorm-player.component.scss']
})
export class ScormPlayerComponent implements OnInit, OnDestroy {
  @Input() source: string;

  url: SafeResourceUrl;

  constructor(private ngbActiveModal: NgbActiveModal,
              private _sanitizer: DomSanitizer,
              private apiService: ApiService) {
  }

  ngOnInit() {
    if (!this.source) {
      return;
    }
    this.apiService.get('scorm/courses/{courseId}/registration', {
      courseId: this.source
    }).subscribe(result => {
      this.url = this._sanitizer.bypassSecurityTrustResourceUrl(result.launchLink);
    });
  }

  ngOnDestroy() {
    document
      .getElementById('viewport')
      .setAttribute('content', 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover');
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
