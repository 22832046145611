import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {QuoteBit} from './quote.models';
import {BitmarkEditableTextComponent} from '../bitmark-editable-text/bitmark-editable-text.component';
import {SubSink} from "../../shared";

@Component({
  selector: 'bitmark-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['../bits.scss', './quote.component.scss']
})
export class QuoteComponent implements OnInit, OnDestroy {
  @Input() bit?: QuoteBit;
  @Input() isEditable = false;
  @Input() isBeingEdited = false;
  @Input() bitId: string;
  @Output() onIsBeingEdited = new EventEmitter<QuoteBit>();
  @ViewChild('quote') quoteRef: ElementRef;
  @ViewChild('editableText') editableText: BitmarkEditableTextComponent;

  private clickListener: () => void;
  private changeSubject = new Subject<any>();
  private subSink = new SubSink();

  ngOnInit() {
    if (this.bitId) {
      this.bit.id = this.bitId;
    }
    if (this.isEditable && this.isBeingEdited) {
      this.enterEditMode();
    }
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
      delete this.clickListener;
    }
  }

  changeQuote($event: string) {
    if ($event && this.bit.body !== $event) {
      this.bit.body = $event;
      this.changeSubject.next($event);
    }
  }

  changeQuotedPerson($event: string) {
    if ($event && this.bit.quotedPerson !== $event) {
      this.bit.quotedPerson = $event;
      this.changeSubject.next($event);
    }
  }

  enterEditMode() {
    if (!this.isEditable) {
      return;
    }
    this.isBeingEdited = true;
    // this.clickListener = this.renderer.listen('window', 'click', (e: Event) => {
    //   if (!this.quoteRef.nativeElement.contains(e.target)) {
    //     this.isBeingEdited = false;
    //   }
    //   this.editableText.setToDefault();
    // });
    this.changeSubject
      .pipe(debounceTime(500))
      .subscribe(() => this.onIsBeingEdited.emit(this.bit));
  }

  exitEditMode() {
    this.isBeingEdited = false;
    this.subSink.unsubscribe(true);
  }
}
