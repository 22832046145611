import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BitWordBankService {
  computeWordBank(solutions: Array<string>, usedSolutions: Array<string>): Array<{ text: string, used: boolean }> {
    const wordBankItems: Array<{ text: string, used: boolean }> = [];
    if (solutions?.length) {
      solutions.forEach(x => {
        wordBankItems.push({text: x, used: usedSolutions?.includes(x)});
      });
    }
    wordBankItems.sort();

    return wordBankItems;
  }

  addOrRemoveSolution(usedSolutions: Array<string>, item: string): Array<string> {
    if (!usedSolutions?.length) {
      return [item];
    } else {
      if (usedSolutions.includes(item)) {
        return usedSolutions.filter(x => x !== item);
      } else {
        return [...usedSolutions, item];
      }
    }
  }
}
