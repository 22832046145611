// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-toc {
  border-style: solid;
  border-radius: 30px;
  text-decoration: none;
  height: 40px;
  min-width: 106px;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: var(--bitmark-reader-primary-color, #543199);
  border-color: var(--bitmark-reader-primary-color, #543199);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
