import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropdownItemModel} from '../dropdown/dropdown/dropdown.model';

@Component({
  selector: 'app-text-context-menu',
  templateUrl: './text-context-menu.component.html',
})
export class TextContextMenuComponent {
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() triangleOffsetStyle: { left: string, top: string };
  @Input() type: 'triangle' | 'dropdown' = null;
  @Output() canceled = new EventEmitter<any>();

  cancel() {
    this.canceled.emit();
  }
}
