import {Directive, Input, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
  @Input() appAutofocus: number;

  constructor(public el: ElementRef) {
  }

  ngOnInit() {
    if (this.appAutofocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, this.appAutofocus || 10);
    }
  }
}
