import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {AuthApiService} from './auth-api.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {LoginResponse} from 'auth/auth.models';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);
  private urlExceptions = [
    '/login',
    '/logout',
    '/health/ping'
  ];

  constructor(private auth: AuthService,
              private authApiService: AuthApiService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        for (let u = 0; u < this.urlExceptions.length; u++) {
          if (request.url.includes(this.urlExceptions[u])) {
            return throwError(() => error);
          }
        }
        if (error.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      }));
  }

  private addToken(request: HttpRequest<any>, jwtToken: string): HttpRequest<any> {
    return request.clone({setHeaders: {Authorization: `Bearer ${jwtToken}`}});
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    console.log(`Request to ${request.url} failed with 401`);
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      console.log(`Refreshing access token`);
      return this.authApiService.tokenRefresh()
        .pipe(switchMap((response: LoginResponse | null) => {
          this.isRefreshing = false;
          if (!response?.token) {
            console.log(`New received access token is null. Logging out.`);
            return this.authApiService.logout();
          }
          console.log(`A new access token received`);
          this.auth.setToken(response.token);
          this.refreshTokenSubject.next(response.token);
          console.log(`Repeating request ${request.url}`);
          return next.handle(this.addToken(request, response.token));
        }))
        .pipe(catchError((error) => {
          console.log(`Error retrieving new access token. Logging out.`);
          this.isRefreshing = false;
          return this.authApiService.logout();
        }));
    } else {
      console.log(`A refresh token request was already sent. Waiting for the new access token.`);
      return this.refreshTokenSubject
        .pipe(filter(token => token != null))
        .pipe(take(1))
        .pipe(switchMap((jwtToken: string) => {
          console.log(`Repeating request ${request.url} (historical)`);
          return next.handle(this.addToken(request, jwtToken));
        }));
    }
  }
}
