import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssignmentBit} from './assignment.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['../bits.scss']
})
export class AssignmentComponent {
  @Input() bit?: AssignmentBit;
  @Output() openResource = new EventEmitter<BitResource>();
}
