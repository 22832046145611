import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelfAssessmentBit} from './self-assessment.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-self-assessment',
  templateUrl: './self-assessment.component.html',
  styleUrls: ['../bits.scss']
})
export class SelfAssessmentComponent {
  @Input() bit?: SelfAssessmentBit;
  @Output() openResource = new EventEmitter<BitResource>();
}
