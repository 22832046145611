import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Details1Bit} from './details-1.models';
import {Observable, Subject} from 'rxjs';
import {BaseBit, BitResource} from '../bits.models';
import {ArticleBit} from '../article/article.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';

@Component({
  selector: 'bitmark-details-1',
  templateUrl: './details-1.component.html',
  styleUrls: ['./details-1.component.scss', '../bits.scss']
})
export class Details1Component {
  @Input() bit?: Details1Bit;
  @Input() canBeEdited = false;
  @Input() isEditable = false;
  @Input() isBeingEditedByUser: boolean;
  @Input() updateInstructionEvent?: Observable<any>;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() cancelBitChanges = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<ArticleBit>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() instructionChanged = new EventEmitter<any>();

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveInlineBit.emit(this.bit);
    }
  }
  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  updateInstructionSubject?: Subject<any> = new Subject<any>();

  summaryClicked(event: MouseEvent) {
    if (this.isBeingEditedByUser) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
