import {Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {StorageMap} from "@ngx-pwa/local-storage";
import {BrowserStorage} from './browser-storage';
import {IndexedDbStorage} from './indexeddb-storage';

@Injectable({providedIn: 'root'})
export class BrowserStorageService {
  volatile: BrowserStorage;
  idb: IndexedDbStorage;

  constructor(private localStorage: LocalStorageService, private storage: StorageMap) {
    this.volatile = new BrowserStorage('volatile', this.localStorage);
    this.idb = new IndexedDbStorage(this.storage);
  }
}
