import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  AfterViewInit,
  Injector,
  ElementRef, OnDestroy,
} from '@angular/core';
import {DatePipe} from '@angular/common';
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbPopoverConfig,
  NgbPopover,
  NgbDatepicker,
  NgbTimepicker,
} from '@ng-bootstrap/ng-bootstrap';
import {noop} from 'rxjs';
import {NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl} from '@angular/forms';
import {DateTimeModel} from './date-time.model';
import {SubSink} from "../sub-sink/sub-sink";

@Component({
  selector: 'bitmark-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  @Input()
  dateString: string;

  @Input()
  inputDatetimeFormat = 'M/d/yyyy H:mm:ss';
  @Input()
  hourStep = 1;
  @Input()
  minuteStep = 15;
  @Input()
  secondStep = 30;
  @Input()
  seconds = true;

  @Input()
  disabled = false;

  showTimePickerToggle = false;

  datetime: DateTimeModel = new DateTimeModel();

  @ViewChild('content') contentRef: ElementRef;

  @ViewChild(NgbDatepicker)
  private dp: NgbDatepicker;

  @ViewChild('tp') tp: NgbTimepicker;

  @ViewChild(NgbPopover)
  private popover: NgbPopover;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  private ngControl: NgControl;
  private subSink = new SubSink();

  // @HostListener('document:mousedown', ['$event'])
  // onGlobalClick(event): void {
  //   if (!this.contentRef?.nativeElement.contains(event.target)) {
  //     this.onSave();
  //   }
  // }

  constructor(private config: NgbPopoverConfig, private inj: Injector) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  ngAfterViewInit(): void {
    this.subSink.sink = this.popover.hidden.subscribe(() => {
      this.showTimePickerToggle = false;
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel));
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeModel();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.datetime = new DateTimeModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  onDateChange($event: any | NgbDateStruct) {
    if ($event.year) {
      $event = `${$event.year}-${$event.month}-${$event.day}`;
    }

    const date = DateTimeModel.fromLocalString($event);

    if (!date) {
      return;
    }

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;

    // this.dp.navigateTo({ year: this.datetime.year, month: this.datetime.month });
  }

  onTimeChange(event: NgbTimeStruct) {
    if (!event) {
      // for prod ngb timepicker bugfix
      const hoursEl = document.querySelector('input[aria-label="Hours"]') as any;
      const minutesEl = document.querySelector('input[aria-label="Minutes"]') as any;
      const secondsEl = document.querySelector('input[aria-label="Seconds"]') as any;
      this.datetime.hour = hoursEl ? parseInt(hoursEl.value, 10) : this.datetime.hour;
      this.datetime.minute = minutesEl ? parseInt(minutesEl.value, 10) : this.datetime.minute;
      this.datetime.second = secondsEl ? parseInt(secondsEl.value, 10) : this.datetime.second;
    } else {
      this.datetime.hour = event?.hour;
      this.datetime.minute = event?.minute;
      this.datetime.second = event?.second;
    }
  }

  onSave() {
    if (this.datetime?.year) {
      this.setDateStringModel();
    }
  }

  setDateStringModel() {
    this.dateString = this.datetime.toString();

    // if (!this.firstTimeAssign) {
    this.onChange(this.dateString);
    // } else {
    // if (this.dateString !== null) {
    //   this.firstTimeAssign = false;
    // }
    // }
  }

  inputBlur($event) {
    this.onTouched();
  }
}
