import {Pipe, PipeTransform} from '@angular/core';
import {generateJSON} from '@tiptap/core';
import {ReaderTipTapTapService, TipTapEditorType} from "../reader/tiptap/reader-tiptap.service";

@Pipe({name: 'bitmark-json'})
export class BitmarkReversePipe implements PipeTransform {
  constructor(private readerTipTapService: ReaderTipTapTapService) {
  }

  transform(value: string): any {
    return generateJSON(value.replace(/\n/gi, '<br/>'), this.readerTipTapService.getExtensions(TipTapEditorType.BitmarkPlus));
  }
}
