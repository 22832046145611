import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {cloneDeep} from 'lodash';
import {Subject} from 'rxjs';
import {LearningPathExternalLinkBit} from './learning-path-external-link.models';
import {BitResource, BitResourceType} from '../../bits.models';
import {BitbookApiService} from '../../../reader/bitbook-api.service';
import {ApiService} from '../../../shared/api/api.service';
import {LearningPathCommon} from '../learning-path.common';
import UrlService from '../../../shared/utils/url.service';
import {BitmarkTextContentService} from '../../../shared';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';

@Component({
  selector: 'bitmark-learning-path-external-link',
  templateUrl: './learning-path-external-link.component.html',
  styleUrls: ['./learning-path-external-link.component.scss', '../learning-path.common.scss', '../../bits.scss']
})
export class LearningPathExternalLinkComponent extends LearningPathCommon implements OnInit {
  @Input() bit?: LearningPathExternalLinkBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningPathExternalLinkBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    this.trimHeader(this.bit);

    if (value) {
      this.prevLearningPathExternalLinkBit = cloneDeep(this.bit);
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  private prevLearningPathExternalLinkBit?: LearningPathExternalLinkBit;
  public isContentVisible = true;
  externalLinkHost = '';
  updateContentEventSubject: Subject<any> = new Subject<any>();

  get isHeaderMinimized(): boolean {
    return !this.isContentVisible ||
      (this.bit.isInfoOnly && !this.bit.body);
  }

  get isHeaderOnly(): boolean {
    if (this.bit.isInfoOnly && !this.bit.body && !this.isBeingEditedByUser) {
      return true;
    }

    return !this.bit.body && !this.isBeingEditedByUser && !this.bit.externalLink;
  }

  get isTracked(): boolean {
    return this.bit.isTracked ?? true;
  }

  constructor(private bitBookApiService: BitbookApiService,
              private apiService: ApiService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              private router: Router,
              private urlService: UrlService) {
    super(readerTipTapService, bitmarkTextContentService);
  }

  ngOnInit() {
    if (this.bit.externalLink) {
      this.externalLinkHost = this.urlService.getHostName(this.bit.externalLink);
    }

    this.handleImage(this.bit);
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevLearningPathExternalLinkBit);
    this.editClosed.emit();
  }

  saveChanges() {
    if (this.bit.externalLink && !this.urlService.isValidInternalOrExternal(this.bit.externalLink)) {
      alert('Invalid URL');
      return;
    }

    this.trimHeader(this.bit);
    this.saveInlineBit.emit(this.bit);
  }

  uploadImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitBookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        if (!this.bit.resource) {
          this.bit.resource = {
            type: BitResourceType.Image
          };
        }
        this.bit.resource.image = {
          src: resourceUrl.url,
          alt: this.bit.instruction
        };
        this.imageExists = true;
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  removeImage() {
    if (this.bit.resource) {
      this.bit.resource = null;
    }
    this.imageExists = false;
  }

  open() {
    if (!this.bit.answer?.hasOpened && this.isTracked) {
      this.bit.answer = {
        ...this.bit.answer,
        hasOpened: true
      };
      this.changed.emit();
    }

    if (this.bit.externalLink) {
      if (!this.urlService.isValidInternalOrExternal(this.bit.externalLink)) {
        return;
      }

      let target = '_blank';
      let isRelative = false;
      if (this.bit.externalLink.startsWith('/')) {
        target = '_top';
        isRelative = true;
      } else {
        const linkUrl = new URL(this.bit.externalLink);
        if (window.location.host === linkUrl.host) {
          target = '_top';
        }
      }

      if (target === '_top') {
        let navRoute = this.bit.externalLink;
        if (!isRelative) {
          const navRouteUrl = new URL(navRoute);
          navRoute = navRouteUrl.pathname + navRouteUrl.search + navRouteUrl.hash;
        }

        if (navRoute.endsWith('/')) {
          this.router.navigate([navRoute]);
        } else {
          this.router.navigateByUrl(navRoute);
        }
      } else {
        window.open(this.bit.externalLink, target);
      }
    }
  }

  toggleContent() {
    this.isContentVisible = !this.isContentVisible;
  }

  markAsDone() {
    this.bit.answer = {
      ...this.bit.answer,
      isDone: true
    };
    this.changed.emit();
  }

  reset(event) {
    event.stopPropagation();
    this.bit.answer = {
      hasOpened: false,
      isDone: false
    };
    this.changed.emit();
  }

  onLinkChanged() {
    if (!this.bit.externalLink) {
      return;
    }

    this.externalLinkHost = this.urlService.getHostName(this.bit.externalLink);

    this.apiService.post('learning-paths/link-info', null, {link: this.bit.externalLink})
      .subscribe(result => {
        if (!result) {
          return;
        }

        if (result.imageUrl && !this.imageExists) {
          if (!this.bit.resource) {
            this.bit.resource = {
              type: BitResourceType.Image
            };
          }
          this.bit.resource.image = {
            src: result.imageUrl,
            alt: result.title
          };
        }

        if (result.title) {
          // if (!this.learningPathExternalLinkBit.externalLinkText) {
          //   this.learningPathExternalLinkBit.externalLinkText = result.title;
          // }

          if (!this.bit.instruction) {
            this.bit.instruction = result.title;
          }
        }

        if (result.description) {
          if (this.bit.body && this.bit.body.content) {
            this.bit.body.content.push(...this.bitmarkTextContentService.getBodyContent(result.description, this.bit.format)?.content);
            this.updateContentEventSubject.next(this.bit.body.content);
          } else if (this.bit.body) {
            this.bit.body += '\n' + result.description;
            this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.bit.body, this.bit.format));
          } else {
            this.bit.body = result.description;
            this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.bit.body, this.bit.format));
          }
        }

        if (result.duration) {
          this.bit.duration = result.duration;
        }
      });
  }
}
