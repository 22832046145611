// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  width: 100%;
  height: 100vh;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: 0 0 13px -2px rgba(0, 0, 0, 0.2);
  color: var(--bitmark-reader-primary-color, #543199);
}

.search-title {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}

bitmark-icon {
  color: #a98ae6;
}

.reader-sidebar__content {
  height: calc(100vh - 146.666px);
  overflow-y: auto;
}

.btn-filter {
  border: 1px solid var(--bitmark-reader-primary-color, #543199);
  background-color: transparent;
  border-radius: 5px;
  min-width: 30px;
  font-size: 12px;
  padding: 0 5px;
  color: #000000;
  cursor: pointer;
}

.btn-filter-active {
  background-color: var(--bitmark-reader-primary-color, #543199);
  color: #ffffff;
}

small {
  font-size: 13px;
  font-weight: normal;
}

.last-searched .header {
  font-size: 2.25rem;
  font-weight: lighter;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
