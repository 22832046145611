import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {AppFlashcardsBit} from '../app-flashcards.models';
import {BitApiWrapper} from '../../../bits.models';
import {AppFlashcardsModeCommonComponent} from '../app-flashcards-mode.common';
import {shuffle} from 'lodash';

@Component({
  selector: 'bitmark-app-flashcards-leitner',
  templateUrl: './app-flashcards-leitner.component.html',
  styleUrls: ['./app-flashcards-leitner.component.scss', '../app-flashcards-mode.common.scss', '../app-flashcards.component.scss']
})
export class AppFlashcardsLeitnerComponent extends AppFlashcardsModeCommonComponent implements OnInit, AfterViewInit, OnDestroy {

  private _appFlashcardsBit?: AppFlashcardsBit;
  @Input()
  set appFlashcardsBit(value: AppFlashcardsBit) {
    this._appFlashcardsBit = value;
    if (!value?.answer && this.initialized) {
      this.initPots();
      this.changePot(this.headerContainerElement.nativeElement.querySelector('.pot-' + -1), -1);
      this.computeLeitnerPotsBitsCount();
    }
  }

  get appFlashcardsBit(): AppFlashcardsBit {
    return this._appFlashcardsBit;
  }

  @Input()
  set isActive(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.ngAfterViewInit();
      });

      this.handleKeyboardFocus(this._listenToKeyboard);
    } else {
      this.handleKeyboardFocus(false);
    }
  }

  private _listenToKeyboard = false;
  @Input()
  set listenToKeyboard(value: boolean) {
    this._listenToKeyboard = value;
    this.handleKeyboardFocus(value);
  }

  @Input() flashcardBits: Array<BitApiWrapper> = [];
  @Input() isInModal = false;
  @Input() isMobile = false;
  @Output() changed = new EventEmitter<any>();

  leinterPotsBitsCount = 0;

  private readonly leitnerModePotsCount = 4;
  private initialized = false;

  constructor(domUtilsService: DomUtilsService,
              renderer: Renderer2) {
    super(domUtilsService, renderer);
    this.currentPotIndex = -1;
  }

  ngOnInit() {
    this.initializeKeyboard();

    this.initialized = true;

    if (!this.flashcardBits?.length) {
      return;
    }

    this.initPots();

    if (this.appFlashcardsBit?.answer?.leitner?.state) {
      this.currentPotIndex = this.appFlashcardsBit.answer.leitner.state.pot;
      this.changePot(null, this.currentPotIndex, this.appFlashcardsBit.answer.leitner.state.bitId, this.appFlashcardsBit.answer.leitner.state.cardState);
    } else {
      this.changePot(null, -1);
    }

    this.computeLeitnerPotsBitsCount();
  }

  ngAfterViewInit() {
    this.computeCurrentPotBackgroundPosition('leitner');
  }

  ngOnDestroy() {
    this.handleKeyboardFocus(false);
  }

  changePot(targetElem: any, index: number, targetBitId?: string, targetCardState?: 'front' | 'back') {
    if (targetElem) {
      this.computeCurrentPotBackgroundPosition('leitner', targetElem);
    }
    this.currentPotIndex = index;
    if (index < 0) {
      const potsBits: Array<string> = [];
      this.appFlashcardsBit.answer.leitner?.pots?.forEach(pot => {
        potsBits.push(...pot);
      });
      if (this.appFlashcardsBit.answer?.leitner?.shuffle && this.appFlashcardsBit.answer?.leitner?.shuffle[-1]?.length) {
        const unansweredBits = this.flashcardBits.filter(x => !potsBits.includes(x.id));
        this.flashcardPoolBits = this.getShuffledCardBits(this.appFlashcardsBit.answer?.leitner.shuffle[-1], unansweredBits);
      } else {
        this.flashcardPoolBits = this.flashcardBits.filter(x => !potsBits.includes(x.id));
      }
    } else {
      const currentPotBitIds = this.appFlashcardsBit.answer.leitner.pots[index];
      const shuffledBitIds = this.appFlashcardsBit.answer.leitner?.shuffle?.length ? this.appFlashcardsBit.answer.leitner.shuffle[index] : [];

      if (shuffledBitIds?.length) {
        this.flashcardPoolBits = this.getShuffledCardBits(shuffledBitIds, this.flashcardBits.filter(x => currentPotBitIds.includes(x.id)));
      } else {
        this.flashcardPoolBits = this.flashcardBits.filter(x => currentPotBitIds.includes(x.id));
      }
    }

    this.cardIndex = targetBitId
      ? this.flashcardPoolBits.findIndex(x => x.id === targetBitId)
      : 0;
    if (this.cardIndex < 0) {
      this.cardIndex = 0;
    }

    this.cardState = targetCardState || 'front';
    this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
    this.prevCardIndex = this.getCardIndex(-1);
    this.prevCard = this.bitToCard(this.flashcardPoolBits[this.prevCardIndex]);
  }

  goToNext(skipBack = false) {
    super.goToNext(skipBack);

    this.appFlashcardsBit.answer.leitner = {
      ...this.appFlashcardsBit.answer.leitner,
      state: {
        pot: this.currentPotIndex,
        bitId: this.card?.bitId,
        cardState: this.cardState
      }
    };

    this.changed.emit();
  }

  goToPrevious() {
    super.goToPrevious();

    this.appFlashcardsBit.answer.leitner = {
      ...this.appFlashcardsBit.answer.leitner,
      state: {
        pot: this.currentPotIndex,
        bitId: this.card?.bitId,
        cardState: this.cardState
      }
    };

    this.changed.emit();
  }

  markAsRight() {
    const currentBit = this.flashcardPoolBits[this.cardIndex];
    if (!currentBit) {
      return;
    }
    const potsCount = this.appFlashcardsBit.answer.leitner.pots.length;
    const currentPotIndex = this.getBitPotIndex(currentBit.id);

    if (currentPotIndex === potsCount - 1) {
      this.goToNext(this.cardState === 'front');
      return;
    }

    if (currentPotIndex < 0) {
      this.appFlashcardsBit.answer.leitner.pots[0].push(currentBit.id);
    } else {
      this.appFlashcardsBit.answer.leitner.pots[currentPotIndex] = this.appFlashcardsBit.answer.leitner.pots[currentPotIndex].filter(x => x !== currentBit.id);
      if (!this.appFlashcardsBit.answer.leitner.pots[currentPotIndex + 1].includes(currentBit.id)) {
        this.appFlashcardsBit.answer.leitner.pots[currentPotIndex + 1].push(currentBit.id);
      }
    }

    this.removeBitFromShuffle(currentBit.id, currentPotIndex);
    this.appFlashcardsBit.answer.leitner.state = {
      pot: currentPotIndex,
      bitId: currentBit.id,
      cardState: this.cardState
    };

    this.generateLeaveAnimation();
    this.removeCardFromPool(currentBit.id);
    this.computeLeitnerPotsBitsCount();

    if (this.cardIndex === this.flashcardPoolBits.length) {
      this.cardIndex = 0;
    }

    if (this.flashcardPoolBits.length === 0) {
      this.card = null;
    } else {
      this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
      this.cardState = 'front-no';
    }

    this.changed.emit();
  }

  markAsWrong() {
    const currentBit = this.flashcardPoolBits[this.cardIndex];
    if (!currentBit) {
      return;
    }
    const currentPotIndex = this.getBitPotIndex(currentBit.id);

    if (currentPotIndex < 0) {
      this.goToNext(this.cardState === 'front');
      return;
    }

    this.appFlashcardsBit.answer.leitner.pots[currentPotIndex] = this.appFlashcardsBit.answer.leitner.pots[currentPotIndex].filter(x => x !== currentBit.id);

    this.removeBitFromShuffle(currentBit.id, currentPotIndex);
    this.appFlashcardsBit.answer.leitner.state = {
      pot: currentPotIndex,
      bitId: currentBit.id,
      cardState: this.cardState
    };


    this.generateLeaveAnimation();
    this.removeCardFromPool(currentBit.id);
    this.computeLeitnerPotsBitsCount();

    if (this.cardIndex === this.flashcardPoolBits.length) {
      this.cardIndex = 0;
    }

    if (this.flashcardPoolBits.length === 0) {
      this.card = null;
    } else {
      this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
      this.cardState = 'front-no';
    }

    this.changed.emit();
  }

  shuffleCards() {
    if (!this.flashcardPoolBits.length) {
      return;
    }

    this.flashcardPoolBits = shuffle(this.flashcardPoolBits);
    this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
    this.prevCardIndex = this.getCardIndex(-1);
    this.prevCard = this.bitToCard(this.flashcardPoolBits[this.prevCardIndex]);

    const shuffleData = this.appFlashcardsBit.answer?.leitner?.shuffle || {};
    shuffleData[this.currentPotIndex] = this.flashcardPoolBits.map(x => x.id);

    this.appFlashcardsBit.answer = {
      ...this.appFlashcardsBit.answer,
      leitner: {
        ...this.appFlashcardsBit.answer?.leitner,
        state: {
          pot: this.currentPotIndex,
          bitId: this.card?.bitId,
          cardState: this.cardState
        },
        shuffle: {...shuffleData}
      }
    };

    this.changed.emit();
  }

  private initPots() {
    if (!this.appFlashcardsBit.answer?.leitner?.pots?.length) {
      this.appFlashcardsBit.answer = {
        ...this.appFlashcardsBit.answer,
        leitner: {
          pots: [
            [], [], [], []
          ]
        }
      };
    } else if (this.appFlashcardsBit.answer?.leitner?.pots?.length < this.leitnerModePotsCount) {
      for (let i = this.appFlashcardsBit.answer?.leitner?.pots?.length; i < this.leitnerModePotsCount; i++) {
        this.appFlashcardsBit.answer.leitner.pots.push([]);
      }
    } else if (this.appFlashcardsBit.answer?.leitner?.pots?.length > this.leitnerModePotsCount) {
      this.appFlashcardsBit.answer.leitner.pots.splice(this.leitnerModePotsCount, this.appFlashcardsBit.answer.leitner.pots.length - this.leitnerModePotsCount);
    }
  }

  private getBitPotIndex(bitId: string): number {
    const potsCount = this.appFlashcardsBit.answer.leitner.pots.length;

    for (let i = 0; i < potsCount; i++) {
      if (this.appFlashcardsBit.answer.leitner.pots[i].includes(bitId)) {
        return i;
      }
    }

    return -1;
  }

  private computeLeitnerPotsBitsCount() {
    let count = 0;
    this.appFlashcardsBit.answer.leitner.pots?.forEach(pot => {
      count += pot.length;
    });

    this.leinterPotsBitsCount = count;
  }

  private removeBitFromShuffle(bitId: string, potIndex: number) {
    if (this.appFlashcardsBit.answer?.leitner?.shuffle && this.appFlashcardsBit.answer?.leitner?.shuffle[potIndex]?.length) {
      this.appFlashcardsBit.answer.leitner.shuffle[potIndex] = this.appFlashcardsBit.answer?.leitner?.shuffle[potIndex].filter(x => x !== bitId);
    }
  }
}
