import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BitResource, BitResourceType} from '../bits.models';
import {ExtractorBlockBit} from './extractor-block.models';
import {DropdownItemModel} from '../../shared';

@Component({
  selector: 'bitmark-extractor-block',
  templateUrl: './extractor-block.component.html',
  styleUrls: ['../bits.scss']
})
export class ExtractorBlockComponent implements OnInit {
  @Input() bit?: ExtractorBlockBit;
  @Input() canBeEdited: boolean;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() saveInlineBit = new EventEmitter<{ bit: ExtractorBlockBit, tags: Array<string> }>();

  protected readonly BitResourceType = BitResourceType;
  dropdownActions: Array<DropdownItemModel> = [];

  ngOnInit() {
    this.dropdownActions = this.bit?.availableClassifications?.map(classification => {
      return {
        label: classification,
        handler: () => {
          if (!this.canBeEdited) {
            return;
          }
          this.bit.classification = classification;
          this.saveInlineBit.emit({bit: this.bit, tags: ['#reclassified']});
        }
      };
    });
  }
}
