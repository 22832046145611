import {AfterViewInit, Component} from '@angular/core';
import {AngularNodeViewComponent} from 'ngx-tiptap';

@Component({
  selector: 'bitmark-nodeview-image',
  templateUrl: './image.component.html'
})
export class NodeviewImageComponent extends AngularNodeViewComponent implements AfterViewInit {
  src = '';

  ngAfterViewInit() {
    this.src = this.node.attrs.src;
  }

  onChange(event: Event): void {
    this.updateAttributes({
      src: (event.target as any).value,
    });
  }
}
