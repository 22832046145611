import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {PageSectionFolderBit} from './page-section-folder.models';
import {BookType, ProductFamily} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-page-section-folder',
  templateUrl: './page-section-folder.component.html',
  styleUrls: ['./page-section-folder.component.scss']
})
export class PageSectionFolderComponent implements OnInit {
  @Input() bit?: PageSectionFolderBit;
  @Input() isNavigateDirectlyToBook?: boolean;
  @Output() navigateToProduct: EventEmitter<{ productId: string, family?: ProductFamily }> = new EventEmitter<{
    productId: string,
    family?: ProductFamily
  }>();
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string }> = new EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily
  }>();
  isWideEntry = false;
  publisher: string;
  title: string;
  bookType: string;
  _operationId: any;
  products: Array<any>;

  @HostListener('document:gmbProductDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    if (event?.detail?.operationId === this._operationId) {
      const prds = event?.detail?.data?.product?.folderProducts?.map((p) => JSON.parse(JSON.stringify(p.product)));
      prds.forEach((p) => {
        p.publisher = p && p?.meta?.publisher?.length ? p.meta.publisher[0].data : '';
        p.title = p && p?.texts?.title ? p.texts.title : '';
      })
      this.products = prds;
    }
  }

  constructor() {
  }

  ngOnInit() {
    this._operationId = (this.bit?.productFolder) + (Math.random() + 1).toString(36).substring(7);
    const event = new CustomEvent('gmbProductRequested', {
      detail: {
        productId: this.bit?.productFolder,
        operationId: this._operationId
      }, bubbles: true
    });
    document.dispatchEvent(event);
  }

  openProductPage(product) {
    if (this.isNavigateDirectlyToBook) {
      if (!product?.bookExists) {
        return;
      }
      this.navigateToBook.emit({bookId: product?._id, fragment: 'start'})
    } else {
      this.navigateToProduct.emit({productId: product?._id});
    }
  }

  setWideImage() {

  }

  protected readonly BookType = BookType;
}
