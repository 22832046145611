import {Component, Input} from '@angular/core';

@Component({
  selector: 'bitmark-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['../bits.scss']
})
export class SurveyComponent {
  @Input() bit?;
}
