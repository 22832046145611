import {Component, Input} from '@angular/core';
import {DetailsImageBit} from './details-image.models';
import {BitType} from '../bits.models';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'bitmark-details-image',
  templateUrl: './details-image.component.html',
  styleUrls: ['./details-image.component.scss', '../bits.scss'],
  animations: [
    trigger('slideToggle', [
      state('collapsed', style({height: '0'})),
      state('expanded', style({height: '*'})),
      transition('collapsed => expanded', animate('500ms ease-in-out', style({ height: '*' }))),
      transition('expanded => collapsed', animate('500ms ease-in-out', style({ height: '0' })))
    ])
  ]
})
export class DetailsImageComponent {
  @Input() bit: DetailsImageBit;
  readonly BitType = BitType;
  isBodyCollapsed = true;
  toggleState = 'collapsed';

  toggleBody() {
    this.isBodyCollapsed = !this.isBodyCollapsed;
    this.toggleState = this.toggleState === 'collapsed' ? 'expanded' : 'collapsed';
  }
}
