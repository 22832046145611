import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {LearningEventPreparationTaskBit} from './learning-event-preparation-task.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';

@Component({
  selector: 'bitmark-learning-event-preparation-task',
  templateUrl: './learning-event-preparation-task.component.html',
  styleUrls: ['./learning-event-preparation-task.component.scss', '../learning-event.common.scss'],
  animations: toggleAnimations
})
export class LearningEventPreparationTaskComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventPreparationTaskBit;
  @Output() changed = new EventEmitter<any>();

  isLeadEmpty = false;
  isItemEmpty = false;

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.toggleStateIsDone = this.bit?.answer?.isDone ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.bit.answer?.isDone);
  }

  toggleCheckbox() {
    if (!this.bit.answer) {
      this.bit.answer = {};
    }

    this.bit.answer.isDone = !this.bit.answer.isDone;

    if (this.isBodyCollapsed) {
      if (this.bit.answer?.isDone) {
        this.toggleStateIsDone = 'collapsed';
      } else {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'expanded';
      }
    } else {
      if (this.bit.answer?.isDone) {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'collapsed';
        this.isBodyCollapsed = true;
      }
    }

    this.changed.emit();
  }
}
