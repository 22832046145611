import {Node, mergeAttributes} from '@tiptap/core';

export interface BulletListOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    bulletList: {
      /**
       * Toggle a bullet list
       */
      toggleBulletList: () => ReturnType,
    };
  }
}

export const BulletList = Node.create<BulletListOptions>({
  name: 'bulletList',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block list',

  content: 'listItem+',

  parseHTML() {
    return [
      {tag: 'ul'},
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleBulletList: () => ({commands}) => {
        return commands.toggleList(this.name, 'listItem');
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-8': () => this.editor.commands.toggleBulletList(),
    };
  }
});

export const NoBulletList = BulletList.extend({
  name: 'noBulletList',
  addCommands(): any {
    return {
      toggleNoBulletList: () => ({commands}) => {
        return commands.toggleList(this.name, (this.options as any).itemTypeName);
      },
      setNoBulletList: () => ({commands}) => {
        return commands.liftListItem(this.name, (this.options as any).itemTypeName);
      }
    };
  }
});
