// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.host {
  margin: 0 calc(var(--bitmark-bit-negative-padding));
  padding: calc(-1 * var(--bitmark-bit-negative-padding));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
