import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class KeypressUtilsService {
  isAlphaNumericalKey(e) {
    const key = e.keyCode;
    return (key >= 48 && key <= 90) || (key >= 96 && key <= 111) || (key >= 186 && key <= 222) || key === 32;
  }

  isNumericalKey(e) {
    return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) !== -1;
  }

  isModifierKey(e) {
    return e.shiftKey || e.ctrlKey || e.altKey || e.metaKey;
  }

  isShiftAlone(e) {
    return e.keyCode === 16;
  }

  isShiftKey(e) {
    return e.shiftKey;
  }

  isCtrlKey(e) {
    return e.ctrlKey || e.metaKey;
  }

  isAltKey(e) {
    return e.altKey;
  }

  isCtrlAndShiftKeyCombo(e) {
    return this.isCtrlKey(e) && this.isShiftKey(e);
  }

  isMetaLeftKey(e) {
    return (e.metaKey && e.keyCode === 91) || e.code === 'MetaLeft';
  }

  isMetaRightKey(e) {
    return (e.metaKey && e.keyCode === 93) || e.code === 'MetaRight';
  }

  isFunctionKey(e) {
    return (112 <= e.keyCode && e.keyCode <= 123);
  }

  isDeadKey(e) {
    return e.key === 'Dead';
  }

  isNavKey(e) {
    const keys = [38, 40, 37, 39, 36, 35, 33, 34]; // up, down, left, right, home, end, pgup, pgdown
    return keys.indexOf(e.keyCode) !== -1;
  }

  isNavHarmKeys(e) {
    const keys = [38, 40, 36, 35, 33, 34]; // up, down, home, end, pgup, pgdown
    return keys.indexOf(e.keyCode) !== -1;
  }

  isDelKey(e) {
    return e.keyCode === 46;
  }

  isBackspaceKey(e) {
    return e.keyCode === 8;
  }

  isLeftNavKey(e) {
    return e.keyCode === 37; // left
  }

  isLeftNavKeyWithShift(e) {
    return e.keyCode === 37 && e.shiftKey; // left
  }

  isRightNavKey(e) {
    return e.keyCode === 39; // right
  }

  isRightNavKeyWithShift(e) {
    return e.keyCode === 39 && e.shiftKey; // right
  }

  isSelectAllTextCombo(e) {
    return e.keyCode === 65 && (e.metaKey || e.ctrlKey); // ctrl + a
  }

  isApplyBoldCombo(e) {
    return e.keyCode === 66 && (e.metaKey || e.ctrlKey); // ctrl + b
  }

  isApplyItalicCombo(e) {
    return e.keyCode === 73 && (e.metaKey || e.ctrlKey); // ctrl + i
  }

  isTabKey(e) {
    return e.keyCode === 9;
  }

  isEnterKey(e) {
    return e.keyCode === 13;
  }

  isSpaceKey(e) {
    return e.keyCode === 32;
  }

  isEscKey(e) {
    return e.keyCode === 27;
  }

  isNbspKey(e) {
    return e.keyCode === 160;
  }

  isMetaKey(e) {
    return e.metaKey || e.key === 'Meta';
  }

  isCopyKeyCombo(e) {
    return (e.ctrlKey || e.metaKey) && e.keyCode === 67;
  }

  isPasteKeyCombo(e) {
    return (e.ctrlKey || e.metaKey) && e.keyCode === 86;
  }

  isNativeBrowserCombo(e) {
    const blackList = [90, 89]; // z, y
    return (e.ctrlKey || e.metaKey) && blackList.indexOf(e.keyCode) === -1;
  }

  isHarmlessKey(e) {
    return this.isShiftAlone(e) || this.isNavKey(e) || this.isNbspKey(e) || this.isFunctionKey(e) || this.isCtrlKey(e) || this.isMetaKey(e) || this.isEscKey(e);
  }

  isRightSeparatorKey(e) {
    if (e.shiftKey) {
      return [':', '%', ')', '}', '?', '>', '!', '"'].indexOf(e.key) !== -1;
    } else {
      return [',', '.', ';', ']', '\''].indexOf(e.key) !== -1;
    }
  }

  isLeftSeparatorKey(e) {
    if (e.shiftKey) {
      return ['#', '%', '@', '`', '(', '{', '/', '<', '"'].indexOf(e.key) !== -1;
    } else {
      return ['\'', '['].indexOf(e.key) !== -1;
    }
  }

  isUndoShortcut(e) {
    return (e.ctrlKey || e.metaKey) && e.keyCode === 90; // Ctrl+Z
  }

  isRedoShortcut(e) {
    return (e.ctrlKey || e.metaKey) && ((e.keyCode === 90 && e.shiftKey) || e.keyCode === 89); // Ctrl+Shift+Z,  Ctrl+Y
  }

  isCutShortcut(e) {
    return (e.ctrlKey || e.metaKey) && e.keyCode === 88; // Ctrl+X
  }

  isIfLongPressedWillDisplayOSXCharPopup(e) {
    // const popupChars = 'èéêëēėęyÿûüùúūnîïíīįìôöòóœøōõàáâäæãåāßśšłžźżçćčñń';
    return ['e', 'y', 'u', 'i', 'o', 'a', 's', 'l', 'z', 'c', 'n'].indexOf(e.key) !== -1;
  }

  isOSXCharPopupNavKey(e) {
    const keys = [229];
    return keys.indexOf(e.keyCode) !== -1;
  }

  isOSXOpenEmojiCombo(e) {
    return e.ctrlKey && e.metaKey && this.isSpaceKey(e);
  }
}
