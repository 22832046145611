import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {AppFlashcardsBit} from '../app-flashcards.models';
import {BitApiWrapper} from '../../../bits.models';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {AppFlashcardsModeCommonComponent} from '../app-flashcards-mode.common';
import {shuffle} from 'lodash';

@Component({
  selector: 'bitmark-app-flashcards-normal',
  templateUrl: './app-flashcards-normal.component.html',
  styleUrls: ['./app-flashcards-normal.component.scss', '../app-flashcards-mode.common.scss', '../app-flashcards.component.scss']
})
export class AppFlashcardsNormalComponent extends AppFlashcardsModeCommonComponent implements OnInit, AfterViewInit, OnDestroy {
  private _appFlashcardsBit?: AppFlashcardsBit;
  @Input()
  set appFlashcardsBit(value: AppFlashcardsBit) {
    this._appFlashcardsBit = value;
    if (!value?.answer && this.initialized) {
      this.changePot(this.headerContainerElement.nativeElement.querySelector('.pot-' + 0), 0);
      this.isFinished = false;
    }
  }

  get appFlashcardsBit(): AppFlashcardsBit {
    return this._appFlashcardsBit;
  }

  @Input()
  set isActive(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.ngAfterViewInit();
      });

      this.handleKeyboardFocus(this._listenToKeyboard);
    } else {
      this.handleKeyboardFocus(false);
    }
  }

  private _listenToKeyboard = false;
  @Input()
  set listenToKeyboard(value: boolean) {
    this._listenToKeyboard = value;
    this.handleKeyboardFocus(value);
  }

  @Input() flashcardBits: Array<BitApiWrapper> = [];
  @Input() isInModal = false;
  @Input() isMobile = false;
  @Output() changed = new EventEmitter<any>();

  @ViewChild('wrongEmoji') wrongEmojiElem: ElementRef;
  @ViewChild('rightEmoji') rightEmojiElem: ElementRef;

  isFinished = false;

  private initialized = false;

  constructor(domUtilsService: DomUtilsService,
              renderer: Renderer2) {
    super(domUtilsService, renderer);
  }

  ngOnInit() {
    this.initializeKeyboard();
    this.checkIfFinished();

    this.initialized = true;

    if (this.isFinished) {
      return;
    }

    if (!this.flashcardBits?.length) {
      return;
    }

    if (this.appFlashcardsBit?.answer?.normal?.state) {
      this.currentPotIndex = this.appFlashcardsBit.answer.normal.state.pot || 0;
      this.changePot(null, this.currentPotIndex, this.appFlashcardsBit.answer.normal.state.bitId, this.appFlashcardsBit.answer.normal.state.cardState);
    } else {
      this.changePot(null, 0);
    }
  }

  ngAfterViewInit() {
    if (!this.isFinished) {
      this.computeCurrentPotBackgroundPosition('normal');
    }
  }

  ngOnDestroy() {
    this.handleKeyboardFocus(false);
  }

  changePot(targetElem: any, index: number, targetBitId?: string, targetCardState?: 'front' | 'back') {
    if (targetElem) {
      this.computeCurrentPotBackgroundPosition('normal', targetElem);
    }

    this.currentPotIndex = index;
    if (index === 0) {
      const wrongRightPotBits: Array<string> = [...(this.appFlashcardsBit.answer?.normal?.wrong || []), ...(this.appFlashcardsBit.answer?.normal?.right || [])];
      if (this.appFlashcardsBit.answer?.normal?.shuffle?.unanswered?.length) {
        const unansweredBits = this.flashcardBits.filter(x => !wrongRightPotBits.includes(x.id));
        this.flashcardPoolBits = this.getShuffledCardBits(this.appFlashcardsBit.answer.normal.shuffle.unanswered, unansweredBits);
      } else {
        this.flashcardPoolBits = this.flashcardBits.filter(x => !wrongRightPotBits.includes(x.id));
      }
    } else {
      const currentPotBitIds = index === -1 ? (this.appFlashcardsBit.answer?.normal?.wrong || []) : (this.appFlashcardsBit.answer?.normal?.right || []);
      const shuffledBitIds = index === -1 ? (this.appFlashcardsBit.answer?.normal?.shuffle?.wrong || []) : (this.appFlashcardsBit.answer?.normal?.shuffle?.right || []);

      if (shuffledBitIds?.length) {
        this.flashcardPoolBits = this.getShuffledCardBits(shuffledBitIds, this.flashcardBits.filter(x => currentPotBitIds.includes(x.id)));
      } else {
        this.flashcardPoolBits = this.flashcardBits.filter(x => currentPotBitIds.includes(x.id));
      }
    }

    this.cardIndex = targetBitId
      ? this.flashcardPoolBits.findIndex(x => x.id === targetBitId)
      : 0;

    if (this.cardIndex < 0) {
      this.cardIndex = 0;
    }

    this.cardState = targetCardState || 'front';
    this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
    this.prevCardIndex = this.getCardIndex(-1);
    this.prevCard = this.bitToCard(this.flashcardPoolBits[this.prevCardIndex]);
  }

  goToNext(skipBack = false) {
    super.goToNext(skipBack);

    this.appFlashcardsBit.answer.normal = {
      ...this.appFlashcardsBit.answer.normal,
      state: {
        pot: this.currentPotIndex,
        bitId: this.card?.bitId,
        cardState: this.cardState
      }
    };
    this.changed.emit();
  }

  goToPrevious() {
    super.goToPrevious();

    this.appFlashcardsBit.answer.normal = {
      ...this.appFlashcardsBit.answer.normal,
      state: {
        pot: this.currentPotIndex,
        bitId: this.card?.bitId,
        cardState: this.cardState
      }
    };
    this.changed.emit();
  }

  markAsRight() {
    const currentBit = this.flashcardPoolBits[this.cardIndex];
    if (!currentBit) {
      return;
    }

    const currentPotIndex = this.getBitPotIndex(currentBit.id);
    if (currentPotIndex === 1) {
      this.goToNext(this.cardState === 'front');
      return;
    }

    this.addJumpAnimation(this.rightEmojiElem);

    if (this.appFlashcardsBit.answer?.normal) {
      if (this.appFlashcardsBit.answer.normal.wrong?.includes(currentBit.id)) {
        this.appFlashcardsBit.answer.normal.wrong = this.appFlashcardsBit.answer.normal.wrong.filter(x => x !== currentBit.id);
      }
      if (!this.appFlashcardsBit.answer.normal.right?.includes(currentBit.id)) {
        this.appFlashcardsBit.answer.normal.right = [...(this.appFlashcardsBit.answer.normal.right || []), currentBit.id];
      }
    } else {
      if (this.appFlashcardsBit.answer) {
        this.appFlashcardsBit.answer.normal = {
          right: [currentBit.id],
          wrong: [],
          state: {
            pot: this.currentPotIndex,
            bitId: currentBit.id,
            cardState: this.cardState
          }
        };
        this.appFlashcardsBit.answer.state = {
          mode: 'normal'
        };
      } else {
        this.appFlashcardsBit.answer = {
          normal: {
            right: [currentBit.id],
            wrong: [],
            state: {
              pot: this.currentPotIndex,
              bitId: currentBit.id,
              cardState: this.cardState
            }
          },
          leitner: {pots: [[], [], [], []]},
          state: {
            mode: 'normal'
          }
        };
      }
    }
    this.removeBitFromShuffle(currentBit.id, this.getPotPropName(currentPotIndex));

    this.generateLeaveAnimation();
    this.removeCardFromPool(currentBit.id);
    if (this.cardIndex === this.flashcardPoolBits.length) {
      this.cardIndex = 0;
    }

    if (this.flashcardPoolBits.length === 0) {
      this.card = null;
    } else {
      this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
      this.cardState = 'front-no';
    }

    this.changed.emit();
    this.checkIfFinished();
  }

  markAsWrong() {
    const currentBit = this.flashcardPoolBits[this.cardIndex];
    if (!currentBit) {
      return;
    }

    const currentPotIndex = this.getBitPotIndex(currentBit.id);
    if (currentPotIndex === -1) {
      this.goToNext(this.cardState === 'front');
      return;
    }

    this.addJumpAnimation(this.wrongEmojiElem);

    if (this.appFlashcardsBit.answer?.normal) {
      if (this.appFlashcardsBit.answer.normal.right?.includes(currentBit.id)) {
        this.appFlashcardsBit.answer.normal.right = this.appFlashcardsBit.answer.normal.right.filter(x => x !== currentBit.id);
      }
      if (!this.appFlashcardsBit.answer.normal.wrong?.includes(currentBit.id)) {
        this.appFlashcardsBit.answer.normal.wrong = [...(this.appFlashcardsBit.answer.normal.wrong || []), currentBit.id];
      }
    } else {
      if (this.appFlashcardsBit.answer) {
        this.appFlashcardsBit.answer.normal = {
          right: [],
          wrong: [currentBit.id],
          state: {
            pot: this.currentPotIndex,
            bitId: currentBit.id,
            cardState: this.cardState
          }
        };
        this.appFlashcardsBit.answer.state = {
          mode: 'normal'
        };
      } else {
        this.appFlashcardsBit.answer = {
          normal: {
            right: [],
            wrong: [currentBit.id]
          },
          leitner: {pots: [[], [], [], []]},
          state: {
            mode: 'normal'
          }
        };
      }
    }
    this.removeBitFromShuffle(currentBit.id, this.getPotPropName(currentPotIndex));

    this.generateLeaveAnimation();
    this.removeCardFromPool(currentBit.id);
    if (this.cardIndex === this.flashcardPoolBits.length) {
      this.cardIndex = 0;
    }

    if (this.flashcardPoolBits.length === 0) {
      this.card = null;
    } else {
      this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
      this.cardState = 'front-no';
    }

    this.changed.emit();
    this.checkIfFinished();
  }

  shuffleCards() {
    if (!this.flashcardPoolBits.length) {
      return;
    }

    this.flashcardPoolBits = shuffle(this.flashcardPoolBits);
    this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
    this.prevCardIndex = this.getCardIndex(-1);
    this.prevCard = this.bitToCard(this.flashcardPoolBits[this.prevCardIndex]);

    let shuffleProp: any = {};
    switch (this.currentPotIndex) {
      case -1:
        shuffleProp = {
          wrong: this.flashcardPoolBits.map(x => x.id)
        };
        break;

      case 0:
        shuffleProp = {
          unanswered: this.flashcardPoolBits.map(x => x.id)
        };
        break;

      case 1:
        shuffleProp = {
          right: this.flashcardPoolBits.map(x => x.id)
        };
        break;

      default:
        break;
    }

    this.appFlashcardsBit.answer = {
      ...this.appFlashcardsBit.answer,
      normal: {
        ...this.appFlashcardsBit.answer?.normal,
        state: {
          pot: this.currentPotIndex,
          bitId: this.card?.bitId,
          cardState: this.cardState
        },
        shuffle: {
          ...this.appFlashcardsBit.answer?.normal?.shuffle,
          ...shuffleProp
        }
      }
    };

    this.changed.emit();
  }

  restart() {
    this.appFlashcardsBit.answer.normal = {
      right: [],
      wrong: [],
      shuffle: null,
      state: null
    };
    this.isFinished = false;

    setTimeout(() => {
      this.changePot(this.headerContainerElement.nativeElement.querySelector('.pot-' + 0), 0);
    }, 200);

    this.changed.emit();
  }

  private checkIfFinished() {
    if (!this.flashcardBits?.length) {
      return;
    }
    this.isFinished = this.flashcardBits.length === ((this.appFlashcardsBit.answer?.normal?.right?.length || 0) + (this.appFlashcardsBit.answer?.normal?.wrong?.length || 0));
  }

  private addJumpAnimation(elem: ElementRef) {
    elem.nativeElement.classList.add('jump-animation');
    elem.nativeElement.addEventListener('webkitAnimationEnd', () => {
      this.removeJumpAnimation(elem);
    });
    elem.nativeElement.addEventListener('onanimationend', () => {
      this.removeJumpAnimation(elem);
    });
    elem.nativeElement.addEventListener('msAnimationEnd', () => {
      this.removeJumpAnimation(elem);
    });
    elem.nativeElement.addEventListener('animationend', () => {
      this.removeJumpAnimation(elem);
    });
  }

  private removeJumpAnimation(elem: ElementRef) {
    elem.nativeElement.classList.remove('jump-animation');
  }

  private getBitPotIndex(bitId: string): number {
    if (this.appFlashcardsBit.answer?.normal?.wrong?.includes(bitId)) {
      return -1;
    }

    if (this.appFlashcardsBit.answer?.normal?.right?.includes(bitId)) {
      return 1;
    }

    return 0;
  }

  private removeBitFromShuffle(bitId: string, potPropName: string) {
    if (this.appFlashcardsBit.answer?.normal?.shuffle && this.appFlashcardsBit.answer?.normal?.shuffle[potPropName]?.length) {
      this.appFlashcardsBit.answer.normal.shuffle[potPropName] = this.appFlashcardsBit.answer?.normal?.shuffle[potPropName].filter(x => x !== bitId);
    }
  }

  private getPotPropName(potIndex: number) {
    if (potIndex === -1) {
      return 'wrong';
    }

    if (potIndex === 1) {
      return 'right';
    }

    return 'unanswered';
  }
}
