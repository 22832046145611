import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BitBranding} from '../shared/models';
import {ApiService} from '../shared/api/api.service';

export interface ThemeBranding {
  publisherId?: number;
  themeId?: string;
  userId?: string;
  userName?: string;
  version?: number;
  timeStamp?: number;
  themeBranding?: BitBranding;
}

export interface ThemeBrandingHistory {
  publisherId?: number;
  themeId?: string;
  history: Array<ThemeBranding>;
}

@Injectable()
export class BrandingApiService {
  constructor(private apiService: ApiService) {
  }

  updateBranding(payload: ThemeBranding): Observable<ThemeBranding> {
    return this.apiService.post('branding', null, payload);
  }

  getBranding(themeId: string, publisherId: number): Observable<ThemeBranding> {
    return this.apiService.get('branding/{themeId}/{publisherId}', {
      themeId,
      publisherId
    });
  }

  getBrandingHistory(themeId: string, publisherId: number): Observable<ThemeBrandingHistory> {
    return this.apiService.get('branding/{themeId}/{publisherId}/history', {
      themeId,
      publisherId
    });
  }

  getBrandingFromHistory(themeId: string, publisherId: number, timeStamp: number): Observable<ThemeBranding> {
    return this.apiService.get('branding/{themeId}/{publisherId}/history/{timeStamp}', {
      themeId,
      publisherId,
      timeStamp
    });
  }
}
