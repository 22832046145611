// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-container {
  margin: var(--bitmark-bit-negative-padding, 0);
  margin-top: 0 !important;
}

.note {
  min-height: 120px;
}

.text-placeholder:before {
  content: " ";
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
