import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {AudioRecordingService, RecordedAudioOutput} from '../audio-recording.service';
import {SubSink} from '../../sub-sink/sub-sink';

@Component({template: ''})
export abstract class AudioRecordingBaseComponent implements AfterViewInit, OnDestroy {
  @Input() isUploadingRecording = false;
  @Output() onStop: EventEmitter<RecordedAudioOutput> = new EventEmitter<RecordedAudioOutput>();
  @Output() onCancel?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('visualizer') canvas: ElementRef;

  recordedTime: string;
  isRecording: boolean;

  private canvasContext: CanvasRenderingContext2D;
  private subSink = new SubSink();

  protected cssFeedbackBackgroundColor: string;
  protected cssFeedbackAudioWaveColor: string;
  private feedbackBackColorStyle: string;
  private feedbackAudioWaveColorStyle: string;

  protected constructor(protected audioRecordingService: AudioRecordingService) {
    this.recordedTime = '00:00';
  }

  ngAfterViewInit() {
    this.canvasContext = this.canvas.nativeElement.getContext('2d');
  }

  ngOnDestroy() {
    if (this.isRecording) {
      this.audioRecordingService.abortRecording();
    }
    this.subSink?.unsubscribe();
  }

  start() {
    this.isRecording = true;
    this.audioRecordingService.startRecording();

    this.subSink.sink = this.audioRecordingService.recordingFailed().subscribe(() => {
      this.onCancel.emit();
    });

    this.subSink.sink = this.audioRecordingService.getRecordedTime().subscribe((data: string) => {
      this.recordedTime = data;
    });

    this.subSink.sink = this.audioRecordingService.getRecordedAnalyzerData().subscribe((data: Uint8Array) => {
      this.drawFeedback(data);
    });
  }

  stop() {
    this.isRecording = false;
    this.audioRecordingService.stopRecording();

    this.subSink.sink = this.audioRecordingService.getRecordedBlob().subscribe((data: RecordedAudioOutput) => {
      this.onStop.emit(data);
    });
  }

  cancelRecording() {
    this.audioRecordingService.abortRecording();

    this.onCancel?.emit();
  }

  private drawFeedback(dataArray: Uint8Array) {
    if (!this.feedbackBackColorStyle) {
      this.feedbackBackColorStyle = getComputedStyle(this.canvas.nativeElement).getPropertyValue(this.cssFeedbackBackgroundColor) || '#000';
    }
    if (!this.feedbackAudioWaveColorStyle) {
      this.feedbackAudioWaveColorStyle = getComputedStyle(this.canvas.nativeElement).getPropertyValue(this.cssFeedbackAudioWaveColor) || '#ea80ff';
    }

    this.audioRecordingService.drawFeedback(
      this.canvas,
      this.canvasContext,
      dataArray,
      this.feedbackBackColorStyle,
      this.feedbackAudioWaveColorStyle);
  }
}
