import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {AngularNodeViewComponent} from 'ngx-tiptap';

@Component({
  selector: 'bitmark-nodeview-gap',
  templateUrl: './audio.component.html',
  styleUrls: ['../../../../bits/resource/resource-audio/resource-audio.component.scss']
})
export class NodeviewAudioComponent extends AngularNodeViewComponent implements AfterViewInit {
  @ViewChild('audioElement') audioElement: ElementRef;
  defaultText = '';

  ngAfterViewInit() {
    const audioEl = this.audioElement.nativeElement as HTMLAudioElement;
    if (audioEl.src) {
      return;
    }
    audioEl.src = this.node.attrs.audioSrc;
    audioEl.load();
  }

  onChange(event: Event): void {
    this.updateAttributes({
    });
  }
}
