import {Link} from '@tiptap/extension-link';

export const BitmarkLink = Link.extend({
    renderHTML(data) {
        if (data?.HTMLAttributes?.href
            && (data.HTMLAttributes.href.startsWith(window.location.origin)
                || data.HTMLAttributes.href.startsWith('/'))) {
            data.HTMLAttributes.target = '_self';
        }
        return this.parent?.(data);
    }
});
