import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class NavigatorUtilsService {
  getCountryCode() {
    let countryCode = navigator.language.slice(3, 5);
    if (!countryCode) {
      countryCode = navigator.language.slice(0, 2);
    }
    return countryCode;
  }
  getLanguageCode() {
    return navigator.language.slice(0, 2) || 'en';
  }
}
