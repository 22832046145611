import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {of, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {LearningEventReadBookBit} from './learning-event-read-book.models';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat, ProductFamily} from '../../../shared/models/bitmark.models';
import {ReaderContentService} from '../../../reader/reader-book/reader-content/reader-content.service';
import {BitbookApiService} from '../../../reader/bitbook-api.service';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';
import {BookEntity} from '../../../reader/reader.models';

@Component({
  selector: 'bitmark-learning-event-read-book',
  templateUrl: './learning-event-read-book.component.html',
  styleUrls: ['./learning-event-read-book.component.scss', '../learning-event.common.scss'],
  animations: toggleAnimations
})
export class LearningEventReadBookComponent extends LearningEventCommon implements OnInit, OnDestroy {
  @Input() bit?: LearningEventReadBookBit;
  @Output() changed = new EventEmitter<any>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() isBookUnaccessibleToUser = new EventEmitter<{ bookId: string }>();

  protected readonly BitmarkFormat = BitmarkFormat;

  isLeadEmpty = false;
  isItemEmpty = false;
  imageExists = false;
  isDataLoaded = false;
  hasAccessToBook = true;
  bookCoverImageSrc = '';
  bookTitle = '';
  inViewportChange: Subject<boolean> = new Subject<boolean>();

  private viewportSubscription: Subscription;

  constructor(private readerContentService: ReaderContentService,
              private bitbookApiService: BitbookApiService,
              readerLeContentService: ReaderLeContentService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.toggleStateIsDone = this.bit?.answer?.isDone ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.bit.answer?.isDone);

    this.viewportSubscription = this.inViewportChange
      .pipe(debounceTime(500))
      .subscribe((inViewport: boolean) => {
        if (!inViewport) {
          return;
        }

        if (this.isDataLoaded) {
          this.viewportSubscription.unsubscribe();
          return;
        }

        this.loadBookData();
        this.viewportSubscription.unsubscribe();
      });
  }

  ngOnDestroy() {
    this.viewportSubscription?.unsubscribe();
  }

  onInViewportChange(evt: { target: Element, visible: boolean }) {
    this.inViewportChange?.next(evt.visible);
  }

  toggleCheckbox() {
    if (!this.bit.answer) {
      this.bit.answer = {};
    }

    this.bit.answer.isDone = !this.bit.answer.isDone;

    if (this.isBodyCollapsed) {
      if (this.bit.answer?.isDone) {
        this.toggleStateIsDone = 'collapsed';
      } else {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'expanded';
      }
    } else {
      if (this.bit.answer?.isDone) {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'collapsed';
        this.isBodyCollapsed = true;
      }
    }

    this.changed.emit();
  }

  open() {
    if (!this.hasAccessToBook) {
      return;
    }

    if (this.bit.book) {
      (this.bit.reference
          ? this.readerContentService.getBookBit(this.bit.book, this.bit.reference)
          : of(undefined)
      ).subscribe(bit => {
        this.navigateToBook.emit({bookId: this.bit.book, fragment: bit?.ref});
      }, () => {
        alert('You do not have access to this book.');
        this.isBookUnaccessibleToUser.emit({bookId: this.bit.book});
      });
    }
  }

  private loadBookData() {
    this.isDataLoaded = true;

    if (!this.bit?.book) {
      return;
    }

    this.bitbookApiService.getBookById(this.bit.book)
      .subscribe(
        data => {
          this.setBookData(data);
        },
        () => {
          this.bitbookApiService.getBasicBookById(this.bit.book)
            .subscribe(data => {
              this.hasAccessToBook = false;
              this.setBookData(data);
            }, (error) => {
              console.error(error);
              this.imageExists = false;
            });
        });
  }

  private setBookData(data: BookEntity) {
    const coverSrc = data?.coverImage?.src1x || data?.coverImage?.src2x || data?.coverImage?.src3x;
    this.imageExists = !!coverSrc;
    if (this.imageExists) {
      this.bookCoverImageSrc = coverSrc;
      this.bookTitle = data.title;
    }
  }
}
