import {Component, Input} from '@angular/core';
import {SystemMessageBit} from './system-message.models';

@Component({
  selector: 'bitmark-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['../bits.scss', './system-message.component.scss'],
})
export class SystemMessageComponent {
  @Input() bit: SystemMessageBit;
}

