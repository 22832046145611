import {Directive, Input, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutoSelect]'
})
export class AutoSelectDirective implements OnInit {
  @Input() appAutoSelect: number;

  constructor(public el: ElementRef) {
  }

  ngOnInit() {
    if (this.appAutoSelect) {
      setTimeout(() => {
        this.el.nativeElement.select();
      }, this.appAutoSelect || 10);
    }
  }
}
