import {environment} from 'environment';
import {SocketIoConfig} from "ngx-socket-io";

export const AppConfig = {
  apiRoutesMap: {
    'app-version': `~/assets/data/package.json`,

    'auth/refresh-token': `/auth/refresh-token`,
    'auth/logout': `/auth/logout`,
    'auth/google': `/auth/googleLogin`,
    'auth/apple': `/auth/appleLogin`,
    'auth/lostPassword': `/auth/lostPassword`,
    'auth/resetPassword': `/auth/resetPassword`,
    'auth/verify-email': `/auth/verify-email`,
    'auth/unsubscribe-web-push': `/auth/unsubscribe-web-push`,
    'auth/verify-email-authenticated': `/auth/verify-email-authenticated`,
    'auth/sso/provider/{email}': `/auth/sso/provider/:email`,
    'auth/sso/endpoints/{provider}': `/auth/sso/endpoints/:provider`,
    'auth/sso/login/{provider}': `/auth/sso/login/:provider`,
    'auth/sso/acs/{provider}': `/auth/sso/acs/:provider`,

    'account/register': `/account/register`,
    'account/register-user-forced': `/account/register-user-forced`,
    'account/profile': `/account/profile`,
    'account/photo': `/account/photo`,
    'account/change-password': `/account/change-password`,
    'account/resend-link': `/account/resend-link`,
    'account/update-availability': `/account/update-availability`,
    'account/subscribe-web-push': `/account/subscribe-web-push`,
    'account/get-pulse/{userId}': `/account/get-pulse/:userId`,
    'account/send-course-join-confirmation-email': '/account/send-course-join-confirmation-email',
    'account/send-course-joined-by-email': '/account/send-course-joined-by-email',
    'account/training-session-join-requests': '/account/training-session-join-requests',
    'account/course-session-join-requests': '/account/course-session-join-requests',
    'account/get-user-emails-or-create-account': '/account/get-user-emails-or-create-account',

    'account/user/{id}': `/users/user/:id`,
    'account/email/{email}': `/users/email/:email`,
    'account/emails': `/users/emails`,
    'account/ids': `/users/ids`,
    'account/resetToken/{resetToken}': `/users/resetToken/:resetToken`,

    'timeline/peers': `/timeline/peers`,
    'timeline/peer/{peerId}': `/timeline/peer/:peerId`,
    'timeline/peer/{peerId}/user': `/timeline/peer/:peerId/user`,
    'timeline/peer/{peerId}/block': `/timeline/peer/:peerId/block`,
    'timeline/peer/{peerId}/unblock': `/timeline/peer/:peerId/unblock`,
    'timeline/peer/{peerId}/between/{startId}/{endId}': `/timeline/peer/:peerId/between/:startId/:endId`,
    'timeline/peer/{peerId}/unread/count': `/timeline/peer/:peerId/unread/count`,
    'timeline/search': `/timeline/search`,
    'timeline/{id}': `/timeline/:id`,
    'timeline/{id}/read': `/timeline/:id/read`,
    'timeline/{id}/feedback': `/timeline/:id/feedback`,
    'timeline/{aggregateId}': `/timeline/:aggregateId`,
    'timeline': `/timeline`,
    'timeline/connection-request': `/timeline/connection-request`,
    'timeline/connection-request/{id}': `/timeline/connection-request/:id`,
    'timeline/connection-request/{id}/accept': `/timeline/connection-request/:id/accept`,
    'timeline/connection-request/{id}/reject': `/timeline/connection-request/:id/reject`,
    'timeline/connection-request/sent': `/timeline/connection-request/sent`,
    'timeline/connection-request/received': `/timeline/connection-request/received`,

    'timeline/schedule-bot': `/timeline/schedule-bot`,
    'timeline/start-bot-action-response-chat': `/timeline/start-bot-action-response-chat`,

    'timeline/translate-book': `/timeline/translate-book`,

    'timeline/groups': `/timeline/groups`,
    'timeline/groups/{userId}': `/timeline/groups/:userId`,
    'timeline/groups/{userId}/leave': `/timeline/groups/:userId/leave`,

    'health/ping': `/health/ping`,
    'health/bogus': `/health/bogus`,

    'storage/presigned': `/storage/presigned`,

    'shop/empty-cart': `/shop/empty-cart`,
    'shop/unbuy-product/{productId}': `/shop/unbuy-product/:productId`,
    'shop/add-product-to-cart': `/shop/add-product-to-cart`,
    'shop/checkout-cart': `/shop/checkout-cart`,
    'shop/get-bought-products': `/shop/get-bought-products`,

    'messages/parse-text': `/messages/parse-text`,

    'countries': `/countries`,
    'countries/languages': `/countries/languages`,

    'det/start': '/det/start',

    'cockpit/{classId}': '/cockpit/:classId',

    'roles/my-students': '/roles/my-students',
  },

  authConfig: {
    loginUrl: `${environment.baseUrl}/auth/login`,
    unlinkUrl: `${environment.baseUrl}/auth/logout`,
    tokenName: 'token',
    tokenPrefix: 'gmb-cosmic',
    authToken: 'Bearer'
  },

  ssoAuthConfig: {
    googleClientId: environment.googleClientId,
    appleClientId: environment.appleClientId
  },

  ng2WebStorageConfig: {
    prefix: 'gmb-cosmic',
    separator: '.',
    caseSensitive: true
  },

  socketIoConfig: {
    url: environment.wsUrl,
    options: {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
      timeout: 20000,
      autoConnect: true,
      upgrade: true
    }
  } as SocketIoConfig,

  supportedLanguages: {
    german: 'de',
    english: 'en'
  },

  locale: {
    language: 'de'
  },

  gridBreakpoints: {
    sm: 680,
    md: 992,
    lg: 1024,
    xl: 1200
  }
};
