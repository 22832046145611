import Image, {ImageOptions} from '@tiptap/extension-image';
import {mergeAttributes} from '@tiptap/core';
import {EventEmitter as EE} from '../../../../shared';
import {BitResource} from '../../../../bits/bits.models';

declare const window: any;
declare const GMBImageResize: any;

window.bitmarkSpace = Object.assign({}, window.bitmarkSpace || {}, {
  imgLoaded: function () {
    const imgEl = arguments[0];
    const widthNative = +imgEl.getAttribute('width') || 0.0001;
    const heightNative = +imgEl.getAttribute('height') || 0.0001;
    const displayWidth = imgEl.clientWidth || 0;
    const displayHeight = imgEl.clientHeight || 0;
    const shrinkFactor = Math.min(displayWidth / widthNative, displayHeight / heightNative);
    const isImageZoomable = shrinkFactor <= .9;
    if (isImageZoomable) {
      imgEl.classList.add('cursor-zoom-in');
    }
  },
  imgError: function () {
    const imgEl = arguments[0];
    if (imgEl) {
      imgEl.style.display = 'none';
    }
  },
  imgClicked: function () {
    const imgEl = arguments[0];
    if (!imgEl.classList.contains('cursor-zoom-in')) {
      return;
    }
    const widthNative = +imgEl.getAttribute('width');
    const heightNative = +imgEl.getAttribute('height');
    const src = imgEl.getAttribute('src');
    const caption = imgEl.getAttribute('title');
    const copyright = imgEl.getAttribute('copyright');
    EE.default.emit('imgClicked', {image: {src, widthNative, heightNative, caption, copyright}} as BitResource);
  }
});

const getImageElement = (elem: HTMLElement): HTMLImageElement | undefined => {
  if (elem.nodeName === 'IMG') {
    return elem as HTMLImageElement;
  }

  return elem.querySelector('img');
};

export interface BitmarkImageOptions extends ImageOptions {
  height: any;
  width: any;
  widthNative: any;
  heightNative: any;
  copyright: string;
  title: string;
}

const CustomImage = Image.extend<BitmarkImageOptions>({
  inline: false,
  group: 'block',
  addAttributes() {
    return {
      ...this.parent?.(),
      alt: {
        default: '',
        parseHTML: element => {
          return getImageElement(element)?.alt;
        }
      },
      alignment: {
        default: 'center',
        renderHTML: attributes => {
          return {class: attributes?.alignment || ''};
        }
      },
      loading: {
        default: undefined,
        parseHTML: () => {
          return '';
        },
        renderHTML: () => {
          return {loading: 'lazy'};
        }
      },
      decoding: {
        default: undefined,
        parseHTML: () => {
          return '';
        },
        renderHTML: () => {
          return {decoding: 'async'};
        }
      },
      height: {
        default: null,
        parseHTML: element => {
          return getImageElement(element)?.height || null;
        },
        renderHTML: attributes => {
          return {height: attributes?.height?.replace ? attributes.height.replace('px', '') : (attributes?.height || '')};
        }
      },
      width: {
        default: null,
        parseHTML: element => {
          return getImageElement(element)?.width || null;
        },
        renderHTML: attributes => {
          return {width: attributes?.width?.replace ? attributes.width.replace('px', '') : (attributes?.width || '')};
        }
      },
      copyright: {
        default: '',
        parseHTML: element => {
          return element.querySelector('[data-type="copyright"]')?.innerHTML || '';
        }
      },
      title: {
        default: '',
        parseHTML: element => {
          return element.querySelector('[data-type="caption"]')?.innerHTML || '';
        }
      },
      onload: {
        default: undefined,
        parseHTML: () => {
          return '';
        },
        renderHTML: () => {
          return {onload: 'bitmarkSpace.imgLoaded(this)'};
        }
      },
      onerror: {
        default: undefined,
        parseHTML: () => {
          return '';
        },
        renderHTML: () => {
          return {onerror: 'bitmarkSpace.imgError(this)'};
        }
      },
      onclick: {
        default: undefined,
        parseHTML: () => {
          return '';
        },
        renderHTML: () => {
          return {onclick: 'bitmarkSpace.imgClicked(this)'};
        }
      },
      src: {
        default: '',
        parseHTML: element => {
          return getImageElement(element)?.src;
        }
      }
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setImageAlignment: (alignment) => ({commands}) => {
        return commands.updateAttributes('image', {
          alignment: alignment
        });
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div[class="tiptap-image-container"]'
      },
      {
        tag: 'img[src]'
      }
    ];
  },
  renderHTML({HTMLAttributes}) {
    HTMLAttributes.src = GMBImageResize.rewriteUrlAndResize(HTMLAttributes.src, 'reader-resource');
    return ['div',
      {'data-type': 'image', 'class': 'tiptap-image-container'},
      ['figure', {class: HTMLAttributes.class || ''},
        ['img',
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {'data-type': 'image'})],
        ['figcaption', {'data-type': 'copyright', 'class': 'tiptap-image-copyright'}, HTMLAttributes.copyright || ''],
        ['figcaption', {'data-type': 'caption', 'class': 'tiptap-image-caption'}, HTMLAttributes.title?.replace(/<!--.*?-->/g, '') || '']
      ]
    ];
  }
});

export default CustomImage;
