// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `details[open] summary ~ * {
  animation: open 0.3s ease-in-out;
}
details[open] summary:after {
  transform: rotate(90deg);
}

details summary {
  outline: 0;
  width: 100%;
  position: relative;
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 680px) {
  details summary {
    align-items: center;
    flex-direction: row;
  }
}
details summary::-webkit-details-marker {
  display: none;
}
details p {
  padding-bottom: 10px;
  margin: 0;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
