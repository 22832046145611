import {Component, Input, OnChanges} from '@angular/core';
import { ShopInfo } from 'core';

@Component({
  selector: 'app-pagination-label',
  templateUrl: './pagination-label.component.html',
  styleUrls: ['./pagination-label.component.scss']
})
export class PaginationLabelComponent implements OnChanges {
  @Input() offset: number;
  @Input() limit: number;
  @Input() total: number;
  @Input() shopBranding: ShopInfo;
  begin = 0;
  end = 0;

  ngOnChanges() {
    this.begin = this.offset + 1;
    this.end = Math.min(this.offset + this.limit, this.total);
  }

}
