// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframely-embed-container {
  width: var(--bitmark-vendor-iframely-embed-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-vendor-iframely-embed-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-vendor-iframely-embed-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-vendor-iframely-embed-body-size-margin, var(--bitmark-x-size-margin, 0));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
