import {MarkedOptions} from '../marked/marked.node';
import {Mark, mergeAttributes} from '@tiptap/core';

const createCircle = (name: string, cssClass: string) => {
  return Mark.create<MarkedOptions>({
    name: name,

    addOptions() {
      return {
        HTMLAttributes: {},
      };
    },

    parseHTML() {
      return [
        {
          tag: `span[class="${cssClass}"]`
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      const customAttributes = {
        class: cssClass
      };
      return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
    }
  });
};

export const Circle = createCircle('circle', 'bitmark-text-circle');
export const UserCircle = createCircle('userCircle', 'bitmark-text-user-circle');
