// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover-bg-height {
  height: 320px !important;
}

.cover-image.cover-bg-transparent {
  background-color: transparent;
}

.cover-image {
  max-width: 320px;
  background-color: #151617;
}
.cover-image.class-mask {
  -webkit-mask-image: url("/assets/images/class_mask.svg");
  mask-image: url("/assets/images/class_mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}
.cover-image.class-mask .cover-image-title {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cover-image img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  border-radius: 4px;
}
.cover-image .cover-image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.is-hidden {
  width: 100%;
  height: 100%;
  position: relative;
}
.is-hidden:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ff8000 26.19%, #bfbfbf 26.19%, #bfbfbf 50%, #ff8000 50%, #ff8000 76.19%, #bfbfbf 76.19%, #bfbfbf 100%);
  background-size: 62px 62px;
  opacity: 0.8;
  content: "";
}

.page-product-video-cover.cover-image {
  max-width: 100%;
}
.page-product-video-cover.cover-image img {
  height: 200px;
  width: 170px;
  width: var(--bitmark-page-product-video-image-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-product-video-image-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-product-video-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-product-video-image-size-margin, var(--bitmark-x-size-margin, 0));
}

.page-product-video-list-cover.cover-image {
  max-width: 100%;
}
.page-product-video-list-cover.cover-image img {
  height: 200px;
  width: 170px;
  width: var(--bitmark-page-product-video-list-image-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-product-video-list-image-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-product-video-list-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-product-video-list-image-size-margin, var(--bitmark-x-size-margin, 0));
}

.cover-default {
  background-color: #ffce00;
  font-weight: 500;
  height: 200px;
  width: 100%;
  max-width: 500px;
  max-height: 700px;
  font-size: 1.2rem;
  color: #212529;
  overflow: hidden;
  line-height: normal;
  border-radius: 4px;
}
.cover-default h3 {
  max-width: 100% !important;
  line-height: normal;
  height: 100%;
}
.cover-default .text-yellow {
  color: #ffce00 !important;
}
.cover-default .big-font {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .cover-default.big-font {
    height: 127.1428571429vw !important;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .cover-default.big-font {
    width: 240px !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
