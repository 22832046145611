import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {BaseUser, ShopInfo, UsersApiService} from 'core';
import {DropdownItemModel} from 'shared';
import {TranslateService} from '@ngx-translate/core';
import {ShopListUser} from 'main/shop/shop.models';
import {ClipboardService} from 'ngx-clipboard';
import {ShopBrandingService} from 'main/shop/shop-branding.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-organizer-list',
  templateUrl: './organizer-list.component.html',
  styleUrls: ['./organizer-list.component.scss'],
})
export class OrganizerListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() users: Array<ShopListUser> = [];
  @Input() label: string;
  @Input() isOrganizer: boolean;
  @Input() filterText: string;
  @Input() sortOrder: { key: string, order: number };
  @Input() listActions: Array<DropdownItemModel>;
  @Input() notBranded: boolean;
  @Output() onEditEmail = new EventEmitter<{ oldEmail: string, newEmail: string }>();
  @Output() onDeleteEmail = new EventEmitter<string>();
  @Output() onSetUserFlagEmojis = new EventEmitter<ShopListUser>();
  @Output() onUpdateUsers = new EventEmitter<Array<ShopListUser>>();
  @ViewChild('editingLabel') editingLabelEl: ElementRef;
  shopBrandingSubscription: Subscription;
  isEditing: boolean;
  operation: string;
  orderActions: any;
  removedUsers: Array<string>;
  redundantUsers: Array<string>;
  shopBranding: ShopInfo;

  constructor(private translate: TranslateService,
              private clipboardService: ClipboardService,
              private shopBrandingService: ShopBrandingService,
              private usersApiService: UsersApiService) {
  }

  ngOnInit() {
    this.orderActions = {
      'firstName': this.getFirstNameInitial,
      'lastName': this.getLastNameInitial,
      'email': this.getEmail,
    };

    this.listActions = [{
      label: this.translate.instant('Shop.Editor.CopyAll'),
      handler: this.copyAll.bind(this),
    }, {
      isSeparator: true,
    }, {
      label: this.translate.instant('Shop.Editor.AddSeveral'),
      handler: this.addSeveral.bind(this),
    }, {
      label: this.translate.instant('Shop.Editor.ReplaceAll'),
      handler: this.replaceAll.bind(this),
    }, {
      isSeparator: true,
    }, {
      label: this.translate.instant('Shop.Editor.DeleteAll'),
      handler: this.deleteAll.bind(this),
    }];
    if (!this.sortOrder) {
      this.sortOrder = {
        key: 'email',
        order: 1,
      };
    }
    this.shopBrandingSubscription = this.shopBrandingService.config$.subscribe(shopBranding => this.shopBranding = shopBranding);
    this.getOrganizersData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.users) {
      return;
    }
    if (changes?.sortOrder && this.orderActions) {
      this.users = this.users.sort(this.sortUsersByOrder.bind(this));
    }
  }

  ngOnDestroy() {
    this.shopBrandingSubscription.unsubscribe();
  }

  getOrganizersData(oldOrganizers?: Array<ShopListUser>, newOrganizers?: Array<string>) {
    this.users = this.users?.length ? this.users : [];
    this.users?.forEach((u) => u.status = null);
    this.usersApiService.getUsersByMails(this.users.map((u) => u.email))
      .subscribe((users: Array<BaseUser>) => {
        if (this.isOrganizer) {
          const userList = this.users?.filter((o) => o?.email).reduce((prev, curr) => {
            const user = users.find((u) => u.email?.toLowerCase() === curr?.email?.toLowerCase() || u.ssoEmail?.toLowerCase() === curr?.email?.toLowerCase());
            if (user) {
              const {id, email, ssoEmail, fullName} = user;
              return prev.concat([Object.assign({}, curr, {id, email, ssoEmail, fullName})]);
            }
            return prev.concat(curr);
          }, []).sort(this.sortUsersByOrder.bind(this));
          if (oldOrganizers) {
            userList.forEach((u) => {
              if (oldOrganizers.findIndex((o) => o.email === u.email) === -1) {
                (u as any).status = 'new';
              }
            });
            this.removedUsers = [];
            oldOrganizers?.forEach((o) => {
              if (userList?.every((u) => u.email !== o?.email)) {
                this.removedUsers.push(o.email);
              }
            });
            this.redundantUsers = [];
            newOrganizers?.forEach((o) => {
              if (oldOrganizers.findIndex((ol) => ol.email === o) !== -1) {
                this.redundantUsers.push(o);
              }
            });
            userList.forEach((e) => {
              if (this.redundantUsers.indexOf(e.email) !== -1) {
                e.status = 'redundant';
              }
            });
          }
          this.users = userList;
        } else {
          const userList = this.users?.filter((o) => o?.email).reduce((prev, curr) => {
            const user = users.find((u) => u.email?.toLowerCase() === curr?.email?.toLowerCase() || u.ssoEmail?.toLowerCase() === curr?.email?.toLowerCase());
            if (user) {
              const {id, email, ssoEmail, fullName} = user;
              return prev.concat([Object.assign({}, curr, {id, email, ssoEmail, fullName})]);
            }
            return prev.concat(curr);
          }, []).sort((x, y) => {
            const xPropValue = this.getLastNameInitial(x)?.toLowerCase();
            const yPropValue = this.getLastNameInitial(y)?.toLowerCase();

            if (xPropValue < yPropValue) {
              return -1;
            } else if (xPropValue > yPropValue) {
              return 1;
            }
            return 0;
          });
          this.users = userList.filter((u) => u.fullName);
        }
      });
  }

  sortUsersByOrder(x, y) {
    const xPropValue = this.orderActions[this.sortOrder?.key](x)?.toLowerCase();
    const yPropValue = this.orderActions[this.sortOrder?.key](y)?.toLowerCase();

    if (xPropValue < yPropValue) {
      return -1;
    } else if (xPropValue > yPropValue) {
      return 1;
    }
    return 0;
  }

  saveChanges() {
    this.isEditing = false;
    if (this.operation === 'add') {
      const oldOrganizers = JSON.parse(JSON.stringify(this.users));
      const newOrganizers = this.extractEmails(this.editingLabelEl.nativeElement.innerText.toLowerCase());
      this.users = (this.users || []).concat(newOrganizers.map((e) => {
        return {email: e};
      }));
      this.users = this.users.filter((item, index) => this.users.findIndex((i) => i.email === item.email) === index);
      this.getOrganizersData(oldOrganizers, newOrganizers);
      this.emitChanges(this.users.map((s) => {
        const existingUser = this.users.find((u) => u.email === s);
        return existingUser ? (existingUser as any) : s;
      }));
    } else {
      const oldOrganizers = JSON.parse(JSON.stringify(this.users));
      const newOrganizers = this.extractEmails(this.editingLabelEl.nativeElement.innerText.toLowerCase());
      this.emitChanges(newOrganizers.map((s) => {
        const existingUser = this.users.find((u) => u.email === s);
        return existingUser ? (existingUser as any) : {email: s};
      }));
      this.users = JSON.parse(JSON.stringify(newOrganizers.map((e) => {
        return {email: e};
      })));
      this.users = this.users.filter((item, index) => this.users.findIndex((i) => i.email === item.email) === index);
      this.getOrganizersData(oldOrganizers, newOrganizers);
    }
    this.operation = '';
  }

  emitChanges(organizers: Array<ShopListUser>) {
    this.onUpdateUsers.emit(organizers.map((u) => {
      const {email, ssoEmail, emoji} = u;
      return Object.assign({}, {email, ssoEmail, emoji});
    }));
  }

  cancelEditing() {
    this.isEditing = false;
    this.operation = '';
    this.editingLabelEl.nativeElement.innerHTML = '';
  }

  addSeveral() {
    this.operation = 'add';
    this.isEditing = true;
  }

  replaceAll() {
    this.operation = 'replace';
    this.isEditing = true;
  }

  deleteAll() {
    this.users = [];
    this.getOrganizersData();
  }

  copyAll() {
    this.clipboardService.copy(this.users.join('\n'));
  }

  deleteEmail(email: string) {
    this.users = this.users.filter((i) => {
      return i.email?.trim()?.toLowerCase() !== email.trim().toLowerCase();
    });
    this.emitChanges(this.users);
  }

  editEmail(data: { oldEmail: string, newEmail: string }) {
    const idx = this.users.findIndex((u) => u.email === data.oldEmail);
    this.users.splice(idx, 1, {email: data.newEmail});
    this.getOrganizersData();
    this.emitChanges(this.users);
  }

  editUser(data: ShopListUser) {
    this.emitChanges(this.users);
  }

  getLastNameInitial(user) {
    if (user.lastName?.length) {
      return user.lastName;
    }
    if (user.email?.indexOf('@') !== -1 && user.email?.split('@')[0].indexOf('.') !== -1) {
      return user.email.split('.')[1];
    }
    if (user.fullName?.indexOf(' ') !== -1 && user.fullName?.split(' ')[1].length > 0) {
      return user.fullName?.split(' ')[1];
    }
    return user.fullName || user.email;
  }

  getFirstNameInitial(user) {
    if (user.firstName?.length) {
      return user.firstName;
    }
    if (user.email?.indexOf('@') !== -1 && user.email?.split('@')[0].indexOf('.') !== -1) {
      return user.email.split('.')[0];
    }
    if (user.fullName?.indexOf(' ') !== -1 && user.fullName?.split(' ')[0].length > 0) {
      return user.fullName?.split(' ')[0];
    }
    return user.fullName || user.email;
  }

  getEmail(user) {
    return user?.email;
  }

  extractEmails(text) {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
  }
}
