// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  border-radius: 8px;
  opacity: 0;
}
img.portrait {
  opacity: 1;
  max-width: 375px;
  width: 100% !important;
  height: auto !important;
}
img.landscape {
  opacity: 1;
  max-width: 100%;
  max-height: 500px;
  height: auto !important;
}

.cover-default {
  border-radius: 8px;
  height: 500px;
  width: 375px;
  font-size: 1.2rem;
  color: #212529;
  line-height: normal;
  margin: auto;
}
.cover-default .inside {
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.cover-default .inside h1 {
  font-family: "Inter", "sans-serif";
  font-weight: 500;
  font-size: 40px;
  max-width: 100% !important;
  line-height: 1.5;
  display: inline-block;
  text-align: left;
  vertical-align: bottom;
}

.cover-shadow {
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.55));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
