import {Component, Input} from '@angular/core';
import {BitResourceArticle} from '../../bits.models';

@Component({
  selector: 'bitmark-resource-article',
  templateUrl: './resource-article.component.html',
  styleUrls: ['../../bits.scss', './resource-article.component.scss']
})
export class ResourceArticleComponent {
  @Input() bitResourceArticle?: BitResourceArticle;
}
