import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Editor} from "@tiptap/core";

@Component({
  selector: 'bitmark-contextual-menu-text',
  templateUrl: './contextual-menu-text.component.html'
})
export class ContextualMenuTextComponent {
  @Input() editor: Editor | any;
  @Output() onDeleteNode: EventEmitter<string> = new EventEmitter<any>();
  @Output() onInsertWarning: EventEmitter<string> = new EventEmitter<any>();
  actions: Array<any>;

  constructor() {
    this.actions = [{
      label: 'Text',
      handler: this.setParagraph.call('text')
    }, {
      label: 'Text',
      handler: this.setParagraph.call('text')
    }, {
      label: 'Text',
      handler: this.setParagraph.call('text')
    }, {isSeparator: true}, {
      label: 'Text',
      handler: this.deleteNode.bind(this)
    }];
  }

  deleteNode() {
    this.onDeleteNode.emit('paragraph');
  }

  setParagraph(paragraphType: string) {
    // const editorContainer = (this.editor.view as any).docView.dom.parentElement.parentElement;
    (this.editor.commands as any).setParagraphClass(paragraphType);
    // editorContainer.querySelector('.contextual-menu-text-type').innerText = paragraphType;
  }
}
