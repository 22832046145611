import {CodeBlockLowlightOptions} from './code-block-lowlight';
import CodeBlock from '@tiptap/extension-code-block';
import {LowlightPlugin} from './lowlight-plugin';
// import hljs from 'highlight.js';
import hljs from 'highlight.js/lib/common';

export const highlightCodeblocks = (content: string): string => {
  const doc = new DOMParser().parseFromString(content, 'text/html');
  doc.querySelectorAll('pre code').forEach((el) => {
    el.innerHTML = escapeNewline(el.innerHTML);
    hljs.highlightElement(el as HTMLElement);
    el.innerHTML = unescapeNewline(el.innerHTML);
  });
  return new XMLSerializer().serializeToString(doc);
};


const escapeNewline = (html: string): string => {
  return html.replace(/<br\s?\/?>/gi, '\n');
};

const unescapeNewline = (html: string): string => {
  return html.replace(/\n/gi, '<br>');
};

export const ParagraphCode = CodeBlock.extend<CodeBlockLowlightOptions>({
  name: 'paragraph',

  priority: 1000,

  group: 'block',

  content: 'text*',

  code: true,

  defining: true,

  parseHTML() {
    return [
      {tag: 'p'},
    ];
  },

  addOptions() {
    return {
      ...this.parent?.(),
      lowlight: {},
      defaultLanguage: null,
      exitOnTripleEnter: false,
      exitOnArrowDown: false,
    };
  },

  addAttributes() {
    return {
      language: {
        default: this.options.defaultLanguage || 'plaintext',
        parseHTML: (this.parent?.() as any).language?.parseHTML
      },
      codeLanguage: {
        default: this.options.defaultLanguage || 'plaintext',
        parseHTML: (this.parent?.() as any).language?.parseHTML
      }
    };
  },

  addProseMirrorPlugins() {
    return [
      ...this.parent?.() || [],
      LowlightPlugin({
        name: this.name,
        lowlight: this.options.lowlight,
        defaultLanguage: this.options.defaultLanguage,
      }),
    ];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setCodeblockLanguage: (language) => ({commands}) => {
        return commands.updateAttributes('paragraph', {
          codeLanguage: language,
          language: language,
          class: 'language-' + language
        });
      },
      toggleCodeblockColoring: () => ({commands}) => {
        const attrs = this.editor.getAttributes('paragraph');
        if (attrs?.language !== 'plaintext') {
          return commands.updateAttributes('paragraph', {language: 'plaintext', class: 'language-plaintext'});
        } else {
          const lang = attrs?.codeLanguage || 'plaintext';
          return commands.updateAttributes('paragraph', {language: lang, class: 'language-' + lang});
        }
      }
    };
  },
});
