import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UrlRewriteService} from '../../../shared/url-rewrites/url-rewrite.service';

@Component({
  selector: 'bitmark-reader-cover',
  templateUrl: './reader-cover.component.html',
  styleUrls: ['./reader-cover.component.scss']
})
export class ReaderCoverComponent implements OnInit, OnChanges {
  @Input() coverImage: string;
  @Input() coverColor?: string;
  @Input() brandingPrimaryColor: string;
  @Input() title: string;
  @Input() width?: number = 0;
  @Input() height?: number = 0;

  isBadImage = false;
  isBadImageDimensions = false;
  format: null | 'portrait' | 'landscape' = null;

  coverSizeQueryWidth: number;

  constructor(private deviceDetectorService: DeviceDetectorService,
              private urlRewriteService: UrlRewriteService) {
  }

  ngOnInit() {
    if (this.width < 420) {
      this.isBadImageDimensions = true;
    }
    if (!this.brandingPrimaryColor) {
      this.brandingPrimaryColor = '#ffce00';
    }
    if (this.coverImage) {
      this.coverImage = this.urlRewriteService.rewriteUrl(this.coverImage);
    }

    if(this.deviceDetectorService.isDesktop()){
      this.coverSizeQueryWidth = 800;
    } else{
      this.coverSizeQueryWidth = 480;
    }
  }

  ngOnChanges() {
    if (!this.coverImage) {
      this.isBadImage = true;
    } else {
      this.isBadImage = false;
    }
  }

  setBadImageStatus() {
    this.isBadImage = true;
    this.coverImage = null;
    throw new Error(`Book cover is invalid for book ${this.title}`);
  }

  onImageLoad() {
    this.format = this.sizeToFormat(this.width, this.height);
  }

  private sizeToFormat(width: number, height: number): 'portrait' | 'landscape' {
    if (!width || !height) {
      return 'portrait';
    }
    return width > height ? 'landscape' : 'portrait';
  }
}
