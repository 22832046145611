// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  width: 200px;
  aspect-ratio: 2.8;
  position: relative;
}

.title {
  position: absolute;
  top: 10px;
  left: 10px;
}
.title > * {
  line-height: 1.2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
