export class Pagination {
  private _recordCount: number;

  page = 1;
  pageCount: number;
  pageSize: number;

  constructor(pageSize = 10) {
    this.pageSize = pageSize;
  }

  get recordCount(): number {
    return this._recordCount;
  }

  set recordCount(value: number) {
    this._recordCount = value;
    this.pageCount = Math.ceil(this._recordCount / this.pageSize);
  }

  increment() {
    this.page++;
  }

  decrement() {
    this.page--;
  }

  reset() {
    this.page = 1;
  }

  isLastPage(): boolean {
    return this.page >= this.pageCount;
  }

  isFirstPage(): boolean {
    return this.page <= 1;
  }

  setPage(page: number) {
    this.page = page;
  }
}
