import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ArrayService {
  mergeArrays(a1: Array<any>, a2: Array<any>, key: string) {
    const diff = this.getDiff(a1, a2, key);
    diff.added.forEach(x => a1.splice(0, 0, x));
    a2.forEach(x => this.updateObject(a1, x, key));
  }

  syncArrays(a1: Array<any>, a2: Array<any>, key: string) {
    const diff = this.getDiff(a1, a2, key);
    diff.added.forEach(d => a1.push(d));
    diff.removed.forEach(d => this.removeObject(a1, d, key));
  }

  updateObject(a: Array<any>, updatedObject: any, key: string) {
    const found = a.find(x => x[key] === updatedObject[key]);
    if (found) {
      Object.assign(found, updatedObject);
    }
  }

  removeObject(a: Array<any>, object: any, key: string) {
    this.removeItem(a, x => x[key] === object[key]);
  }

  removeItem(a: Array<any>, predicate: Function) {
    const idx = a.findIndex(x => predicate(x));
    if (idx === -1) {
      return;
    }
    a.splice(idx, 1);
  }

  getDiff(a1: Array<any>, a2: Array<any>, key: string): { removed: Array<any>, added: Array<any> } {
    return {
      removed: this.getRemovedDiff(a1, a2, key),
      added: this.getRemovedDiff(a2, a1, key),
    };
  }

  sortBy(a: Array<any>, key: string, ignoreCase = false): Array<any> {
    return a.sort((x, y) => {
      const xKey = ignoreCase && x[key].toLowerCase ? x[key]?.toLowerCase() : x[key];
      const yKey = ignoreCase && y[key].toLowerCase ? y[key]?.toLowerCase() : y[key];
      if (xKey < yKey) {
        return -1;
      } else if (xKey > yKey) {
        return 1;
      }
      return 0;
    });
  }

  sortByDescending(a: Array<any>, key: string, ignoreCase = false): Array<any> {
    return a.sort((x, y) => {
      const xKey = ignoreCase && x[key].toLowerCase ? x[key]?.toLowerCase() : x[key];
      const yKey = ignoreCase && y[key].toLowerCase ? y[key]?.toLowerCase() : y[key];
      if (xKey < yKey) {
        return 1;
      } else if (xKey > yKey) {
        return -1;
      }
      return 0;
    });
  }

  arraysAreEqual(arr1: Array<any>, arr2: Array<any>) {
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    arr1 = arr1.sort();
    arr2 = arr2.sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  private getRemovedDiff(a1: Array<any>, a2: Array<any>, key: string) {
    const ret = [];
    a1.forEach((e1: any) => {
      const found = a2.find((e2: any) => {
        return e2[key].toString() === e1[key].toString();
      });
      if (!found) {
        ret.push(e1);
      }
    });
    return ret;
  }
}
