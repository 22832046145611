import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppAiPromptBit} from './app-ai-prompt.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../../reader';
import {ArticleBit} from '../../article/article.models';
import {BitType} from '../../bits.models';

@Component({
  selector: 'bitmark-app-ai-prompt',
  templateUrl: './app-ai-prompt.component.html',
  styleUrls: ['../../bits.scss']
})
export class AppAiPromptComponent {
  @Input() bit?: AppAiPromptBit;
  @Input() isBeingEditedByUser: boolean;
  @Input() canBeEdited = false;
  @Output() cancelBitChanges = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<ArticleBit>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();

  readonly BitType = BitType;

  generate() {
    console.log('generate');
  }
}
