import {Mark, mergeAttributes} from '@tiptap/core';

export interface MarkedOptions {
  HTMLAttributes: Record<string, any>;
}

export const Light = Mark.create<MarkedOptions>({
  name: 'light',

  addOptions() {
    return {
      HTMLAttributes: {
        // style: 'color: #808080'
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mark[class="bitmark-text-light"]'
      },
      {
        tag: 'mark:not(.bitmark-text-user-highlight, .bitmark-text-highlight)',
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  }
});
