import {Subscription} from 'rxjs';

export class SubSink {
  protected subs = new Subscription();

  set sink(subscription: Subscription) {
    this.subs.add(subscription);
  }

  unsubscribe(withReuse = false) {
    this.subs.unsubscribe();
    (this.subs as any)._subscriptions?.forEach(x => this.subs.remove(x));
    if (withReuse) {
      this.subs = new Subscription();
    }
  }

  get length() {
    return (this.subs as any)._subscriptions.length;
  }
}
