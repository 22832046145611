import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbDateAdapter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateAdapter} from '../adapters/custom-date.adapter';
import {CustomDatepickerI18n} from '../i18n/custom-datepicker.i18n';

@Component({
  selector: 'bitmark-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
  ]
})
export class DatePickerComponent {
  @Input() placeholder: string;
  @Input() format: string;

  _ngModel: string;
  @Input()
  set ngModel(value: string) {
    if (!this._ngModel) {
      this._ngModel = value || this.defaultDate.toISOString();
      this.modelHasValue = !!value;
    }
  }

  get ngModel(): string {
    return this._ngModel;
  }

  defaultDate: Date;
  modelHasValue = false;

  @Output() ngModelChange = new EventEmitter<string>();

  constructor() {
    this.defaultDate = new Date();
  }

  modelChanged(event) {
    this.ngModelChange.emit(event);
    this.modelHasValue = !!event;
  }
}
