import {Mark, mergeAttributes} from '@tiptap/core';
import {MarkedOptions} from '../marked/marked.node';

const createDoubleUnderline = (name: string, cssClass: string) => {
  return Mark.create<MarkedOptions>({
    name: name,

    addOptions() {
      return {
        HTMLAttributes: {},
      };
    },

    parseHTML() {
      return [
        {
          tag: `span[class="${cssClass}"]`
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      const customAttributes = {
        class: cssClass
      };
      return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
    }
  });
};

export const DoubleUnderline = createDoubleUnderline('doubleUnderline', 'bitmark-text-double-underline');
export const UserDoubleUnderline = createDoubleUnderline('userDoubleUnderline', 'bitmark-text-user-double-underline');
