import {Injectable, RendererFactory2} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TouchSwipe} from './touch-swipe';

@Injectable({providedIn: 'root'})
export class ModalTouchSwipeService {
  constructor(private rendererFactory: RendererFactory2) {
  }

  applyTouchSwipe(modalRef: NgbModalRef) {
    const ngbModalWindowNativeElement = (modalRef as any)._windowCmptRef.instance._elRef.nativeElement;
    const renderer = this.rendererFactory.createRenderer(null, null);
    const touchSwipe = new TouchSwipe(ngbModalWindowNativeElement, renderer);
    touchSwipe.applyTouchSwipe();
    const sub = touchSwipe.onSwipe.subscribe(() => {
      sub.unsubscribe();
      touchSwipe.destroy();
      modalRef.dismiss();
    });
  }
}

