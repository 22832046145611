import {Injectable} from '@angular/core';
import {concatMap, defer, Observable, Observer, of} from 'rxjs';
import {map, tap} from "rxjs/operators";
import {ApiService} from '../api/api.service';
import {WindowService} from "core";

export interface SsoLogin {
  type: 'email' | 'openid' | 'saml2' | 'pkce' | 'redirect';
  provider: string;
  loginUrl: string;
  logoutUrl: string;
  tokenUrl: string;
}

export interface TokenResponse {
  access_token?: string;
  expires_in?: number;
  id_token?: string;
  refresh_token?: string;
  token_type?: "Bearer";
  error?: string;
}

@Injectable({providedIn: 'root'})
export class SsoService {
  constructor(private apiService: ApiService,
              private windowService: WindowService) {
  }

  getProviderByEmail(email: string): Observable<{ provider: string }> {
    return this.apiService.get('auth/sso/provider/{email}', {email});
  }

  readEndPoints(space: string): Observable<SsoLogin> {
    return space === 'personal'
      ? of({type: 'email'})
      : this.apiService.get('auth/sso/endpoints/{provider}', {provider: space});
  }

  login(space: string): Observable<SsoLogin> {
    return space === 'personal'
      ? of({})
      : this.apiService.get('auth/sso/login/{provider}', {provider: space});
  }

  logout(fullSpace: string): Observable<any> {
    if (fullSpace === 'personal') {
      return of(null);
    }
    const {provider, space} = SsoService.getProviderAndSpace(fullSpace);
    return this.readEndPoints(space)
      .pipe(tap((sso: SsoLogin) => {
        if (sso?.logoutUrl) {
          const logoutUrl = sso.logoutUrl
            .replace('${idp}', provider)
            .replace('${logoutUri}', this.windowService.getBaseUrl());
          window.location.href = logoutUrl;
        }
      }));
  }

  acs(space: string, token: TokenResponse): Observable<any> {
    if (space === 'personal' || !token) {
      return of(null);
    }
    return this.apiService.post('auth/sso/acs/{provider}?no-redirect=true', {provider: space}, token);
  }

  token(space: string, code: string, codeVerifier: string): Observable<TokenResponse> {
    if (space === 'personal') {
      return of(null);
    }
    return this.readEndPoints(space)
      .pipe(concatMap((sso: SsoLogin) => {
        return new Observable((x: Observer<TokenResponse>) => {
          fetch(sso.tokenUrl, {
            method: 'POST',
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams({
              'grant_type': 'authorization_code',
              'client_id': '5tjatoact58uoj8b1gbumgfj9l',
              'redirect_uri': `${this.windowService.getBaseUrl()}/auth-callback`,
              'code': code,
              'code_verifier': codeVerifier
            }).toString()
          })
            .then(res => res.json())
            .then((token: TokenResponse) => {
              if (token.error) {
                x.error(token.error);
              } else {
                x.next(token);
              }
              x.complete();
            }, (err) => {
              x.error(err);
              x.complete();
            });
        })
      }));
  }

  static getSpaceFromUrl(url: string): string {
    const spaceFromUrlMatches = url.match(/\/space\/.*\//)
      || url.match(/\/space\/.*$/);
    const afterSpace = spaceFromUrlMatches
      ? spaceFromUrlMatches[0]
        .replace(new RegExp('/space/', 'g'), '')
      : null;
    if (!afterSpace) {
      return null;
    }
    const x = afterSpace.indexOf('/');
    return x !== -1
      ? afterSpace.substr(0, x)
      : afterSpace;
  }

  static getProviderAndSpace(providerAndSpace: string) {
    if (!providerAndSpace) {
      return {provider: null, space: null, isFromSplit: false};
    }
    const provider = providerAndSpace.includes("@") //hhm
      ? providerAndSpace.split("@")[0]
      : providerAndSpace;
    const space = providerAndSpace.includes("@") //electrosuisse
      ? providerAndSpace.split("@")[1]
      : providerAndSpace;
    return {provider, space, isFromSplit: providerAndSpace.includes("@")};
  }
}
