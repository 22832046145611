import {Component, Input} from '@angular/core';

@Component({
  selector: 'bitmark-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../bits.scss']
})
export class SummaryComponent {
  @Input() bit?;
}
