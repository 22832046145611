import {concatMap, Observable} from 'rxjs';
import {StorageMap} from '@ngx-pwa/local-storage';
import {map} from "rxjs/operators";

export class IndexedDbStorage {
  constructor(private storage: StorageMap) {
  }

  add(key: string, value: any): Observable<any> {
    return this.getAsArray(key)
      .pipe(concatMap((array: Array<any>) => {
        array.push(value);
        return this.store(key, array);
      }));
  }

  remove(key: string, predicate: Function): Observable<any> {
    return this.getAsArray(key)
      .pipe(concatMap((array: any) => {
        const idx = array.findIndex(predicate);
        if (idx !== -1) {
          array.splice(idx, 1);
        }
        return this.store(key, array);
      }));
  }

  store(key: string, value: any): Observable<any> {
    return this.storage.set(key, value);
  }

  get(key: string): Observable<any | Array<any>> {
    return this.storage.get(key);
  }

  watch(key: string): Observable<any> {
    return this.storage.watch(key);
  }

  getAsArray(key: string): Observable<Array<any>> {
    return this.storage.get(key)
      .pipe(map((array: any | Array<any>) => {
        if (!array) {
          array = [];
        }
        if (!(array instanceof Array)) {
          array = [array];
        }
        return array;
      }));
  }

  count(key: string): Observable<number> {
    return this.getAsArray(key)
      .pipe(map((array: Array<any>) => array.length));
  }

  clear(key?: string): Observable<any> {
    return !key
      ? this.storage.clear()
      : this.storage.delete(key);
  }

  check(): Observable<{ isSupported: boolean, quota?: number, usage?: number }> {
    return new Observable(x => {
      this.storage.set('test', 'test')
        .subscribe(() => {
          this.storage.delete('test');
          if (navigator.storage && navigator.storage.estimate) {
            navigator.storage.estimate().then(quota => {
              x.next({isSupported: true, quota: quota.quota, usage: quota.usage});
              x.complete();
            }, () => {
              x.next({isSupported: true});
              x.complete();
            });
          } else {
            x.next({isSupported: true});
            x.complete();
          }
        }, () => {
          x.next({isSupported: false});
          x.complete();
        });
    });
  }
}
