import {TaskItem} from '@tiptap/extension-task-item';
import {mergeAttributes} from '@tiptap/core';
import {EventEmitter as EE} from '../../../../shared';

declare const window: any;

window.bitmarkSpace = Object.assign({}, window.bitmarkSpace || {}, {
  taskItemClicked: function () {
    const inputElem = arguments[0];

    const bitWrapperElem = inputElem.closest('.bit-wrapper1');
    const bitmarkElems = bitWrapperElem.querySelectorAll('[data-bitmark-node]');

    if (!bitmarkElems?.length) {
      return false;
    }

    const inputLi = inputElem.closest('li[data-type="taskItem"]');
    if (inputElem.checked) {
      inputElem.setAttribute('checked', 'checked');
      inputLi.setAttribute('data-checked', 'true');
    } else {
      inputElem.removeAttribute('checked');
      inputLi.setAttribute('data-checked', 'false');
    }

    const bitmarkChangedData = {};
    bitmarkElems?.forEach(node => {
      bitmarkChangedData[node.attributes['data-bitmark-node']?.value] = node.innerHTML;
    });

    const bitId = bitWrapperElem.id?.replace('bit-', '');
    EE.default.emit('bitmark-changed', {id: bitId, data: bitmarkChangedData});

    return true;
  }
});

const BitmarkTaskItem = TaskItem.extend({
  renderHTML({node, HTMLAttributes}) {
    return [
      'li',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
      [
        'label',
        [
          'input',
          {
            type: 'checkbox',
            checked: node.attrs.checked ? 'checked' : null,
            onclick: 'return bitmarkSpace.taskItemClicked(this)'
          },
        ],
        ['span'],
      ],
      ['div', 0],
    ];
  }
});

export default BitmarkTaskItem;
