// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  margin-left: 5px;
}

.placeholder-hint {
  margin: 0 0 0 5px;
}

.placeholder-instruction {
  display: inline-block;
  font-style: italic;
  margin: 0 0 0 5px;
}

.hidden-input {
  visibility: hidden;
  position: fixed;
  left: -9999px;
  min-width: 50px;
  max-width: 250px;
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
