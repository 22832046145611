import {Renderer2} from '@angular/core';
import {fromEvent} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SubSink} from '../sub-sink/sub-sink';
import {DomUtilsService} from '../dom/dom-utils.service';

export class ScrollAntiBlock {
  private subSink = new SubSink();

  constructor(private nativeElement: any,
              private scrollOffset: number,
              private debounceTime: number,
              private stopPropagation: boolean,
              private renderer: Renderer2,
              private deviceDetectorService: DeviceDetectorService,
              private domUtils: DomUtilsService) {
  }

  apply() {
    const isAndroid = this.deviceDetectorService.os.toLowerCase() === 'android';
    const isIos = this.deviceDetectorService.os.toLowerCase() === 'ios';
    const isChrome = this.deviceDetectorService.browser.toLowerCase() === 'chrome';

    this.renderer.setStyle(this.nativeElement, 'padding-bottom', `${this.scrollOffset}px`);
    this.renderer.setStyle(this.nativeElement, 'padding-top', `${this.scrollOffset}px`);

    /**
     if (isIos || isAndroid) {
      this.subSink.sink = fromEvent(this.nativeElement, 'scroll')
        .pipe(debounceTime(this.debounceTime))
        .subscribe(() => {
          const scrollBottomDist = Math.abs(this.domUtils.bottomScrollDistance(this.nativeElement));
          if (scrollBottomDist <= this.scrollOffset && this.domUtils.isScrolledToBottom(this.nativeElement, this.scrollOffset)) {
            // this.domUtils.scrollToBottom(this.nativeElement, this.scrollOffset);
          }

          const scrollTopDist = this.nativeElement.scrollTop;
          if (scrollTopDist <= this.scrollOffset && this.domUtils.isScrolledToTop(this.nativeElement, this.scrollOffset)) {
            this.domUtils.scrollToTop(this.nativeElement, this.scrollOffset);
          }
        });
    }
     **/

    if (isIos || isAndroid) {
      this.subSink.sink = fromEvent(this.nativeElement, 'scroll')
        .subscribe(() => {
          const e = this.nativeElement;
          const db = e.scrollHeight - e.scrollTop - e.clientHeight;

          if (0 === db) {
            const fb = function (ev) {
              return function () {
                ev.scrollTop -= 1;
              };
            };
            setTimeout(fb(e), 0);
          }

          const dt = e.scrollTop;
          if (0 === dt) {
            const ft = function (ev) {
              return function () {
                ev.scrollTop += 1;
              };
            };
            setTimeout(ft(e), 0);
          }
        });
      return;
    }

    if (isChrome) {
      this.subSink.sink = fromEvent(this.nativeElement, 'wheel', {capture: false, passive: false})
        .subscribe(($event: any) => {
          if (this.stopPropagation) {
            $event.stopPropagation();
          }

          const direction = $event.wheelDeltaY < 0 ? 'up' : 'down';
          if (this.domUtils.isScrolledToBottom(this.nativeElement) && direction === 'up' ||
            this.domUtils.isScrolledToTop(this.nativeElement) && direction === 'down') {
            $event.preventDefault();
            $event.stopPropagation();
            return;
          }
        });
    }
  }

  destroy(): void {
    this.subSink.unsubscribe();
  }
}
