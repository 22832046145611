// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}

.multimedia__button--icon {
  line-height: 38px;
}
.multimedia__content {
  flex-grow: 1;
  height: 100%;
  min-height: 200px;
}
.multimedia__content .image-cropper {
  width: 800px;
  height: auto;
}
.multimedia__content__image {
  max-height: 300px;
}
.multimedia__content__video {
  max-width: 100%;
  max-height: 300px;
}
.multimedia__content__dropzone-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  border: 1px dashed #c8c8c8;
  cursor: pointer;
}
.multimedia__content__dropzone-content {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
}
.multimedia__content__input, .multimedia__content__input:focus {
  z-index: 9999;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
