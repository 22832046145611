import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {BaseBit, BitResource, BitResourceVideoLink, BitType} from '../../bits.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {EmbedVideoService} from '../../../shared';

@Component({
  selector: 'bitmark-resource-video-link',
  templateUrl: './resource-video-link.component.html',
  styleUrls: ['./resource-video-link.component.scss', '../../bits.scss']
})
export class ResourceVideoLinkComponent implements OnInit {
  @Input() bit?: BaseBit;
  @Input() bitResource?: BitResource;
  @Input() hostBitType: BitType;
  @Input() bitFormat?: BitmarkFormat;
  @Input() isBeingEditedByUser = false;
  @Output() captionChanged = new EventEmitter<BitResourceVideoLink>();

  embeddedVideoHtml: string;
  videoLogo: string;
  aspectRatio: number;
  videoCss: string;
  videoCaption: string | JSONContent;
  videoCopyright: string;
  private cleanVideoUrl: string;

  constructor(private embedVideoService: EmbedVideoService) {
  }

  ngOnInit() {
    this.videoCss = `video-bit-type-${this.hostBitType}`;

    this.videoCaption = this.bitResource?.videoLink?.caption || this.bitResource?.videoEmbed?.caption;
    this.videoCopyright = this.bitResource?.videoLink?.copyright || this.bitResource?.videoEmbed?.copyright;

    const videoLink = this.bitResource?.videoLink || this.bitResource?.videoOnline || this.bitResource?.videoEmbed;
    if (videoLink) {
      this.cleanVideoUrl = (videoLink?.url || '')
        .replace(new RegExp('//player.vimeo.com/video/', 'g'), '//vimeo.com/');
      // .replace(new RegExp('youtube.com/embed/', 'g'), 'youtube.com/watch?v='); // convert from embedded to normal

      const isYoutubeEmbedded = this.cleanVideoUrl.includes('youtube.com/embed/');

      if (isYoutubeEmbedded) {
        this.cleanVideoUrl += (this.cleanVideoUrl.includes('?') ? '&' : '?') + 'rel=0&controls=true&byline=false&portrait=false&title=false';
      }

      this.aspectRatio = +videoLink?.width && +videoLink?.height
        ? +(+videoLink.width / +videoLink.height).toFixed(2) + .01
        : +(16 / 9).toFixed(2) + .01;

      let qry: any = {rel: 0};
      if (this.isVimeoUrl() && this.cleanVideoUrl.indexOf('?') !== -1) {
        // get query params from vimeo url, for private url sharing purposes
        const params: any = new URLSearchParams(this.cleanVideoUrl.split('?')[1]);
        for (const param of params) {
          if (param?.length > 1) {
            qry[param[0]] = param[1];
          }
        }
      }

      if (!this.isVimeoUrl() && !isYoutubeEmbedded) {
        qry = {
          controls: true,
          byline: false,
          portrait: false,
          title: false,
          ...qry
        };

        if (this.isYoutubeUrl() && new URL(this.cleanVideoUrl).searchParams.get('t')) {
          qry = {
            start: new URL(this.cleanVideoUrl).searchParams.get('t'),
            ...qry
          };

          this.cleanVideoUrl = this.removeURLParameter(this.cleanVideoUrl, 't');
        }
      }

      this.embeddedVideoHtml = isYoutubeEmbedded
        ? this.embedVideoService.embed_youtube_embedded(this.cleanVideoUrl)
        : this.embedVideoService.embed(this.cleanVideoUrl, {
          attr: {width: '100%', height: '100%'},
          query: qry
        });
      this.videoLogo = this.isYoutubeUrl()
        ? 'youtube'
        : this.isVimeoUrl()
          ? 'vimeo'
          : '';
    }

    if (!this.bitFormat) {
      this.bitFormat = BitmarkFormat.MM;
    }
  }

  private isVimeoUrl(): boolean {
    return this.cleanVideoUrl.indexOf('vimeo') !== -1;
  }

  private isYoutubeUrl(): boolean {
    return this.cleanVideoUrl.indexOf('youtube') !== -1 ||
      this.cleanVideoUrl.indexOf('youtu.be') !== -1;
  }

  // https://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript
  private removeURLParameter(url, parameter) {
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {

      const prefix = encodeURIComponent(parameter) + '=';
      const pars = urlparts[1].split(/[&;]/g);

      for (let i = pars.length; i-- > 0;) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }

  onCaptionChanged(content: JSONContent) {
    this.videoCaption = content;
    this.captionChanged.emit({
      ...(this.bitResource?.videoLink || this.bitResource?.videoEmbed),
      caption: content?.content
    });
  }
}
