import {Subject} from 'rxjs';
import {ElementRef} from '@angular/core';

export abstract class RecordingService {
  protected stream;
  protected audioContext: AudioContext;
  protected recorder;
  protected _recordingAnalyzerData = new Subject<Uint8Array>();

  public drawFeedback(canvas: ElementRef<any>,
                      canvasContext: CanvasRenderingContext2D,
                      dataArray: Uint8Array,
                      fill: string | CanvasGradient | CanvasPattern,
                      stroke: string | CanvasGradient | CanvasPattern) {
    const WIDTH = canvas.nativeElement.width;
    const HEIGHT = canvas.nativeElement.height;

    const bufferLength = dataArray.length;

    canvasContext.clearRect(0, 0, WIDTH, HEIGHT);

    canvasContext.fillStyle = fill;
    canvasContext.fillRect(0, 0, WIDTH, HEIGHT);

    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = stroke;

    canvasContext.beginPath();

    const sliceWidth = WIDTH * 1.0 / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {

      const v = dataArray[i] / 128.0;
      const y = v * HEIGHT / 2;

      if (i === 0) {
        canvasContext.moveTo(x, y);
      } else {
        canvasContext.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasContext.lineTo(canvas.nativeElement.width, canvas.nativeElement.height / 2);
    canvasContext.stroke();
  }

  protected analyzeAudio() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }

    const source = this.audioContext.createMediaStreamSource(this.stream);

    const analyser = this.audioContext.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    const analyzeByteTimeDomainData = () => {
      if (this.recorder) {
        requestAnimationFrame(analyzeByteTimeDomainData);
      }

      analyser.getByteTimeDomainData(dataArray);
      this._recordingAnalyzerData.next(dataArray);
    };

    analyzeByteTimeDomainData();
  }
}
