// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-path-cover-mask {
  position: relative;
  z-index: 1;
  -webkit-mask-image: url("/assets/images/notebook/notebook-learning-path-cover-mask.svg");
  mask-image: url("/assets/images/notebook/notebook-learning-path-cover-mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}
.learning-path-cover-mask img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cover-shadow {
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.55));
  transform: translateZ(0); /* added as a workaround for Safari performance issues https://stackoverflow.com/questions/10814178/css-performance-relative-to-translatez0 */
}

.learning-path {
  aspect-ratio: 0.7;
}
.learning-path.sm {
  width: 40px !important;
}
.learning-path.lg {
  width: 350px !important;
}

.draft-book.lg {
  width: 375px !important;
}

.flashcards-img.lg {
  height: 500px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
