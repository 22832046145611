import {Component, Input} from '@angular/core';

@Component({
  selector: 'bitmark-book-default-cover',
  templateUrl: './book-default-cover.component.html'
})
export class BookDefaultCoverComponent {
  @Input() text: string;
  @Input() coverColor?: string;
  @Input() isDraft?: boolean;
  @Input() isNotExist?: boolean;
  @Input() isProductCover?: boolean;
}
