import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InternalLinkBit} from './internal-link.models';
import {BitResource} from '../bits.models';
import {BitbookMqService} from '../../reader/bitbook-mq.service';

@Component({
  selector: 'bitmark-internal-link',
  templateUrl: './internal-link.component.html',
  styleUrls: ['./internal-link.component.scss', '../bits.scss']
})
export class InternalLinkComponent {
  @Input() bit?: InternalLinkBit;
  @Output() openResource = new EventEmitter<BitResource>();

  constructor(private bitbookMqService: BitbookMqService) {
  }

  onClick() {
    this.bitbookMqService.notifyReaderInternalLinkClicked(this.bit);
  }
}
