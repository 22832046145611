import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {cloneDeep} from 'lodash';
import {MessageBit} from './message.models';
import {BitResource, BitResourceType} from '../bits.models';
import {emoticonRegex} from './emoticons.utils';
import {BitbookApiService} from '../../reader/bitbook-api.service';
import {BitmarkFormat} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-message',
  templateUrl: './message.component.html',
  styleUrls: ['../bits.scss', './message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() bit: MessageBit;
  @Input() fullWidth = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<any>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      this.prevMessageBit = cloneDeep(this.bit);
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  private prevMessageBit?: MessageBit;
  isEmojiMessage = false;
  isTextOnly: boolean;
  isResourceOnly: boolean;

  constructor(private bitBookApiService: BitbookApiService) {
  }

  ngOnInit() {
    this.isEmojiMessage = !!(this.bit?.body && this.bit?.body.replace(new RegExp(emoticonRegex), '') === '');
    this.isTextOnly = !!(this.bit.body && !this.bit.resource);
    this.isResourceOnly = !!(!this.bit.body && this.bit.resource);
  }

  uploadImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitBookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        if (!this.bit.resource) {
          this.bit.resource = {
            type: BitResourceType.Image
          };
        }
        this.bit.resource.image = {
          src: resourceUrl.url,
          alt: this.bit.instruction
        };
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  removeImage() {
    if (this.bit.resource) {
      this.bit.resource = null;
    }
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevMessageBit);
    this.editClosed.emit();
  }

  saveChanges() {
    this.bit.format = BitmarkFormat.PP;
    this.saveInlineBit.emit(this.bit);
  }
}

