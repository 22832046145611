import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TranslateModule} from '@ngx-translate/core';
import {RouteReuseStrategy} from '@angular/router';
import {CoreModule} from 'core/core.module';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {environment} from 'environment';
import {appHost} from './app-host';
import * as barrel from '.';
// import Hotjar from '@hotjar/browser';
import {CustomReuseStrategy} from 'custom-reuse-strategy.route';

// if (environment.hotjarSiteId) {
//   Hotjar.init(environment.hotjarSiteId, 6);
// }
const gaTrackingCode = appHost.getSubdomainConfig()?.gaTrackingCode;

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    ServiceWorkerModule.register('custom-ngsw.js', {enabled: environment.production}),
    NgxGoogleAnalyticsModule.forRoot(gaTrackingCode, [{
      command: 'config', values: [gaTrackingCode]
    }])
  ],
  declarations: [...barrel.components],
  exports: [
    CommonModule,
    CoreModule,
    ...barrel.components
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
    CurrencyPipe
  ]
})
export class AppModule {
}
