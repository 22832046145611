// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bot-action-response-container {
  --bitmark-bot-action-response-default-avatar-size-width: 50px;
  --bitmark-bot-action-response-default-avatar-size-height: 50px;
  --bitmark-bot-action-response-bot-bubble-default-background-color: #9bd0ff;
}
.bot-action-response-container .bot-avatar-container {
  --bitmark-bot-action-response-default-message-bubble-background-color: #b1cfeb;
}
.bot-action-response-container .bot-avatar-container .avatar {
  --bitmark-bot-action-response-default-avatar-background-color: #5e63c7;
  --bitmark-bot-action-response-default-avatar-background-image: url('data:image/svg+xml;utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path style="fill: white" d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>');
  background-color: var(--bitmark-bot-action-response-avatar-background-color, var(--bitmark-bot-action-response-default-avatar-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-bot-action-response-avatar-background-image, var(--bitmark-bot-action-response-default-avatar-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-bot-action-response-avatar-background-size, var(--bitmark-bot-action-response-default-avatar-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-bot-action-response-avatar-background-position, var(--bitmark-bot-action-response-default-avatar-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-bot-action-response-avatar-background-repeat, var(--bitmark-bot-action-response-default-avatar-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-bot-action-response-avatar-background-transform, var(--bitmark-bot-action-response-default-avatar-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-bot-action-response-avatar-background-animation-name) var(--bitmark-bot-action-response-avatar-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-bot-action-response-avatar-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-bot-action-response-avatar-background-backdrop-filter);
  width: var(--bitmark-bot-action-response-avatar-size-width, var(--bitmark-bot-action-response-default-avatar-size-width, inherit));
  height: var(--bitmark-bot-action-response-avatar-size-height, var(--bitmark-bot-action-response-default-avatar-size-height, inherit));
  margin: var(--bitmark-bot-action-response-avatar-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-bot-action-response-avatar-size-padding, var(--bitmark-x-size-padding, 0));
}
.bot-action-response-container .bot-avatar-container .avatar-text::after {
  --bitmark-bot-action-response-avatar-default-font-color: #fff;
  content: var(--bitmark-bot-action-response-avatar-string);
  font-family: var(--bitmark-bot-action-response-avatar-font-family, var(--bitmark-bot-action-response-avatar-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-bot-action-response-avatar-font-size, var(--bitmark-bot-action-response-avatar-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-bot-action-response-avatar-font-weight, var(--bitmark-bot-action-response-avatar-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-bot-action-response-avatar-font-style, var(--bitmark-bot-action-response-avatar-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-bot-action-response-avatar-font-text-decoration, var(--bitmark-bot-action-response-avatar-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-bot-action-response-avatar-font-text-decoration, var(--bitmark-bot-action-response-avatar-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-bot-action-response-avatar-font-line-height, var(--bitmark-bot-action-response-avatar-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-bot-action-response-avatar-font-color, var(--bitmark-bot-action-response-avatar-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-bot-action-response-avatar-font-align, var(--bitmark-bot-action-response-avatar-default-font-align, var(--bitmark-bot-action-response-avatar-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-bot-action-response-avatar-font-align, var(--bitmark-bot-action-response-avatar-default-font-align, var(--bitmark-bot-action-response-avatar-default-font-align, var(--bitmark-main-font-alignt, left))));
}
.bot-action-response-container .bot-action-response-message-arrow {
  position: absolute;
  top: -11px;
  left: calc(var(--bitmark-bot-action-response-avatar-size-width, var(--bitmark-bot-action-response-default-avatar-size-width)) / 2 - 9px);
  color: var(--bitmark-bot-action-response-bot-bubble-background-color, var(--bitmark-bot-action-response-bot-bubble-default-background-color));
}
.bot-action-response-container .bot-action-user-answer-message-arrow {
  position: absolute;
  top: -11px;
  right: 15px;
  color: var(--bitmark-bot-action-response-user-bubble-background-color, var(--bitmark-bot-action-response-bot-bubble-default-background-color));
}
.bot-action-response-container .bot-action-response-message {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 600px;
  background-color: var(--bitmark-bot-action-response-bot-bubble-background-color, var(--bitmark-bot-action-response-bot-bubble-default-background-color));
  border-radius: 0.3rem;
}
.bot-action-response-container .bot-action-user-response-message {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 600px;
  background-color: var(--bitmark-bot-action-response-user-bubble-background-color, var(--bitmark-bot-action-response-bot-bubble-default-background-color));
  border-radius: 0.3rem;
}

.bot-action-option {
  --bitmark-bot-action-response-response-button-default-background-color: #fff;
  --bitmark-bot-action-response-response-button-default-border-radius: 25px;
  --bitmark-bot-action-response-response-button-default-size-padding: 5px 15px;
  --bitmark-bot-action-response-response-button-default-size-margin: 0 0 10px 0;
  font-family: var(--bitmark-bot-action-response-response-button-font-family, var(--bitmark-bot-action-response-response-button-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-bot-action-response-response-button-font-size, var(--bitmark-bot-action-response-response-button-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-bot-action-response-response-button-font-weight, var(--bitmark-bot-action-response-response-button-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-bot-action-response-response-button-font-style, var(--bitmark-bot-action-response-response-button-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-bot-action-response-response-button-font-text-decoration, var(--bitmark-bot-action-response-response-button-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-bot-action-response-response-button-font-text-decoration, var(--bitmark-bot-action-response-response-button-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-bot-action-response-response-button-font-line-height, var(--bitmark-bot-action-response-response-button-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-bot-action-response-response-button-font-color, var(--bitmark-bot-action-response-response-button-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-bot-action-response-response-button-font-align, var(--bitmark-bot-action-response-response-button-default-font-align, var(--bitmark-bot-action-response-response-button-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-bot-action-response-response-button-font-align, var(--bitmark-bot-action-response-response-button-default-font-align, var(--bitmark-bot-action-response-response-button-default-font-align, var(--bitmark-main-font-alignt, left))));
  --bitmark-bot-action-response-response-button-background-shorthand-composed: var(--bitmark-bot-action-response-response-button-background, var(--bitmark-bot-action-response-response-button-default-background-color)) var(--bitmark-bot-action-response-response-button-background-image, var(--bitmark-bot-action-response-response-button-default-background-image, none)) var(--bitmark-bot-action-response-response-button-background-repeat, var(--bitmark-bot-action-response-response-button-default-background-repeat, no-repeat)) var(--bitmark-bot-action-response-response-button-background-position, var(--bitmark-bot-action-response-response-button-default-background-position, center)) / var(--bitmark-bot-action-response-response-button-background-size, var(--bitmark-bot-action-response-response-button-default-background-size, cover));
  background: var(--bitmark-bot-action-response-response-button-background-shorthand, var(--bitmark-bot-action-response-response-button-background-shorthand-composed));
  transform: var(--bitmark-bot-action-response-response-button-background-transform, var(--bitmark-bot-action-response-response-button-default-background-transform, none));
  animation: var(--bitmark-bot-action-response-response-button-background-animation-name) var(--bitmark-bot-action-response-response-button-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-bot-action-response-response-button-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-bot-action-response-response-button-background-backdrop-filter);
  border-top: var(--bitmark-bot-action-response-response-button-border-top-width, var(--bitmark-bot-action-response-response-button-default-border-top-width)) var(--bitmark-bot-action-response-response-button-border-style, var(--bitmark-bot-action-response-response-button-default-border-style)) var(--bitmark-bot-action-response-response-button-border-color, var(--bitmark-bot-action-response-response-button-default-border-color));
  border-right: var(--bitmark-bot-action-response-response-button-border-right-width, var(--bitmark-bot-action-response-response-button-default-border-right-width)) var(--bitmark-bot-action-response-response-button-border-style, var(--bitmark-bot-action-response-response-button-default-border-style)) var(--bitmark-bot-action-response-response-button-border-color, var(--bitmark-bot-action-response-response-button-default-border-color));
  border-bottom: var(--bitmark-bot-action-response-response-button-border-bottom-width, var(--bitmark-bot-action-response-response-button-default-border-bottom-width)) var(--bitmark-bot-action-response-response-button-border-style, var(--bitmark-bot-action-response-response-button-default-border-style)) var(--bitmark-bot-action-response-response-button-border-color, var(--bitmark-bot-action-response-response-button-default-border-color));
  border-left: var(--bitmark-bot-action-response-response-button-border-left-width, var(--bitmark-bot-action-response-response-button-default-border-left-width)) var(--bitmark-bot-action-response-response-button-border-style, var(--bitmark-bot-action-response-response-button-default-border-style)) var(--bitmark-bot-action-response-response-button-border-color, var(--bitmark-bot-action-response-response-button-default-border-color));
  border-radius: var(--bitmark-bot-action-response-response-button-border-radius, var(--bitmark-bot-action-response-response-button-default-border-radius, y));
  margin: var(--bitmark-bot-action-response-response-button-size-margin, var(--bitmark-bot-action-response-response-button-default-size-margin, 0));
  padding: var(--bitmark-bot-action-response-response-button-size-padding, var(--bitmark-bot-action-response-response-button-default-size-padding, 0));
}
.bot-action-option .bot-action-option-item {
  --bitmark-bot-action-response-response-button-item-default-font-color: #fff;
  --bitmark-bot-action-response-response-button-item-default-background-color: #543199;
  font-family: var(--bitmark-bot-action-response-response-button-item-font-family, var(--bitmark-bot-action-response-response-button-item-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-bot-action-response-response-button-item-font-size, var(--bitmark-bot-action-response-response-button-item-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-bot-action-response-response-button-item-font-weight, var(--bitmark-bot-action-response-response-button-item-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-bot-action-response-response-button-item-font-style, var(--bitmark-bot-action-response-response-button-item-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-bot-action-response-response-button-item-font-text-decoration, var(--bitmark-bot-action-response-response-button-item-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-bot-action-response-response-button-item-font-text-decoration, var(--bitmark-bot-action-response-response-button-item-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-bot-action-response-response-button-item-font-line-height, var(--bitmark-bot-action-response-response-button-item-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-bot-action-response-response-button-item-font-color, var(--bitmark-bot-action-response-response-button-item-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-bot-action-response-response-button-item-font-align, var(--bitmark-bot-action-response-response-button-item-default-font-align, var(--bitmark-bot-action-response-response-button-item-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-bot-action-response-response-button-item-font-align, var(--bitmark-bot-action-response-response-button-item-default-font-align, var(--bitmark-bot-action-response-response-button-item-default-font-align, var(--bitmark-main-font-alignt, left))));
  background-color: var(--bitmark-bot-action-response-response-button-item-background-color, var(--bitmark-bot-action-response-response-button-item-default-background-color));
}

.animation-init {
  opacity: 0;
  transform: translate3d(0, 25px, 0);
  transition-property: opacity, transform;
}

.animation-animate {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.animation-typing {
  animation: typing;
  animation-duration: 2s;
}

.transition-delay-1s {
  transition-delay: 1s;
}

.animation-delay-1s {
  animation-delay: 1s;
}

.transition-delay-2s {
  transition-delay: 2s;
}

.animation-delay-2s {
  animation-delay: 2s;
}

.transition-delay-3s {
  transition-delay: 3s;
}

.animation-delay-3s {
  animation-delay: 3s;
}

.transition-delay-4s {
  transition-delay: 4s;
}

.animation-delay-4s {
  animation-delay: 4s;
}

.transition-delay-5s {
  transition-delay: 5s;
}

.animation-delay-5s {
  animation-delay: 5s;
}

.transition-delay-6s {
  transition-delay: 6s;
}

.animation-delay-6s {
  animation-delay: 6s;
}

.bot-action-response-typing-message {
  top: 0;
  left: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.bot-action-response-typing-message span {
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 10px;
  width: 10px;
  background: #543199;
  border-radius: 50%;
}
.bot-action-response-typing-message span:nth-child(2) {
  animation-delay: 0.2s;
}
.bot-action-response-typing-message span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes typing {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
