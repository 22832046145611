export const defaultContent = [[
  {
    'type': 'heading',
    'attrs': {
      'level': 1
    },
    'content': [
      {
        'type': 'text',
        'text': 'WELCOME! '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'textStyle',
            'attrs': {
              'color': ''
            }
          }
        ],
        'text': '🤗'
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    },
    'content': [
      {
        'type': 'text',
        'text': 'Thank you for checking out our brand new editor. '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'textStyle',
            'attrs': {
              'color': ''
            }
          }
        ],
        'text': '🧐'
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    },
    'content': [
      {
        'type': 'text',
        'text': 'It is simple, but very powerful. We hope you will enjoy it. '
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    }
  },
  {
    'type': 'image',
    'attrs': {
      'textAlign': 'left',
      'src': 'https://resources-test.bitmark.cloud/bit-resource-upload/user-14/1x9M/tnx-fish-02.png',
      'alt': '',
      'title': '',
      'class': 'center',
      'height': 467,
      'width': 700,
      'copyright': '',
      'onload': 'bitmarkSpace.imgLoaded(this)',
      'onclick': 'bitmarkSpace.imgClicked(this)'
    }
  },
  {
    'type': 'heading',
    'attrs': {
      'level': 2
    },
    'content': [
      {
        'type': 'text',
        'text': 'What we provide...'
      }
    ]
  },
  {
    'type': 'heading',
    'attrs': {
      'level': 1
    },
    'content': [
      {
        'type': 'text',
        'text': 'Some Nice Text Formats, like this Title ✍'
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    },
    'content': [
      {
        'type': 'text',
        'marks': [
          {
            'type': 'bold'
          }
        ],
        'text': 'Bold'
      },
      {
        'type': 'text',
        'text': ', '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'italic'
          }
        ],
        'text': 'italic'
      },
      {
        'type': 'text',
        'text': ', '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'strike'
          }
        ],
        'text': 'strikethrough'
      },
      {
        'type': 'text',
        'text': ', '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'textStyle',
            'attrs': {
              'color': ''
            }
          },
          {
            'type': 'light'
          }
        ],
        'text': 'light'
      },
      {
        'type': 'text',
        'text': ' and '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'mark',
            'attrs': {
              'color': '#fffb81'
            }
          }
        ],
        'text': 'highlighted'
      },
      {
        'type': 'text',
        'text': ', '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'subscript'
          }
        ],
        'text': 'Sub'
      },
      {
        'type': 'text',
        'text': '- and '
      },
      {
        'type': 'text',
        'marks': [
          {
            'type': 'superscript'
          }
        ],
        'text': 'Super'
      },
      {
        'type': 'text',
        'text': 'Script. '
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    },
    'content': [
      {
        'type': 'text',
        'text': ' '
      }
    ]
  }
],  [{
'type': 'heading',
'attrs': {'level': 2},
'content': [{'type': 'text', 'text': 'Lists'}]
}, {
'type': 'bulletList',
'content': [{
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Bullet Lists'}]
}]
}, {
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Nothing extraordinary'}]
}, {
'type': 'bulletList',
'content': [{
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'but hey,'}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'still very useful'}]
}]
}]
}]
}, {
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Numbered Lists'}]
}]
}, {
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Lettered Lists'}]
}]
}, {
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Tasks'}]
}, {
'type': 'taskList',
'content': [{
'type': 'taskItem',
'attrs': {'checked': true},
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Buy ☕️'}]
}]
}, {
'type': 'taskItem',
'attrs': {'checked': false},
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Buy 🥖'}]
}]
}]
}]
}, {
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'marks': [{'type': 'bold'}], 'text': 'Bulletless'}, {
'type': 'text',
'text': ' Lists'
}]
}, {
'type': 'bulletList',
'content': [{
'type': 'listItem',
'content': [{
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Why do other editors don\'t have these?'}]
}]
}]
}]
}]
}], [{
'type': 'heading',
'attrs': {'level': 2},
'content': [{'type': 'text', 'text': 'Code'}]
}, {
'type': 'codeBlock',
'attrs': {'language': 'javascript', 'codeLanguage': 'javascript'},
'content': [{'type': 'text', 'text': 'let like = "Get More Brain";'}]
}],  [{
'type': 'heading',
'attrs': {'level': 2},
'content': [{'type': 'text', 'text': 'Links '}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{
'type': 'text',
'marks': [{'type': 'link', 'attrs': {'href': 'https://google.com', 'target': '_blank'}}],
'text': 'Links'
}, {'type': 'text', 'text': ' come with no surprise, even so, we don\'t like them too much in a text.'}]
}],  {
  body: 'Never trust quotes you find on the internet',
  format: 'text',
  type: 'quote',
  quotedPerson: 'Albert Einstein',
}, [
  {
    'type': 'heading',
    'attrs': {
      'level': 2
    },
    'content': [
      {
        'type': 'text',
        'text': 'Math Formulas'
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    },
    'content': [
      {
        'type': 'text',
        'text': 'Do you still remember this formula?'
      }
    ]
  },
  {
    'type': 'paragraph',
    'attrs': {
      'class': 'normal',
      'paragraphType': 'normal'
    },
    'content': [
      {
        'type': 'mathComponent',
        'attrs': {
          'formula': 'x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}'
        }
      }
    ]
  }
], { type: 'note', format: 'bitmark++', body: [{
'type': 'heading',
'attrs': {'level': 2},
'content': [{'type': 'text', 'text': 'What not...'}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{
'type': 'text',
'text': 'Do you miss something? Probably not, however, you might be suprised that we'
}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'don\'t support styling with fonts, text size, color...'}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'This is a true content-first text editor '}, {
'type': 'text',
'marks': [{'type': 'textStyle', 'attrs': {'color': ''}}],
'text': '🍾'
}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': ' '}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{
'type': 'text',
'marks': [{'type': 'textStyle', 'attrs': {'color': ''}}],
'text': '🙌'
}, {'type': 'text', 'text': ' «Look Ma, no hands!»'}]
}, {
'type': 'paragraph',
'attrs': {'class': 'normal', 'paragraphType': 'normal'},
'content': [{'type': 'text', 'text': 'Yep, there is no save button. Changes are saved automatically.'}]
}]}];
