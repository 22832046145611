import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppModule} from 'app.module';
import {AppComponent} from './app.component';
import {routes} from './app.routes';

@NgModule({
  imports: [
    AppModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'disabled',
      onSameUrlNavigation: 'reload',
      enableTracing: false
    })
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModuleRouted {
}

