import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {cloneDeep} from 'lodash';
import {PadletEmbedBit} from './padlet-embed.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-padlet-embed',
  templateUrl: './padlet-embed.component.html',
  styleUrls: ['padlet-embed.component.scss', '../bits.scss']
})
export class PadletEmbedComponent implements OnInit {
  @Input() bit?: PadletEmbedBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<PadletEmbedBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      this.prevPadletEmbedBit = cloneDeep(this.bit);
    }
  }
  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }
  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  url: SafeResourceUrl;
  settingsUrl: string;
  private prevPadletEmbedBit?: PadletEmbedBit;

  constructor(private _sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.bit?.padletId) {
      this.initPadletUrl();
      this.settingsUrl = this.bit.padletId;
    }
  }

  initPadletUrl() {
    this.url = this._sanitizer.bypassSecurityTrustResourceUrl(`https://padlet.com/embed/${this.bit.padletId}`);
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevPadletEmbedBit);
    this.editClosed.emit();
  }

  saveChanges() {
    if (this.settingsUrl) {
      this.bit.padletId = this.settingsUrl.split('/')?.at(-1);
    } else {
      this.bit.padletId = '';
    }

    this.saveInlineBit.emit(this.bit);

    this.settingsUrl = this.bit.padletId;
    if (this.bit.padletId) {
      this.initPadletUrl();
    }
  }
}
