import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BrowserStorageService} from '../../../shared/browser-storage/browser-storage.service';

@Injectable({providedIn: 'root'})
export class ReaderFiltersService {
  private FILTERS_KEY = 'reader-filters-';
  private LAST_SEARCHERD_KEY = 'reader-last-searched-';

  constructor(private browserStorageService: BrowserStorageService) {
  }

  getActiveFiltersForBook(bookExternalId: string): Observable<Array<string>> {
    return this.browserStorageService.idb.get(`${this.FILTERS_KEY}${bookExternalId}`);
  }

  storeActiveFiltersForBook(bookExternalId: string, activeFilters: any): Observable<any> {
    return this.browserStorageService.idb.store(`${this.FILTERS_KEY}${bookExternalId}`, activeFilters);
  }

  addLastSearchedItem(bookExternalId: string, searchQuery: string) {
    if (!searchQuery) {
      return;
    }
    this.removeLastSearchedDuplicated(bookExternalId, searchQuery);
    this.getLastViewed(bookExternalId)
      .subscribe(items => {
        items.unshift(searchQuery);
        if (items.length > 3) {
          items.pop();
        }
        this.storeLastViewed(bookExternalId, items);
      });
  }

  removeLastSearchedDuplicated(bookExternalId: string, searchQuery: string) {
    this.getLastViewed(bookExternalId)
      .subscribe(lastSearchedItems => {
        lastSearchedItems = lastSearchedItems.filter(x => x !== searchQuery);
        this.storeLastViewed(bookExternalId, lastSearchedItems);
      });
  }

  private getLastViewed(bookExternalId: string): Observable<Array<string>> {
    return this.browserStorageService.idb.get(`${this.LAST_SEARCHERD_KEY}${bookExternalId}`);
  }

  private storeLastViewed(bookExternalId: string, lastSearchedItems: Array<string>): Observable<any> {
    return this.browserStorageService.idb.store(`${this.LAST_SEARCHERD_KEY}${bookExternalId}`, lastSearchedItems);
  }
}
