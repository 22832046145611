import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {BitmarkSettings} from '../settings/bitmark.settings';

@Pipe({name: 'bitmarkDate'})
export class BitmarkDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(value: string | number | Date, format?: string, locale?: string): string {
    if (!value) {
      return '';
    }

    try {
      return this.datePipe.transform(value, format, undefined, locale || BitmarkSettings.locale);
    } catch (_) {
      return '';
    }
  }
}
