import {Component, Input} from '@angular/core';
import {GeneralLayoutService, IdentityService, User} from 'core';
import {FocusableOption} from '@angular/cdk/a11y';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-box-peer',
  templateUrl: './user-box-peer.component.html',
  styleUrls: ['./user-box-peer.component.scss']
})
export class UserBoxPeerComponent implements FocusableOption {
  @Input() user: User;
  @Input() isBlocked?: boolean;
  @Input() canOpenAvatar?: boolean;
  @Input() showEnrollment?: boolean;
  @Input() photoSize = '';
  @Input() dotSize = '';
  @Input() textClass = '';
  @Input() showUnreadMessages = true;

  constructor(private router: Router,
              private identityService: IdentityService,
              private generalLayoutService: GeneralLayoutService) {
  }

  focus() {
    const space = this.identityService.getSpace();
    this.router.navigate([space, 'timeline', this.user.id], {replaceUrl: true})
      .then(() => this.generalLayoutService.toggleSectionClass())
      .catch(() => this.generalLayoutService.toggleSectionClass());
  }
}
