import {Injectable} from '@angular/core';
import RecordRTC from 'recordrtc';
import {Observable, Subject} from 'rxjs';
import * as dayjs_ from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {RecordingService} from './recording.service';

const dayjs = dayjs_;
dayjs.extend(duration);

export interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

// analyzer data taken from
// https://github.com/mdn/dom-examples/tree/7cedb8887a0b7b9335ccb876a9dd0fa941b7ddcd/media/web-dictaphone

@Injectable({providedIn: 'root'})
export class AudioRecordingService extends RecordingService {
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  getRecordedAnalyzerData(): Observable<Uint8Array> {
    return this._recordingAnalyzerData.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  hasPermissions(): Promise<boolean> {
    return navigator.permissions.query(
      {name: 'microphone' as PermissionName}
    ).then((status) => {
      return status.state === 'granted';
    });
  }

  startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');
    navigator.mediaDevices.getUserMedia({audio: true})
      .then(s => {
        this.stream = s;
        this.record();
        this.analyzeAudio();
      }).catch(() => {
      this._recordingFailed.next('');
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/mp3'
    });

    this.recorder.record();
    this.startTime = dayjs();
    this.interval = setInterval(
      () => {
        const currentTime = dayjs();
        const diffTime = dayjs.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
      },
      500
    );
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop((blob) => {
        if (this.startTime) {
          const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
          this.stopMedia();
          this._recorded.next({blob: blob, title: mp3Name});
        }
      }, () => {
        this.stopMedia();
        this._recordingFailed.next('');
      });
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }
}
