// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-content {
  min-height: 100vh;
  padding: 10px 10px 125px 10px;
}

.dont-panic {
  background-image: url("/assets/images/resources/dont-panic.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  text-shadow: 0 0 10px #000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
