// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.host {
  background-color: var(--bitmark-reader-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-reader-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-reader-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-reader-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-reader-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-reader-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-reader-background-animation-name) var(--bitmark-reader-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-reader-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-reader-background-backdrop-filter);
  width: 100%;
  height: 100%;
}

.reader-book-header {
  position: absolute;
  top: 0;
  line-height: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}

.logo-container {
  position: absolute;
  bottom: 8px;
  right: 65px;
}
.logo-container .logo-img {
  width: 100px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--bitmark-reader-gmb-logo-image);
}

.progress-bar-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: var(--bitmark-reader-progress-bar-display, block);
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--bitmark-reader-content-width);
}
.centered.paper-size {
  max-width: var(--bitmark-reader-content-width);
}

.reader-book-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
}
@media (min-width: 992px) {
  .reader-book-sidebar {
    position: relative;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
