import {Component, Input} from '@angular/core';
import {StatementBit} from './statement.models';

@Component({
  selector: 'bitmark-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['../bits.scss']
})
export class StatementComponent {
  @Input() bit?: StatementBit;
}
