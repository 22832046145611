import {Mark, mergeAttributes} from '@tiptap/core';

export interface DeletedOptions {
  HTMLAttributes: Record<string, any>;
}

export const Deleted = Mark.create<DeletedOptions>({
  name: 'deleted',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      color: {
        default: '#97d789',
        parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: attributes => {
          if (!attributes.color) {
            return {};
          }

          return {
            'data-color': attributes.color,
            style: `background-color: none !important;
                    text-decoration: underline;
                    text-underline-position: under;
                    text-decoration-color: #dfb5a2;
                    text-decoration-thickness: 2px;`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 's',
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ['s', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands(): any {
    return {
      setDeleted: attributes => ({commands}) => {
        return commands.setMark(this.name, attributes);
      },
      toggleDeleted: attributes => ({commands}) => {
        return commands.toggleMark(this.name, attributes);
      },
      unsetDeleted: () => ({commands}) => {
        return commands.unsetMark(this.name);
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-d': () => this.editor.commands.toggleHighlight(),
    };
  },

  // addInputRules() {
  //   return [
  //     markInputRule({
  //       find: inputRegex,
  //       type: this.type,
  //     }),
  //   ];
  // },

  // addPasteRules() {
  //   return [
  //     markPasteRule({
  //       find: pasteRegex,
  //       type: this.type,
  //     }),
  //   ];
  // },
});
