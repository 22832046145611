import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseBit, BitApiWrapper} from '../bits/bits.models';
import {BitbookApiService} from './bitbook-api.service';

@Injectable()
export class ReaderClipboardService {
  constructor(private bitbookApiService: BitbookApiService) {
  }

  cutToClipboard(bits: Array<{ id: string } | { bitmark: string } | BaseBit>, queryParams?: any): Observable<any> {
    return this.bitbookApiService.cutToClipboard(bits, queryParams);
  }

  copyToClipboard(bits: Array<{ id: string } | { bitmark: string } | BaseBit>, queryParams?: any): Observable<any> {
    return this.bitbookApiService.copyToClipboard(bits, queryParams);
  }

  pasteToBookFromClipboard(bookExternalId: string, afterBitId?: string, queryParams?: any): Observable<{ count: number, results: Array<BitApiWrapper> }> {
    return this.bitbookApiService.pasteFromClipboard(bookExternalId, afterBitId, queryParams);
  }

  getClipboard(queryParams?: any): Observable<Array<BitApiWrapper>> {
    return this.bitbookApiService.getClipboardBits(queryParams);
  }
}
