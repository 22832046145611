class PageStatus {
  details = '';

  constructor(details?: string | any) {
    this.details = details || this.details;
  }

  get message() {
    return this.details.toString();
  }
}

export class PageState {
  private current = 'loading';
  loading: PageStatus;
  error: PageStatus;
  ready: PageStatus;

  constructor(state?: string) {
    this.current = state || 'loading';
  }

  setLoading(message?: string) {
    this.current = 'loading';
    this.loading = new PageStatus(message || 'Loading ...');
  }

  isLoading(): boolean {
    return this.current === 'loading';
  }

  setError(details?: any) {
    this.current = 'error';
    this.error = new PageStatus(details || 'Error occurred when initializing the page.');
  }

  isError(): boolean {
    return this.current === 'error';
  }

  setReady(message?: string) {
    this.current = 'ready';
    this.error = new PageStatus(message || 'Ready');
  }

  isReady(): boolean {
    return this.current === 'ready';
  }
}
