import {Injectable, Injector} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {BrowserStorageService} from '../browser-storage/browser-storage.service';
import {BrowserStorage} from '../browser-storage/browser-storage';
import {IdentityUser, ShopInfo, SpacesConfig} from '../account/account.models';
import {UserPresenceService} from "core";

@Injectable({providedIn: 'root'})
export class IdentityService {

  private defaultSpace = 'personal';

  constructor(private browserStorageService: BrowserStorageService,
              private localStorageService: LocalStorageService,
              private injector: Injector) {
  }

  set user(user: IdentityUser) {
    this.browserStorageService.volatile.store('user', user);
  }

  get user(): IdentityUser {
    return this.browserStorageService.volatile.retrieve('user');
  }

  get spacesConfig(): SpacesConfig {
    return this.browserStorageService.volatile.retrieve('shopData');
  }

  get emails(): Array<string> {
    return [this.user.email?.trim().toLowerCase(), this.user.ssoEmail?.trim().toLowerCase()]
      .filter(x => x);
  }

  // setAvailability(isAvailable: boolean) {
  //   const user = this.user;
  //   user.isAvailable = isAvailable;
  //   this.user = user;
  //   const userPresenceService = this.injector.get(UserPresenceService);
  //   userPresenceService.notifyCurrentAvailabilityStatus();
  // }

  get settings(): BrowserStorage {
    return !this.user
      ? null
      : new BrowserStorage(`settings.${this.user.email}`, this.localStorageService);
  }

  getSpace(): string {
    return this.user?.claims?.loginSpace || this.user?.claims?.spaces[0] || this.defaultSpace;
  }

  getSpaceName(): string {
    return this.spacesConfig[this.user?.claims?.loginSpace || this.user?.claims?.spaces[0] || this.defaultSpace]?.name
        || this.spacesConfig[this.user?.claims?.loginSpace || this.user?.claims?.spaces[0] || this.defaultSpace]?.space?.name
        || this.user?.claims?.loginSpace || this.user?.claims?.spaces[0] || this.defaultSpace;
  }

  getDefaultSpace(): string {
    return this.defaultSpace;
  }

  isForcedSpace(): boolean {
    return this.user?.claims?.isForcedSpace;
  }

  isSsoLogin(): boolean {
    return this.user?.claims?.isSsoLogin;
  }

  loginProvider(): string {
    return this.user?.claims?.loginProvider === 'email'
      ? 'personal'
      : this.user?.claims?.loginProvider;
  }

  getFullSpace(): string {
    return this.user?.claims?.loginProvider === 'email'
      ? 'personal'
      : (this.user as any)?.loginProvider;
  }

  getFullShopsForSpace(space: string): any {
    return this.spacesConfig[space]?.shops || [];
  }

  getUserShopIdsForSpace(): string[] {
    const space = this.getSpace();
    return this.user?.claims?.shops?.map((s) => {
      if (s.split('.').length < 2 || s.split('.')[0] !== space) {
        return null;
      }
      return s.split('.')[1];
    }).filter((i) => i);
  }

  getShopsIdsForSpace(space: string): string[] {
    return Object.keys(this.spacesConfig[space]?.shops) || [];
  }

  getSelectedShop(assortmentId: string): ShopInfo {
    const space = this.getSpace();
    const shops = this.spacesConfig && this.spacesConfig[space]?.shops;
    return shops
      ? Object.keys(shops).reduce((prev: any, curr: string) => {
        if (curr === assortmentId) {
          return shops[curr];
        }
        return prev;
      }, {})
      : null;
  }

  canAny(claims: Array<object>): boolean {
    if (!claims?.length) {
      return true;
    }
    if (!this.user || !this.user.claims) {
      return false;
    }
    for (let i = 0; i < claims.length; i++) {
      const claim = claims[i];
      const keys = Object.keys(claim);
      for (let k = 0; k < keys.length; k++) {
        const claimName = keys[k];
        if (this.user.claims[claimName] === claim[claimName]) {
          return true;
        }
      }
    }
    return false;
  }

  canAll(claims: Array<object>): boolean {
    if (!claims) {
      return true;
    }
    if (!this.user || !this.user.claims) {
      return false;
    }
    for (let i = 0; i < claims.length; i++) {
      const claim = claims[i];
      const keys = Object.keys(claim);
      for (let k = 0; k < keys.length; k++) {
        const claimName = keys[k];
        if (this.user.claims[claimName] !== claim[claimName]) {
          return false;
        }
      }
    }
    return true;
  }
}
