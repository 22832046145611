import {Injectable} from "@angular/core";
import {BaseBit, BitApiWrapper, BitType} from "./bits.models";
import {
  MatchSolutionGroupedComponent,
  InterviewComponent,
  TrueFalseComponent, TrueFalse1Component,
  EssayComponent,
  MultipleChoiceComponent, MultipleChoice1Component,
  ClozeComponent, MultipleResponseComponent, MultipleResponse1Component,
  MatchMatrixComponent
} from "../../public-api";

export interface BitEvalResult {
  totalAnswers?: number;
  correctAnswers?: number;
  incorrectAnswers?: number;
  answeredAnswers?: number;
  notAnsweredAnswers?: number;
  analyticsTags?: string[];
}

@Injectable({providedIn: 'root'})
export class BitEvalService {
  private evalFns = new Map<BitType, (x: BaseBit) => BitEvalResult>([
    [BitType.MatchSolutionGrouped, MatchSolutionGroupedComponent.evalAnswer],
    [BitType.Interview, InterviewComponent.evalAnswer],
    [BitType.TrueFalse, TrueFalseComponent.evalAnswer],
    [BitType.TrueFalse1, TrueFalse1Component.evalAnswer],
    [BitType.Essay, EssayComponent.evalAnswer],
    [BitType.MultipleChoice, MultipleChoiceComponent.evalAnswer],
    [BitType.MultipleChoice1, MultipleChoice1Component.evalAnswer],
    [BitType.ClozeSolutionGrouped, ClozeComponent.evalAnswer],
    [BitType.Cloze, ClozeComponent.evalAnswer],
    [BitType.MultipleChoiceText, ClozeComponent.evalAnswer],
    [BitType.ClozeAndMultipleChoiceText, ClozeComponent.evalAnswer],
    [BitType.ClozeInstructionGrouped, ClozeComponent.evalAnswer],
    [BitType.MultipleResponse, MultipleResponseComponent.evalAnswer],
    [BitType.MultipleResponse1, MultipleResponse1Component.evalAnswer],
    [BitType.MatchMatrix, MatchMatrixComponent.evalAnswer],
  ]);

  evalAnswer(bitWrapper: BitApiWrapper): BitEvalResult {
    // console.log('evalAnswer', bitWrapper.bit);
    const fn = this.evalFns.get(bitWrapper?.bit?.type);
    return fn
      ? fn.apply(null, [bitWrapper.bit])
      : null;
  }
}
