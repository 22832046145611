import {Component, Input} from '@angular/core';
import {BitmarkFormat} from '../../shared/models';
import {TipTapEditorType} from '../../reader/tiptap/reader-tiptap.service';

@Component({
  selector: 'bitmark-text',
  templateUrl: './bitmark-text.component.html',
  styleUrls: ['./bitmark-text.component.scss']
})
export class BitmarkTextComponent {
  @Input() text?: string;
  @Input() format?: BitmarkFormat;
  @Input() editorType?: TipTapEditorType;
  @Input() cssClass?: string;
  @Input() truncate = false;
  @Input() language = '';
}
