import {Strike} from '@tiptap/extension-strike';
import {mergeAttributes} from '@tiptap/core';

export const UserStrike = Strike.extend({
  name: 'userStrike',
  renderHTML({HTMLAttributes}) {
    const customAttributes = {
      class: 'bitmark-text-user-strike'
    };
    return ['s', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
  },
});
