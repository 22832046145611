import {Component, Input} from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import {HighchartsChartBit} from './highcharts-chart.models';

HC_more(Highcharts);

@Component({
  selector: 'bitmark-highcharts-chart',
  templateUrl: './highcharts-chart.component.html',
  styleUrls: ['./highcharts-chart.component.scss']
})
export class HighchartsChartComponent {
  @Input() bit?: HighchartsChartBit;

  Highcharts: typeof Highcharts = Highcharts;
}
