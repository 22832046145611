// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resizable-draggable {
  outline: 1px dashed green;
}
.resizable-draggable.active {
  outline-style: solid;
  background-color: rgba(128, 255, 128, 0.0509803922);
}
.resizable-draggable:hover {
  cursor: all-scroll;
}
.resizable-draggable span:first-of-type {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, -100%, 0);
}
.resizable-draggable span:nth-of-type(2) {
  position: absolute;
  top: 50%;
  transform: translate3d(-100%, -50%, 0);
}
.resizable-draggable span:nth-of-type(3) {
  position: absolute;
  transform: translate3d(-100%, -100%, 0);
}

.resize-action {
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent transparent #008000;
}
.resize-action:hover, .resize-action:active {
  cursor: nwse-resize;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
