import {Component, Inject, Input} from '@angular/core';
import {BitmarkConfig} from '../../bitmark.module';

@Component({
  selector: 'bitmark-icon',
  styleUrls: ['./icon.component.scss'],
  template: '<svg width="20" height="20" class="d-block w-100 h-100" fill="currentColor"><use attr.href="assets/svg/icon-sprites.svg?v={{bitmarkConfig.environmentVersion}}#{{name}}"></use></svg>'
})
export class IconComponent {
  @Input() name: string;

  constructor(@Inject('BitmarkConfig') public bitmarkConfig: BitmarkConfig) {}
}
