import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {BrowserStorageService} from '../../../shared/browser-storage/browser-storage.service';

@Injectable({providedIn: 'root'})
export class ReaderPdfLocalJobsService {
  private JOBS_KEY = 'notebook-local-jobs';

  constructor(private browserStorageService: BrowserStorageService) {
  }

  getRunningJobs() {
    return this.browserStorageService.idb.get(this.JOBS_KEY);
  }

  addJob(job: { jobId: string, fileName: string, status: number, coverSrc?: SafeResourceUrl }) {
    return new Observable(x => {
      this.browserStorageService.idb.get(this.JOBS_KEY)
        .subscribe(runningJobs => {
          runningJobs = runningJobs || [];
          runningJobs.push(job);
          this.browserStorageService.idb.store(this.JOBS_KEY, runningJobs).subscribe();
          x.next();
          x.complete();
        });
    });
  }

  updateJob(jobId: string, status: number): Observable<any> {
    return new Observable(x => {
      this.browserStorageService.idb.get(this.JOBS_KEY)
        .subscribe(runningJobs => {
          runningJobs = runningJobs || [];
          const job = runningJobs.find((j) => j.jobId === job.jobId);
          job.status = status;
          this.browserStorageService.idb.store(this.JOBS_KEY, runningJobs).subscribe();
          x.next();
          x.complete();
        });
    });
  }

  removeJob(jobId: string): Observable<any> {
    return new Observable(x => {
      this.browserStorageService.idb.get(this.JOBS_KEY)
        .subscribe(runningJobs => {
          runningJobs = runningJobs || [];
          runningJobs = runningJobs.filter((j) => j.jobId !== jobId);
          this.browserStorageService.idb.store(this.JOBS_KEY, runningJobs).subscribe();
          x.next();
          x.complete();
        });
    });
  }

  clearJobs(): Observable<any> {
    return this.browserStorageService.idb.clear(this.JOBS_KEY);
  }
}
