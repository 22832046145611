import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {IdentityService} from '../auth/identity.service';

export enum SocketMessageType {
  UserHeartbeat = 'sck.user-heartbeat',
  UserAvailability = 'sck.user-availability',
  UserIsTyping = 'sck.user-is-typing',
  UserFinishedTyping = 'sck.user-finished-typing',
  TimelineEntryCreated = 'sck.timeline-entry-created',
  TimelineEntryDeleted = 'sck.timeline-entry-deleted',
  TimelineEntryRead = 'sck.timeline-entry-read',
  TimelineEntryUpdated = 'sck.timeline-entry-updated',
  AccountProfileUpdated = 'sck.account-profile-updated',
  TimelineGroupCreated = 'sck.timeline-group-created',
  TimelineConnectionRequested = 'sck.timeline-connection-requested',
  TimelineConnectionRevoked = 'sck.timeline-connection-revoked',
  TimelineConnectionAccepted = 'sck.timeline-connection-accepted',
  TimelineConnectionRejected = 'sck.timeline-connection-rejected'
}

export class SocketEvent {
  messageType: SocketMessageType;
  senderUserId?: number;
  peerUserId?: number;
  payload?: any | { conRequestId?: number };
}

@Injectable({providedIn: 'root'})
export class SocketIoService {
  constructor(private socket: Socket,
              private identityService: IdentityService) {
    socket.ioSocket.on('connect', () => {
      console.log(`Socket.connected. Connected: ${this.isConnected()}`);
    });
    socket.ioSocket.on('disconnect', () => {
      console.log(`Socket.disconnected: Connected: ${this.isConnected()}`);
    });
    socket.ioSocket.on('reconnecting', () => {
      console.log(`Socket.reconnecting: Connected: ${this.isConnected()}`);
    });
    socket.ioSocket.on('reconnect', () => {
      console.log(`Socket.reconnect: Connected: ${this.isConnected()}`);
    });
  }

  isConnected() {
    return this.socket.ioSocket.connected;
  }

  emit(event: string, msg: SocketEvent) {
    this.socket.emit(event, Object.assign(msg, {senderUserId: this.identityService.user?.id}));
  }

  event(event: string): Observable<SocketEvent> {
    return this.socket.fromEvent(event)
      .pipe(
        filter((e: SocketEvent) => e.peerUserId === this.identityService.user?.id || e.peerUserId === 0)
      );
  }
}
