import {Component, Input} from '@angular/core';

@Component({
  selector: 'bitmark-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent {
  @Input() isSearchResult = false;
  @Input() isBookDetails = false;
  @Input() catalogPrice: number;
  @Input() simulatedPrice: number;
}
