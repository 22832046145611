import {AfterViewInit, Component} from '@angular/core';
import {AngularNodeViewComponent} from 'ngx-tiptap';

@Component({
  selector: 'bitmark-nodeview-gap',
  templateUrl: './gap.component.html',
  styleUrls: ['../../../../bits/cloze/gap-placeholder/gap-placeholder.component.scss']
})
export class NodeviewGapComponent extends AngularNodeViewComponent implements AfterViewInit {
  defaultText = '';

  ngAfterViewInit() {
    this.defaultText = this.node.attrs.text;
  }

  onChange(event: Event): void {
    this.updateAttributes({
    });
  }
}
