import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CodeBit} from './code.models';

@Component({
  selector: 'bitmark-code',
  templateUrl: './code.component.html',
  styleUrls: ['../bits.scss'],
})
export class CodeComponent {
  @Input() bit?: CodeBit;
  @Input() canBeEdited = false;
  @Output() saveInlineBit = new EventEmitter<CodeBit>();

  constructor() {
  }

  saveChanges() {
    this.saveInlineBit.emit(this.bit);
  }

  onUpdateContent() {
    this.saveChanges();
  }
}
