import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiRouterService} from '../shared/api/api-router.service';

@Injectable()
export class ParserApiService {
  // private PARSER_URL = 'https://parser-test.bitmark.cloud/parser';

  constructor(private apiRouterService: ApiRouterService) {
  }

  parseBitmark(bitmark: string) {
    return this.buildRequest(bitmark, 'POST');
  }

  private buildRequest(formData, method) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'text/plain');
    myHeaders.append('Authorization', window.localStorage.getItem('gmb-cosmic_token'));

    const requestOptions: RequestInit = {
      method: method,
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    const parserRouteUrl = this.apiRouterService.computeRouteById('client/parser', null, null);

    return new Observable(observer => {
      fetch(parserRouteUrl, requestOptions)
        .then(res => {
          return res.json();
        })
        .then(body => {
          // observer.next([{
          //   bit: {
          //     "id": 33393,
          //     "body": "",
          //     "type": "article",
          //     "format": "bitmark--",
          //     "resource": {
          //       "type": "image",
          //       "image": {
          //         "alt": "",
          //         "src": "https://docs.bitmark.cloud/bit-books/swiss_life_select/gesundheitsvorsorge_fr/web-resources/tables/02_tableau.png",
          //         "width": 300,
          //         "format": "png",
          //         "height": 65,
          //         "caption": "Tableau 2: Aperçu des médicaments",
          //         "license": "",
          //         "copyright": "",
          //         "showInIndex": false,
          //         "widthNative": 1679,
          //         "heightNative": 361
          //       }
          //     }
          //   }
          // }]);
          observer.next(body);
          observer.complete();
        })
        .catch(err => observer.error(err));
    });
  }
}
