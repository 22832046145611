import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ObjectService {
  findLastIndexOf(items: Array<any>, predicate: (x: any) => boolean) {
    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i];

      if (predicate(item)) {
        return i;
      }
    }
  }

  mergeObjects(target: object, source: object) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key];
      const targetValue = target[key];
      target[key] = this.mergeValues(targetValue, sourceValue);
    });

    return target;
  }

  isObject(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  isArray(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }

  private mergeValues(target: any, source: any) {
    if (this.isObject(target) && this.isObject(source)) {
      return this.mergeObjects(target, source);
    }
    if (source === undefined) {
      return target;
    }
    return source;
  }

  hasNonNullValue(obj: any, parent: string, kys: Array<string>, keyChecked: string = null): boolean {
    if (!obj || !kys?.length || !this.isObject(obj)) {
      return false;
    }
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      const val = obj[k];
      const isKeyMatchingParent = keyChecked === parent
        || (parent === '{' && keyChecked?.startsWith('{'));
      if (val !== undefined && val !== null && isKeyMatchingParent && kys.includes(k)) {
        return true;
      }
      if (this.isArray(val)) {
        for (const v of val) {
          if (this.hasNonNullValue(v, parent, kys, k)) {
            return true;
          }
        }
      }
      if (this.isObject(val)) {
        if (this.hasNonNullValue(val, parent, kys, k)) {
          return true;
        }
      }
    }
    return false;
  }

  getHashOfString(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
  }
}
