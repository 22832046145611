import {Injector} from '@angular/core';
import {Node} from '@tiptap/core';
import {AngularNodeViewRenderer} from 'ngx-tiptap';

import {NodeviewMathComponent} from './math.component';

const MathComponentExtension = (injector: Injector): Node => {
  return Node.create({
    name: 'mathComponent',
    group: 'inline',
    inline: true,
    selectable: true,
    atom: false,
    addAttributes() {
      return {
        formula: {
          default: '',
        },
      };
    },
    parseHTML() {
      return [{tag: 'angular-component-math'}];
    },
    renderHTML({HTMLAttributes}) {
      // return ['bitmark-nodeview-math', mergeAttributes(HTMLAttributes)];
      // return AngularNodeViewRenderer(NodeviewMathComponent, {injector});
      // <div class="d-inline-block">
      //   <math-field
      return ['math-field', {class: 'math-field', 'read-only': 'true'}, HTMLAttributes.formula];
    },
    addNodeView() {
      return AngularNodeViewRenderer(NodeviewMathComponent, {injector});
    },
  });
};

export default MathComponentExtension;
