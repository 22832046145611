import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {cloneDeep} from 'lodash';
import {ImagesLogoGraveBit} from './images-logo-grave.models';
import {BitResource, BitType} from '../bits.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';

@Component({
  selector: 'bitmark-images-logo-grave',
  templateUrl: './images-logo-grave.component.html',
  styleUrls: ['./images-logo-grave.component.scss']
})
export class ImagesLogoGraveComponent implements OnInit {
  @Input() bit?: ImagesLogoGraveBit;
  @Input() isBeingEditedByUser: boolean;
  @Input() canBeEdited = false;
  @Output() cancelBitChanges = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<ImagesLogoGraveBit>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();

  protected readonly BitType = BitType;

  imagesLogoGrave: Array<BitResource> = [];
  imageLogos: Array<BitResource> = [];
  imageCaptions: Array<string> = [];

  ngOnInit() {
    if (!this.bit?.logos?.length) {
      return;
    }
    this.imagesLogoGrave = cloneDeep(this.bit?.logos);
    this.imagesLogoGrave.forEach(logo => {
      this.imageCaptions.push(logo.image.caption);

      const newLogo = {...logo};
      newLogo.image.zoomDisabled = true;
      newLogo.image.caption = '';
      this.imageLogos.push(newLogo);
    });
  }
}
