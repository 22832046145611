import {Extension} from '@tiptap/core';
import {Plugin, PluginKey} from 'prosemirror-state';
import {Slice} from 'prosemirror-model';
import {DOMParser as ProseMirrorDOMParser} from 'prosemirror-model';

const uploadImage = async (baseUrl: string, dataURI: string, bitId: string): Promise<{ url: string, width: number, height: number }> => {
  const mime = dataURI.split(',')[0].match(/:(.*?);/)[1];
  const byteString = atob(dataURI.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], {type: mime});
  const file = new File([blob], 'uploaded-image', {type: mime});
  const formData = new FormData();
  formData.append('file', file, file.name);

  const url = `${baseUrl}/bits/resource`;
  const newUrlResponse: any = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('gmb-cosmic_token'),
      'x-bit-id': bitId
    }
  });
  if (!newUrlResponse.ok) {
    return null;
  }
  const newUrl = await newUrlResponse.json();
  return {
    url: newUrl.url,
    width: newUrl.width,
    height: newUrl.height
  };
};

const pastePluginKey = new PluginKey('handlePastePlugin');

const HandlePaste = Extension.create({
  name: 'handlePaste',

  addOptions() {
    return {
      HTMLAttributes: {
        bitId: 'color: #808080'
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: pastePluginKey,
        props: {
          handlePaste: (view, event, slice) => {
            const clipboardData = event.clipboardData;
            const pastedHtml = clipboardData.getData('text/html');

            const hasFiles = !!event.clipboardData?.files?.length;

            if (pastedHtml) {
              const doc = new DOMParser().parseFromString(pastedHtml, 'text/html');

              // Find all base64 images
              const images = Array.from(doc.querySelectorAll('img[src^="data:image/"]'));

              if (images.length) {
                setTimeout(async () => {
                  for (const img of images) {
                    const dataURI = (img as any).src;

                    const uploadedImageData = await uploadImage(this.options.bitbookClientApiUrl, dataURI, this.options.bitId);

                    img.setAttribute('src', uploadedImageData?.url);
                    if (uploadedImageData?.width) {
                      img.setAttribute('width', `${uploadedImageData?.width}`);
                    }
                    if (uploadedImageData?.height) {
                      img.setAttribute('height', `${uploadedImageData?.height}`);
                    }
                  }

                  // Convert the updated DOM to ProseMirror nodes
                  const pmDoc = ProseMirrorDOMParser.fromSchema(view.state.schema).parse(doc.body);
                  const fragment = pmDoc.content;

                  const newSlice = new Slice(fragment, slice.openStart, slice.openEnd);

                  const transaction = view.state.tr.replaceSelection(newSlice);
                  view.dispatch(transaction);
                }, 0);
                // Prevent default paste behavior
                return true;
              }
            } else if (hasFiles) { // https://github.com/ueberdosis/tiptap/issues/2912#issuecomment-1169631614
              const images = Array.from(
                event.clipboardData.files
              ).filter(file => /image/i.test(file.type));

              if (images.length === 0) {
                return true;
              }

              event.preventDefault();

              const {schema} = view.state;

              setTimeout(async () => {
                images.forEach(image => {
                  const reader = new FileReader();

                  reader.onload = async (readerEvent) => {
                    const uploadedImageData = await uploadImage(this.options.bitbookClientApiUrl, readerEvent.target.result as string, this.options.bitId);

                    const node = schema.nodes.image.create({
                      src: uploadedImageData.url,
                      width: uploadedImageData.width,
                      height: uploadedImageData.height
                    });
                    const transaction = view.state.tr.replaceSelectionWith(node);
                    view.dispatch(transaction);
                  };
                  reader.readAsDataURL(image);
                });
              });

              // Prevent default paste behavior
              return true;
            } else {
              // Let Tiptap continue with the default paste behavior
              return false;
            }
          },
          handleDOMEvents: {
            drop: (view, event) => { // https://github.com/ueberdosis/tiptap/issues/2912#issuecomment-1169631614
              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length;

              if (!hasFiles) {
                return;
              }

              const images = Array.from(event.dataTransfer.files).filter(file =>
                /image/i.test(file.type)
              );

              if (images.length === 0) {
                return;
              }

              event.preventDefault();

              const {schema} = view.state;
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY
              });

              setTimeout(async () => {
                images.forEach(image => {
                  const reader = new FileReader();

                  reader.onload = async (readerEvent) => {
                    const uploadedImageData = await uploadImage(this.options.bitbookClientApiUrl, readerEvent.target.result as string, this.options.bitId);

                    const node = schema.nodes.image.create({
                      src: uploadedImageData.url,
                      width: uploadedImageData.width,
                      height: uploadedImageData.height
                    });
                    const transaction = view.state.tr.insert(
                      coordinates.pos,
                      node
                    );
                    view.dispatch(transaction);
                  };
                  reader.readAsDataURL(image);
                });
              });
              return true;
            },
          }
        }
      })
    ];
  }
});

export default HandlePaste;
