import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EvtUtilsService {
  isEventType(e, eventType) {
    if (!e) {
      return false;
    }
    if (e.eventType && e.eventType.toString().toLowerCase() === eventType.toString().toLowerCase()) {
      return true;
    }
    if (e.e) {
      return this.isEventType(e.e, eventType);
    }
    if (e.originalEvent) {
      return this.isEventType(e.originalEvent, eventType);
    }
    return e.toString() === '[object ' + eventType + 'Event]';
  }

  isKeyboardEvt(e) {
    return this.isEventType(e, 'Keyboard');
  }

  isMouseEvt(e) {
    return this.isEventType(e, 'Mouse');
  }

  isClipboardEvt(e) {
    return this.isEventType(e, 'Clipboard');
  }

  cancelEvt(e) {
    if (!e) {
      return;
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopImmediatePropagation) {
      e.stopImmediatePropagation();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    e.cancelBubble = true;
    e.returnValue = false;
  }
}
