import {Injectable, RendererFactory2} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DomUtilsService} from '../dom/dom-utils.service';
import {ScrollAntiBlock} from './scroll-anti-block';

@Injectable({providedIn: 'root'})
export class ModalScrollAntiBlockService {
  constructor(private rendererFactory: RendererFactory2,
              private deviceDetectorService: DeviceDetectorService,
              private domUtils: DomUtilsService) {
  }

  applyAntiBlock(modalRef: NgbModalRef, scrollOffset = 3, debounceTime = 50, stopPropagation = false) {
    const ngbModalWindowNativeElement = (modalRef as any)._windowCmptRef.instance._elRef.nativeElement;
    const renderer = this.rendererFactory.createRenderer(null, null);
    const scrollAntiBlock = new ScrollAntiBlock(ngbModalWindowNativeElement, scrollOffset, debounceTime, stopPropagation, renderer, this.deviceDetectorService, this.domUtils);
    scrollAntiBlock.apply();
    modalRef.result.finally(() => {
      scrollAntiBlock.destroy();
    });
  }
}
