import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Editor} from "@tiptap/core";

@Component({
  selector: 'bitmark-contextual-menu-quote',
  templateUrl: './contextual-menu-quote.component.html'
})
export class ContextualMenuQuoteComponent {
  @Input() editor: Editor | any;
  @Output() onDeleteNode: EventEmitter<string> = new EventEmitter<any>();

  deleteNode(nodeType: string) {
    this.onDeleteNode.emit(nodeType);
  }
}
