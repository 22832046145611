import {
  ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle
} from '@angular/router';

export class CustomReuseStrategy extends BaseRouteReuseStrategy {
  // routesToCache: string[] = ["dashboard"];
  // storedRouteHandles = new Map<string, DetachedRouteHandle>();

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future?.data?.alwaysRefresh) {
      return false;
    }
    // if (!!future?.data?.reuseRouteKey) {
    //   return false;
    // }
    return future.routeConfig === curr.routeConfig;
  }

  /**
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data?.reuseRouteKey;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (!handle) {
      return;
    }
    const key = this.getKey(route);
    this.storedRouteHandles.set(key, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = this.getKey(route);
    return this.storedRouteHandles.has(key);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const key = this.getKey(route);
    return this.storedRouteHandles.get(key);
  }

  private getKey(route: ActivatedRouteSnapshot): string {
    return route.data.reuseRouteKey;
    // return route.pathFromRoot
    //   .map((snapshot: ActivatedRouteSnapshot) => snapshot.routeConfig ? snapshot.routeConfig.path : '')
    //   .filter((path: string) => path.length > 0)
    //   .join('');
  }
    **/
}
