const ColorGroup1: string[] = [
  '#2e3700',
  '#4b5a00',
  '#697d00',
  '#86a100',
  '#a4c400',
  '#b4ce2e',
  '#c5d95c',
  '#d5e48a',
  '#e6efb8',
];

const ColorGroup2: string[] = [
  '#1b2f06',
  '#2c4e0b',
  '#3d6c0f',
  '#4f8b13',
  '#60a917',
  '#7cb840',
  '#99c86a',
  '#b6d794',
  '#d3e7be',
];

const ColorGroup3: string[] = [
  '#002700',
  '#003f00',
  '#005800',
  '#007100',
  '#008a00',
  '#2e9f2e',
  '#5cb45c',
  '#8ac98a',
  '#b8deb8',
];

const ColorGroup4: string[] = [
  '#00302f',
  '#004f4e',
  '#006d6c',
  '#008c8b',
  '#00aba9',
  '#2ebab8',
  '#5cc9c8',
  '#8ad8d7',
  '#b8e8e7',
];

const ColorGroup5: string[] = [
  '#082d3f',
  '#0c4a68',
  '#116791',
  '#1684b9',
  '#1ba1e2',
  '#44b2e7',
  '#6dc3ec',
  '#96d4f2',
  '#bfe5f7',
];

const ColorGroup6: string[] = [
  '#001643',
  '#00256e',
  '#003399',
  '#0042c4',
  '#0050ef',
  '#2e6ff2',
  '#5c8ff5',
  '#8aaff8',
  '#b8cefb',
];

const ColorGroup7: string[] = [
  '#1e0047',
  '#310075',
  '#4400a3',
  '#5700d1',
  '#6a00ff',
  '#852eff',
  '#a05cff',
  '#bb8aff',
  '#d6b8ff',
];

const ColorGroup8: string[] = [
  '#300047',
  '#4e0075',
  '#6d00a3',
  '#8b00d1',
  '#aa00ff',
  '#b92eff',
  '#c85cff',
  '#d88aff',
  '#e7b8ff',
];

const ColorGroup9: string[] = [
  '#44203a',
  '#703460',
  '#9c4985',
  '#c85dab',
  '#f472d0',
  '#f68bd8',
  '#f8a5e1',
  '#fabee9',
  '#fcd8f2',
];

const ColorGroup10: string[] = [
  '#3c0020',
  '#630035',
  '#8a004a',
  '#b1005e',
  '#d80073',
  '#df2e8c',
  '#e65ca5',
  '#ed8abf',
  '#f4b8d8',
];

const ColorGroup11: string[] = [
  '#2d000a',
  '#4b0011',
  '#680018',
  '#85001e',
  '#a20025',
  '#b22e4c',
  '#c35c73',
  '#d48a9b',
  '#e5b8c2',
];

const ColorGroup12: string[] = [
  '#400600',
  '#690900',
  '#930d00',
  '#bc1000',
  '#e51400',
  '#e93e2e',
  '#ee685c',
  '#f3938a',
  '#f8bdb8',
];

const ColorGroup13: string[] = [
  '#461d00',
  '#733000',
  '#a04300',
  '#cd5500',
  '#fa6800',
  '#fb832e',
  '#fc9e5c',
  '#fdba8a',
  '#fed5b8',
];

const ColorGroup14: string[] = [
  '#432e03',
  '#6e4b05',
  '#9a6806',
  '#c58608',
  '#f0a30a',
  '#f2b336',
  '#f5c462',
  '#f8d58e',
  '#fbe5bb',
];

const ColorGroup15: string[] = [
  '#3c3600',
  '#635900',
  '#8a7c00',
  '#b19e00',
  '#d8c100',
  '#dfcc2e',
  '#e6d75c',
  '#ede38a',
  '#f4eeb8',
];

const ColorGroup16: string[] = [
  '#3c2914',
  '#533a1c',
  '#6b4a24',
  '#825a2c',
  '#987752',
  '#af9578',
  '#c6b39e',
  '#dcd1c4',
];

const ColorsSet: any = {
  1: ColorGroup1,
  2: ColorGroup2,
  3: ColorGroup3,
  4: ColorGroup4,
  5: ColorGroup5,
  6: ColorGroup6,
  7: ColorGroup7,
  8: ColorGroup8,
  9: ColorGroup9,
  10: ColorGroup10,
  11: ColorGroup11,
  12: ColorGroup12,
  13: ColorGroup13,
  14: ColorGroup14,
  15: ColorGroup15,
  16: ColorGroup16
};

export const NotebookCoverColors: string[] = [
  ...ColorGroup1,
  ...ColorGroup2,
  ...ColorGroup3,
  ...ColorGroup4,
  ...ColorGroup5,
  ...ColorGroup6,
  ...ColorGroup7,
  ...ColorGroup8,
  ...ColorGroup9,
  ...ColorGroup10,
  ...ColorGroup11,
  ...ColorGroup12,
  ...ColorGroup13,
  ...ColorGroup14,
  ...ColorGroup15,
  ...ColorGroup16
];

export const getComplimentaryColor = (color: string): string => {
  const complimentaryColors = [];

  for (const prop in ColorsSet) {
    if (ColorsSet.hasOwnProperty(prop)) {
      const colorsArr = ColorsSet[prop];

      if (!colorsArr.includes(color)) {
        complimentaryColors.push(...colorsArr);
      }
    }
  }

  return complimentaryColors[Math.floor(Math.random() * complimentaryColors.length)];
};
