import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
  fromModel(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  toModel(date: NgbDateStruct): string {
    return date ? new Date(date.year, date.month - 1, date.day).toISOString() : null;
  }
}
