import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DropdownItemModel} from '../dropdown/dropdown.model';

@Component({
    selector: 'app-dropdown-mobile',
    templateUrl: './dropdown-mobile.component.html',
    styleUrls: ['../dropdown.scss', './dropdown-mobile.component.scss'],
})
export class DropdownMobileComponent implements AfterViewInit {
    @Input() actions: Array<DropdownItemModel> = [];
    @Input() additionalContent: TemplateRef<any> = null;
    @Input() hasRawLabels = false;
    @Output() canceled = new EventEmitter<any>();
    window: any = window;
    @ViewChild('wrapper') wrapper: ElementRef;

    constructor(private deviceDetectorService: DeviceDetectorService,
                private renderer: Renderer2) {
    }

    ngAfterViewInit() {
        this.renderer.setStyle(this.wrapper.nativeElement, 'bottom', `${this.window.innerHeight - this.window.visualViewport.height}px`);
        this.renderer.setStyle(this.wrapper.nativeElement, 'height', 'auto');
        if (!this.deviceDetectorService.isDesktop()) {
            this.window.visualViewport.addEventListener('resize', () => {
                setTimeout(() => {
                    this.renderer.setStyle(this.wrapper.nativeElement, 'bottom', `${this.window.innerHeight - this.window.visualViewport.height}px`);
                    this.renderer.setStyle(this.wrapper.nativeElement, 'height', 'auto');
                }, 100);
            });
        }
    }

    choose(action: DropdownItemModel) {
        if (action && action.handler) {
            action.handler.apply(null, [action]);
        }

        if (action && action.keepOpenAfterClick) {
            return;
        }

        this.close();
    }

    close() {
        this.canceled.emit(true);
    }
}
