// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  display: inline;
}

.auth {
  min-height: 100%;
  width: 100%;
}
.auth a {
  display: inline;
}
.auth .logo {
  flex: 1 1 30%;
}
.auth .logo img {
  width: 185px;
  height: 35px;
}
.auth .form {
  flex: 1 1 60%;
  max-width: 350px;
}
.auth .form label {
  color: white;
  text-align: left;
  line-height: 1.5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
