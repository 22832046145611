import {Component, Input} from '@angular/core';
import {PagePersonBit} from './page-person.models';

@Component({
  selector: 'bitmark-page-person',
  templateUrl: './page-person.component.html',
  styleUrls: ['../bits.scss', './page-person.component.scss']
})
export class PagePersonComponent {
  @Input() bit?: PagePersonBit;
}
