import {Injectable} from '@angular/core';
import {map, filter} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MqService {
  private subject = new Subject<any>();

  public pub(queue: string, payload: any = null): void {
    return this.subject.next({queue, payload});
  }

  public sub(queue: string): Observable<any> {
    return this.subject.asObservable().pipe(
      filter(m => {
        return m.queue === queue;
      }),
      map(m => {
        return m.payload;
      }));
  }
}
