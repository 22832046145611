export interface PkceResponse {
  codeVerifier: string;
  codeChallenge: string;
}

export class PkceHelper {
  private static createRandomString(length: number) {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const randomArray = new Uint8Array(length);
    crypto.getRandomValues(randomArray);
    let result = "";
    randomArray.forEach((number) => result += chars[number % chars.length]);
    return result;
  }

  private static async sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return crypto.subtle.digest("SHA-256", data);
  }

  private static base64urlencode(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let base64 = "";
    bytes.forEach((byte) => base64 += String.fromCharCode(byte));
    return btoa(base64)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  public static async preparePkce(): Promise<PkceResponse> {
    const codeVerifier = PkceHelper.createRandomString(128);
    const hashed = await PkceHelper.sha256(codeVerifier);
    const codeChallenge = PkceHelper.base64urlencode(hashed);
    return {codeVerifier, codeChallenge};
  }
}
