import {Mark, mergeAttributes} from '@tiptap/core';
import {MarkedOptions} from '../marked/marked.node';
import {EventEmitter as EE} from '../../../../shared';

declare const window: any;

window.bitmarkSpace = Object.assign({}, window.bitmarkSpace || {}, {
  crossRefLinkClicked: function () {
    const linkElem = arguments[0];

    EE.default.emit('crossRefLinkClicked', {bookId: linkElem.getAttribute('xref'), reference: linkElem.getAttribute('reference')});
  }
});


export const CrossRefNode = Mark.create<MarkedOptions>({
  name: 'xref',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      xref: {
        default: null,
      },
      reference: {
        default: null
      },
      onclick: {
        default: undefined,
        parseHTML: () => '',
        renderHTML: () => {
          return {onclick: 'bitmarkSpace.crossRefLinkClicked(this)'};
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a[class="bitmark-text-x-ref"]'
      },
      {
        tag: 'a[xref]'
      }
    ];
  },

  renderHTML({HTMLAttributes}) {
    const customAttributes = {
      class: 'bitmark-text-x-ref'
    };
    return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
  }
});
