import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {FloatingMenuDirective} from 'ngx-tiptap';
import {Editor, JSONContent} from '@tiptap/core';
import {ProsemirrorBit} from '../../../bits/prosemirror/prosemirror.models';
import {BitbookApiService} from '../../bitbook-api.service';
import {BitApiWrapper, BitType} from '../../../bits/bits.models';
import {ReaderTipTapTapService, TipTapEditorType} from '../../tiptap/reader-tiptap.service';
import {SubSink} from '../../../shared';

@Component({
  selector: 'bitmark-reader-text-editor',
  templateUrl: './reader-text-editor.component.html',
  styleUrls: ['./reader-text-editor.component.scss'],
  viewProviders: [FloatingMenuDirective],
})
export class ReaderTextEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  private updates = new Subject();
  @ViewChild('readerTextEditor') textEditorRef: ElementRef;
  @Input() isReadOnly = true;
  @Input() disableKeyboardInput = false;
  @Input() bitContent: any;
  @Input() bit: ProsemirrorBit;
  @Input() bitWrapper: BitApiWrapper;
  @Input() placeholder?: string;
  @Input() updateContentEvent?: Observable<any>;
  @Output() save = new EventEmitter<any>();
  @Output() debounceUpdate = new EventEmitter();
  @Output() updateBit = new EventEmitter<JSONContent>();
  @Output() created = new EventEmitter<Editor>();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileReplaceInput') fileReplaceInput: ElementRef;
  editor: Editor = {} as any;
  bitmark = '';
  value = ``;
  isRendered = false;
  isSpellcheckOn = false;
  BitType = BitType;

  private isUpdateFromOutside = false;
  private subSink = new SubSink();

  @HostListener('document:mousedown', ['$event'])
  mouseout(event) {
    if (typeof event?.target?.className !== 'string') {
      return false;
    }
    if ((this.editor.view as any).docView.dom.parentElement.parentElement.contains(event.target)
      || event.target?.className?.indexOf('dropdown-item-button') !== -1
      || event.target?.parentElement?.className?.indexOf('dropdown-item-button') !== -1
      || event.target?.className?.indexOf('tiptap-bubble-item') !== -1
      || event.target?.className?.indexOf('scrollable') !== -1
      || event.target?.parentElement?.className?.indexOf('tiptap-bubble-item') !== -1) {
      // if the click is outside the editor
      if (event.target?.className?.length && event.target?.className?.indexOf('contextual-menu-trigger') === -1 &&
        event.target?.className?.indexOf('tiptap-bubble-item') === -1 &&
        event.target?.className?.indexOf('dropdown-item-button') === -1 &&
        event.target?.parentElement?.className?.indexOf('dropdown-item-button') === -1 &&
        event.target?.parent?.className?.indexOf('dropdown-item-button') === -1 &&
        event.target?.parentElement.parentElement.querySelectorAll('.menu-bubble-item').length === 0 &&
        ((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('.contextual-menu-absolute .show').length ||
          // if there is an open menu, just close the menu and keep the current selection
          [].filter.call((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('#contextual-menu-general'), function (div) {
            return !div.className.match(/\bd-hidden\b/) && div.style.display !== 'none';
          }).length)) {
        event.preventDefault();
        this.readerTipTapService.closeOpenedMenus(this.editor);
      } else if (event.target?.className?.indexOf('dropdown-item-button') !== -1 || event.target?.parentElement?.className?.indexOf('dropdown-item-button') !== -1 || event.target?.className?.indexOf('scrollable') !== -1) {
        // [].forEach.call((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('.contextual-menu-absolute'), function(div) {
        //   div.style.display = 'inline-block';
        // });
      } else {
        // just a normal click inside, should show contextual menu
        [].forEach.call((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('.contextual-menu-absolute'), function (div) {
          div.style.display = 'inline-block';
        });
      }
      // } else if(this.bit && !document.querySelector('#bit-' + this.bit?.id).contains(event?.target)) {
      //   this.setEditorReadonly();
    } else {
      this.readerTipTapService.setEditorReadonly(this.editor);
      this.isSpellcheckOn = false;
      const keyboards = document.getElementsByClassName('ML__keyboard');
      for (let i = 0; i < keyboards.length; i++) {
        keyboards[i].className = keyboards[i].className.replace(/\bis-visible\b/g, '');
      }
    }
  }

  constructor(private bitbookApiService: BitbookApiService,
              private readerTipTapService: ReaderTipTapTapService) {
  }

  ngOnInit() {
    this.subSink.sink = this.updates
      .pipe(debounceTime(500))
      .subscribe(() => {
        if (!this.isUpdateFromOutside) {
          this.emitUpdate();
        }
        this.isUpdateFromOutside = false;
      });

    if (this.updateContentEvent) {
      this.subSink.sink = this.updateContentEvent.subscribe((content) => {
        if (content) {
          this.editor.commands.setContent(content);
          this.isUpdateFromOutside = true;
        }
      });
    }

    this.editor = new Editor({
      editable: !this.isReadOnly,
      onCreate: () => this.created.emit(this.editor),
      onTransaction: ({editor, transaction}) => {
        if (transaction.steps.length && this.isRendered) {
          this.updates.next(event);
        }
      },
      onFocus: () => {
        if (this.disableKeyboardInput) {
          this.editor.chain().blur();
        }
      },
      editorProps: this.readerTipTapService.getEditorProps(),
      enableInputRules: false,
      enablePasteRules: false,
      extensions: this.readerTipTapService.getExtensions(TipTapEditorType.BitmarkPlus, () => this.editor, this.placeholder, null, this.bit.id)
    });
    if (this.bitContent) {
      this.editor.commands.setContent(this.bitContent);
    }
  }

  ngAfterViewInit() {
    this.isRendered = true;
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    this.editor.destroy();
    this.editor = null;
  }

  focus() {
    this.textEditorRef.nativeElement.querySelector('.ProseMirror').focus();
    this.isSpellcheckOn = true;
  }

  selectAll() {
    this.editor.commands.selectAll();
  }

  emitUpdate() {
    this.updateBit.emit(this.editor.getJSON());
  }

  uploadImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitbookApiService.uploadResource(file)
      .subscribe((resourceData: { url: string, width: number, height: number }) => {
        const imgCommands = this.editor.chain().focus().setImage({src: resourceData.url});
        if (resourceData.width && resourceData.height) {
          imgCommands.updateAttributes('image', {width: resourceData.width, height: resourceData.height});
        }
        imgCommands.run();
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  replaceImage() {
    if (!this.fileReplaceInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileReplaceInput.nativeElement.files[0];
    this.bitbookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        this.editor.commands.deleteSelection();
        this.editor.commands.setImage({src: resourceUrl.url});
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  enterEditMode() {
    this.isSpellcheckOn = true;
  }

  deleteNode(nodeName: string) {
    this.readerTipTapService.deleteNode(this.editor, nodeName);
  }

  clearHeader() {
    this.editor.commands.clearNodes();
  }

  insertMath() {
    const to = this.editor.view.state.selection.to;
    const from = this.editor.view.state.selection.from;
    const formula = this.editor.view.state.doc.textBetween(from, to, '');
    this.editor.commands.insertContent(`<angular-component-math formula='${formula}'></angular-component-math>`);
  }

  toggleHeader(level: number) {
    if (!level) {
      this.editor.chain().focus().clearNodes().unsetAllMarks().run();
    }
    this.editor.chain().focus().toggleHeading({level: level as any}).run();
    this.readerTipTapService.closeOpenedMenus(this.editor);
  }

  addLink() {
    if (this.editor.isActive('link')) {
      this.editor.commands.unsetLink();
      return;
    }
    const src = prompt('Enter the link URL');

    if (src) {
      const to = this.editor.view.state.selection.to;
      const from = this.editor.view.state.selection.from;
      if (!to || !from || Math.abs(to - from) === 0 || !this.editor.state.doc.textBetween(from, to)) {
        const mark = this.editor.schema.marks.link.create({
          href: src,
        });
        const transaction = this.editor.state.tr.insertText(src + ' ');
        transaction.addMark(from, from + src.length, mark);
        this.editor.view.dispatch(transaction);
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink({href: src, target: '_blank'}).run();
      }
    }
  }
}
