import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  Input,
  IterableDiffer,
  IterableDiffers,
  Renderer2
} from '@angular/core';
import {OrderByPipe} from '../pipes';

@Directive({
  selector: '[appOrderingAnimation]'
})
export class OrderingAnimationDirective implements AfterViewInit, DoCheck {
  @Input() dataList: Array<any> = [];
  @Input() orderBy: { order: 'asc' | 'desc', column?: string } = {order: 'asc'};
  @Input() idFieldName = 'id';
  @Input() animationDurationMs = 500;
  @Input() itemHeight = 0;

  private orderByPipe = new OrderByPipe();
  private arrayDiffer: IterableDiffer<any>;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private iterableDiffers: IterableDiffers) {
  }

  ngAfterViewInit() {
    this.arrayDiffer = this.iterableDiffers.find(this.dataList).create(this.trackBy.bind(this));
    this.init();
  }

  ngDoCheck() {
    if (!this.arrayDiffer) {
      return;
    }

    const arrayChanges = this.arrayDiffer.diff(this.dataList);
    if (arrayChanges) {
      this.refresh();
      return;
    }
  }

  private trackBy(index: number, item: any) {
    return `${index}-${item[this.orderBy.column]}`;
  }

  private init() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', 0);
    this.refresh(true);
  }

  private refresh(isInitial = false) {
    const orders = {};
    this.orderByPipe.transform(this.dataList, this.orderBy.order, this.orderBy.column)
      .forEach((dataItem, idx) => orders[dataItem[this.idFieldName]] = idx);

    setTimeout(() => {
      for (let i = 0; i < this.elementRef.nativeElement.children.length; i++) {
        const el = this.elementRef.nativeElement.children[i];
        const curTop = el.offsetTop;
        const newTop = orders[el.id] * Math.max(el.clientHeight, this.itemHeight);
        if (curTop !== newTop || isInitial) {
          this.renderer.setStyle(el, 'top', `${newTop}px`);
          if (!isInitial) {
            this.renderer.addClass(el, 'moving');
            setTimeout(() => this.renderer.removeClass(el, 'moving'), this.animationDurationMs);
          }
        }
      }
      this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', 1);
    });
  }
}
