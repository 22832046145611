import {BitApiWrapper} from '../../bits.models';
import {FlashcardCardData} from './app-flashcards.models';
import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {DomUtilsService} from '../../../shared/dom/dom-utils.service';
import {Flashcard1Bit} from '../../flashcard-1/flashcard-1.models';

@Component({
  template: '',
})
export abstract class AppFlashcardsModeCommonComponent {
  @ViewChild('headerContainer') headerContainerElement: ElementRef;
  @ViewChild('cardContainer') cardContainerElement: ElementRef;
  @ViewChild('prevCardContainer') prevCardContainerElement: ElementRef;

  flashcardPoolBits: Array<BitApiWrapper> = [];
  card: FlashcardCardData = null;
  prevCard: FlashcardCardData = null;
  cardIndex = 0;
  prevCardIndex = 0;
  cardState: any = 'front';
  currentPotIndex = 0;

  private keyUpBound: any;
  private keyDownBound: any;

  constructor(private domUtilsService: DomUtilsService,
              private renderer: Renderer2) {
  }

  abstract changePot(event: any, index: number, targetBitId?: string, targetCardState?: 'front' | 'back');

  abstract markAsRight();

  abstract markAsWrong();

  goToNext(skipBack = false) {
    if ((this.cardState === 'front' || this.cardState === 'front-no') && !skipBack) {
      this.cardState = 'back';
    } else {
      this.prevCardIndex = this.cardIndex;
      this.prevCard = this.bitToCard(this.flashcardPoolBits[this.prevCardIndex]);
      this.cardIndex = this.getCardIndex(1);
      this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
      this.cardState = 'front-no';

      this.generateLeaveAnimation();
    }
  }

  goToPrevious() {
    if (this.cardState === 'front' || this.cardState === 'front-no') {
      if (!this.prevCard) {
        return;
      }

      const clonedCard = this.prevCardContainerElement?.nativeElement?.cloneNode(true);
      if (!clonedCard) {
        return;
      }
      clonedCard.classList.add('translate-back-animation');
      clonedCard.classList.remove('prev-flashcards-card-container');
      clonedCard.addEventListener('onanimationend', () => {
        clonedCard.remove();
      });
      clonedCard.addEventListener('webkitAnimationEnd', () => {
        clonedCard.remove();
      });
      clonedCard.addEventListener('msAnimationEnd', () => {
        clonedCard.remove();
      });
      clonedCard.addEventListener('animationend', () => {
        clonedCard.remove();
      });
      this.renderer.insertBefore(this.prevCardContainerElement.nativeElement.parentElement, clonedCard, this.cardContainerElement.nativeElement);

      setTimeout(() => {
        this.cardIndex = this.getCardIndex(-1);
        this.card = this.bitToCard(this.flashcardPoolBits[this.cardIndex]);
      }, 580);

      this.prevCardIndex = this.getCardIndex(-2);
      this.prevCard = this.bitToCard(this.flashcardPoolBits[this.prevCardIndex]);
    } else {
      this.cardState = 'front';
    }
  }

  protected initializeKeyboard() {
    this.keyUpBound = this.handleKeyPress.bind(this);
    this.keyDownBound = this.handleKeyDown.bind(this);
  }

  protected handleKeyboardFocus(focused: boolean) {
    if (focused) {
      document.addEventListener('keydown', this.keyDownBound);
      document.addEventListener('keyup', this.keyUpBound);
    } else {
      document.removeEventListener('keydown', this.keyDownBound);
      document.removeEventListener('keyup', this.keyUpBound);
    }
  }

  protected handleKeyPress(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    switch (event.key) {
      case 'f': {
        this.markAsWrong();
        break;
      }

      case 'g': {
        this.markAsRight();
        break;
      }

      case 'ArrowLeft': {
        this.goToPrevious();
        break;
      }

      case 'ArrowRight': {
        this.goToNext();
        break;
      }

      default:
        break;
    }

    return false;
  }

  protected handleKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  protected bitToCard(bit: BitApiWrapper): FlashcardCardData {
    if (!bit?.bit) {
      return null;
    }
    const cardContent = (bit.bit as Flashcard1Bit)?.cards?.length ?  (bit.bit as Flashcard1Bit)?.cards[0] : null;
    return {
      bitId: bit.id,
      front: {
        text: cardContent?.question,
        format: bit.bit.format
      },
      back: {
        text: cardContent?.answer,
        format: bit.bit.format
      }
    };
  }

  protected getCardIndex(amount: number): number {
    if (amount === 0) {
      return this.cardIndex;
    }
    if (this.cardIndex + amount < 0) {
      return this.flashcardPoolBits.length + this.cardIndex + amount;
    }

    if (this.cardIndex + amount > this.flashcardPoolBits.length - 1) {
      return this.flashcardPoolBits.length - this.cardIndex - amount;
    }

    return this.cardIndex + amount;
  }

  protected removeCardFromPool(bitId: string) {
    this.flashcardPoolBits = this.flashcardPoolBits.filter(x => x.id !== bitId);
  }

  protected generateLeaveAnimation() {
    const clonedCard = this.cardContainerElement.nativeElement.cloneNode(true);
    clonedCard.classList.add('translate-animation');
    clonedCard.classList.remove('flashcards-card-container');
    clonedCard.addEventListener('onanimationend', () => {
      clonedCard.remove();
    });
    clonedCard.addEventListener('webkitAnimationEnd', () => {
      clonedCard.remove();
    });
    clonedCard.addEventListener('msAnimationEnd', () => {
      clonedCard.remove();
    });
    clonedCard.addEventListener('animationend', () => {
      clonedCard.remove();
    });
    this.renderer.appendChild(this.cardContainerElement.nativeElement.parentElement, clonedCard);
  }

  protected computeCurrentPotBackgroundPosition(cssPropType: string, target?: HTMLElement) {
    if (!target) {
      this.domUtilsService.waitForDifferentStyleValueWithParentElement(
        this.headerContainerElement.nativeElement,
        '.pot-' + this.currentPotIndex,
        'visibility',
        'hidden').subscribe(() => {
        setTimeout(() => {
          target = this.headerContainerElement.nativeElement.querySelector('.pot-' + this.currentPotIndex);
          this.headerContainerElement.nativeElement.style.setProperty(`--bitmark-app-flashcards-${cssPropType}-current-pot-position-x`, target.offsetLeft + 'px');
        }, 100);
      });
    } else if (!target.classList.contains('flashcards-pot')) {
      target = target.parentElement;
      this.headerContainerElement.nativeElement.style.setProperty(`--bitmark-app-flashcards-${cssPropType}-current-pot-position-x`, target.offsetLeft + 'px');
    } else {
      this.headerContainerElement.nativeElement.style.setProperty(`--bitmark-app-flashcards-${cssPropType}-current-pot-position-x`, target.offsetLeft + 'px');
    }
  }

  protected getShuffledCardBits(shuffledBitIds: Array<string>, bits: Array<BitApiWrapper>): Array<BitApiWrapper> {
    const extraBits = bits.filter(x => !shuffledBitIds.includes(x.id));
    return [
      ...shuffledBitIds.map(x => bits.find(y => y.id === x)).filter(x => x),
      ...extraBits
    ];
  }
}
