import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BitbookApiService} from '../../../../reader/bitbook-api.service';

@Component({
  selector: 'bitmark-choose-book-anchor',
  templateUrl: './choose-book-anchor.component.html',
  styleUrls: ['./choose-book-anchor.component.scss']
})
export class ChooseBookAnchorComponent implements OnInit {
  @Input() bookId: string;
  @Output() anchorChosen: EventEmitter<{ id: string, title: string }> = new EventEmitter<{
    id: string,
    title: string
  }>();

  chapterAnchors: Array<{ anchor: string, chapter: string, level: number }> = [];
  displayChapterAnchors: Array<{ anchor: string, chapter: string, level: number }> = [];

  constructor(private ngbActiveModal: NgbActiveModal,
              private bitbookApiService: BitbookApiService) {
  }

  ngOnInit() {
    this.bitbookApiService.getBookById(this.bookId)
      .subscribe(result => {
        this.chapterAnchors = result?.toc
          .filter(x => x.type === 'chapter' && x.anchor)
          .map(tocEntry => {
            const tocTitle = tocEntry.item ?
              `${tocEntry.item} - ${tocEntry.title}` :
              tocEntry.title;

            return {
              anchor: tocEntry.anchor,
              chapter: tocTitle,
              level: tocEntry.level
            };
          });
        this.displayChapterAnchors = [...this.chapterAnchors];
      }, (err) => {
        console.error(err);
      });
  }

  searchChapter(evt: Event) {
    const value = (evt.target as any)?.value;
    if (!value) {
      this.displayChapterAnchors = [...this.chapterAnchors];
      return;
    }

    this.displayChapterAnchors = this.chapterAnchors.filter(chapterAnchor => {
      return chapterAnchor.chapter.toLowerCase().includes(value.toLowerCase());
    });
  }

  chooseAnchor(id: string, title: string) {
    this.anchorChosen.emit({id, title});
    this.ngbActiveModal.close();
  }

  closeModal() {
    this.ngbActiveModal.close();
  }

  getLevelIndent(level: number): number {
    if (level > 3) {
      return 3;
    }

    return level - 1;
  }

  getLevelPadding(level: number): string {
    return `${this.getLevelIndent(level) * 20}px`;
  }
}
