import {Injectable} from '@angular/core';
import {FormStyle, getLocaleDayPeriods, TranslationWidth} from '@angular/common';
import {NgbTimepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {BitmarkSettings} from '../settings/bitmark.settings';

@Injectable()
export class CustomTimepickerI18n extends NgbTimepickerI18n {
  private readonly _periods: Readonly<[string, string]>;

  constructor() {
    super();

    this._periods = getLocaleDayPeriods(BitmarkSettings.locale, FormStyle.Standalone, TranslationWidth.Narrow);
  }

  getMorningPeriod(): string { return this._periods[0]; }

  getAfternoonPeriod(): string { return this._periods[1]; }
}
