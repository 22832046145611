import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AppConfig} from 'app.config';
import {environment} from 'environment';

@Injectable({providedIn: 'root'})
export class ApiRouterService {
  private allRoutes: any;

  constructor() {
    this.allRoutes = null;
    this.init();
  }

  private static replaceParams(route: string, params: any): string {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
      const param = ':' + keys[i];
      route = route.replace(param, params[keys[i]]);
    }
    return route;
  }

  private getAllRoutes(): Observable<Object> {
    return of(AppConfig.apiRoutesMap);
  }

  private init() {
    this.getAllRoutes()
      .subscribe((routes: Object) => this.allRoutes = routes);
  }

  getRouteById(routeId: string, data: any): Observable<string> {
    return new Observable((x: any) => {
      if (!this.allRoutes) {
        this.getAllRoutes()
          .subscribe((routes: Object) => {
              this.allRoutes = routes;
              try {
                const routeUrl = this.computeRouteById(routeId, data);
                x.next(routeUrl);
              } catch (err) {
                x.error(err);
              }
              x.complete();
            },
            (error: HttpErrorResponse) => {
              console.error(error);
              x.error(error);
              x.complete();
            }
          );
      } else {
        try {
          const routeUrl = this.computeRouteById(routeId, data);
          x.next(routeUrl);
        } catch (error) {
          console.error(error);
          x.error(error);
        }
        x.complete();
      }
    });
  }

  computeRouteById(routeId: string, data: any) {
    if (!this.allRoutes) {
      throw new Error('No routes defined');
    }

    let cleanRouteId = routeId;
    let query = '';
    const k = routeId.indexOf('?');
    if (k !== -1) {
      cleanRouteId = routeId.substr(0, k);
      query = routeId.substr(k);
    }

    let routePath: string = this.allRoutes[cleanRouteId];
    if (!routePath) {
      throw new Error('Attempt to hit an invalid route: ' + routeId);
    }
    if (data) {
      routePath = ApiRouterService.replaceParams(routePath, data);
      if (routePath.indexOf(':', 0) > -1 && !routePath.toLowerCase().startsWith('http')) {
        throw new Error('Attempt to hit an incomplete route: ' + routeId + ' > ' + routePath);
      }
    }

    return routePath.toLowerCase().startsWith("http")
      ? routePath + query
      : routePath.startsWith("~/")
        ? routePath.substr(2) + query
        : environment.baseUrl + routePath + query;
  }
}
