import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FileUtilsService {
  download(fileName: string, content: string) {
    const blob = new Blob([content]);

    this.downloadBlob(fileName, blob);
  }

  downloadBlob(fileName: string, blob: Blob) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const blobUrl = URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    });
  }

  fetchAndDownload(fileName: string, url: string): Observable<void> {
    return new Observable(x => {
      fetch(url)
        .then(data => data.blob())
        .then(blob => {
          this.downloadBlob(fileName, blob);
        })
        .finally(() => {
          x.next();
          x.complete();
        });
    });
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});
  }

  pickFromDesktopFileSystem(acceptedMimeTypes = '*', isMultiple = false, maxCount = 0): Observable<File[]> {
    return new Observable((x: Subscriber<File[]>) => {
      const input: HTMLInputElement = document.createElement('input');
      input.type = 'file';
      input.style.display = 'none';
      input.accept = acceptedMimeTypes || '*';
      input.multiple = isMultiple;
      document.body.appendChild(input);
      input.onchange = ($event: any) => {
        document.body.removeChild(input);
        const selectedFiles = $event.target.files as Array<File>;
        if (!selectedFiles?.length) {
          return;
        }
        if (maxCount && selectedFiles.length > maxCount) {
          x.error();
          x.complete();
          return;
        }
        x.next(selectedFiles);
      };
      input.click();
    });
  }
}
