import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterviewBit, InterviewQuestion} from './interview.models';
import {BitResource} from '../bits.models';
import {ReaderContentWidth} from '../../shared/models/bitmark.models';
import {BitEvalResult} from '../bit-eval.service';

@Component({
  selector: 'bitmark-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['../bits.scss']
})
export class InterviewComponent implements OnInit {
  private _bit?: InterviewBit;
  @Input() set bit(value: InterviewBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.wordBankItems = [];
      this.computeWordBank();
    }
  }

  get bit(): InterviewBit {
    return this._bit;
  }

  @Input() isInstructionGrouped = false;
  @Input() readOnly = false;
  @Input() allowAnswerSubmit = true;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  wordBankItems: Array<{ text: string, used: boolean }> = [];
  questionRowsCount: Array<number> = [];

  private initialized = false;

  ngOnInit() {
    this.computeWordBank();

    this.bit?.questions.forEach(question => {
      this.questionRowsCount.push(question?.reasonableNumOfChars ? this.computeRowsCount(question.reasonableNumOfChars) : 0);
    });

    this.initialized = true;
  }

  private computeWordBank() {
    if (this.isInstructionGrouped && this.bit?.questions) {
      this.bit.questions.filter(q => !!q.instruction).forEach(q => {
        this.wordBankItems.push({text: q.instruction, used: this.bit.answer?.usedSolutions?.includes(q.instruction)});
      });
    }

    if (this.bit.questions?.length) {
      this.bit.questions.forEach(q => {
        q.additionalSolutions?.forEach(x => {
          this.wordBankItems.push({text: x, used: this.bit.answer?.usedSolutions?.includes(x)});
        });
      });
    }

    this.wordBankItems.sort();
  }

  change(question: InterviewQuestion, evt: Event) {
    question.answer = {text: (evt.target as any)?.value};
    this.changed.emit();
  }

  toggleWordBankSolution(item: { text: string, used: boolean }) {
    item.used = !item.used;

    if (!this.bit.answer?.usedSolutions) {
      this.bit.answer = {
        usedSolutions: [item.text]
      };
    } else {
      if (this.bit.answer.usedSolutions.includes(item.text)) {
        this.bit.answer.usedSolutions = this.bit.answer.usedSolutions.filter(x => x !== item.text);
      } else {
        this.bit.answer.usedSolutions = [...this.bit.answer.usedSolutions, item.text];
      }
    }

    this.changed.emit();
  }

  private computeRowsCount(charsCount: number) {
    const charsPerLine = window.innerWidth < ReaderContentWidth ? 25 : 50;
    return Math.floor(charsCount / charsPerLine) + 1;
  }

  static evalAnswer(bit: InterviewBit): BitEvalResult {
    return {
      totalAnswers: bit.questions.length,
      answeredAnswers: bit.questions.filter(p => !!p.answer?.text).length,
      notAnsweredAnswers: bit.questions.filter(p => !p.answer?.text).length
    };
  }
}
