import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'smartDate'
})
export class SmartDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe,
              private translate: TranslateService) {}

  transform(value: Date | string, titlecase?: boolean): string {
    const date = new Date(value);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    if (this.isSameDay(date, now)) {
      return titlecase ? `${this.toTitleCase(this.translate.instant('HandIn.Today'))}, ${this.formatTime(date)}` : `${this.translate.instant('HandIn.Today')}, ${this.formatTime(date)}`;
    } else if (this.isSameDay(date, yesterday)) {
      return titlecase ? `${this.toTitleCase(this.translate.instant('HandIn.Yesterday'))}, ${this.formatTime(date)}` : `${this.translate.instant('HandIn.Yesterday')}, ${this.formatTime(date)}`;
    } else {
      return this.datePipe.transform(date, 'MMM d, y, HH:mm') || '';
    }
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  }

  private formatTime(date: Date): string {
    return this.datePipe.transform(date, 'HH:mm') || '';
  }

  private toTitleCase(str: string) {
    if (!str) {
      return null;
    }
    return str.replace(/\b\w/g, txt => txt.toUpperCase());
  }
}
