import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BitResource, BitResourceType} from '../../../bits.models';

@Component({
  selector: 'bitmark-resource-document-link-edit',
  templateUrl: './resource-document-link-edit.component.html',
  styleUrls: ['../../../bits.scss']
})
export class ResourceDocumentLinkEditComponent {
  @Output() onSave: EventEmitter<BitResource> = new EventEmitter<BitResource>();

  documentLink: string;
  documentLinkResource: BitResource | undefined;

  constructor(private ngbActiveModal: NgbActiveModal) {
  }

  onLinkChanged() {
    if (this.documentLink) {
      this.documentLinkResource = undefined;
      setTimeout(() => {
        this.documentLinkResource = {
          type: BitResourceType.Document,
          document: {
            url: this.documentLink
          }
        };
      }, 100);
    } else {
      this.documentLinkResource = undefined;
    }
  }

  saveChanges() {
    this.onSave.emit(this.documentLinkResource);
    this.ngbActiveModal.close();
  }

  cancelChanges() {
    this.ngbActiveModal.close();
  }
}
