import {Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {BitbookMqService} from '../../bitbook-mq.service';
import {ReaderBasketService} from '../../reader-basket.service';
import {BitApiWrapper, BitType} from '../../../bits/bits.models';
import {DropdownItemModel, SubSink} from '../../../shared';
import {ReaderClipboardService} from '../../reader-clipboard.service';
import {BitmarkConfig} from "../../../bitmark.module";
import {RolesApiService, TaughtClass} from "../../roles-api.service";

@Component({
  selector: 'bitmark-reader-content-basket',
  templateUrl: './reader-content-basket.component.html',
  styleUrls: ['./reader-content-basket.component.scss'],
})
export class ReaderContentBasketComponent implements OnInit, OnDestroy {
  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() findInBook = new EventEmitter<BitApiWrapper>();

  BitType = BitType;

  items: Array<BitApiWrapper>;
  bitActions: Array<DropdownItemModel>;
  sub = new SubSink();
  isLoading = true;

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private el: ElementRef,
              private basketSvc: ReaderBasketService,
              private bitbookMqService: BitbookMqService,
              private translate: TranslateService,
              private readerClipboard: ReaderClipboardService,
              private clipboardService: ClipboardService,
              private rolesApiService: RolesApiService) {
  }

  ngOnInit() {
    this.getBasketItems();
    this.rolesApiService.getTaughtClasses()
      .subscribe((classes: Array<TaughtClass>) => {
        this.bitActions = [{
          label: this.translate.instant('Reader.Actions.SendContinue'),
          handler: this.sendBit.bind(this)
        }, classes?.length ? {
          label: this.translate.instant('Reader.Actions.SendToClass'),
          handler: this.sendBitToClass.bind(this)
        } : null, {
          label: this.translate.instant('Reader.Actions.SaveToNotebook'),
          handler: this.saveBit.bind(this)
        }, {
          id: 'find-in-book',
          label: this.translate.instant('Reader.Actions.FindInBook'),
          handler: this.findBitInBook.bind(this)
        }, {
          isSeparator: true,
          isHidden: !this.bitmarkConfig.isProUser,
        }, {
          label: 'Reader.Actions.CopyBitmarkType',
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkToClipboard.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyBitmarkJSON'),
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkJsonToClipboard.bind(this)
        }, {isSeparator: true}, {
          label: this.translate.instant('Shared.Copy'),
          handler: this.copyBitToClipboard.bind(this)
        }, {
          label: this.translate.instant('Shared.Paste'),
          handler: this.pasteFromClipboard.bind(this)
        }, {isSeparator: true}, {
          label: this.translate.instant('Reader.Basket.RemoveFromBasket'),
          handler: this.onRemoveFromBasket.bind(this)
        }].filter(x => !!x);
      });
    this.sub.sink = this.bitbookMqService.onBasketChange()
      .subscribe(this.getBasketItems.bind(this));
  }

  private getBasketItems() {
    this.isLoading = true;
    this.basketSvc.getBasketItems()
      .subscribe((bkt: Array<BitApiWrapper>) => {
        if (bkt?.length) {
          this.items = bkt;
        } else {
          this.items = [];
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onRemoveFromBasket(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    const item = this.items.find(i => i.id === bitWrapper.id);
    this.basketSvc.removeFromBasket(item.id, this.items.length).subscribe(() => {
      this.items = this.items.filter((i => i.id !== item.id));
    });
  }

  private copyBitmarkToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(bitWrapper.bitmark || '');
  }

  private copyBitmarkJsonToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(JSON.stringify(bitWrapper.bit || ''));
  }

  private sendBit(dropdownItemModel: DropdownItemModel) {
    this.sendBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private sendBitToClass(dropdownItemModel: DropdownItemModel) {
    this.sendBitsToClass.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private saveBit(dropdownItemModel: DropdownItemModel) {
    this.saveBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private findBitInBook(dropdownItemModel: DropdownItemModel) {
    this.findInBook.emit(dropdownItemModel.data as BitApiWrapper);
  }

  private copyBitToClipboard(dropdownItemModel: DropdownItemModel) {
    this.readerClipboard.copyToClipboard([{id: dropdownItemModel.data.id}])
      .subscribe(() => this.setFocus());
  }

  private cutBitToClipboard(dropdownItemModel: DropdownItemModel) {
    this.readerClipboard.cutToClipboard([dropdownItemModel.data.bit])
      .subscribe(() => this.setFocus());
  }

  private pasteFromClipboard() {
    this.readerClipboard.getClipboard().subscribe((clipboardBits) => {
      const bits = clipboardBits.map((b: BitApiWrapper) => {
        return b.id ? b.id : b.bit;
      });
      // TODO HANDLE AS ARRAY
      this.basketSvc.addToBasket(bits[0])
        .subscribe(() => this.getBasketItems());
    });
  }

  private setFocus() {
    // console.log('focus');
    (this.el.nativeElement as any).focus();
  }
}
