import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  locale_en_gb,
  plugin_annotate_locale_en_gb,
  markup_editor_locale_en_gb,
} from '@pqina/pintura';
import {BitmarkSettings} from '../shared/settings/bitmark.settings';

@Injectable()
export class AnnotationEditorTranslationsService {
  private lang;
  private locale = {};

  constructor(private translate: TranslateService) {
    this.lang = BitmarkSettings.locale;
    this.setLocale();
  }

  getLocale() {
    if (this.lang !== BitmarkSettings.locale) {
      this.lang = BitmarkSettings.locale;
      this.setLocale();
    }
    return {...this.locale};
  }

  setLocale() {
    const locale = Object.assign({}, locale_en_gb, {
      labelColorPalette: this.translate.instant('Reader.Handwritten.ColorPalette'),
      labelSizeExtraSmall: this.translate.instant('Reader.Handwritten.ExtraSmall'),
      labelSizeSmall: this.translate.instant('Reader.Handwritten.Small'),
      labelSizeMediumSmall: this.translate.instant('Reader.Handwritten.MediumSmall'),
      labelSizeMedium: this.translate.instant('Reader.Handwritten.Medium'),
      labelSizeMediumLarge: this.translate.instant('Reader.Handwritten.MediumLarge'),
      labelSizeLarge: this.translate.instant('Reader.Handwritten.Large'),
      labelSizeExtraLarge: this.translate.instant('Reader.Handwritten.ExtraLarge'),
      statusLabelLoadImage: (state) => {
        if (!state || !state.task) {
          return this.translate.instant('Reader.Handwritten.WaitingForImage');
        }
        if (state.error) {
          return state.error.code === 'IMAGE_TOO_SMALL'
            ? 'Minimum image size is {minWidth} &times; {minHeight}'
            : this.translate.instant('Reader.Handwritten.ErrorLoadingImage');
        }
        if (state.task === 'blob-to-bitmap') {
          return this.translate.instant('Reader.Handwritten.PreparingImage') + '&hellip;';
        }
        return this.translate.instant('Reader.Handwritten.LoadingImage') + '&hellip;';
      },
    });
    this.locale = Object.assign(plugin_annotate_locale_en_gb, markup_editor_locale_en_gb, locale, {});
  }
}
