import { Component, Input, OnInit } from '@angular/core';
import { DropdownItemModel } from '../dropdown/dropdown/dropdown.model';
import { BitApiWrapper } from '../../bits/bits.models';

@Component({
  selector: 'bitmark-skeleton-loader',
  styleUrls: ['./skeleton-loader.component.scss'],
  templateUrl: './skeleton-loader.component.html'
})
export class SkeletonLoaderComponent implements OnInit{
  @Input() name: string;
  @Input() bitWrapper: BitApiWrapper;
  @Input() actions: Array<DropdownItemModel> = [];

  ngOnInit() {
    this.actions?.forEach((a) => {
      a.data = this.bitWrapper;
    });
  }
}
