import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MarkBit} from './mark.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-mark',
  templateUrl: './mark.component.html',
  styleUrls: ['../bits.scss']
})
export class MarkComponent {
  @Input() bit?: MarkBit;
  @Output() openResource = new EventEmitter<BitResource>();
}
