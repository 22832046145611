// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 470px;
  width: 100%;
  height: 30px;
}

input {
  width: 100%;
  background: transparent;
  border: none;
  color: #656769;
  font-size: 1.2rem;
}

bitmark-icon {
  color: #a98ae6;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
