// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-pointer {
  width: 0;
  height: 0;
  margin: auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--bitmark-note-background-color);
}

.wrapper {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.wrapper.is-edit {
  background-color: var(--bitmark-edit-settings-color);
  border-radius: 0.3rem;
}
.wrapper .bot-action-send-container {
  background-color: var(--bitmark-note-background-color);
  border-radius: 0.3rem;
}
.wrapper .bot-action-send-container .edit-input {
  visibility: hidden;
  width: 1px;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.edit-container {
  background-color: var(--bitmark-edit-settings-color);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.edit-container .state-button {
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  min-height: 30px;
  font-size: 16px;
  min-width: 90px;
  font-weight: 500;
}
.edit-container .state-save-button {
  background-color: var(--bitmark-reader-primary-color) !important;
  color: #ffffff;
}
.edit-container .state-cancel-button {
  color: var(--bitmark-reader-primary-color);
}

.bit-button {
  justify-content: center !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
