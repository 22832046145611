import {Injectable, RendererFactory2} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TouchSwipe} from './touch-swipe';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({providedIn: 'root'})
export class ModalTouchSwipeService {
  constructor(private rendererFactory: RendererFactory2,
              private deviceDetectorService: DeviceDetectorService) {
  }

  applyTouchSwipe(modalRef: NgbModalRef) {
    if (!this.deviceDetectorService.isMobile()) {
      return;
    }

    const ngbModalWindowNativeElement = (modalRef as any)._windowCmptRef.instance._elRef.nativeElement;
    const renderer = this.rendererFactory.createRenderer(null, null);
    const touchSwipe = new TouchSwipe(ngbModalWindowNativeElement, renderer);
    touchSwipe.applyTouchSwipe();
    touchSwipe.onSwipe.subscribe(() => {
      touchSwipe.destroy();
      modalRef.dismiss();
    });
  }
}

