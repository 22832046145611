// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  border-radius: 0.3rem;
}
.modal-container .video-placeholder {
  height: 300px;
}
.modal-container .video-placeholder .video-logo {
  width: 80px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
