import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import {SubSink} from "../sub-sink/sub-sink";

@Injectable({providedIn: 'root'})
export class RouterExtService implements OnDestroy {
  private previousUrl: string = undefined;
  private visitedRoutes: Array<string> = [];

  private isInitialized = false;
  private isImperativeBack = false;
  private subSink = new SubSink();

  constructor(private router: Router) {
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  init() {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;
    this.subSink.sink = this.router.events
      .pipe(filter(ev => ev instanceof NavigationEnd))
      .subscribe(() => {
        setTimeout(() => this.isImperativeBack = false, 2000);
      });
    this.subSink.sink = this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log(events);
        let currentUrl = this.previousUrl;
        if (!currentUrl) {
          currentUrl = this.getRoutePath(events[1]);
        }

        if (events[0].id === 1 // if first load
          && !this.visitedRoutes?.length) {
          this.visitedRoutes.push(this.getRoutePath(events[0], true));
        }
        if (this.getRoutePath(events[1]) !== this.getPathName(this.visitedRoutes[this.visitedRoutes.length - 1])) {
          this.visitedRoutes.push(this.getRoutePath(events[1], true));
        }

        const tempPreviousUrl = this.getRoutePath(events[0]);
        if (currentUrl !== tempPreviousUrl) {
          this.previousUrl = events[0].urlAfterRedirects;
        }
      });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  getNavigationTrigger(): 'imperative' | 'popstate' {
    return (this.router as any).lastSuccessfulNavigation.trigger;
  }

  wasNavigationBack(): boolean {
    return this.getNavigationTrigger() === 'popstate' || this.isImperativeBack;
  }

  goBack(defaultRoute: string) {
    const prevUrl = this.getPreviousUrl();
    this.isImperativeBack = true;
    if (prevUrl && prevUrl.indexOf('/login') === -1 && prevUrl !== '/space') {
      this.visitedRoutes.pop();
      let prevRoute = this.visitedRoutes.pop();
      prevRoute = prevRoute && decodeURIComponent(prevRoute);

      if (prevRoute) {
        if (prevRoute === prevUrl) {
          history.back();
        } else if (prevRoute.includes('?')) {
          this.router.navigateByUrl(prevRoute);
        } else {
          this.router.navigate([prevRoute]);
        }
      } else {
        this.router.navigate([defaultRoute]);
      }
    } else {
      this.router.navigate([defaultRoute]);
    }
  }

  private getRoutePath(route: RoutesRecognized | null, withSearch?: boolean): string {
    if (!route?.urlAfterRedirects) {
      return '';
    }

    const newRouteUrl = new URL(route?.urlAfterRedirects, window.location.origin);

    if (withSearch) {
      return newRouteUrl.pathname + newRouteUrl.search;
    }
    return newRouteUrl.pathname;
  }

  private getPathName(route: string): string {
    if (!route) {
      return '';
    }

    const newRouteUrl = new URL(route, window.location.origin);
    return newRouteUrl.pathname;
  }
}
