import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DropdownItemModel} from '../dropdown/dropdown.model';

@Component({
  selector: 'bitmark-dropdown-mobile',
  templateUrl: './dropdown-mobile.component.html',
  styleUrls: ['../dropdown.scss', './dropdown-mobile.component.scss'],
})
export class DropdownMobileComponent implements AfterViewInit {
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() additionalContent: TemplateRef<any> = null;

  @Output() selected = new EventEmitter<Array<DropdownItemModel>>();
  @Output() canceled = new EventEmitter<any>();

  window: any = window;
  @ViewChild('wrapper') wrapper: ElementRef;

  constructor(private deviceDetectorService: DeviceDetectorService,
              private ngbActiveModal: NgbActiveModal,
              private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.wrapper.nativeElement, 'bottom', `${this.window.innerHeight - this.window.visualViewport.height}px`);
    this.renderer.setStyle(this.wrapper.nativeElement, 'height', 'auto');
    if (!this.deviceDetectorService.isDesktop()) {
      this.window.visualViewport.addEventListener('resize', () => {
        setTimeout(() => {
          this.renderer.setStyle(this.wrapper.nativeElement, 'bottom', `${this.window.innerHeight - this.window.visualViewport.height}px`);
          this.renderer.setStyle(this.wrapper.nativeElement, 'height', 'auto');
        }, 100);
      });
    }
  }

  choose(action: DropdownItemModel) {
    if (action?.actions?.length) {
      this.selected.emit(action.actions);
    }

    if (action?.handler) {
      action.handler.apply(null, [action]);
      this.canceled.emit(true);
    }

    if (action?.keepOpenAfterClick) {
      return;
    }
  }

  close() {
    this.canceled.emit(true);
  }
}
