import {HttpErrorResponse} from '@angular/common/http';

export class AppError {
  status: number;
  url: string;
  message: string;
  stack: string;
  request?: {
    userAgent: string;
    method: string;
    authorization?: string;
    body?: any;
  };
}

export class AppErrorConverter {
  static ToAppError(error: AppError | Error | HttpErrorResponse): AppError {
    if (error instanceof AppError) {
      return error;
    }
    if (!navigator.onLine) {
      return Object.assign(new AppError(), {
        status: 0,
        url: null,
        message: 'No Internet Connection',
        stack: null
      });
    }
    if (error instanceof HttpErrorResponse) {
      return Object.assign(new AppError(), {
        status: error.status,
        url: error.url,
        message: error.statusText || (error.error && error.error.message) || error.message || error.toString(),
        stack: error.error ? error.error.stack : null,
        request: error.error ? error.error.request : null
      });
    }

    return Object.assign(new AppError(), {
      status: 0,
      url: null,
      message: error.message,
      stack: error.stack
    });
  }
}
