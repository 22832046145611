import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';
import {
  NgbActiveModal,
  NgbDropdownModule,
  NgbModule,
  NgbTooltipModule,
  NgbPopover,
  NgbModal, NgbRatingModule
} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {InViewportModule} from 'ng-in-viewport';
import {NgxTiptapModule} from 'ngx-tiptap';
import {AutosizeModule} from 'ngx-autosize';
import {AngularPinturaModule} from '@pqina/angular-pintura';
import {MonacoEditorModule} from 'ngx-monaco-editor-v2';
import {GridJsAngularComponent} from 'gridjs-angular';
import {HighchartsChartModule} from 'highcharts-angular';

import * as sharedBarrel from './shared/index';
import * as bitsBarrel from './bits/index';
import * as readerBarrel from './reader/index';
import {BitmarkSettings} from './shared/settings/bitmark.settings';
import {ApiRouterService} from './shared/api/api-router.service';
import {ApiService} from './shared/api/api.service';
import {BitbookApiService} from './reader/bitbook-api.service';
import {PublisherApiService} from './reader/publisher-api.service';
import {ParserApiService} from './reader/parser-api.service';
import {ReaderBasketService} from './reader/reader-basket.service';
import {ReaderBitsBinService} from './reader/reader-bits-bin.service';
import {ReaderClipboardService} from './reader/reader-clipboard.service';
import {BrandingApiService} from './reader/branding-api.service';
import {BrandingRenderService} from './reader/branding-render.service';
import {RolesApiService} from './reader/roles-api.service';
import {ReaderContentService} from './reader/reader-book/reader-content/reader-content.service';
import {ArrayService} from './shared/utils/array.service';
import {DomUtilsService} from './shared/dom/dom-utils.service';
import {FileUtilsService} from './shared/utils/file-utils.service';
import {ObjectService} from './shared/utils/object.service';
import {EmbedVideoService} from './shared/utils/embed-video.service';
import {ReaderTipTapTapService} from './reader/tiptap/reader-tiptap.service';
import {AnnotationEditorTranslationsService} from './reader/annotation-editor-translations.service';
import {BitmarkTextContentService, ScrollDirective} from './shared';
import {BitUtilsService} from './shared/utils/bit-utils.service';
import {AnalyticsService} from "./shared/analytics/analytics.service";

import localeEn from '@angular/common/locales/en';
import localeEnUk from '@angular/common/locales/en-GB';
import localeEnCh from '@angular/common/locales/en-CH';
import localeDe from '@angular/common/locales/de';
import localeDeCh from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeRo from '@angular/common/locales/ro';

registerLocaleData(localeEn);
registerLocaleData(localeEnUk);
registerLocaleData(localeEnCh);
registerLocaleData(localeDe);
registerLocaleData(localeDeCh);
registerLocaleData(localeFr);
registerLocaleData(localeFrCh);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeItCh);
registerLocaleData(localeRo);

export interface BitmarkConfig {
  bitbookClientApiUrl: string;
  openAiApiUrl: string;
  brandingApiUrl: string;
  ltiHandlingApiBaseUrl: string;
  productionEnvironment: boolean;
  environmentVersion: string;
  isProUser?: boolean;
  hasChatGpt?: boolean;
  space: string;
  userIcon: {
    photoUrl: string;
    fullName: string;
  };
  userEmails: {
    email: string;
    ssoEmail: string;
  }
  userLanguage: string;
  localParser?: {
    enabled: boolean;
  },
  editorMenusFromTheme?: {
    enabled: boolean;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbTooltipModule,
    NgbRatingModule,
    NgbDropdownModule,
    TranslateModule,
    AngularPinturaModule,
    InfiniteScrollModule,
    InViewportModule,
    NgxTiptapModule,
    AutosizeModule,
    PortalModule,
    MonacoEditorModule.forRoot(),
    GridJsAngularComponent,
    HighchartsChartModule
  ],
  declarations: [
    ...sharedBarrel.components,
    ...bitsBarrel.components,
    ...bitsBarrel.pipes,
    ...readerBarrel.components
  ],
  exports: [
    NgbModule,
    bitsBarrel.componentsExport,
    readerBarrel.ReaderBookComponent,
    readerBarrel.BitWrapperComponent,
    sharedBarrel.sharedPipes,
    sharedBarrel.componentsExport
  ],
  providers: [
    NgbActiveModal,
    NgbPopover,
    NgbModal,
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BitmarkModule {
  static forRoot(config: BitmarkConfig): ModuleWithProviders<BitmarkModule> {
    return {
      ngModule: BitmarkModule,
      providers: [{
        provide: 'BitmarkConfig',
        useValue: config
      },
        ApiRouterService,
        ApiService,
        BitbookApiService,
        PublisherApiService,
        ParserApiService,
        ReaderBasketService,
        ReaderBitsBinService,
        ReaderClipboardService,
        AnnotationEditorTranslationsService,
        BrandingApiService,
        BrandingRenderService,
        RolesApiService,
        BitmarkSettings,
        ReaderContentService,
        ScrollDirective,
        ArrayService,
        DomUtilsService,
        FileUtilsService,
        ObjectService,
        EmbedVideoService,
        ReaderTipTapTapService,
        BitUtilsService,
        BitmarkTextContentService,
        AnalyticsService
      ]
    };
  }
}
