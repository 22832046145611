import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownItemModel} from "../../../../shared";

@Component({
  selector: 'bitmark-contextual-menu-header',
  templateUrl: './contextual-menu-header.component.html'
})
export class ContextualMenuHeaderComponent implements OnInit {
  @Input() editor: any;
  @Output() onDeleteNode: EventEmitter<string> = new EventEmitter<any>();
  @Output() onClearHeader: EventEmitter<string> = new EventEmitter<any>();
  menuActions: Array<DropdownItemModel> = [];

  ngOnInit() {
    this.menuActions = [{
      label: 'Title',
      handler: this.setTitle.bind(this)
    }, {
      label: 'Subtitle',
      handler: this.setSubtitle.bind(this)
    }, {
      label: 'Text',
      handler: this.clearHeader.bind(this)
    }, {isSeparator: true}, {
      label: 'Delete',
      handler: this.deleteNode.bind(this)
    }];
  }

  setTitle() {
    this.editor.chain().focus().toggleHeading({level: 1}).run();
  }

  setSubtitle() {
    this.editor.chain().focus().toggleHeading({level: 2}).run();
  }

  deleteNode() {
    this.onDeleteNode.emit('heading');
  }

  clearHeader() {
    this.onClearHeader.emit();
  }
}
