import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Subject} from 'rxjs';
import {JSONContent} from '@tiptap/core';
import {BaseBit, BitResource, BitResourceType, BitType} from '../bits.models';
import {BitmarkFormat, BookType, ProductFamily} from '../../shared/models/bitmark.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import {BitmarkTextContentService} from '../../shared';
import {BitUtilsService} from '../../shared/utils/bit-utils.service';
import {ModuleBit} from "./module.models";
import {BitbookApiService} from "../../reader/bitbook-api.service";
import {ReaderContext} from "../../reader/reader.models";

@Component({
  selector: 'bitmark-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss', '../bits.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',  // Use '*' to adapt to content size
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        opacity: 0
      })),
      transition('open <=> closed', [
        animate('0.3s')
      ]),
    ]),
    trigger('fadeOut', [
      state('visible', style({
        opacity: 1
      })),
      state('invisible', style({
        opacity: 0
      })),
      transition('visible => invisible', [
        animate('0.5s')
      ])
    ])
  ]
})
export class ModuleComponent implements OnInit, OnChanges {
  @Input() moduleBit?: ModuleBit;
  @Input() moduleBitSubtype: BitType = BitType.Module;
  @Input() canBeEdited = false;
  @Input() isEditable = false;
  @Input() isResourceType = false;
  @Input() context: ReaderContext;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() saveInlineBit = new EventEmitter<ModuleBit>();
  @Output() onIsBeingEdited = new EventEmitter<BaseBit>();
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }> = new EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily
  }>();

  //TODO remove
  @Output() cancelBitChanges = new EventEmitter<any>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() instructionChanged = new EventEmitter<any>();
  @ViewChild('editorComponent') editorComponentRef: ReaderTextEditorComponent;

  private _isBeingEditedByUser?: boolean;

  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;
  updateContentEventSubject: Subject<any> = new Subject<any>();

  bodyFormat: BitmarkFormat;
  isBodyEmpty = true;
  isBitEmpty = false;
  hasBitResource = false;
  productListProgress: Array<{ externalId: string, progress: number }> = [];
  isCompleted = false;
  _operationId: string;
  products: Array<any>;
  isWideEntry = false;
  publisher: string;
  title: string;
  isDescriptionOpen = false;
  isRegularUserInAcademy = false;
  protected readonly BitResourceType = BitResourceType;

  @HostListener('document:gmbProductDetailsDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    const thisEventProductIds = event.detail?.products.filter((p) => {
      return this.moduleBit?.productId.find((pid) => pid === p._id)
    })
    if(thisEventProductIds.length){
      const prds = event?.detail?.products.filter((p) => {
        return this.moduleBit?.productId?.find((pid) => pid === p._id)
      })
      prds?.forEach((p) => {
        p.publisher = p && p?.meta?.publisher?.length ? p.meta.publisher[0].data : '';
        p.title = p && p?.texts?.title ? p.texts.title : '';
      })
      this.products = prds.map((p) => {
        return {
          ...p,
          progress: this.productListProgress.find((pr) => pr?.externalId === p?._id)
        }
      });
    }
  }

  constructor(private bitmarkTextContentService: BitmarkTextContentService,
              private bitUtilsService: BitUtilsService,
              private bitbookApiService: BitbookApiService) {
  }

  ngOnInit() {
    console.log('Can be edited: ', this.canBeEdited);
    this.bodyFormat = this.bitmarkTextContentService.getBodyFormat(this.moduleBit.body, this.moduleBit.format);
    this.computeBitVisibility();
    this.isRegularUserInAcademy = this.context === 'self-learning-readonly';
    if (this.moduleBit?.productId?.length) {
      this._operationId = (this.moduleBit?.productId[0]) + (Math.random() + 1).toString(36).substring(7);
      const event = new CustomEvent('gmbProductDetailsRequested', {
        detail: {
          productIds: this.moduleBit?.productId,
          operationId: this._operationId
        }, bubbles: true
      });
      document.dispatchEvent(event);
      this.bitbookApiService.getProgressForBooks(this.moduleBit.productId).subscribe((res: Array<{
        externalId: string,
        progress: number
      }>) => {
        this.productListProgress = this.moduleBit.productId.map((p) => {
          const bookProgress = res.find((pr) => pr.externalId === p);
          return {
            externalId: p,
            ...bookProgress
          }
        })
        if (this.productListProgress?.every((p) => p.progress === 100)) {
          this.isCompleted = true;
        }
        if (this.products?.length) {
          this.products = this.products.map((p) => {
            return {
              ...p,
              progress: this.productListProgress.find((pr) => pr?.externalId === p?._id)
            }
          })
        }
        console.log('1');
      }, (err) => {
        console.error(err);
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.moduleBit?.currentValue?.body && (this.moduleBit as any)?.isBeingGenerated) {
      this.computeBitVisibility();
      if (this.editorComponentRef) {
        if (changes?.moduleBit?.currentValue?.body?.toString().indexOf('[object Object]') !== -1) {
          console.log(1);
        }
        this.editorComponentRef.editor.commands.setContent(changes?.moduleBit?.currentValue?.body);
      }
    }
  }

  saveChanges() {
    this.computeBitVisibility();
    this.saveInlineBit.emit(this.moduleBit);
  }

  onUpdateContent(bit: ModuleBit, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;

    if (this.canBeEdited && !this.isBeingEditedByUser) {
      this.saveChanges();
    }
  }

  updateBitResource(data: { resource: BitResource | undefined, instruction: string, body: string }) {
    this.moduleBit.resource = data.resource;
    if (data.instruction && this.bitmarkTextContentService.isElementEmpty(this.moduleBit.instruction, BitmarkFormat.MM)) {
      this.moduleBit.instruction = data.instruction;
      this.instructionChanged.next(data.instruction);
    }
    if (data.body) {
      const isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.moduleBit.body, this.moduleBit.format);
      if (!isBodyEmpty && this.moduleBit.body.content) {
        this.moduleBit.body.content.push(...this.bitmarkTextContentService.getBodyContent(data.body, this.bodyFormat)?.content);
        this.updateContentEventSubject.next(this.moduleBit.body.content);
      } else if (!isBodyEmpty && Array.isArray(this.moduleBit.body)) {
        this.moduleBit.body.push(...this.bitmarkTextContentService.getBodyContent(data.body, this.bodyFormat)?.content);
        this.updateContentEventSubject.next(this.moduleBit.body);
      } else if (!isBodyEmpty) {
        this.moduleBit.body += '\n' + data.body;
        this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.moduleBit.body, this.bodyFormat));
      } else {
        this.moduleBit.body = data.body;
        this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.moduleBit.body, this.bodyFormat));
      }
    }

    this.computeBitVisibility();
  }

  openBook() {
    this.isDescriptionOpen = !this.isDescriptionOpen;
  }

  enterEditMode() {
    if (this.canBeEdited) {
      this.editorComponent.enterEditMode();
    }
  }

  private computeBitVisibility() {
    console.log('compute bit visibility');
    this.hasBitResource = this.bitUtilsService.hasBitResource(this.moduleBit);
    this.isBitEmpty = this.bitmarkTextContentService.isElementEmpty(this.moduleBit?.item, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(this.moduleBit?.lead, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(this.moduleBit?.instruction, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(this.moduleBit?.body, this.moduleBit?.format)
      && !this.hasBitResource;

    const isBodyElContent = this.bitmarkTextContentService.isElementEmpty(this.moduleBit.body, this.moduleBit.format);
    this.isBodyEmpty = this.moduleBitSubtype == BitType.Article
      ? isBodyElContent
      : this.isEditable && this.canBeEdited
        ? false
        : isBodyElContent;
  }

  openProductPage(product) {
    this.navigateToBook.emit({bookId: product?._id, fragment: null});
  }

  setWideImage() {

  }

  protected readonly BookType = BookType;
}
