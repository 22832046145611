// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-footer-sections-container {
  justify-content: var(--bitmark-page-footer-sections-flex-justify, flex-start);
}

.page-footer-section {
  width: var(--bitmark-page-footer-section-size-width, 25%);
  height: var(--bitmark-page-footer-section-size-height, auto);
  padding: var(--bitmark-page-footer-section-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-footer-section-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-page-footer-section-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-page-footer-section-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-footer-section-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-page-footer-section-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-page-footer-section-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-footer-section-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-page-footer-section-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-page-footer-section-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-footer-section-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-page-footer-section-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-page-footer-section-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-footer-section-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-page-footer-section-border-radius, var(--bitmark-x-border-radius, y));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
