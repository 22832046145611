import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AngularNodeViewComponent} from 'ngx-tiptap';

@Component({
  selector: 'bitmark-nodeview-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['../../../../bits/match-solution-grouped/match-select-placeholder/match-select-placeholder.component.scss']
})
export class NodeviewMultipleChoiceComponent extends AngularNodeViewComponent implements OnInit, AfterViewInit {
  options = [];
  selectPlaceholderBitActions = [];
  selectedAnswer: string = null;

  ngAfterViewInit() {
    this.selectedAnswer = this.node.attrs.text;
  }

  ngOnInit() {
    this.options?.forEach(value => {
      this.selectPlaceholderBitActions.push({
        label: value,
        handler: () => this.setAnswer(value)
      });
    });
  }

  setAnswer(value: string) {
    this.selectedAnswer = value;
  }

  openPopup() {
    const option = prompt('Enter an option');
    [option].forEach(value => {
      this.selectPlaceholderBitActions.push({
        label: value,
        handler: () => this.setAnswer(value)
      });
    });
    if (option) {
      this.setOptions([option]);
    }
  }

  setOptions(options: Array<string>): void {
    this.options = options;
    this.updateAttributes({
    });
  }
}
