import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {JSONContent} from '@tiptap/core';
import {BaseBit} from '../../bits.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {BitmarkTextContentService} from '../../../shared';
import {ModuleBit} from "../module.models";
import {BitbookApiService} from "../../../reader/bitbook-api.service";
import {TimeService} from "../../../shared/utils/time.service";

@Component({
  selector: 'bitmark-module-fields',
  templateUrl: './module-fields.component.html',
  styleUrls: ['./module-fields.component.scss']
})
export class ModuleFieldsComponent implements OnInit {
  @Input() bit?: ModuleBit;
  @Input() isBeingEditedByUser: boolean;
  @Input() canBeEdited = false;
  @Input() brandingKey?: string;
  @Input() updateInstructionEvent?: Observable<any>;
  @Output() onIsBeingEdited = new EventEmitter<BaseBit>();

  @ViewChild('fileInput') fileInput: ElementRef;

  BitmarkFormats = BitmarkFormat;

  isLeadEmpty = false;
  isItemEmpty = false;
  isInstructionEmpty = false;
  isTechnicalTermEmpty = false;
  bitTypeBranding: string;
  isImageError: boolean;
  coverImage: string;
  _durationMinutes: number;
  _durationHours: number;
  moduleDuration: any;

  constructor(private bitmarkTextContentService: BitmarkTextContentService,
              private bitbookApiService: BitbookApiService,
              private timeService: TimeService) {
  }

  ngOnInit() {
    this.bitTypeBranding = this.brandingKey || this.bit?.type;
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isInstructionEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.instruction, BitmarkFormat.MM);
    this.isTechnicalTermEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.technicalTerm?.technicalTerm, BitmarkFormat.MM);

    const duration = this.timeService.parseDuration(this.bit.duration);
    this._durationHours = duration.hours();
    this._durationMinutes = duration.minutes();
    this.moduleDuration = this.bit.duration;
    // this._durationHours = Math.floor((this.bit?.duration || this.bit._duration) / 60) || 0;
    // this._durationMinutes = (this.bit?.duration || this.bit._duration) % 60 || 0;

    this.updateInstructionEvent?.subscribe(x => {
      this.isInstructionEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.instruction, BitmarkFormat.MM);
    });
    this.coverImage = 'https://resources-test.getmorebrain.com/bit-resource-upload/user-177/31ld/41fqclma3tzugyi3rdsa5h_sensaletion_training.webp?width=1600';
    this.isImageError = false;
  }

  onDurationChanged(bit: any, changes: any){
    bit.duration = changes;

    const duration = this.timeService.parseDuration(this.bit.duration);
    this._durationHours = duration.hours();
    this._durationMinutes = duration.minutes();

    if(this.canBeEdited){
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  onItemChanged(bit: BaseBit, item: string) {
    bit.item = item;

    this.isItemEmpty = this.bit?.item?.trim()?.length === 0;

    if (this.canBeEdited) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  onLeadChanged(bit: BaseBit, lead: string) {
    bit.lead = lead;

    this.isLeadEmpty = this.bit?.lead?.trim()?.length === 0;

    if (this.canBeEdited) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  onInstructionChanged(bit: BaseBit, instruction: string) {
    bit.instruction = instruction

    this.isInstructionEmpty = this.bit?.instruction?.trim()?.length === 0;

    if (this.canBeEdited) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  removeImage(){
    this.bit.icon = null;
    this.onIsBeingEdited.emit(this.bit);
  }

  uploadImage(){
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitbookApiService.uploadResource(file, this.bit.id).subscribe((resource) => {
      this.bit.icon = resource?.url;
      this.onIsBeingEdited.emit(this.bit);
      console.log('updated image');
    });
  }
}
