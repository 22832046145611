import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {emoticons} from './emoticons.utils';

@Component({
  selector: 'app-emoticons',
  templateUrl: './emoticons.component.html',
  styleUrls: ['./emoticons.component.scss']
})
export class EmoticonsComponent implements OnInit {
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  emoticons = [];

  ngOnInit() {
    this.emoticons = emoticons.split(' ');
  }

  emoticonSelected(emoticon) {
    this.selected.emit(emoticon);
  }
}
