import {Component, Input} from '@angular/core';
import {BitResource} from '../../bits.models';

@Component({
  selector: 'bitmark-resource-website-link',
  templateUrl: './resource-website-link.component.html',
  styleUrls: ['./resource-website-link.component.scss', '../../bits.scss']
})
export class ResourceWebsiteLinkComponent {
  @Input() bitResource?: BitResource;

  cssClasses = [];

  onPreviewError() {
    this.cssClasses = [...this.cssClasses, 'preview-error'];
  }
}
