import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AppFlashcardsBit} from './app-flashcards.models';
import {BitbookApiService} from '../../../reader/bitbook-api.service';
import {BitApiWrapper} from '../../bits.models';
import {BitWrapperComponent} from '../../../reader';
import {BitbookMqService} from '../../../reader/bitbook-mq.service';
import {AppFlashcardsNormalComponent} from './normal-2/app-flashcards-normal.component';
import {AppFlashcardsLeitnerComponent} from './leitner-2/app-flashcards-leitner.component';
import {SubSink} from '../../../shared';

@Component({
  selector: 'bitmark-app-flashcards',
  templateUrl: './app-flashcards.component.html',
  styleUrls: ['./app-flashcards.component.scss', '../../bits.scss']
})
export class AppFlashcardsComponent implements OnInit, OnDestroy {
  @Input() bitWrapper?: BitApiWrapper;
  @Input() bit?: AppFlashcardsBit;
  @Input() isInModal = false;

  @Input()
  set isFocused(value: boolean) {
    if (!value) {
      this.isKeyboardFocused = false;
    }
  }

  @Output() changed = new EventEmitter<any>();

  @ViewChild('normalModeComponent') normalModeComponentElem: AppFlashcardsNormalComponent;
  @ViewChild('leitnerModeComponent') leitnerModeComponentElem: AppFlashcardsLeitnerComponent;

  isMobile = false;
  flashcardBits: Array<BitApiWrapper> = [];
  isLeitnerMode = false;
  loadingFlashcards = true;
  isKeyboardFocused = false;
  isDataLoaded = false;
  inViewportChange: Subject<boolean> = new Subject<boolean>();

  private sub = new SubSink();
  private viewportSubscription: Subscription;

  constructor(private bitbookApiService: BitbookApiService,
              private deviceDetectorService: DeviceDetectorService,
              private ngbModal: NgbModal,
              private ngbActiveModal: NgbActiveModal,
              private bitbookMqService: BitbookMqService) {
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isInModal = this.ngbModal.hasOpenModals();
    this.isLeitnerMode = this.bit.answer?.state?.mode === 'leitner';

    if (this.isInModal && !this.isDataLoaded) {
      this.loadData();
    } else {
      this.viewportSubscription = this.inViewportChange
        .pipe(debounceTime(500))
        .subscribe((inViewport: boolean) => {
          if (!inViewport) {
            return;
          }

          if (this.isDataLoaded) {
            this.viewportSubscription.unsubscribe();
            return;
          }

          this.loadData();
          this.viewportSubscription.unsubscribe();
        });
    }
    this.sub.sink = this.bitbookMqService.onBitShuffle(this.bitWrapper?.id).subscribe(this.shuffle.bind(this));
  }

  ngOnDestroy() {
    this.viewportSubscription?.unsubscribe();
    this.sub.unsubscribe();
  }

  loadData() {
    this.isDataLoaded = true;
    if (this.bit?.flashcardSet?.length) {
      this.loadingFlashcards = true;
      this.bitbookApiService.getFlashcards(this.bit.flashcardSet)
        .subscribe(data => {
          data.books?.forEach(book => {
            this.flashcardBits = [...this.flashcardBits, ...book.content];
          });
          this.loadingFlashcards = false;
        }, (err) => {
          this.loadingFlashcards = false;
          console.error(err);
        });
    } else {
      this.loadingFlashcards = false;
    }
  }

  toggleLeitnerMode(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isLeitnerMode = !this.isLeitnerMode;

    this.bit.answer.state = {
      mode: this.isLeitnerMode ? 'leitner' : 'normal'
    };
    this.changed.emit();
  }

  learn() {
    const modalRef = this.ngbModal.open(BitWrapperComponent, {
      windowClass: 'reader-modal',
      modalDialogClass: 'h-100',
      backdrop: 'static',
      backdropClass: 'd-none',
      keyboard: true,
      animation: false
    });
    (modalRef.componentInstance as BitWrapperComponent).bitWrapper = this.bitWrapper;
    (modalRef.componentInstance as BitWrapperComponent).context = 'notebook';
    (modalRef.componentInstance as BitWrapperComponent).fullScreen = true;
  }

  closeModal() {
    this.ngbActiveModal.close();
  }

  focusKeyboard() {
    this.isKeyboardFocused = !this.isKeyboardFocused;
  }

  shuffle() {
    if (this.isLeitnerMode) {
      this.leitnerModeComponentElem.shuffleCards();
    } else {
      this.normalModeComponentElem.shuffleCards();
    }
  }

  onInViewportChange(evt: { target: Element, visible: boolean }) {
    this.inViewportChange?.next(evt.visible);
  }
}
