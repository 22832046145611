import {Injector} from '@angular/core';
import {Node, mergeAttributes} from '@tiptap/core';
import {AngularNodeViewRenderer} from 'ngx-tiptap';

import {NodeviewVideoComponent} from './video.component';

const VideoComponentExtension = (injector: Injector): Node => {
  return Node.create({
    name: 'videoComponent',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    addAttributes() {
      return {
        videoSrc: {
          default: '',
        },
      };
    },
    parseHTML() {
      return [{tag: 'angular-component-video'}];
    },
    renderHTML({HTMLAttributes}) {
      return ['angular-component-video', mergeAttributes(HTMLAttributes)];
    },
    addNodeView() {
      return AngularNodeViewRenderer(NodeviewVideoComponent, {injector});
    },
  });
};

export default VideoComponentExtension;
