import {Component} from '@angular/core';
import {AudioRecordingBaseComponent} from '../../../shared/recording/audio-recording-base/audio-recording-base.component';
import {AudioRecordingService} from '../../../shared/recording/audio-recording.service';

@Component({
  selector: 'bitmark-record-audio-edit',
  templateUrl: './record-audio-edit.component.html',
  styleUrls: ['./record-audio-edit.component.scss']
})
export class RecordAudioEditComponent extends AudioRecordingBaseComponent {
  constructor(audioRecordingService: AudioRecordingService) {
    super(audioRecordingService);
    this.cssFeedbackBackgroundColor = '--bitmark-record-audio-audio-recorder-recording-background-color';
    this.cssFeedbackAudioWaveColor = '--bitmark-record-audio-audio-recorder-recording-audio-wave-color';
  }
}
