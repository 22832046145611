import {BaseBit} from '../bits.models';

export enum PlaceholderType {
  Gap = 'gap',
  Select = 'select'
}

export interface ClozeBit extends BaseBit {
  body?: string;
  placeholders?: any;
  quizCountItems?: boolean;
  quizStrikethroughSolutions?: boolean;
  additionalSolutions?: Array<string>;
}

export interface ClozeAndMultipleChoiceBit extends BaseBit {
  body?: string;
  placeholders?: any;
}

export interface GapPlaceholderBit {
  type: PlaceholderType.Gap;
  item: string;
  solutions: Array<string>;
  instruction: string;
  hint: string;
  isCaseSensitive: boolean;
  isExample: boolean;
  example: string;
  answer?: {
    text: string;
  };
}

export interface SelectPlaceholderBit {
  type: PlaceholderType.Select;
  item: string;
  options: Array<{ text: string, richText: string, isCorrect: boolean }>;
  prefix: string;
  prefixRichText: string;
  postfix: string;
  postfixRichText: string;
  hint: string;
  instruction: string;
  isExample: boolean;
  example: string;
  answer?: {
    text: string;
  };
}
