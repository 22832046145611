import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, RendererFactory2} from '@angular/core';
import {TouchSwipe, TouchSwipePayload} from './touch-swipe';

@Directive({
  selector: '[appTouchSwipe]'
})
export class TouchSwipeDirective implements OnInit, OnDestroy {
  @Input() x?;
  @Input() y?;
  @Input() transition?;
  @Input() axis?: 'horizontal' | 'vertical';
  @Input() gripVerticalStart?: number;
  @Input() gripVerticalEnd?: number;

  @Output() onPan = new EventEmitter<TouchSwipePayload>();
  @Output() onSwipe = new EventEmitter<TouchSwipePayload>();
  @Output() onSwipeAbort = new EventEmitter<TouchSwipePayload>();

  private touchSwipe: TouchSwipe;

  constructor(private elementRef: ElementRef,
              private rendererFactory: RendererFactory2) {
  }

  ngOnInit() {
    const renderer = this.rendererFactory.createRenderer(null, null);
    this.touchSwipe = new TouchSwipe(this.elementRef.nativeElement, renderer, this.x, this.y, this.transition, this.axis, this.gripVerticalStart, this.gripVerticalEnd);
    this.touchSwipe.applyTouchSwipe();
    this.touchSwipe.onSwipe.subscribe((e) => {
      this.onSwipe.emit(e);
    });
  }

  ngOnDestroy() {
    if (this.touchSwipe) {
      this.touchSwipe.destroy();
    }
  }
}
