// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-label-text {
  white-space: pre-line !important;
}

.orange-close {
  color: #ff8000;
}

.orange-editable-field {
  border: 1px solid #ff8000;
  border-radius: 8px;
  min-width: 250px;
  margin-left: -8px;
  padding: 0 0 0 5px;
  justify-content: space-between !important;
}

.translated-label-icon {
  height: 16px;
  cursor: pointer;
}

.translated-label {
  border: 1px solid #000000;
  padding: 0 5px;
  margin-left: -8px;
}

.min-250 {
  min-width: 250px;
}

.min-height-200 {
  min-height: 200px;
}

.title {
  font-size: 2.25rem;
  font-weight: 200;
  line-height: 40px;
  word-wrap: break-word;
}

.subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.editable-label-container {
  width: auto;
  display: inline-block;
}

@media (min-width: 992px) {
  .editable-label-container {
    min-width: 600px;
  }
}
@media (max-width: 680px) {
  .editable-label-container {
    width: 100%;
  }
}
.line-clamp span {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
