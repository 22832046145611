import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SurveyRatingBit} from './survey-rating.models';
import {DomUtilsService} from '../../../shared/dom/dom-utils.service';

@Component({
  selector: 'bitmark-survey-rating',
  templateUrl: './survey-rating.component.html',
  styleUrls: ['./survey-rating.component.scss', '../../bits.scss']
})
export class SurveyRatingComponent implements OnInit, AfterViewInit {
  private _bit?: SurveyRatingBit;
  @Input() set bit(value: SurveyRatingBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.computeSelectedLevel();
      this.computeFeedbackProps();
    }
  }

  get bit(): SurveyRatingBit {
    return this._bit;
  }

  @Input() displayOnly = false;
  @Input() isOnce = false;
  @Input() allowAnswerSubmit = true;
  @Output() changed = new EventEmitter<any>();

  levels: Array<string> = [];
  selectedLevel = '';
  showFeedback = false;

  private displayWithSign = false;
  private initialized = false;

  constructor(private domUtilsService: DomUtilsService) {
  }

  ngOnInit() {
    if (!this.bit?.ratingLevelStart || !this.bit?.ratingLevelEnd) {
      return;
    }

    this.displayWithSign = this.bit.ratingLevelStart.level < 0;
    for (let i = this.bit.ratingLevelStart.level; i <= this.bit.ratingLevelEnd.level; i++) {
      this.levels.push(this.displayWithSign ? `${(i <= 0 ? '' : '+')}${i}` : i.toString());
    }

    this.computeSelectedLevel();
    this.computeFeedbackProps();

    this.initialized = true;
  }

  ngAfterViewInit() {
    if (this.selectedLevel) {
      this.domUtilsService.scrollElementIntoView(`#bit-${this.bit.id}-selected-level`);
    }
  }

  markAsSelected(level: string) {
    if (this.displayOnly || !this.allowAnswerSubmit) {
      return;
    }

    const levelNo = Number(level);
    this.bit.answer = {
      level: this.bit.answer?.level === levelNo ? null : levelNo,
      hasSelected: true
    };

    this.computeSelectedLevel();

    if (!this.isOnce) {
      this.changed.emit();
    }
  }

  submitAnswer() {
    this.showFeedback = false;
    this.displayOnly = true;
    this.bit.answer.timestamp = new Date();
    this.changed.emit();
  }

  private computeSelectedLevel() {
    let selectedLevelNo = null;
    if (this.bit.answer?.hasSelected) {
      selectedLevelNo = this.bit.answer.level;
    } else if (this.bit.ratingLevelSelected !== undefined && this.bit.ratingLevelSelected !== null) {
      selectedLevelNo = this.bit.ratingLevelSelected;
    }

    if (selectedLevelNo !== null) {
      this.selectedLevel = this.displayWithSign ? `${(selectedLevelNo <= 0 ? '' : '+')}${selectedLevelNo}` : selectedLevelNo.toString();
    } else {
      this.selectedLevel = '';
    }
  }

  private computeFeedbackProps() {
    if (!this.isOnce) {
      return;
    }
    this.displayOnly = !!this.bit.answer?.hasSelected;
    this.showFeedback = !this.bit.answer?.hasSelected;
  }
}
