import {Renderer2} from '@angular/core';
import {fromEvent} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SubSink} from 'core/sub-sink/sub-sink';
import {DomUtilsService} from '../../../../../bitmark/src/lib/shared/dom/dom-utils.service';

export class ScrollAntiBlock {
  private subSink = new SubSink();

  constructor(private nativeElement: any,
              private scrollOffset: number,
              private debounceTime: number,
              private renderer: Renderer2,
              private deviceDetectorService: DeviceDetectorService,
              private domUtils: DomUtilsService) {
  }

  apply() {
    const isAndroid = this.deviceDetectorService.os.toLowerCase() === 'android';
    const isIos = this.deviceDetectorService.os.toLowerCase() === 'ios';
    const isChrome = this.deviceDetectorService.browser.toLowerCase() === 'chrome';

    this.renderer.setStyle(this.nativeElement, 'padding-bottom', `${this.scrollOffset}px`);
    this.renderer.setStyle(this.nativeElement, 'padding-top', `${this.scrollOffset}px`);

    if (isIos || isAndroid) {
      this.handleMobile();
      this.subSink.sink = fromEvent(this.nativeElement, 'scroll')
        .subscribe(() => this.handleMobile());
      return;
    }

    if (isChrome) {
      this.subSink.sink = fromEvent(this.nativeElement, 'wheel', {capture: false, passive: false})
        .subscribe(this.handleChrome.bind(this));
    }
  }

  destroy() {
    this.subSink.unsubscribe();
  }

  private fb(e) {
    return () => {
      e.scrollTop -= 1;
    };
  }

  private ft(e) {
    return () => {
      e.scrollTop += 1;
    };
  }

  private handleMobile() {
    const e = this.nativeElement;
    const db = e.scrollHeight - e.scrollTop - e.clientHeight;

    if (0 === db) {
      setTimeout(this.fb(e), 0);
      return;
    }

    const dt = e.scrollTop;
    if (0 === dt) {
      setTimeout(this.ft(e), 0);
    }
  }

  private handleChrome($event) {
    const direction = $event.wheelDeltaY < 0 ? 'up' : 'down';
    if (this.domUtils.isScrolledToBottom(this.nativeElement) && direction === 'up' ||
      this.domUtils.isScrolledToTop(this.nativeElement) && direction === 'down') {
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
  }
}
