import {Component, Input} from '@angular/core';
import {DropdownItemModel} from '../dropdown/dropdown/dropdown.model';

@Component({
  selector: 'bitmark-resource-button',
  templateUrl: './resource-button.component.html',
  styleUrls: ['./resource-button.component.scss']
})
export class ResourceButtonComponent {
  @Input() type: 'video' | 'audio' | 'image' | 'documents' | 'audio-image' | 'website';
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() actions: Array<DropdownItemModel> = [];
}
