// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-take-picture {
  border-radius: 10px;
  background: var(--bitmark-take-picture-button-background-shorthand, var(--bitmark-take-picture-button-background-color));
  width: 70px;
  height: 70px;
  border-top: var(--bitmark-take-picture-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-take-picture-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-take-picture-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-take-picture-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-take-picture-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-take-picture-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-take-picture-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-take-picture-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-take-picture-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-take-picture-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-take-picture-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-take-picture-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-take-picture-button-border-radius, var(--bitmark-x-border-radius, y));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
