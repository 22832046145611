import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges, OnDestroy, OnInit,
  Output, SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BitbookMqService} from '../../bitbook-mq.service';
import {SubSink, EvtUtilsService} from '../../../shared';

@Component({
  selector: 'bitmark-reader-search-box',
  templateUrl: 'reader-search-box.component.html',
  styleUrls: ['reader-search-box.component.scss'],
})
export class ReaderSearchBoxComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  private text = '';
  private sub = new SubSink();
  @Output() close = new EventEmitter<any>();

  @ViewChild('input', {static: false}) private inputElement: ElementRef<HTMLInputElement>;

  constructor(private evtUtilsService: EvtUtilsService,
              private bitbookMqService: BitbookMqService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub.sink = this.bitbookMqService.onSearchHistorySelected()
      .subscribe(this.onSearchFromHistory.bind(this));
    this.sub.sink = this.bitbookMqService.onSearchQuerySet()
      .subscribe(this.setValue.bind(this));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.route.snapshot.queryParams.q) {
      this.text = this.route.snapshot.queryParams.q;
      this.setValue(this.text);
    }
    fromEvent(this.inputElement.nativeElement, 'input').pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe((event: any) => {
      this.changeHandler(event);
    });
    this.setValue(this.text);
    this.setFocus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.text) {
      this.setValue(changes.text.currentValue);
    }
  }

  changeHandler($event) {
    if (typeof $event.target.value !== 'string') {
      return;
    }
    this.bitbookMqService.notifySearchQueryChanged($event.target.value);
  }

  setFocus() {
    this.inputElement.nativeElement.focus();
  }

  onFocusIn($event: any) {
    this.inputElement.nativeElement.setSelectionRange(0, $event.target.value.length);
  }

  onKeydownEscape($event) {
    this.evtUtilsService.cancelEvt($event);
    const value = this.inputElement.nativeElement.value.trim();
    if (value) {
      this.setValue('');
    } else {
      this.close.emit();
    }
  }

  onSearchFromHistory(historyItem: { searchQuery: string, activeFilters: any }) {
    this.setValue(historyItem.searchQuery);
  }

  private setValue(value: string) {
    if (!this.inputElement) {
      return;
    }
    this.inputElement.nativeElement.value = value;
    this.bitbookMqService.notifySearchQueryChanged(value);
  }
}
