import {Injectable} from '@angular/core';
import {NgbDatepickerI18nDefault} from '@ng-bootstrap/ng-bootstrap';
import {BitmarkSettings} from '../settings/bitmark.settings';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18nDefault {
  constructor() {
    super(BitmarkSettings.locale);
  }
}
