import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export default class StringCaseService {
  kebabToCamel(prop: string): string {
    prop = prop
      .replace(/-./g, x => x[1].toUpperCase())
      .replace(/_/g, '-');

    return prop.charAt(0).toLowerCase() + prop.slice(1);
  }

  camelToKebab(prop: string): string {
    return prop
      .replace(/[A-Z0-9]/g, letter => `-${letter.toLowerCase()}`)
      .replace(/_/g, '-');
  }
}
