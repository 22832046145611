import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { ShopInfo } from 'core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() offset: number;
  @Input() limit: number;
  @Input() total: number;
  @Input() shopBranding: ShopInfo;
  @Output() nextPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() previousPage: EventEmitter<any> = new EventEmitter<any>();
  begin = 0;
  end = 0;

  ngOnChanges() {
    this.begin = this.offset + 1;
    this.end = Math.min(this.offset + this.limit, this.total);
  }

  clickNextPage() {
    this.nextPage.emit();
  }

  clickPreviousPage() {
    this.previousPage.emit();
  }
}
