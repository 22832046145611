import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ShopInfo} from 'core';

@Injectable({providedIn: 'root'})
export class ShopBrandingService {

  private configSubject: BehaviorSubject<ShopInfo> = new BehaviorSubject({
    backgroundColor: '',
    backgroundImage: '',
    borderWidth: 0,
    borderColor: 'black',
    folderColor: '',
    folderTitleColor: '',
    folderTitleSecondaryColor: '',
    headerBackgroundColor: '',
    primaryColor: '',
    secondaryColor: '',
    sectionTitleColor: '',
    sectionTitleSecondaryColor: '',

    primaryActionButtonColor: '#543199',
    primaryActionButtonFontColor: 'white',
    primaryDropdownBackgroundColor: 'white',
    linkColor: '',

    headerFontColor: 'white',
    primaryDropdownFontColor: 'black'
  });

  get config$(): Observable<any> {
    return this.configSubject.asObservable();
  }

  updateConfig(newConfig: any): void {
    this.configSubject.next(newConfig);
  }

  getDefaults() {
    return {
      backgroundColor: 'black',
      backgroundImage: '',
      borderWidth: 0,
      borderColor: 'black',
      folderPrimaryColor: 'white',
      folderSecondaryColor: '#656769',
      folderTitleSecondaryColor: '#656769',
      headerBackgroundColor: 'black',
      primaryColor: 'white',
      secondaryColor: '#656769',
      sectionTitleColor: 'white',
      sectionTitleSecondaryColor: '#656769',

      primaryDropdownBackgroundColor: '#543199',
      primaryDropdownFontColor: 'white',
      primaryActionButtonColor: '#543199',
      primaryActionButtonFontColor: 'white',
      linkColor: '#9B67FF',
      headerFontColor: 'white',
    }
      ;
  }

  getTestDefaults() {
    return {
      backgroundColor: 'red',
      backgroundImage: '',
      borderWidth: 0,
      borderColor: 'black',
      folderPrimaryColor: 'white',
      folderSecondaryColor: '#656769',
      folderTitleSecondaryColor: '#656769',
      headerBackgroundColor: 'black',
      primaryColor: 'blue',
      secondaryColor: 'orange',
      sectionTitleColor: 'white',
      sectionTitleSecondaryColor: '#656769',

      primaryDropdownBackgroundColor: '#543199',
      primaryDropdownFontColor: 'white',
      primaryActionButtonColor: '#543199',
      primaryActionButtonFontColor: 'white',
      linkColor: '#9B67FF',
      headerFontColor: 'white',
    };
  }
}
