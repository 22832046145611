// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box {
  background-color: #272a2c;
  border-radius: 5px;
}
.search-box input {
  width: 100%;
  background: transparent;
  border: none;
  color: #656769;
  flex-grow: 5;
}

.search-box:focus-within {
  background: #361F63 !important;
}

.lg.search-box {
  max-width: 470px;
  width: 100%;
  height: 40px;
}
.lg.search-box input {
  font-size: 1.5rem;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.md.search-box {
  max-width: 470px;
  width: 100%;
  height: 30px;
}
.md.search-box input {
  font-size: 1.5rem;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
