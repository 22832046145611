// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1000;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
@media (max-width: 1024px) {
  .split {
    width: 100%;
  }
}

/* Control the left side */
.split-left {
  left: 0;
  background-color: #2d2d2d;
}

.split-bitmark-editor {
  background-color: #000000;
  border-radius: 8px;
  color: #7ec33b;
  white-space: pre-wrap;
  overflow: auto;
}

/* Control the right side */
.split-right {
  right: 0;
  background-color: #ffffff;
}
@media (max-width: 1024px) {
  .split-right {
    display: none;
  }
}

.bit-shadow {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
