import {BitmarkBitFeedback, BitmarkFormat, BookEntityToc} from '../shared/models';
import {TocItem} from '../reader';
import {BookEntity, BookEntityPublisher} from '../reader/reader.models';

export enum BitType {
  Invisible = 'invisible',
  SystemMessage = 'system-message',

  Article = 'article',
  Note = 'note',
  SideNote = 'side-note',
  EditorNote = 'editor-note',
  Example = 'example',
  Info = 'info',
  Remark = 'remark',
  Hint = 'hint',
  Help = 'help',
  Warning = 'warning',
  Danger = 'danger',
  Bug = 'bug',
  StickyNote = 'sticky-note', // ??
  Editorial = 'editorial',
  BookFrontispiece = 'book-frontispiece',
  BookTitle = 'book-title',
  BookCopyright = 'book-copyright',
  BookDedication = 'book-dedication',
  BookForeword = 'book-foreword',
  BookPreface = 'book-preface',
  BookPrologue = 'book-prologue',
  BookEpilogue = 'book-epilogue',
  BookIntroduction = 'book-introduction',
  BookIncitingIncident = 'book-inciting-incident',
  BookConclusion = 'book-conclusion',
  BookAfterword = 'book-afterword',
  BookPostscript = 'book-postscript',
  BookAppendix = 'book-appendix',
  BookAddendum = 'book-addendum',
  BookAcknowledgements = 'book-acknowledgements',
  BookListOfContributors = 'book-list-of-contributors',
  BookBibliography = 'book-bibliography',
  BookReferenceList = 'book-reference-list',
  BookEndnotes = 'book-endnotes',
  BookNotes = 'book-notes',
  BookCopyrightPermissions = 'book-copyright-permissions',
  BookTeaser = 'book-teaser',
  BookAuthorBio = 'book-author-bio',
  BookRequestForABookReview = 'book-request-for-a-book-review',
  BookComingSoon = 'book-coming-soon',
  BookReadMore = 'book-read-more',
  BookSummary = 'book-summary',
  BookImprint = 'book-imprint',
  BookLink = 'book-link',
  BookClose = 'book-close',
  Conclusion = 'conclusion',
  ChapterSubjectMatter = 'chapter-subject-matter',

  Image = 'image',
  ImageLink = 'image-link',
  ImageZoom = 'image-zoom',
  Screenshot = 'screenshot',
  FocusImage = 'focus-image',
  Photo = 'photo',
  BrowserImage = 'browser-image',
  ImageSuperWide = 'image-super-wide',
  ImagePrototype = 'image-prototype',
  ImagePortrait = 'image-portrait',
  ImageLandscape = 'image-landscape',
  ImageOnDevice = 'image-on-device',
  PageBanner = 'page-banner',
  ImageMood = 'image-mood',
  Figure = 'figure',
  ImageFigure = 'image-figure',
  ImageBanner = 'image-banner',
  ImageStyled = 'image-styled',
  ImageScreenshot = 'image-screenshot',
  ImageResponsive = 'image-responsive',
  ImageSeparator = 'image-separator',
  ImagesLogoGrave = 'images-logo-grave',
  LifeSkillSticker = 'life-skill-sticker',
  Audio = 'audio',
  AudioLink = 'audio-link',
  AudioEmbed = 'audio-embed',
  Video = 'video',
  VideoLink = 'video-link',
  VideoLinkLandscape = 'video-link-landscape',
  VideoLinkPortrait = 'video-link-portrait',
  VideoEmbed = 'video-embed',
  VideoEmbedLandscape = 'video-embed-landscape',
  VideoEmbedPortrait = 'video-embed-portrait',
  VideoPortrait = 'video-portrait',
  VideoLandscape = 'video-landscape',
  StillImageFilm = 'still-image-film',
  StillImageFilmLink = 'still-image-film-link',
  StillImageFilmEmbed = 'still-image-film-embed',
  WebsiteLink = 'website-link',
  Document = 'document',
  DocumentLink = 'document-link',
  DocumentEmbed = 'document-embed',
  DocumentDownload = 'document-download',
  AppLink = 'app-link',
  Featured = 'featured',
  NewspaperArticle = 'newspaper-article',
  BlogArticle = 'blog-article',
  BookArticle = 'book-article',
  NotebookArticle = 'notebook-article',
  WorkbookArticle = 'workbook-article',

  Table = 'table',
  TableImage = 'table-image',

  Code = 'code',

  Quote = 'quote',
  BookEpigraph = 'book-epigraph',

  PrototypeImages = 'prototype-images',

  SurveyRating = 'survey-rating',
  SurveyRatingOnce = 'survey-rating-once',
  SurveyRatingDisplay = 'survey-rating-display',

  FootNote = 'foot-note',
  BotActionSend = 'bot-action-send',

  BotActionResponse = 'bot-action-response',
  Cloze = 'cloze',
  ClozeSolutionGrouped = 'cloze-solution-grouped',
  ClozeInstructionGrouped = 'cloze-instruction-grouped',
  ConversationLeft1 = 'conversation-left-1',
  ConversationLeft1Scream = 'conversation-left-1-scream',
  ConversationLeft1Thought = 'conversation-left-1-thought',
  ConversationRight1 = 'conversation-right-1',
  ConversationRight1Scream = 'conversation-right-1-scream',
  ConversationRight1Thought = 'conversation-right-1-thought',
  MultipleChoiceText = 'multiple-choice-text',
  ClozeAndMultipleChoiceText = 'cloze-and-multiple-choice-text',
  MultipleChoice = 'multiple-choice',
  MultipleChoice1 = 'multiple-choice-1',
  MultipleResponse = 'multiple-response',
  MultipleResponse1 = 'multiple-response-1',
  Assignment = 'assignment',
  ButtonCopyText = 'button-copy-text',
  PageBuyButton = 'page-buy-button',
  PageBuyButtonAlt = 'page-buy-button-alt',
  PageBuyButtonPromotion = 'page-buy-button-promotion',
  PageFooter = 'page-footer',
  PagePerson = 'page-person',
  PageOpenBook = 'page-open-book',
  PageProduct = 'page-product',
  PageProductList = 'page-product-list',
  PageProductVideo = 'page-product-video',
  PageProductVideoList = 'page-product-video-list',
  PageSectionFolder = 'page-section-folder',
  Chapter = 'chapter',
  InternalLink = 'internal-link',
  Essay = 'essay',
  Flashcard1 = 'flashcard-1',
  Interview = 'interview',
  InterviewInstructionGrouped = 'interview-instruction-grouped',
  Match = 'match',
  MatchAll = 'match-all',
  MatchReverse = 'match-reverse',
  MatchAllReverse = 'match-all-reverse',
  MatchSolutionGrouped = 'match-solution-grouped',
  MatchMatrix = 'match-matrix',
  RecordAudio = 'record-audio',
  RecordVideo = 'record-video',
  ReleaseNote = 'release-note',
  TrueFalse = 'true-false',
  TrueFalse1 = 'true-false-1',
  SelfAssessment = 'self-assessment',
  Statement = 'statement',
  Details1 = 'details-1',
  About = 'about',
  DetailsImage = 'details-image',
  LearningEventClassroomEvent = 'le-classroom-event',
  LearningEventCompletion = 'le-completion',
  LearningEventExternalLink = 'le-external-link',
  LearningEventLearningObjectives = 'le-learning-objectives',
  LearningEventLearningStep = 'le-learning-step',
  LearningEventPreparationTask = 'le-preparation-task',
  LearningEventReadBook = 'le-read-book',
  LearningEventVideoCall = 'le-video-call',
  LearningPathLti = 'learning-path-lti',
  LearningPathClosing = 'learning-path-closing',
  LearningPathVideoCall = 'learning-path-video-call',
  LearningPathBook = 'learning-path-book',
  LearningPathClassroomEvent = 'learning-path-classroom-event',
  LearningPathExternalLink = 'learning-path-external-link',
  LearningPathStep = 'learning-path-step',
  LearningPathLearningGoal = 'learning-path-learning-goal',
  BookSources = 'book-sources',
  PadletEmbed = 'vendor-padlet-embed',
  Mark = 'mark',
  Toc = 'toc',
  Summary = 'summary',
  Scorm = 'scorm',
  SampleSolution = 'sample-solution',
  PreparationNote = 'preparation-note',
  Correction = 'correction',
  TakePicture = 'take-picture',
  Survey = 'survey',
  SurveyMatrix = 'survey-matrix',
  SurveyMatrixMe = 'survey-matrix-me',
  Message = 'message',
  Page = 'page',
  PageArticle = 'page-article',
  ExtractorPage = 'extractor-page',
  ExtractorBlock = 'extractor-block',
  ExtractorPageWithBlocks = 'extractor-page-with-blocks',
  ExtractorRepeatedText = 'extractor-repeated-text',
  ExtractorPageNumber = 'extractor-page-number',
  ExtractorPageHeader = 'extractor-page-header',
  ExtractorPageFooter = 'extractor-page-footer',
  AnnotationBookmark = 'annotation-bookmark',
  AnnotationNote = 'annotation-note',
  AnnotationFavorite = 'annotation-favorite',
  AnnotationHandwritten = 'annotation-handwritten',
  Highlight = 'highlight',
  Checklist = 'checklist',
  Separator = 'separator',
  VideoTranscript = 'video-transcript',
  AudioTranscript = 'audio-transcript',
  Module = 'module',

  BitBookSummary = 'bit-book-summary',
  BitBookEnding = 'bit-book-ending',
  VirtualMarkBookAsRead = 'virtual-mark-book-as-read',
  VirtualHandIn = 'virtual-hand-in',
  VirtualHandInReview = 'virtual-hand-in-review',
  VirtualHandInResult = 'virtual-hand-in-result',

  Card1 = 'card-1',
  Question1 = 'question-1',

  LangLearningOutcomes = 'lang-learning-outcomes',
  LangEnablingLanguageSkills = 'lang-enabling-language-skills',
  LangLifeSkills = 'lang-life-skills',
  LangEnglishAroundWorld = 'lang-english-around-world',
  LangGoodToKnow = 'lang-good-to-know',
  LangLearningStrategy = 'lang-learning-strategy',
  LangLikeALocal = 'lang-like-a-local',
  LangUsefulPhrases = 'lang-useful-phrases',
  LangLevelDown = 'lang-level-down',
  LangLevelUp = 'lang-level-up',
  LangExtraActivity = 'lang-extra-activity',
  LangVideoScript = 'lang-video-script',
  LangAudioScript = 'lang-audio-script',
  LangVocabulary = 'lang-vocabulary',
  LangHomework = 'lang-homework',
  LangTeacherNote = 'lang-teacher-note',
  LangTeacherPronunciation = 'lang-teacher-pronunciation',
  LangMaterial = 'lang-material',
  LangLearningGoal = 'lang-learning-goal',

  AiPrompt = 'ai-prompt',
  NoteAi = 'note-ai',
  SummaryAi = 'summary-ai',
  ArticleAi = 'article-ai',

  ReviewNote = 'review-note',
  ReviewAuthorNote = 'review-author-note',
  ReviewReviewerNote = 'review-reviewer-note',
  ReviewRequestForReviewNote = 'review-request-for-review-note',
  ReviewApprovedNote = 'review-approved-note',

  Step = 'step',
  StepImageScreenshot = 'step-image-screenshot',
  StepImageScreenshotWithPointer = 'step-image-screenshot-with-pointer',

  Collapsible = 'collapsible',
  SideNoteCollapsible = 'side-note-collapsible',
  InfoCollapsible = 'info-collapsible',
  RemarkCollapsible = 'remark-collapsible',
  WarningCollapsible = 'warning-collapsible',
  DangerCollapsible = 'danger-collapsible',
  NoteCollapsible = 'note-collapsible',
  ExampleCollapsible = 'example-collapsible',
  HintCollapsible = 'hint-collapsible',
  BugCollapsible = 'bug-collapsible',

  CookIngredients = 'cook-ingredients',
  CookPreparation = 'cook-preparation',
  CookStep = 'cook-step',
  CookRemark = 'cook-remark',
  CookVariation = 'cook-variation',
  CookInsert = 'cook-insert',
  CookArrangement = 'cook-arrangement',
  CookPracticeAdvise = 'cook-practice-advise',
  CookPlate = 'cook-plate',
  CookRecommendation = 'cook-recommendation',
  CookPersonalRecommendation = 'cook-personal-recommendation',
  CookSideDrink = 'cook-side-drink',
  CookSideDish = 'cook-side-dish',
  Recipe = 'recipe',

  ArticleAlt = 'article-alt',
  PageArticleAlt = 'page-article-alt',
  NoteAlt = 'note-alt',
  ExampleAlt = 'example-alt',
  RemarkAlt = 'remark-alt',
  StandardArticleNormative = 'standard-article-normative',
  StandardArticleNonNormative = 'standard-article-non-normative',
  StandardNoteNormative = 'standard-note-normative',
  StandardNoteNonNormative = 'standard-note-non-normative',
  StandardExampleNormative = 'standard-example-normative',
  StandardExampleNonNormative = 'standard-example-non-normative',
  StandardRemarkNormative = 'standard-remark-normative',
  StandardRemarkNonNormative = 'standard-remark-non-normative',
  InfoAlt = 'info-alt',
  HintAlt = 'hint-alt',
  HelpAlt = 'help-alt',
  WarningAlt = 'warning-alt',
  DangerAlt = 'danger-alt',
  BugAlt = 'bug-alt',
  ConclusionAlt = 'conclusion-alt',
  SeparatorAlt = 'separator-alt',

  ImageFigureAlt = 'image-figure-alt',
  StandardImageFigureNormative = 'standard-image-figure-normative',
  StandardImageFigureNonNormative = 'standard-image-figure-non-normative',
  StandardTableImageNormative = 'standard-table-image-normative',
  StandardTableImageNonNormative = 'standard-table-image-non-normative',
  StandardRemarkTableImageNormative = 'standard-remark-table-image-normative',
  StandardRemarkTableImageNonNormative = 'standard-remark-table-image-non-normative',

  TableAlt = 'table-alt',
  TableImageAlt = 'table-image-alt',
  ImageSeparatorAlt = 'image-separator-alt',
  StandardTableNormative = 'standard-table-normative',
  StandardTableNonNormative = 'standard-table-non-normative',
  StandardRemarkTableNormative = 'standard-remark-table-normative',
  StandardRemarkTableNonNormative = 'standard-remark-table-non-normative',

  AppAiPrompt = 'app-ai-prompt',
  AppFlashcards = 'app-flashcards',
  AppGetScreenshot = 'app-get-screenshot',
  AppCreateBitsFromImage = 'app-create-bits-from-image',
  AppCodeEditor = 'app-code-editor',

  IFramelyEmbed = 'vendor-iframely-embed',
  HighchartsChart = 'vendor-highcharts-chart'
}

export enum BitEditorStatus {
  Ready = 'ready',
  Updating = 'updating',
  Failed = 'failed'
}

export interface BitFeedback {
  timeStamp: number;
  streaming?: boolean;
  correctness?: string;
  message?: string;
  topic?: {
    name: string;
  };
  context?: Array<{
    offset: number;
    length: number;
    content: string;
  }>;
}

export interface BaseBit {
  id?: string;
  item?: any;
  lead?: any;
  instruction?: any;
  technicalTerm?: {
    lang?: string;
    technicalTerm?: any;
  };
  hint?: string;
  type: BitType;
  format?: BitmarkFormat;
  resource?: BitResource;
  reference?: string;
  lang?: string;
  isExample?: boolean;
  icon?: string;
  publisher?: Array<string>;
  theme?: Array<string>;
  feedback?: Array<BitFeedback>;
  feedbackEngine?: {
    feedbackId: string;
    userId: string;
    timeOnTask: number;
  };
  feedbackId?: Array<string>;
  feedbackType?: BitmarkBitFeedback;
  answer?: any;
  feedbackAnswer?: {
    step?: number;
    message?: string;
  };
  disableFeedback?: boolean;
  analyticsTag?: Array<string>;
  handInApproved?: boolean;
}

export interface BitResourceImage {
  src?: string;
  src1x?: string;
  src2x?: string;
  src3x?: string;
  showInIndex?: boolean;
  format?: string;
  width?: number;
  widthNative?: number;
  height?: number;
  heightNative?: number;
  alt?: string;
  caption?: any;
  license?: string;
  copyright?: string;
  provider?: string;
  zoomDisabled?: boolean;
}

export interface BitResourceImageLink {
  provider: string;
  url: string;
}

export interface BitResourceAudio {
  format?: string;
  src?: string;
  duration?: string;
  autoplay?: boolean;
  caption?: string;
}

export interface BitResourceAudioLink {
  provider: string;
  url: string;
  duration: string;
  autoplay: boolean;
}

export interface BitResourceVideo {
  format?: string;
  src?: string;
  width?: number;
  height?: number;
  displayWidth?: number;
  displayHeight?: number;
  duration?: string;
  mute?: boolean;
  autoplay?: boolean;
  allowSubtitles?: boolean;
  showSubtitles?: boolean;
  alt?: string;
  caption?: any;
  license?: string;
  copyright?: string;
  posterImage?: {
    src?: string;
    format?: string;
    width?: number;
    height?: number;
    widthNative?: number;
    heightNative?: number;
  };
}

export interface BitResourceVideoLink {
  provider: string;
  url: string;
  width?: string;
  height?: string;
  duration?: string;
  posterImage?: string;
  thumbnails?: Array<{ src1x: string; format: string; size: string }>;
  caption?: any;
  copyright?: string;
}

export interface BitResourceArticle {
  format: BitmarkFormat;
  body?: string;
  href?: string;
}

export interface BitResourceArticleLink {
  url: string;
}

export interface BitResourceDocument {
  url: string;
  type?: string;
}

export interface BitResourceWebsiteLink {
  siteName: string;
  url: string;
}

export interface BitResourceUploadDetails {
  fileName: string;
  fileType: string;
  displayHeight: number;
  displayWidth: number;
  height: number;
  width: number;
  orientation: number;
  xRes: number;
  yRes: number;
}

export interface BitResourcePreview {
  title?: string;
  description?: string;
  icon?: {
    svg?: string;
    license?: string;
    code?: string;
    tint?: string;
  };
  image?: {
    src1x?: string;
    src?: string;
    format?: string;
    width?: number;
    height?: number;
  };
}

export interface BitResourceSize {
  width: number;
  height: number;
  shrinkFactor: number;
  zoomDisabled: boolean;
}

export enum BitResourceType {
  Image = 'image',
  ImageOnLine = 'image-online',
  ImageLink = 'image-link',
  Audio = 'audio',
  AudioLink = 'audio-link',
  ImageWithAudio = 'image-with-audio',
  Video = 'video',
  VideoLink = 'video-link',
  Article = 'article',
  Website = 'website',
  WebsiteLink = 'website-link',
  Document = 'document'
}

export interface BitResource {
  type: BitResourceType;
  search?: string;
  preview?: BitResourcePreview;
  private?: any | {
    uploadDetails?: any | BitResourceUploadDetails
  };
  image?: BitResourceImage;
  imageLink?: BitResourceImageLink;
  imageLandscape?: BitResourceImage;
  imagePortrait?: BitResourceImage;
  imageOnline?: BitResourceImageLink; // todo: deprecated
  audio?: BitResourceAudio;
  audioLink?: BitResourceAudioLink;
  video?: BitResourceVideo;
  videoEmbed?: BitResourceVideoLink;
  videoLink?: BitResourceVideoLink;
  videoOnline?: BitResourceVideoLink; // todo: deprecated
  article?: BitResourceArticle;
  articleLink?: BitResourceArticleLink;
  document?: BitResourceDocument;
  websiteLink?: BitResourceWebsiteLink;
  website?: BitResourceWebsiteLink; // todo: deprecated
}

export enum ReaderEditorOperationType {
  PdfUpload = 'pdfUpload'
}

export interface ReaderEditorOperation {
  operationType: ReaderEditorOperationType;
  data: {
    runningJobs: Array<any>
  };
}

export interface BitApiWrapperMeta {
  originBook?: BookEntity;
  context?: string;
  subject?: string;
  topics?: Array<string>;
  grammar?: Array<string>;
  skills?: Array<string>;
  ageRange?: Array<any>;
  tags?: Array<string>;
  grade?: string;
  complexity?: string;
  levelIlr?: string;
  levelCefr?: string;
  levelActfl?: string;
  language?: string;
  learningLanguage?: string;
  masterBook?: BookEntity;
  copyright?: string;
  publisher?: BookEntityPublisher;
  license?: string;
  publications?: Array<string>;
  authors?: Array<string>;
  isPreview?: boolean;
  rootBitId?: string;
  originBitId?: string;
  thisBook?: BookEntity;
  chapterPath?: Array<BookEntityToc>;
  publisherId?: string;
  seriesId?: string;
  createdByUser?: {
    id?: number;
    externalId?: string | number;
    email?: string;
  };

  groups?: Array<string>; //todo: is this still returned from api
  sLanguage?: string; //todo: is this still returned from api
  sLearningLanguage?: string; //todo: is this still returned from api
  themeId?: string; // todo: add this to api  //todo: is this still returned from api

  branding?: BitApiBrandingMeta;
}

export interface BitApiWrapper {
  id?: string;
  bitmark?: string;
  bit?: BaseBit;
  bookId?: string | number;
  deletionDate?: string;
  spaceId?: string;
  isTrashed?: boolean;
  system?: BitApiWrapperSystem;
  lastUpdatedAt?: string;
  index?: number;
  meta?: BitApiWrapperMeta;
  alternateBits?: Array<any>;
  bitLikeCounter?: number;
  bitPopularityCounter?: number;
  publishedBit?: {
    id?: number;
    bit?: BaseBit;
    hasNewBitVersion?: boolean;
    lastUpdatedAt?: string;
    hint?: string;
  };
  bitInstanceId?: number;
  chapterPath?: Array<BookEntityToc>;

  errors?: Array<any>;
  tags?: Array<string>;
  analyticsTag?: Array<string>;
  reductionTag?: Array<string>;
  editorOperation?: ReaderEditorOperation;
  editorLoadingPre?: boolean;
  editorLoadingPost?: boolean;
  isBeingEdited?: boolean;
  canBeEdited?: boolean;
  isBeingEditedByUser?: boolean;
  saveUserEdit?: boolean;
  isEditNotAllowed?: boolean;
  showBitActions?: boolean;

  // for notebook summary
  // actions?: Array<DropdownItemModel>;

  annotations?: Array<BitApiAnnotation>;
  externalAnnotations?: Array<BitApiAnnotation>;

  // when editing with Bitmark
  updatedTimestamp?: string;

  // when saving bitmark
  tocEntry?: TocItem;

  // for externalAnnotations
  user?: any;

  feedbacks?: {
    feedbackBit: BaseBit;
    id: number;
    senderId: number;
    senderUser: any;
    timeStamp: number;
    tlEntityId: number;
  }[]
}

export interface BitApiAnnotation {
  id: number;
  type: string;
  userId: number;
  bitId: number;
  data: BaseBit;
  tocEntry?: TocItem;
  showBitActions?: boolean;
  user?: any;
  createdAt?: string;
}

export interface BitApiBrandingMeta {
  theme: string;
  publisher: BookEntityPublisher;
}

export interface BitsViewPortVisibility {
  bitsWithContentInViewPort: Array<BitApiWrapper>;
  bitsTouchingViewPort: Array<BitApiWrapper>;
  bitAnnotationsTouchingViewPort: Array<BitApiAnnotation>;
  stats: {
    computationTimeMs: number;
    activeEditorsCount: number;
  };
}

export interface BitApiWrapperSystem {
  parser?: string;
  creationDate?: string;
  parserVersion?: string;
  bitmarkVersion?: string;
}

export type BitmarkTextPart = string | { style: string; text: string; chain: string };
export type BitmarkText = Array<BitmarkTextPart>;

export interface BitFeedbackRes {
  attempts: number;
  bitId: string;
  bitmark: string;
  bookId: number;
  createdAt: any;
  createdBy: number;
  deletionDate: any;
  id: any;
  isTrashed: boolean;
  language: string;
  learningLanguage: string;
  lastUpdatedAt: any;
  bit: BaseBit;
}

