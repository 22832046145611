import {Component, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {VideoRecordingService} from '../../../../shared/recording/video-recording.service';
import {VideoRecordingBaseComponent} from '../../../../shared/recording/video-recording-base/video-recording-base.component';

@Component({
  selector: 'bitmark-resource-video-recording-edit',
  templateUrl: './resource-video-recording-edit.component.html',
  styleUrls: ['./resource-video-recording-edit.component.scss']
})
export class ResourceVideoRecordingEditComponent extends VideoRecordingBaseComponent implements OnInit {
  constructor(videoRecordingService: VideoRecordingService,
              deviceDetectorService: DeviceDetectorService) {
    super(videoRecordingService, deviceDetectorService);
    this.cssFeedbackAudioWaveColor = '--bitmark-resource-video-recording-audio-wave-color';
  }

  ngOnInit() {
    if (!this.deviceDetectorService.isMobile()) {
      this.videoCameraConfigOptions.audio = true;
    }
    this.initialize().subscribe();
  }
}
