import {Paragraph} from '@tiptap/extension-paragraph';

export const BitmarkParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: 'normal',
      },
      paragraphType: {
        default: 'normal'
      }
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setParagraphClass: (paragraphType) => ({ commands }) => {
        return commands.updateAttributes('paragraph', { class: paragraphType + ' background-text', paragraphType: paragraphType});
      },
    };
  },
});
