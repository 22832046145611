import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'environment';
import {MqService} from '../mq/mq.service';
import {ApiService} from '../api/api.service';

export interface AppVersion {
  string: string;
  major: number;
  minor: number;
  patch: number;
}

@Injectable({providedIn: 'root'})
export class AppEventsService {
  private _newVersion: string = null;

  constructor(private mqService: MqService,
              private apiService: ApiService) {
  }

  private stringToAppVersion(appVersion: string): AppVersion {
    const split = appVersion.split('.');
    return {
      string: appVersion,
      major: +split[0] || 0,
      minor: +split[1] || 0,
      patch: +split[2] || 0,
    };
  }

  notifyAppLoaded() {
    this.mqService.pub('app.events.loaded', null);
  }

  onAppLoaded(): Observable<any> {
    return this.mqService.sub('app.events.loaded');
  }

  notifyAppUnloaded() {
    this.mqService.pub('app.events.unloaded', null);
  }

  onAppUnloaded(): Observable<any> {
    return this.mqService.sub('app.events.unloaded');
  }

  notifyAppShown() {
    this.mqService.pub('app.events.shown', null);
  }

  onAppShown(): Observable<any> {
    return this.mqService.sub('app.events.shown');
  }

  notifyAppHidden() {
    this.mqService.pub('app.events.hidden', null);
  }

  onAppHidden(): Observable<any> {
    return this.mqService.sub('app.events.hidden');
  }

  notifyAppOffline() {
    this.mqService.pub('app.events.offline', null);
  }

  onAppOffline() {
    return this.mqService.sub('app.events.offline');
  }

  notifyAppOnline() {
    this.mqService.pub('app.events.online', null);
  }

  onAppOnline() {
    return this.mqService.sub('app.events.online');
  }

  notifyAppBackOnline() {
    this.mqService.pub('app.events.backOnline', null);
  }

  onAppBackOnline() {
    return this.mqService.sub('app.events.backOnline');
  }

  notifyAppPushNotificationHandled() {
    this.mqService.pub('app.events.pushNotification', null);
  }

  onAppPushNotificationHandled() {
    return this.mqService.sub('app.events.pushNotification');
  }

  checkForUpdates(): Observable<AppVersion | null> {
    this._newVersion = null;
    return new Observable(x => {
      const qry = ApiService.buildQuery([{t: new Date().getTime()}]);
      this.apiService.get(`app-version?${qry}`, null)
        .pipe(map((res: { version: string }) => {
          const newVersion = this.stringToAppVersion(res.version);
          const curVersion = this.stringToAppVersion(environment.version);
          if (newVersion.major > curVersion.major || newVersion.minor > curVersion.minor || newVersion.patch > curVersion.patch) {
            this._newVersion = newVersion.string;
            return newVersion;
          }
          return null;
        }))
        .subscribe((newVersion: AppVersion | null) => {
          x.next(newVersion);
          x.complete();
        }, () => {
          x.next(null);
          x.complete();
        });
    });
  }

  get newVersion(): string | null {
    return this._newVersion;
  }
}

