import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {JSONContent} from '@tiptap/core';
import {Flashcard1Bit} from './flashcard-1.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import {BitmarkFormat} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-flashcard-1',
  templateUrl: './flashcard-1.component.html',
  styleUrls: ['./flashcard-1.component.scss']
})
export class Flashcard1Component implements OnInit {
  @Input() bit: Flashcard1Bit;
  @Input() canBeEdited: boolean;
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() saveInlineBit = new EventEmitter<Flashcard1Bit>();

  isMobile = false;

  constructor(private deviceDetectorService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
  }

  onUpdateQuestion(bit: Flashcard1Bit, bitJson: JSONContent) {
    if (!bit.cards?.length) {
      bit.cards = [{
        question: bitJson?.content
      }];
    } else {
      bit.cards[0].question = bitJson?.content;
    }

    if (this.canBeEdited) {
      bit.format = BitmarkFormat.PP;
      this.saveInlineBit.emit(this.bit);
    }
  }

  onUpdateAnswer(bit: Flashcard1Bit, bitJson: JSONContent) {
    if (!bit.cards?.length) {
      bit.cards = [{
        answer: bitJson?.content
      }];
    } else {
      bit.cards[0].answer = bitJson?.content;
    }

    if (this.canBeEdited) {
      bit.format = BitmarkFormat.PP;
      this.saveInlineBit.emit(this.bit);
    }
  }
}
