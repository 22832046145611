import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ScrollbarService {
  _document = document;
  scrolledFromTop = 0;
  previousBodyPaddingRight;

  lockBodyScrollbar() {
    this.scrolledFromTop = this._document.body.scrollTop || this._document.documentElement.scrollTop;
    this._document.body.classList.add('overflow-hidden');
    this._document.body.style.position = 'fixed';
    this._document.body.style.top = (this.scrolledFromTop * (-1)) + 'px';
    this.previousBodyPaddingRight = this._document.body.style.paddingRight;
    const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
    this._document.body.style.paddingRight = `${scrollBarGap}px`;
  }

  unLockBodyScrollbar() {
    this._document.body.classList.remove('overflow-hidden');
    this._document.body.style.position = 'static';
    this._document.body.style.top = 'inherit';
    this._document.body.style.marginRight = '0';
    scrollTo(0, this.scrolledFromTop);
    this._document.body.style.paddingRight = this.previousBodyPaddingRight;
  }
}
