import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class WindowService {
  getBaseUrl(): string {
    const port = [80, undefined, ""].indexOf(window.location.port) !== -1
      ? "" :
      `:${window.location.port}`;
    return `${window.location.protocol}//${window.location.hostname}${port}`;
  }

  getHostName(): string {
    return window.location.hostname;
  }
}
