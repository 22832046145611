import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'bitmark-reader-search-header',
  templateUrl: './reader-search-header.component.html',
  styleUrls: ['./reader-search-header.component.scss'],
})
export class ReaderSearchHeaderComponent {
  @Input() isExternalSearch: boolean;
  @Output() toggleSidebar = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
}
