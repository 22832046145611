// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item-button {
  border: none;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: left;
}
.dropdown-item-button:disabled {
  cursor: default;
}
.dropdown-item-button:not(:disabled):hover {
  background-color: #a98ae6;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
