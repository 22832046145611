import {Component, EventEmitter, Input, Output} from '@angular/core';
import {cloneDeep} from 'lodash';
import {LearningPathStepBit} from './learning-path-step.models';
import {BitResource} from '../../bits.models';
import {LearningPathCommon} from '../learning-path.common';
import {BitmarkTextContentService} from '../../../shared';
import {ReaderTipTapTapService} from "../../../reader/tiptap/reader-tiptap.service";

@Component({
  selector: 'bitmark-learning-path-step',
  templateUrl: './learning-path-step.component.html',
  styleUrls: ['./learning-path-step.component.scss', '../learning-path.common.scss', '../../bits.scss']
})
export class LearningPathStepComponent extends LearningPathCommon {
  @Input() bit?: LearningPathStepBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningPathStepBit>();

  private prevLearningPathStepBit?: LearningPathStepBit;

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    this.trimHeader(this.bit);

    if (value) {
      this.prevLearningPathStepBit = cloneDeep(this.bit);
    }
  }
  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }
  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  get isHeaderMinimized(): boolean {
    return (this.bit.answer?.isDone || this.bit.isInfoOnly) && !this.bit?.body;
  }

  get isTracked(): boolean {
    return this.bit.isTracked ?? true;
  }

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService) {
    super(readerTipTapService, bitmarkTextContentService);
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevLearningPathStepBit);
    this.editClosed.emit();
  }

  saveChanges() {
    this.trimHeader(this.bit);
    this.saveInlineBit.emit(this.bit);
  }

  markAsDone() {
    this.bit.answer = {
      isDone: true
    };
    this.changed.emit();
  }

  reset(event) {
    event.stopPropagation();
    this.bit.answer = {
      isDone: false
    };
    this.changed.emit();
  }
}
