// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.translate-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  z-index: 5;
  animation: translate-forward 600ms linear forwards;
}

.translate-back-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  z-index: 5;
  animation: translate-backward 600ms linear forwards;
}

@keyframes translate-forward {
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-800px, -50%);
    opacity: 0;
  }
}
@keyframes translate-backward {
  0% {
    transform: translate(-800px, -50%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
.prev-flashcards-card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-800px, -50%);
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
