import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BitTranslateLanguagePickerComponent, SubSink} from '../../../shared';
import {BitType} from '../../../bits/bits.models';
import {ReaderTextEditorComponent} from '../reader-text-editor/reader-text-editor.component';
import {BitbookApiService} from "../../bitbook-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'bitmark-html-translator',
  templateUrl: './html-translator.component.html',
  styleUrls: ['./html-translator.component.scss']
})
export class ReaderHtmlTranslatorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;
  @ViewChild('codeEditor') codeEditorElement: ElementRef;
  @Input() bookExternalId: string;
  @Input() isOpen = false;
  @Output() close = new EventEmitter();
  leftJson = '';
  private subSink = new SubSink();
  pasteListener: () => void;
  @Input() isBeingEdited = true;
  @Input() canBeEdited = false;
  BitType = BitType;
  translatedHtml: string;
  glossaryTranslations: Array<any>;

  constructor(private bitbookApiService: BitbookApiService,
              private ngbModal: NgbModal){

  }

  ngAfterViewInit() {
    this.subSink.sink = fromEvent(this.codeEditorElement.nativeElement, 'input')
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe();
  }

  ngOnDestroy() {
    if (this.pasteListener) {
      this.pasteListener();
    }
  }

  translateHtml(){
    const modalRef = this.ngbModal.open(BitTranslateLanguagePickerComponent, {
      windowClass: 'transparent-modal md',
      animation: false,
    });

    const sub = (modalRef.componentInstance as BitTranslateLanguagePickerComponent).languageChosen.subscribe((language: string) => {
      if (!language) {
        return;
      }
      const txt = this.codeEditorElement.nativeElement.value;
      this.bitbookApiService.translateContent({bit: {bitmark: txt}} as any, language || 'en', null)
        .subscribe((translatedBitmark) => {
          this.translatedHtml = translatedBitmark.bitmark;
        }, (err) => console.error(err));
    })

  }

  onSave() {
    // const txt = this.codeEditorElement.nativeElement.value;
    this.close.emit();
  }

  enterEditMode() {
    if (this.canBeEdited) {
      this.isBeingEdited = true;
      this.editorComponent.enterEditMode();
    }
  }
}
