import {Injectable} from '@angular/core';
import {concatMap, Observable, of, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {BrowserStorageService} from '../shared/browser-storage/browser-storage.service';
import {BitbookApiService} from './bitbook-api.service';
import {BitbookMqService} from './bitbook-mq.service';
import {BaseBit, BitApiWrapper} from '../bits/bits.models';

@Injectable()
export class ReaderBasketService {
  private BASKET_COUNT_KEY = 'reader-basket-count';

  constructor(private browserStorageService: BrowserStorageService,
              private bitBookMqService: BitbookMqService,
              private bitBookApiService: BitbookApiService) {
  }

  getBasketItems(): Observable<Array<BitApiWrapper>> {
    return this.bitBookApiService.getBasketBits()
      .pipe(concatMap((basket: Array<any>) => {
        this.bitBookMqService.notifyBasketCountUpdated(basket.length);
        return this.browserStorageService.idb.store(`${this.BASKET_COUNT_KEY}`, basket.length)
          .pipe(map(() => basket));
      }));
  }

  getBasketCount(): Observable<number> {
    return this.browserStorageService.idb.get(`${this.BASKET_COUNT_KEY}`)
      .pipe(concatMap(cachedCount => cachedCount
        ? of(cachedCount)
        : this.getBasketCountFromNetwork()))
      .pipe(tap(count => this.bitBookMqService.notifyBasketCountUpdated(count)));
  }

  getBasketCountFromNetwork(): Observable<number> {
    return this.bitBookApiService.getBasketCount()
      .pipe(concatMap((basketCount: { count: number }) => {
        this.bitBookMqService.notifyBasketCountUpdated(basketCount.count);
        return this.browserStorageService.idb.store(`${this.BASKET_COUNT_KEY}`, basketCount.count)
          .pipe(map(() => basketCount.count));
      }));
  }

  addToBasket(bit: BaseBit | string | number): Observable<number> {
    return this.bitBookApiService.addBitToBasket(typeof bit !== 'object' ? {id: bit} : bit)
      .pipe(concatMap((basketCount: { count: number }) => {
        this.bitBookMqService.notifyBasketCountUpdated(basketCount.count);
        return this.browserStorageService.idb.store(`${this.BASKET_COUNT_KEY}`, basketCount.count)
          .pipe(map(() => basketCount.count));
      }));
  }

  addBookBitsToBasket(bookId: string): Observable<number> {
    return this.bitBookApiService.addBookBitsToBasket(bookId)
      .pipe(concatMap((basketCount: { count: number }) => {
        this.bitBookMqService.notifyBasketCountUpdated(basketCount.count);
        return this.browserStorageService.idb.store(`${this.BASKET_COUNT_KEY}`, basketCount.count)
          .pipe(map(() => basketCount.count));
      }));
  }

  removeFromBasket(bitId: any, oldBasketLength: number): Observable<number> {
    return this.bitBookApiService.removeBitFromBasket(bitId)
      .pipe(concatMap(() => {
        this.bitBookMqService.notifyBasketCountUpdated(oldBasketLength - 1);
        return this.browserStorageService.idb.store(`${this.BASKET_COUNT_KEY}`, oldBasketLength - 1)
          .pipe(map(() => oldBasketLength - 1));
      }));
  }

  clearBasket(): Observable<number> {
    return this.bitBookApiService.clearBasket()
      .pipe(concatMap(() => {
        this.bitBookMqService.notifyBasketCountUpdated(0);
        this.bitBookMqService.notifyBasketChange();
        return this.browserStorageService.idb.store(`${this.BASKET_COUNT_KEY}`, 0)
          .pipe(map(() => 0));
      }));
  }
}
