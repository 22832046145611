import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReaderBitsBinService} from '../../reader-bits-bin.service';
import {DropdownItemModel, SubSink} from '../../../shared';
import {BookEntity} from '../../reader.models';
import {BitbookMqService} from '../../bitbook-mq.service';
import {BrandingRenderService} from '../../branding-render.service';
import {BitApiWrapper} from '../../../bits/bits.models';

@Component({
  selector: 'bitmark-reader-notebook-bin',
  templateUrl: './reader-notebook-bin.component.html',
  styleUrls: ['./reader-notebook-bin.component.scss']
})
export class ReaderNotebookBinComponent implements OnInit, OnDestroy {
  @Input() notebook: BookEntity;

  binCount = 0;
  actions: Array<DropdownItemModel> = [];
  bitActions: Array<DropdownItemModel> = [];
  brandingClass = '';
  bits: Array<BitApiWrapper> = [];
  isLoading = false;

  private sub = new SubSink();

  // this is needed when there are items added to the bin in a different tab
  @HostListener('document:visibilitychange', ['$event'])
  synchroniseBinCount() {
    if (!document.hidden) {
      this.readerBitsBinService.getBinCount(this.notebook.id)
        .subscribe(count => this.binCount = count);
    }
  }

  constructor(private readerBitsBinService: ReaderBitsBinService,
              private bitbookMqService: BitbookMqService,
              private brandingRenderService: BrandingRenderService,
              private ngbModal: NgbModal,
              private ngbActiveModal: NgbActiveModal,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.sub.sink = this.readerBitsBinService.getBinCount(this.notebook.id)
      .subscribe(count => this.binCount = count);
    this.sub.sink = this.bitbookMqService.onBookBinCountUpdated(this.notebook.id)
      .subscribe(count => this.binCount = count);

    this.actions = [{
      label: this.translate.instant('Reader.Bin.RestoreAll'),
      handler: this.restoreAllBits.bind(this)
    }, {
      label: this.translate.instant('Reader.Bin.EmptyBin'),
      handler: this.emptyBin.bind(this)
    }];

    this.bitActions = [{
      label: this.translate.instant('Reader.Bin.Restore'),
      handler: this.restoreBit.bind(this)
    }, {
      label: this.translate.instant('Shared.DeletePermanentlyContinue'),
      handler: this.deleteBit.bind(this)
    }];

    this.isLoading = true;
    this.readerBitsBinService.getBinItems(this.notebook.id)
      .subscribe(data => {
        this.bits = data;
        this.isLoading = false;
      }, () => {
        this.bits = [];
        this.isLoading = false;
      });

    this.brandingRenderService.applyBookBranding(this.notebook)
      .subscribe((res: { themeClass: string }) => {
        this.brandingClass = res?.themeClass;
        this.bitbookMqService.notifyReaderBrandingSet(null);
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  close() {
    this.ngbActiveModal.close();
  }

  private restoreBit(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerBitsBinService.restoreFromBin(this.notebook.id, bitId).subscribe(() => {
      this.bits = this.bits.filter(x => x.id !== bitId);
    });
  }

  private deleteBit(dropdownItemModel: DropdownItemModel) {
    if (!confirm(this.translate.instant('Reader.Bin.DeleteBitConfirmation'))) {
      return;
    }

    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerBitsBinService.deleteFromBin(this.notebook.id, bitId).subscribe(() => {
      this.bits = this.bits.filter(x => x.id !== bitId);
    });
  }

  private restoreAllBits() {
    this.readerBitsBinService.restoreAllFromBin(this.notebook.id, this.bits.map(x => x.id)).subscribe(() => {
      this.bits = [];
    });
  }

  private emptyBin() {
    this.readerBitsBinService.emptyBin(this.notebook.id).subscribe(() => {
      this.bits = [];
    });
  }
}
