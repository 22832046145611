import {Component, Input, OnInit} from '@angular/core';
import {BitResourceDocument} from '../../bits/bits.models';

@Component({
  selector: 'bitmark-document-icon',
  templateUrl: './document-icon.component.html',
  styleUrls: ['./document-icon.component.scss']
})
export class DocumentIconComponent implements OnInit {
  @Input() document: BitResourceDocument;

  fileTypeColors: Array<{ extensions: Array<string>, primary: string, secondary: string }> = [
    {
      extensions: ['pdf'],
      primary: '#cb1c05',
      secondary: '#fc8c8d'
    },
    {
      extensions: ['doc', 'docx'],
      primary: '#087fcb',
      secondary: '#7cc9f8'
    },
    {
      extensions: ['xls', 'xlsx'],
      primary: '#79ac00',
      secondary: '#b4e441'
    },
    {
      extensions: ['ppt', 'pptx'],
      primary: '#7b0000',
      secondary: '#bf393a'
    },
    {
      extensions: ['mp3', 'ogg'],
      primary: '#2d25de',
      secondary: '#91a8ff'
    },
    {
      extensions: ['mp4'],
      primary: '#425055',
      secondary: '#8e9ca1'
    },
    {
      extensions: ['avi'],
      primary: '#838383',
      secondary: '#bcbcbc'
    },
    {
      extensions: ['mov'],
      primary: '#76b8b7',
      secondary: '#afdcda'
    },
    {
      extensions: ['aac'],
      primary: '#09ced5',
      secondary: '#4ef8ff'
    },
    {
      extensions: ['png'],
      primary: '#d25ae3',
      secondary: '#ed91fa'
    },
    {
      extensions: ['gif'],
      primary: '#eac300',
      secondary: '#f9db53'
    },
    {
      extensions: ['jpg'],
      primary: '#e38600',
      secondary: '#ffcf8b'
    },
    {
      extensions: ['txt'],
      primary: '#bbcacf',
      secondary: '#dfeaed'
    },
    {
      extensions: ['zip'],
      primary: '#3a2a10',
      secondary: '#ed8b4a'
    }
  ];
  documentExtension: string;
  documentPrimary: string;
  documentSecondary: string;

  ngOnInit() {
    this.documentExtension = this.document?.url?.split('.')?.pop()?.toLowerCase();
    if (!this.documentExtension) {
      return;
    }

    const matchedColorScheme = this.fileTypeColors.find(x => x.extensions.includes(this.documentExtension));

    if (matchedColorScheme) {
      this.documentPrimary = matchedColorScheme.primary;
      this.documentSecondary = matchedColorScheme.secondary;
    }
  }
}
