import {
  Directive,
  HostListener,
  Input,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';

@Directive({
  selector: '[ceModel]'
})
export class ContentEditableDirective implements OnInit, OnChanges {
  @Input() ceDefault: string;
  @Input() ceModel: string;
  @Input() ceAllowNewline?: boolean;
  @Output() ceChange = new EventEmitter();
  @Output() ceEnterPressed = new EventEmitter();

  constructor(private elRef: ElementRef) {
  }

  @HostListener('keypress', ['$event'])
  onKeypress($event: any) {
    if ($event.key === 'Enter' && !$event.shiftKey && !this.ceAllowNewline) {
      $event.preventDefault();
      this.ceEnterPressed.emit();
      (document.activeElement as any).blur();
      return false;
    }
  }

  @HostListener('input', ['$event'])
  onChange() {
    const value = this.elRef.nativeElement.innerText;
    // if ($event.key === 'Enter' && !$event.shiftKey) {
    //   $event.preventDefault();
    //   this.ceEnterPressed.emit();
    //   (document.activeElement as any).blur();
    //   return false;
    // }
    if (value === '') {
      this.setPlaceHolderColor(true);
    } else {
      this.setPlaceHolderColor(false);
    }
    this.ceModel = value;
    this.ceChange.emit(value);
  }

  @HostListener('blur', ['$event'])
  onFocusout() {
    let value = this.elRef.nativeElement.innerText;
    value = value.replace(/(\r\n|\n|\r)/gm, '');
    if (value === '') {
      this.elRef.nativeElement.innerText = this.ceDefault;
    }
  }

  ngOnInit() {
    if (this.ceModel) {
      this.elRef.nativeElement.innerText = this.ceModel;
    } else {
      this.elRef.nativeElement.innerText = this.ceDefault;
      this.setPlaceHolderColor(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const cv = changes['ceModel'].currentValue;
    if (this.elRef.nativeElement.innerText !== cv) {
      this.elRef.nativeElement.innerText = cv;
      if (cv === '') {
        this.elRef.nativeElement.innerText = this.ceDefault;
      }
    }
  }

  private setPlaceHolderColor(isDefault: boolean) {
    if (this.elRef.nativeElement.hasAttribute('placeholder')) {
      this.elRef.nativeElement.style.color = isDefault ? '#595959' : '#000';
    }
  }
}
