// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bit-dropdown {
  max-width: 100px;
  min-width: 80px;
}
.bit-dropdown.answered {
  max-width: 25ch !important;
  width: -moz-fit-content;
  width: fit-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
