import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FileModel} from './file.model';

@Injectable({providedIn: 'root'})
export class FileService {
  private arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const typedArray = new Uint8Array(arrayBuffer);
    const str = typedArray.reduce((data, byte) => data + String.fromCharCode(byte), '');
    return btoa(str);
  }

  private dataUrlToU8Arr(dataUrl: string) {
    const arr = dataUrl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
  }

  base64ToDataUrl(base64: string, type: string) {
    return `data:${type};base64,${base64}`;
  }

  toFileModel(file: File): Observable<FileModel> {
    return new Observable(o => {
      const arrayBufferFileReader: FileReader = new FileReader();
      arrayBufferFileReader.readAsArrayBuffer(file);
      arrayBufferFileReader.onloadend = () => {
        const arrayBuffer = arrayBufferFileReader.result as ArrayBuffer;
        const dataUrl = this.base64ToDataUrl(this.arrayBufferToBase64(arrayBuffer), file.type);
        o.next({
          name: file.name,
          size: file.size,
          lastModified: file.lastModified,
          url: dataUrl,
          arrayBuffer
        });
        o.complete();
      };
    });
  }

  toFile(fileModel: FileModel): File {
    const blob = new Blob([this.dataUrlToU8Arr(fileModel.url)], {
      type: fileModel.mime
    }) as any;
    blob.name = fileModel.name;
    blob.lastModified = fileModel.lastModified;
    return blob;
  }
}
