import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Editor} from "@tiptap/core";
import {findParentNode} from 'prosemirror-utils';
import {DropdownItemModel} from '../../../../shared';

@Component({
  selector: 'bitmark-contextual-menu-list',
  templateUrl: './contextual-menu-list.component.html',
  styleUrls: ['./contextual-menu-list.component.scss']
})
export class ContextualMenuListComponent implements OnInit {
  @Input() editor: Editor | any;
  @Output() onDeleteNode: EventEmitter<string> = new EventEmitter<any>();
  menuActions: Array<DropdownItemModel> = [];

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.menuActions = [{
      label: this.translate.instant('Reader.Editor.List'),
      prefix: '•',
      handler: this.toggleUnorderedList.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.NumberList'),
      prefix: '1. ',
      handler: this.toggleNumberedList.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.LetterList'),
      prefix: 'A. ',
      handler: this.toggleLetterList.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.LetterList'),
      prefix: 'a. ',
      handler: this.toggleLetterListLower.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.RomanList'),
      prefix: 'I. ',
      handler: this.toggleOrderedRomanList.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.RomanList'),
      prefix: 'i. ',
      handler: this.toggleOrderedRomanListLower.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.TaskList'),
      prefix: '✓︎',
      handler: this.toggleTaskList.bind(this)
    }, {
      label: this.translate.instant('Reader.Editor.NoBullets'),
      prefix: ' ',
      handler: this.toggleNoBullets.bind(this)
    }, {isSeparator: true}, {
      label: this.translate.instant('Shared.Delete'),
      prefix: ' ',
      handler: this.deleteList.bind(this)
    }];
  }

  toggleUnorderedList() {
    (this.editor.chain().focus() as any).toggleBulletList().run();
  }

  toggleNumberedList() {
    (this.editor.chain().focus() as any).toggleOrderedList().run();
  }

  toggleLetterList() {
    (this.editor.chain().focus() as any).toggleLetteredList().run();
  }

  toggleLetterListLower() {
    (this.editor.chain().focus() as any).toggleLetteredListLower().run();
  }

  toggleOrderedRomanListLower() {
    (this.editor.chain().focus() as any).toggleOrderedListRomanLower().run();
  }

  toggleOrderedRomanList() {
    (this.editor.chain().focus() as any).toggleOrderedListRoman().run();
  }

  toggleTaskList() {
    (this.editor.chain().focus() as any).toggleTaskList().run();
  }

  toggleNoBullets() {
    (this.editor.chain().focus() as any).toggleNoBulletList().run();
  }

  deleteNode(nodeType: string) {
    this.onDeleteNode.emit(nodeType);
  }

  deleteList() {
    let parentUl = findParentNode((node) => node.type === this.editor.view.state.schema.nodes.bulletList)(this.editor.view.state.selection);
    if (parentUl) {
      this.editor.commands.deleteRange({from: parentUl.pos, to: parentUl.start + parentUl.node.nodeSize});
      return;
    }
    parentUl = findParentNode((node) => node.type === this.editor.view.state.schema.nodes.orderedList)(this.editor.view.state.selection);
    if (parentUl) {
      this.editor.commands.deleteRange({from: parentUl.pos, to: parentUl.start + parentUl.node.nodeSize});
      return;
    }
    parentUl = findParentNode((node) => node.type === this.editor.view.state.schema.nodes.noBulletList)(this.editor.view.state.selection);
    if (parentUl) {
      this.editor.commands.deleteRange({from: parentUl.pos, to: parentUl.start + parentUl.node.nodeSize});
      return;
    }
    parentUl = findParentNode((node) => node.type === this.editor.view.state.schema.nodes.taskList)(this.editor.view.state.selection);
    if (parentUl) {
      this.editor.commands.deleteRange({from: parentUl.pos, to: parentUl.start + parentUl.node.nodeSize});
      return;
    }
    parentUl = findParentNode((node) => node.type === this.editor.view.state.schema.nodes.letteredList)(this.editor.view.state.selection);
    if (parentUl) {
      this.editor.commands.deleteRange({from: parentUl.pos, to: parentUl.start + parentUl.node.nodeSize});
      return;
    }
  }
}
