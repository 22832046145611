import {Mark, mergeAttributes} from '@tiptap/core';
import {MarkedOptions} from '../marked/marked.node';

const createHighlight = (name: string, cssClass: string) => {
  return Mark.create<MarkedOptions>({
    name: name,

    addOptions() {
      return {
        HTMLAttributes: {},
      };
    },

    parseHTML() {
      return [
        {
          tag: `mark[class="${cssClass}"]`
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      const customAttributes = {
        class: cssClass,
        'data-highlighted': true
      };
      return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
    }
  });
};

export const TextHighlight = createHighlight('highlight', 'bitmark-text-highlight');
export const UserTextHighlight = createHighlight('userHighlight', 'bitmark-text-user-highlight');
