import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropdownItemModel} from '../dropdown/dropdown/dropdown.model';

@Component({
  selector: 'app-text-context-menu-desktop',
  templateUrl: './text-context-menu-desktop.component.html',
  styleUrls: ['./text-context-menu-desktop.component.scss'],
})
export class TextContextMenuDesktopComponent {
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() triangleOffsetStyle: {left: string, top: string};
  @Output() canceled = new EventEmitter<any>();

  cancel() {
    this.canceled.emit();
  }
}
