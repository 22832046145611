import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {RecordedVideoOutput, VideoRecordingService} from '../../../../shared/recording/video-recording.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {ResourceBaseCameraEditComponent} from '../../../../shared/recording/video-recording-base/resource-base-camera-edit.component';

@Component({
  selector: 'bitmark-resource-camera-photo-edit',
  templateUrl: './resource-camera-photo-edit.component.html',
  styleUrls: ['./resource-camera-photo-edit.component.scss']
})
export class ResourceCameraPhotoEditComponent extends ResourceBaseCameraEditComponent implements OnInit, OnDestroy {
  @Input() isUploadingPhoto = false;
  @Input() hideTakePictureButton = false;
  @Output() onCapture: EventEmitter<RecordedVideoOutput> = new EventEmitter<RecordedVideoOutput>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileImageInput') fileImageInput: ElementRef;

  constructor(videoRecordingService: VideoRecordingService,
              deviceDetectorService: DeviceDetectorService,
              private fileUtilsService: FileUtilsService) {
    super(videoRecordingService, deviceDetectorService);
  }

  ngOnInit() {
    this.initialize().subscribe();
  }

  ngOnDestroy() {
    this.destroy();
  }

  uploadMobilePhoto() {
    if (!this.fileImageInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileImageInput.nativeElement.files[0];
    this.videoRecordingService.abortRecording();
    this.onCapture.emit({
      file: file
    });
  }

  takePicture() {
    if (this.isMobile) {
      this.fileImageInput.nativeElement.click();
      return;
    }

    this.captureImage();
    this.videoRecordingService.abortRecording();

    const base64String = this.videoCanvas.nativeElement.toDataURL('image/png');
    const blob = this.fileUtilsService.dataURItoBlob(base64String);
    this.onCapture.emit({
      blob: blob,
      title: `capture_${new Date().getTime()}.png`
    });
  }

  cancelRecording() {
    this.cancel();
    this.onCancel.emit();
  }
}
