import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatchPair} from '../match.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {BitUtilsService} from '../../../shared/utils/bit-utils.service';

@Component({
  selector: 'bitmark-match-gap-placeholder',
  templateUrl: './match-gap-placeholder.component.html',
  styleUrls: ['../../bits.scss']
})
export class MatchGapPlaceholderComponent implements OnChanges {
  @Input() pair?: MatchPair;
  @Input() format?: BitmarkFormat;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() changed = new EventEmitter<any>();

  answerCssClass = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      if (this.hasFeedback) {
        const isAnswerCorrect = this.hasFeedback
          && this.pair.values
            .map(x => BitUtilsService.convertBitSolutionAnswer(x, this.pair.isCaseSensitive))
            .includes(BitUtilsService.convertBitSolutionAnswer(this.pair.answer?.text, this.pair.isCaseSensitive));
        this.answerCssClass = {
          correct: this.pair.isExample || isAnswerCorrect,
          incorrect: !isAnswerCorrect,
        };
      } else {
        this.answerCssClass = '';
      }
    }
  }

  change(evt: Event) {
    const answer = (evt.target as any)?.value;
    this.pair.answer = {text: answer};
    this.changed.emit();
  }
}
