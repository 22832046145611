import {Injectable} from '@angular/core';
import {concatMap, delay, distinct, Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {BitBranding, BookType, BrandingDefaultThemes, DefaultBitBranding, NotebookTypes} from '../shared/models';
import {ApiService} from '../shared/api/api.service';
import {ThemeBranding} from './branding-api.service';
import {BitApiWrapper, BitApiWrapperMeta} from '../bits/bits.models';
import {BookEntity, ReaderModes} from './reader.models';
import StringCaseService from '../shared/utils/string-case.service';

@Injectable()
export class BrandingRenderService {
  private renderedBrandings: Array<string> = [];
  private retrievedBrandings: { [key: string]: ThemeBranding } = {};
  public themeSubject = new Subject<{ [p: string]: ThemeBranding }>();

  constructor(private apiService: ApiService,
              private stringCaseService: StringCaseService) {
  }

  private flattenObj(obj, parent, res = {}) {
    for (const key in obj) {
      const propName = parent ? parent + '_' + key : key;
      if (typeof obj[key] == 'object') {
        this.flattenObj(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }

  private computeBrandingStyles(bitBranding: BitBranding, prefix = null): string {
    if (!bitBranding) {
      return '';
    }
    let ret = '';
    const flattenObj = this.flattenObj(bitBranding, null);
    Object.keys(flattenObj).forEach((prop: string) => {
      const kebabCaseProp = this.stringCaseService.camelToKebab(prop);

      const isCssProp = !kebabCaseProp.endsWith('-show-in-editor');
      if (isCssProp) {
        let propVal = flattenObj[prop];
        const isString = kebabCaseProp.indexOf('string') !== -1;
        const isImage = kebabCaseProp.endsWith('-image');

        const isImageCss = isImage && propVal.indexOf('-gradient') !== -1;
        const isImageUrl = isImage && !isImageCss;
        const isImageSvg = isImage && propVal.includes('<svg');

        if (isImageSvg) {
          propVal = `"data:image/svg+xml,${this.encodeSvg(propVal)}"`;
        }

        const propValue = isImageUrl || isImageSvg
          ? `url(${propVal})`
          : isString
            ? `'${propVal}'`
            : propVal;
        if ((prefix && kebabCaseProp.startsWith(prefix)) || !prefix) {
          ret += propVal
            ? `--bitmark-${kebabCaseProp}: ${propValue};`
            : '';
        }
      }
    });

    return ret;
  }

  // https://github.com/yoksel/url-encoder/blob/master/src/js/script.js#L134
  private encodeSvg(data: string): string {
    const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
    data = data.replace(/"/g, `'`);

    data = data.replace(/>\s{1,}</g, `><`);
    data = data.replace(/\s{2,}/g, ` `);

    // Using encodeURIComponent() as replacement function
    // allows to keep result code readable
    return data.replace(symbols, encodeURIComponent);
  }

  private renderBrandingStyles(themeClass: string, styles: string): Observable<any> {
    if (!styles || !themeClass) {
      return of(null);
    }
    if (this.renderedBrandings.indexOf(themeClass) !== -1) {
      return of({themeClass});
    }
    this.renderedBrandings.push(themeClass);
    const headEl = document.getElementsByTagName('head')[0];
    const styleEl = document.createElement('style');
    const styleElContent = `.${themeClass} {${styles}}\n`;
    styleEl.appendChild(document.createTextNode(styleElContent));
    headEl.appendChild(styleEl);
    return of({themeClass}).pipe(delay(100)); // make sure headerEl is rendered before return;
  }

  private getBrandingToRender(themeId: string, publisherId: number) {
    if (!themeId) {
      this.themeSubject.next(this.retrievedBrandings);
      return;
    }
    const key = `${publisherId}:${themeId}`;
    if (this.retrievedBrandings[key]) {
      this.themeSubject.next(this.retrievedBrandings);
      return;
    }
    if (this.retrievedBrandings[key]?.themeId) {
      this.themeSubject.next(this.retrievedBrandings);
      return;
    }
    this.retrievedBrandings[key] = {};
    this.apiService.get('branding/{themeId}/{publisherId}?compact=true', {
      publisherId: publisherId,
      themeId: themeId
    }).subscribe((x: ThemeBranding) => {
      this.retrievedBrandings[key] = x;
      this.themeSubject.next(this.retrievedBrandings);
    });
  }

  getBitThemeBranding(bitWrapper: BitApiWrapper): Observable<ThemeBranding> {
    const publisherId = this.getBitPublisherId(bitWrapper);
    const themeId = this.getBitThemeName(bitWrapper);

    return new Observable<ThemeBranding>(x => {
      this.themeSubject.asObservable()
        .pipe(map(v => v[`${publisherId}:${themeId}`]))
        .pipe(distinct())
        .subscribe(y => x.next(y));

      this.getBrandingToRender(themeId, publisherId);
    });
  }

  getBookThemeBrandingProps(book: BookEntity): Observable<ThemeBranding> {
    const publisherId = +book?.publisherId || 0;
    const themeId = book?.theme || BrandingDefaultThemes.SystemDefault;

    return new Observable<ThemeBranding>(x => {
      this.themeSubject.asObservable()
        .pipe(map(v => v[`${publisherId}:${themeId}`]))
        .pipe(distinct())
        .subscribe(y => x.next(y));

      this.getBrandingToRender(themeId, publisherId);
    });
  }

  applyBitBranding(bitWrapper: BitApiWrapper): Observable<{ themeClass: string }> {
    if (!bitWrapper) {
      return of({themeClass: null});
    }
    const themeClass = this.getBitThemeClass(bitWrapper);

    if (this.renderedBrandings.indexOf(themeClass) !== -1) {
      return of({themeClass});
    }

    return this.getBitThemeBranding(bitWrapper)
      .pipe(concatMap((pBranding: ThemeBranding) => {
        if (!pBranding || Object.keys(pBranding).length === 0 || this.renderedBrandings.indexOf(themeClass) !== -1) {
          return of({themeClass});
        }
        const bitBranding = pBranding?.themeBranding || DefaultBitBranding;
        const styles = this.computeBrandingStyles(bitBranding);
        return this.renderBrandingStyles(themeClass, styles);
      }));
  }

  applyBookBranding(bitBook: BookEntity): Observable<{ themeClass: string }> {
    const publisherId = +bitBook?.publisherId || 0;
    const themeId = bitBook?.theme;
    const themeClass = `bitmark-publisher-${publisherId}-theme-${themeId}-book`;
    if (this.renderedBrandings.indexOf(themeClass) !== -1) {
      return of({themeClass});
    }
    return this.apiService.get('branding/{themeId}/{publisherId}?compact=true', {
      publisherId: publisherId,
      themeId: themeId
    }).pipe(concatMap((pBranding: ThemeBranding) => {
      const bitBranding = pBranding?.themeBranding || DefaultBitBranding;
      const styles = this.computeBrandingStyles(bitBranding, 'reader');
      return this.renderBrandingStyles(themeClass, styles);
    }));
  }

  getBookThemeBranding(meta: BitApiWrapperMeta) {
    const bookType = meta?.thisBook?.type || meta?.originBook?.type;
    const notebookSubtype = meta?.thisBook?.subtype;

    const preferredTheme = this.hasBitTheme(meta)
      ? meta?.branding?.theme
      : (bookType === BookType.Collection
        ? meta?.originBook?.theme || meta?.thisBook?.theme
        : meta?.thisBook?.theme || meta?.originBook?.theme);

    if (preferredTheme) {
      return preferredTheme;
    }

    switch (bookType) {
      case BookType.LearningPath:
        return BrandingDefaultThemes.LearningPath;

      case BookType.Collection:
        if (notebookSubtype === NotebookTypes.LearningPath) {
          return BrandingDefaultThemes.NotebookLearningPath;
        }
        return BrandingDefaultThemes.SystemDefault;

      default:
        return null;
    }
  }

  getBitPublisherId(bitWrapper: BitApiWrapper) {
    return this.hasBitTheme(bitWrapper.meta)
      ? +bitWrapper.meta?.branding?.publisher?.id
      : +bitWrapper.meta?.publisherId || +bitWrapper.meta?.thisBook?.publisherId || +bitWrapper.meta?.thisBook?.publisher?.id || +bitWrapper.meta?.originBook?.publisherId || 0;
  }

  getBitPublisherName(bitWrapper: BitApiWrapper) {
    return this.hasBitTheme(bitWrapper.meta)
      ? bitWrapper.meta?.branding?.publisher?.name
      : bitWrapper.meta?.publisher?.name || bitWrapper.meta?.thisBook?.publisher?.name || bitWrapper.meta?.originBook?.publisher?.name || 'System';
  }

  getBitPublisherCode(bitWrapper: BitApiWrapper): string {
    return this.hasBitTheme(bitWrapper.meta)
      ? bitWrapper.meta?.branding?.publisher?.code
      : bitWrapper.meta?.publisher?.code || bitWrapper.meta?.thisBook?.publisher?.code || bitWrapper.meta?.originBook?.publisher?.code || 'system';
  }

  getBitThemeName(bitWrapper: BitApiWrapper): string {
    return this.getBookThemeBranding(bitWrapper.meta) || BrandingDefaultThemes.SystemDefault;
  }

  getBitThemeClass(bitWrapper: BitApiWrapper) {
    const publisherId = this.getBitPublisherId(bitWrapper);
    const themeId = this.getBitThemeName(bitWrapper);
    return `bitmark-publisher-${publisherId}-theme-${themeId}`;
  }

  hasBitTheme(meta: BitApiWrapperMeta) {
    return meta?.branding?.publisher?.id && meta?.branding.theme;
  }
}
