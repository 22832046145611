import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiConfig} from './api.config';
import {BitmarkConfig} from '../../bitmark.module';

@Injectable()
export class ApiRouterService {
  private allRoutes: object;

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig) {
    this.allRoutes = null;
    this.init();
  }

  private static replaceParams(route: string, params: object): string {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
      const param = ':' + keys[i];
      route = route.replace(param, params[keys[i]]);
    }
    return route;
  }

  private static replaceQueryParams(route: string, params: object): string {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
      const param = `{${keys[i]}}`;
      route = route.replace(param, params[keys[i]]);
    }
    return route;
  }

  private getAllRoutes(): Observable<object> {
    return of(ApiConfig.apiRoutesMap);
  }

  private init() {
    this.getAllRoutes()
      .subscribe((routes: object) => {
        this.allRoutes = routes;
      });
  }

  private getBaseUrl(routePath: string): string {
    if (routePath.startsWith('branding')) {
      return this.bitmarkConfig.brandingApiUrl;
    }

    if (routePath.startsWith('lti')
      || routePath.startsWith('learning-paths')
      || routePath.startsWith('scorm')
      || routePath.startsWith('roles')) {
      return this.bitmarkConfig.ltiHandlingApiBaseUrl;
    }

    if (routePath.startsWith('openai')) {
      return this.bitmarkConfig.openAiApiUrl;
    }

    return this.bitmarkConfig.bitbookClientApiUrl;
  }

  getRouteById(routeId: string, data: any, queryParams: any = null): Observable<string> {
    return new Observable((x: any) => {
      if (!this.allRoutes) {
        this.getAllRoutes()
          .subscribe((routes: object) => {
              this.allRoutes = routes;
              try {
                const routeUrl = this.computeRouteById(routeId, data, queryParams);
                x.next(routeUrl);
              } catch (err) {
                x.error(err);
              }
              x.complete();
            },
            (error: HttpErrorResponse) => {
              console.error(error);
              x.error(error);
              x.complete();
            },
          );
      } else {
        try {
          const routeUrl = this.computeRouteById(routeId, data, queryParams);
          x.next(routeUrl);
        } catch (error) {
          console.error(error);
          x.error(error);
        }
        x.complete();
      }
    });
  }

  computeRouteById(routeId: string, data: any, queryParams: any = null) {
    if (!this.allRoutes) {
      throw new Error('No routes defined');
    }

    let cleanRouteId = routeId;
    let query = '';
    const k = routeId.indexOf('?');
    if (k !== -1) {
      cleanRouteId = routeId.substr(0, k);
      query = routeId.substr(k);
    }

    let routePath: string = this.allRoutes[cleanRouteId];
    if (!routePath) {
      throw new Error('Attempt to hit an invalid route: ' + routeId);
    }
    if (data) {
      routePath = ApiRouterService.replaceParams(routePath, data);
      if (routePath.indexOf(':', 0) > -1 && !routePath.toLowerCase().startsWith('http')) {
        throw new Error('Attempt to hit an incomplete route: ' + routeId + ' > ' + routePath);
      }
    }
    if (queryParams) {
      routePath = ApiRouterService.replaceQueryParams(routePath, queryParams);
    }
    return routePath.toLowerCase().startsWith('http')
      ? routePath + query
      : routePath.startsWith('~/')
        ? routePath.substr(2) + query
        : `${this.getBaseUrl(routePath)}/${routePath}${query}`;
  }
}
