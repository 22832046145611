import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {Observable} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {IdentityService} from './identity.service';
import {appHost} from "app-host";
import {SsoService} from "core";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private identityService: IdentityService,
              private router: Router,
              private cookieService: CookieService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    const subDomainCfg = appHost.getSubdomainConfig();
    const queryParams = (state as any)._root?.value?.queryParams;
    const forcedSpace = queryParams?.spc;
    const baseUrl = state.url.split('?')[0];

    const publicPages = ['/public', '/shop', '/logout', '/logout-sso', '/generic-error', '/register', '/verify-email', '/lost-password', '/reset-password', '/auth-callback'];
    const isPublicPage = !!publicPages.find(x => state.url.startsWith(x));

    const authAdminPages = ['/bo'];
    const isAuthAdminPage = !!authAdminPages.find(x => state.url.startsWith(x));

    if (isPublicPage) {
      return true;
    }

    if (isAuthenticated) {
      if (isAuthAdminPage) {
        return true;
      }

      const loggedInSpace = this.identityService.getSpace();
      const spaceFromUrl = SsoService.getSpaceFromUrl(state.url);
      const {provider, space, isFromSplit} = SsoService.getProviderAndSpace(spaceFromUrl);
      if (isFromSplit || !space) {
        return this.router.navigate(['space', loggedInSpace, 'my-library'], {
          replaceUrl: true
        });
      }
      if (space && loggedInSpace !== space) {
        this.router.navigate(['generic-error'], {
          skipLocationChange: true,
          queryParams: {
            code: 400,
            message: `Space assigned at login (${loggedInSpace}) is different from the space requested in the url (${spaceFromUrl})`
          }
        });
        return false;
      }
      if (["/space", `/space/${space}`].includes(baseUrl)) {
        const redirectCookieUrl = this.getCookieRedirectUrl();
        if (redirectCookieUrl) {
          console.log(`Founded redirectUrl in cookies: ${redirectCookieUrl}`);
        }
        let redirectUrl = redirectCookieUrl || `space/${space}/my-library`;
        const fragmentIdx = redirectUrl.indexOf('#');
        let fragment = null;
        if (fragmentIdx != -1) {
          fragment = redirectUrl.substring(fragmentIdx + 1);
          redirectUrl = redirectUrl.substring(0, fragmentIdx);
        }
        this.router.navigate([redirectUrl], {
          replaceUrl: true,
          fragment
        });
        return false;
      }
      const knownPage = ['/login', '/register', '/timeline', '/workspace', '/contact', '/academy', '/profile', '/my-library']
        .find(x => state.url.startsWith(x));
      if (knownPage) {
        this.router.navigate(['space', space, knownPage.substr(1)], {replaceUrl: true});
        return false;
      }
      const allowedBasePage = ['/space']
        .find(x => state.url.startsWith(x));
      if (!allowedBasePage) {
        this.router.navigate(['space', space, 'my-library'], {replaceUrl: true});
        return false;
      }
    } else {
      const urlSpace = state.url.split('/')[2];
      let space = forcedSpace
        ? 'personal'
        : urlSpace?.includes("@")
          ? urlSpace
          : subDomainCfg?.space
          || (['localhost', 'cosmic', 'app'].includes(subDomainCfg.subDomain) ? urlSpace : '')
          || 'personal';
      if (['undefined', 'null'].includes(space)) {
        space = 'personal';
      }
      const isProperLoginUrl = state.url.startsWith(`/space/${space}/login`);
      if (!isProperLoginUrl) {
        if (state.url !== '/space' && !state.url.includes('/login') && !state.url.startsWith('/space?')) {
          const expiryDate: Date = new Date();
          expiryDate.setHours(expiryDate.getMinutes() + 10);
          this.cookieService.set('redirectUrl', state.url, expiryDate);
        }
        this.router.navigate(['space', space, 'login'], {
          queryParams,
          replaceUrl: true
        });
        return false;
      }
    }
    return this.identityService.canAny(route.data?.claims);
  }

  private getCookieRedirectUrl() {
    const redirectCookieUrl = this.cookieService.get('redirectUrl');
    this.cookieService.delete('redirectUrl');

    const redirectCookieUrlDecoded = redirectCookieUrl
      ? decodeURIComponent(redirectCookieUrl)
      : null;

    const isInvalid = redirectCookieUrlDecoded?.includes('/bo/');

    return isInvalid
      ? null
      : redirectCookieUrlDecoded;
  }
}
