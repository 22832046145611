import {Injectable} from '@angular/core';
import {concatMap, Observable, of} from 'rxjs';
import {BrowserStorageService} from '../shared/browser-storage/browser-storage.service';
import {BookEntity} from './reader.models';
import {BitApiWrapper} from '../bits/bits.models';

@Injectable({providedIn: 'root'})
export class ReaderLocalContentService {
  private BOOK_KEY = 'reader-local-content-';
  private l1Cache = {};
  pageSize = 50;

  constructor(private browserStorageService: BrowserStorageService) {
  }

  getBookContentEndingWithBit(bookExternalId: string, lastBitIndex: number): Observable<Array<BitApiWrapper>> {
    return this.initL1Cache(bookExternalId)
      .pipe(concatMap(() => this.getBookById(bookExternalId)))
      .pipe(concatMap((book: BookEntity) => {
        if (!book) {
          return of(null);
        }
        let firstBitIndexFromCurrentPage = lastBitIndex - this.pageSize;
        if (firstBitIndexFromCurrentPage < 0) {
          firstBitIndexFromCurrentPage = 0;
        }
        const bitsInCurrentPageIndexes = this.generateRange(firstBitIndexFromCurrentPage, lastBitIndex - 1);
        return this.retrieveFromLocalCache(bookExternalId, lastBitIndex, bitsInCurrentPageIndexes);
      }));
  }

  getBookContentStartingWithBit(bookExternalId: string, lastBitIndex: number): Observable<Array<BitApiWrapper>> {
    return this.initL1Cache(bookExternalId)
      .pipe(concatMap(() => this.getBookById(bookExternalId)))
      .pipe(concatMap((book: BookEntity) => {
        if (!book) {
          return of(null);
        }
        const lastBitIndexFromCurrentPage = lastBitIndex + this.pageSize;
        const bitsInCurrentPageIndexes = this.generateRange(lastBitIndex, lastBitIndexFromCurrentPage + 1);
        return this.retrieveFromLocalCache(bookExternalId, lastBitIndex, bitsInCurrentPageIndexes);
      }));
  }

  getBookContentWithBitInMiddle(bookExternalId: string, lastBitIndex: number): Observable<Array<BitApiWrapper>> {
    return this.initL1Cache(bookExternalId)
      .pipe(concatMap(() => this.getBookById(bookExternalId)))
      .pipe(concatMap((book: BookEntity) => {
        if (!book) {
          return of(null);
        }
        let firstBitIndexFromCurrentPage = lastBitIndex - (this.pageSize / 2);
        if (firstBitIndexFromCurrentPage < 0) {
          firstBitIndexFromCurrentPage = 0;
        }
        const bitsInCurrentPageIndexes = this.generateRange(firstBitIndexFromCurrentPage, lastBitIndex + (this.pageSize/2) - 1);
        return this.retrieveFromLocalCache(bookExternalId, lastBitIndex, bitsInCurrentPageIndexes);
      }));
  }

  storeBookFilters(book: BookEntity): Observable<any> {
    return this.browserStorageService.idb.store(`${this.BOOK_KEY}${book.externalId}-filters`, book?.filters);
  }

  getBookById(bookExternalId: string): Observable<BookEntity> {
    return this.browserStorageService.idb.get(`${this.BOOK_KEY}${bookExternalId}`);
  }

  //todo: do we still use this?
  storeBookContent(bookExternalId: string, bits: Array<BitApiWrapper>): Observable<any> {
    return of(null);
    // return new Observable(x => {
    //   const key = `${this.BOOK_KEY}${bookExternalId}-bits`;
    //   const qry = this.l1Cache[key]
    //     ? of(this.l1Cache[key])
    //     : this.browserStorageService.idb.get(key);
    //
    //   qry.subscribe(content => {
    //     if (!content) {
    //       content = {};
    //     }
    //     bits?.forEach((b: BitApiWrapper) => {
    //       if (b && b.bit && b.index >= 0 && !content[b.index]) {
    //         content[b.index] = b;
    //       }
    //     });
    //     this.l1Cache[key] = content;
    //     this.browserStorageService.idb.store(key, content).subscribe(() => x.next(null));
    //   });
    // });
  }

  getBookFilters(bookExternalId: string): Observable<Array<any>> {
    return this.browserStorageService.idb.get(`${this.BOOK_KEY}${bookExternalId}-filters`)
      .pipe(concatMap((filters) => of(filters || [])));
  }

  addSearchHistoryItem(bookExternalId: string, historyItem: { searchQuery: string, activeFilters: any }): Observable<any> {
    return this.browserStorageService.idb.get(`${this.BOOK_KEY}${bookExternalId}-search-history`)
      .pipe(concatMap((content) => {
        if (!content) {
          content = [];
        }
        content = content.filter(item => item.searchQuery !== historyItem.searchQuery);
        content.unshift(historyItem);
        if (content.length > 3) {
          content.pop();
        }
        return this.browserStorageService.idb.store(`${this.BOOK_KEY}${bookExternalId}-search-history`, content);
      }));
  }

  getSearchHistory(bookExternalId: string): Observable<Array<{ historyItem: { searchQuery: string, activeFilters: any } }>> {
    return this.browserStorageService.idb.get(`${this.BOOK_KEY}${bookExternalId}-search-history`);
  }

  destroyL1Cache(bookExternalId: string) {
    this.l1Cache[`${this.BOOK_KEY}${bookExternalId}-bits`] = {};
  }

  storeLastSearch(data: { searchQuery: string, searchResults: Array<any>, bookId: string, filters: any, count: number, offsetToTop: number, page: number }) {
    return this.browserStorageService.idb.store(`${this.BOOK_KEY}${data.bookId}-last-search`, data);
  }

  getLastSearch(bookExternalId: string): Observable<{ searchQuery: string, searchResults: Array<any>, bookExternalId: string, filters: any, count: number, offsetToTop: number, page: number }> {
    return this.browserStorageService.idb.get(`${this.BOOK_KEY}${bookExternalId}-last-search`);
  }

  clearLastSearch(bookExternalId: string): Observable<any> {
    return this.browserStorageService.idb.clear(`${this.BOOK_KEY}${bookExternalId}-last-search`);
  }

  private initL1Cache(bookExternalId: string): Observable<any> {
    return of(null);
    // const key = `${this.BOOK_KEY}${bookExternalId}-bits`;
    // if (this.l1Cache[key]) {
    //   return of(null);
    // }
    // return this.browserStorageService.idb.get(key)
    //   .pipe(tap((content: Array<BitApiWrapper>) => this.l1Cache[key] = content));
  }

  private retrieveFromLocalCache(bookExternalId: string, lastBitIndex: number, range: Array<number>): Observable<Array<BitApiWrapper>> {
    const key = `${this.BOOK_KEY}${bookExternalId}-bits`;
    const qry = this.l1Cache[key]
      ? of(this.l1Cache[key])
      : this.browserStorageService.idb.get(key);

    return new Observable(x => {
      qry.subscribe((content: Array<BitApiWrapper>) => {
        if (content && content[lastBitIndex]) {
          const page = [];
          for (let i = 0; i < range.length; i++) {
            if (!content[range[i]]) {
              x.next(null);
              return x.complete();
            }
            page.push(content[range[i]]);
          }
          x.next(page);
          return x.complete();
        }
        x.next(null);
        x.complete();
      });
    });
  }

  private generateRange(min: number, max: number): Array<number> {
    const arr = [];
    for (let i = min; i <= max; i += 1) {
      arr.push(i);
    }
    return arr;
  }
}
