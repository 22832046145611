import {Component, Input} from '@angular/core';
import {ReleaseNoteBit} from './release-note.models';

@Component({
  selector: 'bitmark-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss', '../bits.scss']
})
export class ReleaseNoteComponent {
  @Input() bit?: ReleaseNoteBit;
}
