import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../shared/api/api.service';
import {BookEntity} from './reader.models';
import {Publisher} from './publisher.models';
import {BitmarkConfig} from "../bitmark.module";

@Injectable()
export class PublisherApiService {
  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private apiService: ApiService) {
  }

  getPublishers(): Observable<Array<Publisher>> {
    return this.apiService.get('publishers', null);
  }

  updatePublisher(publisherId: number, publisherName: string, publisherCode: string, themes: Array<string>): Observable<Publisher> {
    const apiKey = this.bitmarkConfig.productionEnvironment
      ? "e78862330949d1d8452844b47b3762e4"
      : "admin";
    const qry = ApiService.buildQuery([{api_key: apiKey}], true);
    return this.apiService.put(`publishers/{publisherId}?${qry}`, {publisherId}, {
      name: publisherName,
      code: publisherCode,
      themes
    });
  }

  createNotebook(notebook: BookEntity): Observable<BookEntity> {
    return this.apiService.post('client/books', null, notebook);
  }

  updateNotebook(notebook: BookEntity): Observable<BookEntity> {
    if (!notebook.branding) {
      delete notebook.branding;
    }
    delete notebook.subtype;
    delete notebook.coverImage;
    return this.apiService.put('client/books/{bookExternalId}', {bookExternalId: notebook.id}, notebook);
  }

  deleteNotebook(id: string): Observable<{ id: number }> {
    return this.apiService.delete('client/books/{bookExternalId}', {bookExternalId: id}, null);
  }

  deleteNotebooks(bookIds: Array<string>): Observable<any> {
    return this.apiService.delete('client/books/trash', null, {bookIds});
  }

  trashNotebook(id: string): Observable<{ id: string }> {
    return this.apiService.delete('client/books/trash/{bookExternalId}', {bookExternalId: id}, null);
  }

  restoreNotebook(id: string): Observable<{ id: string }> {
    return this.apiService.put('client/books/trash/{bookExternalId}/restore', {bookExternalId: id}, null);
  }
}
