import {Component, Input} from '@angular/core';
import {ShopInfo} from 'core';
import {RouterExtService} from 'bitmark';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent {
  @Input() defaultRoute: string;
  @Input() shopBranding: ShopInfo;

  constructor(private routerExtService: RouterExtService) {
  }

  back() {
    this.routerExtService.goBack(this.defaultRoute);
  }
}
