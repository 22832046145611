// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-event-header .bit-checkbox {
  --bitmark-le-completion-instruction-default-font-size: 24px;
  font-size: var(--bitmark-le-completion-instruction-font-size, var(--bitmark-le-completion-instruction-default-font-size, var(--bitmark-main-font-size)));
  line-height: var(--bitmark-le-completion-instruction-font-line-height, var(--bitmark-le-completion-instruction-default-font-line-height, var(--bitmark-main-font-line-height)));
}

.learning-event-item {
  --bitmark-le-completion-item-default-font-color: #543199;
  --bitmark-le-completion-item-default-font-size: 12px;
  font-family: var(--bitmark-le-completion-item-font-family, var(--bitmark-le-completion-item-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-completion-item-font-size, var(--bitmark-le-completion-item-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-completion-item-font-weight, var(--bitmark-le-completion-item-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-completion-item-font-style, var(--bitmark-le-completion-item-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-completion-item-font-text-decoration, var(--bitmark-le-completion-item-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-completion-item-font-text-decoration, var(--bitmark-le-completion-item-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-completion-item-font-line-height, var(--bitmark-le-completion-item-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-completion-item-font-color, var(--bitmark-le-completion-item-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-completion-item-font-align, var(--bitmark-le-completion-item-default-font-align, var(--bitmark-le-completion-item-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-completion-item-font-align, var(--bitmark-le-completion-item-default-font-align, var(--bitmark-le-completion-item-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.learning-event-lead {
  --bitmark-le-completion-lead-default-font-color: #543199;
  --bitmark-le-completion-lead-default-font-size: 12px;
  font-family: var(--bitmark-le-completion-lead-font-family, var(--bitmark-le-completion-lead-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-completion-lead-font-size, var(--bitmark-le-completion-lead-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-completion-lead-font-weight, var(--bitmark-le-completion-lead-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-completion-lead-font-style, var(--bitmark-le-completion-lead-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-completion-lead-font-text-decoration, var(--bitmark-le-completion-lead-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-completion-lead-font-text-decoration, var(--bitmark-le-completion-lead-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-completion-lead-font-line-height, var(--bitmark-le-completion-lead-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-completion-lead-font-color, var(--bitmark-le-completion-lead-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-completion-lead-font-align, var(--bitmark-le-completion-lead-default-font-align, var(--bitmark-le-completion-lead-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-completion-lead-font-align, var(--bitmark-le-completion-lead-default-font-align, var(--bitmark-le-completion-lead-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.learning-event-lead-dash-before {
  --bitmark-le-completion-lead-before-after-default-font-color: #543199;
  --bitmark-le-completion-lead-before-after-default-font-size: 12px;
}
.learning-event-lead-dash-before:before {
  content: var(--bitmark-le-completion-lead-content-before-string, var(--bitmark-le-completion-lead-before-after-default-string, var(--bitmark-bit-lead-content-before-string)));
  margin: var(--bitmark-le-completion-lead-content-before-margin, var(--bitmark-le-completion-lead-before-after-default-margin, var(--bitmark-bit-lead-content-before-margin)));
  font-family: var(--bitmark-le-completion-lead-content-before-font-family, var(--bitmark-le-completion-lead-before-after-default-font-family, var(--bitmark-bit-lead-content-before-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-completion-lead-content-before-font-size, var(--bitmark-le-completion-lead-before-after-default-font-size, var(--bitmark-bit-lead-content-before-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-completion-lead-content-before-font-weight, var(--bitmark-le-completion-lead-before-after-default-font-weight, var(--bitmark-bit-lead-content-before-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-completion-lead-content-before-font-style, var(--bitmark-le-completion-lead-before-after-default-font-style, var(--bitmark-bit-lead-content-before-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-completion-lead-content-before-font-text-decoration, var(--bitmark-le-completion-lead-before-after-default-font-text-decoration, var(--bitmark-bit-lead-content-before-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-completion-lead-content-before-font-text-decoration, var(--bitmark-le-completion-lead-before-after-default-font-text-decoration, var(--bitmark-bit-lead-content-before-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-completion-lead-content-before-font-line-height, var(--bitmark-le-completion-lead-before-after-default-font-line-height, var(--bitmark-bit-lead-content-before-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-completion-lead-content-before-font-color, var(--bitmark-le-completion-lead-before-after-default-font-color, var(--bitmark-bit-lead-content-before-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-completion-lead-content-before-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-completion-lead-content-before-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.learning-event-lead-dash-after {
  --bitmark-le-completion-lead-before-after-default-font-color: #543199;
  --bitmark-le-completion-lead-before-after-default-font-size: 12px;
}
.learning-event-lead-dash-after:after {
  content: var(--bitmark-le-completion-lead-content-after-string, var(--bitmark-le-completion-lead-before-after-default-string, var(--bitmark-bit-lead-content-after-string)));
  margin: var(--bitmark-le-completion-lead-content-after-margin, var(--bitmark-le-completion-lead-before-after-default-margin, var(--bitmark-bit-lead-content-after-margin)));
  font-family: var(--bitmark-le-completion-lead-content-after-font-family, var(--bitmark-le-completion-lead-before-after-default-font-family, var(--bitmark-bit-lead-content-after-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-completion-lead-content-after-font-size, var(--bitmark-le-completion-lead-before-after-default-font-size, var(--bitmark-bit-lead-content-after-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-completion-lead-content-after-font-weight, var(--bitmark-le-completion-lead-before-after-default-font-weight, var(--bitmark-bit-lead-content-after-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-completion-lead-content-after-font-style, var(--bitmark-le-completion-lead-before-after-default-font-style, var(--bitmark-bit-lead-content-after-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-completion-lead-content-after-font-text-decoration, var(--bitmark-le-completion-lead-before-after-default-font-text-decoration, var(--bitmark-bit-lead-content-after-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-completion-lead-content-after-font-text-decoration, var(--bitmark-le-completion-lead-before-after-default-font-text-decoration, var(--bitmark-bit-lead-content-after-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-completion-lead-content-after-font-line-height, var(--bitmark-le-completion-lead-before-after-default-font-line-height, var(--bitmark-bit-lead-content-after-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-completion-lead-content-after-font-color, var(--bitmark-le-completion-lead-before-after-default-font-color, var(--bitmark-bit-lead-content-after-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-completion-lead-content-after-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-completion-lead-content-after-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-le-completion-lead-before-after-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.learning-event-instruction {
  width: calc(100% - 35px);
  --bitmark-le-completion-instruction-default-font-color: #000;
  --bitmark-le-completion-instruction-default-font-size: 24px;
  font-family: var(--bitmark-le-completion-instruction-font-family, var(--bitmark-le-completion-instruction-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-completion-instruction-font-size, var(--bitmark-le-completion-instruction-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-completion-instruction-font-weight, var(--bitmark-le-completion-instruction-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-completion-instruction-font-style, var(--bitmark-le-completion-instruction-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-completion-instruction-font-text-decoration, var(--bitmark-le-completion-instruction-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-completion-instruction-font-text-decoration, var(--bitmark-le-completion-instruction-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-completion-instruction-font-line-height, var(--bitmark-le-completion-instruction-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-completion-instruction-font-color, var(--bitmark-le-completion-instruction-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-completion-instruction-font-align, var(--bitmark-le-completion-instruction-default-font-align, var(--bitmark-le-completion-instruction-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-completion-instruction-font-align, var(--bitmark-le-completion-instruction-default-font-align, var(--bitmark-le-completion-instruction-default-font-align, var(--bitmark-main-font-alignt, left))));
  text-decoration-color: var(--bitmark-le-completion-strike-color, var(--bitmark-le-completion-instruction-font-color, --bitmark-le-completion-instruction-default-font-color)) !important;
  text-decoration-thickness: var(--bitmark-le-completion-strike-thickness, 2px) !important;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
.learning-event-instruction.is-closed {
  text-decoration-line: line-through;
}

.learning-event-activity-type {
  --bitmark-le-completion-activity-type-default-font-color: #000;
  --bitmark-le-completion-activity-type-default-font-size: 12px;
  --bitmark-le-completion-activity-type-default-font-weight: bold;
  --bitmark-le-completion-activity-type-default-font-line-height: 1.3;
  --bitmark-le-completion-activity-type-default-border-top-width: 2px;
  --bitmark-le-completion-activity-type-default-border-right-width: 2px;
  --bitmark-le-completion-activity-type-default-border-bottom-width: 2px;
  --bitmark-le-completion-activity-type-default-border-left-width: 2px;
  --bitmark-le-completion-activity-type-default-border-style: solid;
  --bitmark-le-completion-activity-type-default-border-color: #543199;
  --bitmark-le-completion-activity-type-default-border-radius: 5px;
  font-family: var(--bitmark-le-completion-activity-type-font-family, var(--bitmark-le-completion-activity-type-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-le-completion-activity-type-font-size, var(--bitmark-le-completion-activity-type-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-le-completion-activity-type-font-weight, var(--bitmark-le-completion-activity-type-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-le-completion-activity-type-font-style, var(--bitmark-le-completion-activity-type-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-le-completion-activity-type-font-text-decoration, var(--bitmark-le-completion-activity-type-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-le-completion-activity-type-font-text-decoration, var(--bitmark-le-completion-activity-type-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-le-completion-activity-type-font-line-height, var(--bitmark-le-completion-activity-type-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-le-completion-activity-type-font-color, var(--bitmark-le-completion-activity-type-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-le-completion-activity-type-font-align, var(--bitmark-le-completion-activity-type-default-font-align, var(--bitmark-le-completion-activity-type-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-le-completion-activity-type-font-align, var(--bitmark-le-completion-activity-type-default-font-align, var(--bitmark-le-completion-activity-type-default-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-le-completion-activity-type-border-top-width, var(--bitmark-le-completion-activity-type-default-border-top-width)) var(--bitmark-le-completion-activity-type-border-style, var(--bitmark-le-completion-activity-type-default-border-style)) var(--bitmark-le-completion-activity-type-border-color, var(--bitmark-le-completion-activity-type-default-border-color));
  border-right: var(--bitmark-le-completion-activity-type-border-right-width, var(--bitmark-le-completion-activity-type-default-border-right-width)) var(--bitmark-le-completion-activity-type-border-style, var(--bitmark-le-completion-activity-type-default-border-style)) var(--bitmark-le-completion-activity-type-border-color, var(--bitmark-le-completion-activity-type-default-border-color));
  border-bottom: var(--bitmark-le-completion-activity-type-border-bottom-width, var(--bitmark-le-completion-activity-type-default-border-bottom-width)) var(--bitmark-le-completion-activity-type-border-style, var(--bitmark-le-completion-activity-type-default-border-style)) var(--bitmark-le-completion-activity-type-border-color, var(--bitmark-le-completion-activity-type-default-border-color));
  border-left: var(--bitmark-le-completion-activity-type-border-left-width, var(--bitmark-le-completion-activity-type-default-border-left-width)) var(--bitmark-le-completion-activity-type-border-style, var(--bitmark-le-completion-activity-type-default-border-style)) var(--bitmark-le-completion-activity-type-border-color, var(--bitmark-le-completion-activity-type-default-border-color));
  border-radius: var(--bitmark-le-completion-activity-type-border-radius, var(--bitmark-le-completion-activity-type-default-border-radius, y));
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  color: var(--bitmark-le-completion-toggle-btn-color, #543199);
  transition: transform 500ms ease;
}
.toggle-btn.rotate {
  transform: translate(0%, -50%) rotate(90deg);
}

.hidden-input {
  visibility: hidden;
  height: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
