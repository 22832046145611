// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover-default .cover-image {
  width: -moz-fit-content;
  width: fit-content;
  aspect-ratio: 0.7;
  background-color: #1ba1e2;
}
.cover-default .notebook-standard-mask, .cover-default .notebook-full-cover-mask {
  z-index: 1;
  -webkit-mask-image: url("/assets/images/notebook/notebook-cover-mask.svg");
  mask-image: url("/assets/images/notebook/notebook-cover-mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-position: center;
  mask-position: center;
  overflow: hidden;
}
.cover-default .notebook-custom-cover-mask {
  overflow: hidden;
  aspect-ratio: auto;
}
.cover-default .notebook-full-cover-mask .cover-picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cover-default.lg {
  font-weight: 500;
  width: 350px;
  height: 500px;
  font-size: 1.2rem;
}
.cover-default.lg.no-cover-type-image {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 375px;
  height: auto;
}
.cover-default.lg.cover-fill {
  width: 350px;
  height: 500px;
}
@media (max-width: 1024px) {
  .cover-default.lg.cover-fill {
    width: 285px;
    height: 407px;
  }
}
.cover-default.lg .showcase-letter {
  font-size: 270px;
}
@media (max-width: 1024px) {
  .cover-default.lg .showcase-letter {
    font-size: 200px;
  }
}
.cover-default.lg .showcase-letter-emoji {
  font-size: 230px;
}
@media (max-width: 1024px) {
  .cover-default.lg .showcase-letter-emoji {
    font-size: 150px;
  }
}
.cover-default.lg .notebook-standard-mask .cover-picture, .cover-default.lg .notebook-standard-mask .showcase {
  padding-left: 60px;
  padding-right: 10px;
  width: 100%;
  justify-content: center;
}
.cover-default.lg .notebook-full-cover-mask .cover-picture {
  margin-left: 50px;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.cover-default.md {
  font-weight: 500;
  height: 200px;
  width: 100%;
  max-width: 500px;
  font-size: 13px;
}
.cover-default.md .showcase-letter {
  font-size: 7rem;
}
.cover-default.md .showcase-letter-emoji {
  font-size: 5.75rem;
  margin-top: 15px;
}
.cover-default.md.no-cover-type-image {
  display: flex;
}
.cover-default.md .notebook-standard-mask .cover-picture, .cover-default.md .notebook-standard-mask .showcase {
  padding-left: 30px;
  padding-right: 10px;
  width: 100%;
  justify-content: center;
}
.cover-default.md .notebook-full-cover-mask .cover-picture {
  margin-left: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.cover-default.md .notebook-custom-cover-mask {
  height: -moz-fit-content !important;
  height: fit-content !important;
  max-height: 100%;
  align-self: flex-end;
}
.cover-default.sm {
  font-weight: 500;
  height: 55px;
  width: 38px;
  font-size: 13px;
}
.cover-default.sm .showcase-letter {
  font-size: 1.5rem !important;
}
.cover-default.sm .showcase-letter-emoji {
  font-size: 1.5rem !important;
  margin-top: 5px;
}
.cover-default.sm .notebook-standard-mask .cover-picture, .cover-default.sm .notebook-standard-mask .showcase {
  padding-left: 10px;
  padding-right: 5px;
  width: 100%;
  justify-content: center;
}
.cover-default.sm .notebook-full-cover-mask .cover-picture {
  margin-left: 5px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.bookmark-shadow {
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.55));
  transform: translateZ(0); /* added as a workaround for Safari performance issues https://stackoverflow.com/questions/10814178/css-performance-relative-to-translatez0 */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
