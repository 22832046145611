import {Component, EventEmitter, Input, Output} from '@angular/core';
import {cloneDeep} from 'lodash';
import {BitResource} from '../../bits.models';
import {LearningPathLearningGoalBit} from './learning-path-learning-goal.models';
import {LearningPathCommon} from '../learning-path.common';
import {BitmarkTextContentService} from '../../../shared';
import {ReaderTipTapTapService} from "../../../reader/tiptap/reader-tiptap.service";

@Component({
  selector: 'bitmark-learning-path-learning-goal',
  templateUrl: './learning-path-learning-goal.component.html',
  styleUrls: ['./learning-path-learning-goal.component.scss', '../learning-path.common.scss', '../../bits.scss']
})
export class LearningPathLearningGoalComponent extends LearningPathCommon {
  @Input() bit?: LearningPathLearningGoalBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningPathLearningGoalBit>();

  private prevLearningPathGoalBit?: LearningPathLearningGoalBit;

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    this.trimHeader(this.bit);

    if (value) {
      this.prevLearningPathGoalBit = cloneDeep(this.bit);
    }
  }
  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }
  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  get isHeaderOnly(): boolean {
    return !this.bit.body && !this.isBeingEditedByUser;
  }

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService) {
    super(readerTipTapService, bitmarkTextContentService);
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevLearningPathGoalBit);
    this.editClosed.emit();
  }

  saveChanges() {
    this.trimHeader(this.bit);
    this.saveInlineBit.emit(this.bit);
  }
}
