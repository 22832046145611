import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TrueFalseBit, TrueFalseStatement} from './true-false.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-true-false',
  templateUrl: './true-false.component.html',
  styleUrls: ['../bits.scss']
})
export class TrueFalseComponent implements OnInit, OnChanges {
  @Input() bit?: TrueFalseBit;
  @Input() bitId: string;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Input() allowAnswerSubmit = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  answerTrueCss = {};
  answerFalseCss = {};

  ngOnInit() {
    if (this.bit.isExample) {
      this.bit.statements?.forEach((s: TrueFalseStatement, idx: number) => {
        if (s.isExample) {
          s.answer = {choice: s.example};
          this.handleFeedback(s, idx);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.doRefresh();
    }
  }

  change(statement: TrueFalseStatement, value: boolean) {
    statement.answer = {choice: value};
    this.changed.emit();
  }

  private handleFeedback(s: TrueFalseStatement, idx: number) {
    const isAnswerCorrect = s.isCorrect === s.answer?.choice;
    this.answerTrueCss[idx] = !s.answer
      ? 'incorrect'
      : s.answer?.choice
        ? (isAnswerCorrect ? 'correct' : 'incorrect')
        : '';
    this.answerFalseCss[idx] = !s.answer
      ? 'incorrect'
      : !s.answer?.choice
        ? (isAnswerCorrect ? 'correct' : 'incorrect')
        : '';
  }

  static evalAnswer(bit: TrueFalseBit): BitEvalResult {
    return {
      totalAnswers: bit.statements.length,
      answeredAnswers: bit.statements.filter(p => !!p.answer).length,
      notAnsweredAnswers: bit.statements.filter(p => !p.answer).length,
      correctAnswers: bit.statements.filter(p => p.answer && p.answer.choice === p.isCorrect).length,
      incorrectAnswers: bit.statements.filter(p => (p.answer && p.answer.choice !== p.isCorrect) || !p.answer).length
    };
  }

  doRefresh() {
    this.bit.statements?.forEach((s: TrueFalseStatement, idx: number) => {
      if (this.hasFeedback) {
        this.handleFeedback(s, idx);
      } else {
        this.answerTrueCss[idx] = this.answerFalseCss[idx] = '';
      }
    });
  }
}
