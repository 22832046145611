import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BitbookApiService} from '../../../../reader/bitbook-api.service';
import {BookCoverEntity} from '../../../../reader/reader.models';

@Component({
  selector: 'bitmark-choose-book',
  templateUrl: './choose-book.component.html',
  styleUrls: ['./choose-book.component.scss']
})
export class ChooseBookComponent implements OnInit {
  @Output() bookChosen: EventEmitter<BookCoverEntity> = new EventEmitter<BookCoverEntity>();

  books: Array<BookCoverEntity>;
  displayBooks: Array<BookCoverEntity>;

  constructor(private ngbActiveModal: NgbActiveModal,
              private bitbookApiService: BitbookApiService) {
  }

  ngOnInit() {
    this.bitbookApiService.getBooksCovers()
      .subscribe(data => {
        this.books = data;
        this.displayBooks = data;
      }, (err) => {
        console.error(err);
      });
  }

  searchBook(evt: Event) {
    const value = (evt.target as any)?.value;
    if (!value) {
      this.displayBooks = [...this.books];
      return;
    }

    this.displayBooks = this.books.filter(book => {
      return book.title.toLowerCase().includes(value.toLowerCase()) ||
        book.externalId.toLowerCase().includes(value.toLowerCase());
    });
  }

  chooseBook(book: BookCoverEntity) {
    this.bookChosen.emit(book);
    this.ngbActiveModal.close();
  }

  closeModal() {
    this.ngbActiveModal.close();
  }
}
