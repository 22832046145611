import {OrderedList} from '@tiptap/extension-ordered-list';

export const LetteredListLower = OrderedList.extend({
  name: 'letteredListLower',
  addCommands(): any {
    return {
      toggleLetteredListLower: () => ({ commands }) => {
        return commands.toggleList(this.name, 'listItem');
      },
      setLetteredListLower: () => ({ commands }) => {
        return commands.liftListItem(this.name, 'listItem');
      }
    };
  }
});

export const LetteredList = OrderedList.extend({
  name: 'letteredList',
  addCommands(): any {
    return {
      toggleLetteredList: () => ({ commands }) => {
        return commands.toggleList(this.name, 'listItem');
      },
      setLetteredList: () => ({ commands }) => {
        return commands.liftListItem(this.name, 'listItem');
      }
    };
  }
});

export const OrderedListRomanLower = OrderedList.extend({
  name: 'orderedListRomanLower',
  addCommands(): any {
    return {
      toggleOrderedListRomanLower: () => ({ commands }) => {
        return commands.toggleList(this.name, 'listItem');
      },
      setOrderedListRomanLower: () => ({ commands }) => {
        return commands.liftListItem(this.name, 'listItem');
      }
    };
  }
});

export const OrderedListRoman = OrderedList.extend({
  name: 'orderedListRoman',
  addCommands(): any {
    return {
      toggleOrderedListRoman: () => ({ commands }) => {
        return commands.toggleList(this.name, 'listItem');
      },
      setOrderedListRoman: () => ({ commands }) => {
        return commands.liftListItem(this.name, 'listItem');
      }
    };
  }
});
