import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {Ng2UiAuthModule} from '@faubulous/ng2-ui-auth';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {SocketIoModule} from 'ngx-socket-io';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InViewportModule} from 'ng-in-viewport';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialAuthServiceConfig,
  SocialLoginModule, GoogleInitOptions,
} from '@abacritt/angularx-social-login';
import {NgxStripeModule} from 'ngx-stripe';
import {NgxPayPalModule} from 'ngx-paypal';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {GraphQLModule} from 'graphql/graphql.module';

import {
  EnsureModuleLoadedOnceGuard,
  UnauthorizedInterceptor,
  GlobalErrorHandler, MyMissingTranslationHandler,
} from './';
import {AppConfig} from 'app.config';
import {environment} from 'environment';
import localeEn from '@angular/common/locales/en';
import localeEnUk from '@angular/common/locales/en-GB';
import localeEnCh from '@angular/common/locales/en-CH';
import localeDe from '@angular/common/locales/de';
import localeDeCh from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeRo from '@angular/common/locales/ro';

registerLocaleData(localeEn);
registerLocaleData(localeEnUk);
registerLocaleData(localeEnCh);
registerLocaleData(localeDe);
registerLocaleData(localeDeCh);
registerLocaleData(localeFr);
registerLocaleData(localeFrCh);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeItCh);
registerLocaleData(localeRo);

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false
};

const providers = [
  NgbModal,
  SocialAuthService,
  Location,
  { provide: LocationStrategy, useClass: PathLocationStrategy },
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(AppConfig.ssoAuthConfig.googleClientId, googleLoginOptions),
        },
      ], onError: (err) => {
        console.error(err);
      },
    } as SocialAuthServiceConfig,
  },
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
  {
    provide: MissingTranslationHandler,
    useClass: MyMissingTranslationHandler,
  },
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
];

@NgModule({
  imports: [
    NgxWebstorageModule.forRoot(AppConfig.ng2WebStorageConfig),
    Ng2UiAuthModule.forRoot(AppConfig.authConfig),
    SocketIoModule.forRoot(AppConfig.socketIoConfig),
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    InViewportModule,
    SocialLoginModule,
    NgxPayPalModule,
    GraphQLModule,
    NgxStripeModule.forRoot(environment.stripeClientId),
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: providers,
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
