import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CookIngredient, CookIngredientsBit} from './cook-ingredients.models';

@Component({
  selector: 'bitmark-cook-ingredients',
  templateUrl: './cook-ingredients.component.html',
  styleUrls: ['cook-ingredients.component.scss']
})
export class CookIngredientsComponent implements OnInit {
  @Input() bit?: CookIngredientsBit;
  @Output() changed = new EventEmitter<any>();

  protected readonly parseFloat = parseFloat;

  displayedServings: string;
  displayedQuantities: Array<{ shouldShow: boolean, value: number }> = [];
  displayUnitAbbr = true;
  displayedUnits: Array<string> = [];

  ngOnInit() {
    this.displayedServings = this.bit?.servings?.servings.toFixed(this.bit?.servings?.decimalPlaces || 1);
    this.displayedQuantities = this.bit?.ingredients?.map(x => ({value: x.quantity, shouldShow: x.quantity > 0}));
    this.computeDisplayedUnits();
  }

  recomputeQuantities() {
    let displayedServingsNo = Number(this.displayedServings);
    if (isNaN(displayedServingsNo) || displayedServingsNo <= 0) {
      displayedServingsNo = 0;
    }

    this.displayedQuantities = this.bit?.ingredients?.map(x => {
      if (x.disableCalculation) {
        return {value: x.quantity, shouldShow: x.quantity > 0};
      }

      return {value: x.quantity * (displayedServingsNo / this.bit?.servings?.servings), shouldShow: x.quantity > 0};
    });
  }

  changeUnitDisplay(event: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    this.displayUnitAbbr = !this.displayUnitAbbr;
    this.computeDisplayedUnits();
  }

  computeDisplayedUnits() {
    this.displayedUnits = this.bit?.ingredients?.map(x => this.displayUnitAbbr ? (x.unitAbbr || x.unit) : (x.unit || x.unitAbbr));
  }

  onIngredientChecked(ingredient: CookIngredient, event: Event) {
    ingredient.answer = (event.target as any).checked;
    this.changed.emit();
  }
}
