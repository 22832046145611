import {VideoRecordingService} from '../video-recording.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  template: '',
})
export class ResourceBaseCameraEditComponent {
  @ViewChild('videoStream') videoStream: ElementRef;
  @ViewChild('videoCanvas') videoCanvas: ElementRef;

  isStreaming: boolean;
  aspectRatio?: number;
  isMobile: boolean;
  canSwitchCamera = false;
  isCameraSwitching = false;

  videoCameraConfigOptions = {
    video: {
      deviceId: '',
      width: {min: 640, ideal: 1280, max: 1920},
      height: {min: 480, ideal: 720, max: 1080}
    },
    audio: false
  };
  protected videoDevices: Array<MediaDeviceInfo> = [];
  currentDevice: MediaDeviceInfo;

  constructor(protected videoRecordingService: VideoRecordingService,
              protected deviceDetectorService: DeviceDetectorService) {
  }

  initialize(): Observable<any> {
    this.isMobile = this.deviceDetectorService.isMobile();

    return new Observable<any>(x => {
      this.videoRecordingService.getDeviceIds().then(devices => {
        this.canSwitchCamera = devices.length > 1;
        this.videoDevices = devices;
        this.currentDevice = devices[0];

        this.videoCameraConfigOptions.video.deviceId = this.currentDevice.deviceId;
        this.videoRecordingService.startCamera(this.videoCameraConfigOptions).then((stream) => {
          this.isStreaming = true;
          const {aspectRatio} = stream.getTracks()?.find(x => x.kind === 'video')?.getSettings();
          if (aspectRatio) {
            this.aspectRatio = aspectRatio;
          }

          this.videoStream.nativeElement.onloadedmetadata = () => {
            if (this.videoStream.nativeElement.videoWidth && this.videoStream.nativeElement.videoHeight) {
              this.aspectRatio = this.videoStream.nativeElement.videoWidth / this.videoStream.nativeElement.videoHeight;
            }
          };

          this.videoStream.nativeElement.srcObject = stream;
          this.videoStream.nativeElement.play();

          x.next();
          x.complete();
        });
      });
    });
  }

  destroy() {
    this.videoRecordingService.abortRecording();

    if (this.videoStream?.nativeElement?.srcObject) {
      this.videoStream.nativeElement.srcObject = null;
    }
  }

  cancel() {
    this.videoRecordingService.abortRecording();
    this.videoStream.nativeElement.srcObject = null;
  }

  revertCamera() {
    this.captureImage();
    this.isCameraSwitching = true;

    this.videoRecordingService.abortRecording();
    const index = this.videoDevices.findIndex(x => x.deviceId === this.videoCameraConfigOptions.video.deviceId);
    if (index >= 0 && index < this.videoDevices.length - 1) {
      this.currentDevice = this.videoDevices[index + 1];
    } else {
      this.currentDevice = this.videoDevices[0];
    }
    this.videoCameraConfigOptions.video.deviceId = this.currentDevice.deviceId;
    this.videoRecordingService.startCamera(this.videoCameraConfigOptions).then((stream) => {
      this.videoStream.nativeElement.onloadedmetadata = () => {
        if (this.videoStream.nativeElement.videoWidth && this.videoStream.nativeElement.videoHeight) {
          this.aspectRatio = this.videoStream.nativeElement.videoWidth / this.videoStream.nativeElement.videoHeight;
        }
      };
      this.videoStream.nativeElement.srcObject = stream;
      this.videoStream.nativeElement.play();
      this.isCameraSwitching = false;
    });
  }

  protected captureImage() {
    this.videoCanvas.nativeElement.width = this.videoStream.nativeElement.videoWidth;
    this.videoCanvas.nativeElement.height = this.videoStream.nativeElement.videoHeight;
    this.videoCanvas.nativeElement.getContext('2d').drawImage(this.videoStream.nativeElement, 0, 0, this.videoStream.nativeElement.videoWidth, this.videoStream.nativeElement.videoHeight);
  }
}
