import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BitResource, BitResourceType} from '../../../bits.models';

@Component({
  selector: 'bitmark-resource-image-link-edit',
  templateUrl: './resource-image-link-edit.component.html',
  styleUrls: ['./resource-image-link-edit.component.scss', '../../../bits.scss']
})
export class ResourceImageLinkEditComponent {
  @Output() onSave: EventEmitter<BitResource> = new EventEmitter<BitResource>();

  imageLink: string;
  imageLinkResource: BitResource | undefined;

  constructor(private ngbActiveModal: NgbActiveModal) {
  }

  onLinkChanged() {
    if (this.imageLink) {
      this.imageLinkResource = undefined;
      setTimeout(() => {
        this.imageLinkResource = {
          type: BitResourceType.ImageLink,
          imageLink: {
            url: this.imageLink,
            provider: ''
          }
        };
      }, 100);
    } else {
      this.imageLinkResource = undefined;
    }
  }

  saveChanges() {
    this.onSave.emit(this.imageLinkResource);
    this.ngbActiveModal.close();
  }

  cancelChanges() {
    this.ngbActiveModal.close();
  }
}
