import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-page404',
  template: ''
})
export class Page404Component implements OnInit {
  ngOnInit() {
    throwError(() => new HttpErrorResponse({
      statusText: 'Not found',
      status: 404
    })).subscribe();
  }
}
