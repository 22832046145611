import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectPlaceholderBit} from '../cloze.models';
import {EvtUtilsService} from '../../../shared';
import {BitUtilsService} from '../../../shared/utils/bit-utils.service';

@Component({
  selector: 'bitmark-select-placeholder',
  templateUrl: './select-placeholder.component.html',
  styleUrls: ['./select-placeholder.component.scss', '../../bits.scss']
})
export class SelectPlaceholderComponent implements OnInit, OnChanges {
  @Input() selectPlaceholderBit?: SelectPlaceholderBit;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Input() allowAnswerSubmit = true;
  @Output() changed = new EventEmitter<any>();

  answerCssClass = {};
  selectPlaceholderBitActions = [];
  selectedAnswer: { text: string, richText: string, isCorrect: boolean } = null;

  constructor(private evtUtilsService: EvtUtilsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      if (this.hasFeedback) {
        const isAnswerCorrect = !!this.selectPlaceholderBit.options
          .find(x => x.isCorrect
            && BitUtilsService.convertBitSolutionAnswer(x.text) === BitUtilsService.convertBitSolutionAnswer(this.selectPlaceholderBit.answer?.text));
        Object.assign(this.answerCssClass, {
          answered: true,
          correct: isAnswerCorrect,
          incorrect: !isAnswerCorrect,
          disabled: !this.allowAnswerSubmit && !this.selectPlaceholderBit.isExample
        });
      } else {
        this.answerCssClass = {
          disabled: !this.allowAnswerSubmit && !this.selectPlaceholderBit.isExample
        };
      }
    }
  }

  ngOnInit() {
    this.selectPlaceholderBit?.options?.forEach(choice => {
      this.selectPlaceholderBitActions.push({
        label: choice.text,
        handler: () => this.setAnswer(choice)
      });
    });

    if (this.selectPlaceholderBit.isExample && !this.selectPlaceholderBit.answer) {
      const correctAnswer = this.selectPlaceholderBit?.options?.find(x => x.isCorrect);

      if (this.selectPlaceholderBit.example) {
        this.setExampleAnswer({
          text: this.selectPlaceholderBit.example,
          richText: '',
          isCorrect: correctAnswer.text === this.selectPlaceholderBit.example
        });
      } else {
        this.setExampleAnswer(correctAnswer);
      }
    }
    if (this.selectPlaceholderBit.answer) {
      Object.assign(this.answerCssClass, {answered: true});
    }
  }

  setExampleAnswer(choice: { text: string, richText: string, isCorrect: boolean }) {
    this.selectedAnswer = choice;
    this.answerCssClass = {
      answered: true, correct: choice.isCorrect,
      incorrect: !choice.isCorrect,
      disabled: !this.allowAnswerSubmit && !this.selectPlaceholderBit.isExample
    };
    this.selectPlaceholderBit.answer = {text: choice.text};
  }

  setAnswer(choice: { text: string, richText: string, isCorrect: boolean }) {
    this.selectedAnswer = choice;
    this.answerCssClass = {
      answered: true,
      disabled: !this.allowAnswerSubmit && !this.selectPlaceholderBit.isExample
    };
    this.selectPlaceholderBit.answer = {text: choice.text};
    this.changed.emit();
  }

  openDropdown($event) {
    if (this.readOnly || this.selectPlaceholderBit.isExample) {
      this.evtUtilsService.cancelEvt($event);
      return;
    }
  }
}
