import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {IdentityService} from './identity.service';

@Injectable({providedIn: 'root'})
export class AuthGuardChild implements CanActivateChild {
  constructor(private authService: AuthService,
              private identityService: IdentityService,
              private router: Router) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated() && (route.routeConfig.path === 'login' || route.routeConfig.path === 'register')) {
      setTimeout(() => {
        throw new HttpErrorResponse({error: 'Unauthorized', status: 403});
      });
      return false;
    }

    const routeClaims = (route.data && route.data.claims) ? route.data.claims : [];
    if (!routeClaims || !routeClaims.length) {
      return true;
    }

    if (!this.authService.isAuthenticated()) {
      if (route.routeConfig.path === 'profile/verify-email') {
        this.router.navigate(['verify-email'], {queryParams: route.queryParams});
        return false;
      }
      if (!this.identityService.user) {
        this.router.navigate(['login'], {queryParams: route.queryParams});
        return false;
      }
      return true;
    }

    return this.identityService.canAny(routeClaims);
  }
}
