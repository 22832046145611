// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 10005;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
@media (max-width: 1024px) {
  .split {
    width: 100%;
  }
}

/* Control the left side */
.split-left {
  left: 0;
  background-color: #2d2d2d;
}

.split-bitmark-editor {
  background-color: #000000;
  border-radius: 8px;
  color: greenyellow;
  white-space: pre-wrap;
  overflow: auto;
}

/* Control the right side */
.split-right {
  right: 0;
  background-color: #000000;
  border-radius: 0;
}
.split-right pre {
  color: greenyellow;
}
@media (max-width: 1024px) {
  .split-right {
    display: none;
  }
}

.reader-content-wrapper {
  background-color: var(--bitmark-reader-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-reader-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-reader-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-reader-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-reader-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-reader-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-reader-background-animation-name) var(--bitmark-reader-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-reader-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-reader-background-backdrop-filter);
}
.reader-content-wrapper .reader-content {
  background-color: var(--bitmark-reader-content-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-reader-content-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-reader-content-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-reader-content-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-reader-content-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-reader-content-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-reader-content-background-animation-name) var(--bitmark-reader-content-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-reader-content-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-reader-content-background-backdrop-filter);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
