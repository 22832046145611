import {map} from 'rxjs/operators';
import {LocalStorageService} from 'ngx-webstorage';
import {Observable, of} from 'rxjs';

export class BrowserStorage {
  constructor(private key: string,
              private localStorage: LocalStorageService) {
  }

  add(raw: string, value: any) {
    const contents = this.localStorage.retrieve(this.key);
    let array;
    if (!contents || !contents[raw]) {
      array = [];
    } else {
      array = contents[raw];
    }
    if (!(array instanceof Array)) {
      array = [array];
    }
    array.push(value);
    this.store(raw, array);
  }

  remove(raw: string, value: any) {
    const contents = this.localStorage.retrieve(this.key);
    let array;
    if (!contents || !contents[raw]) {
      array = [];
    } else {
      array = contents[raw];
    }
    if (!(array instanceof Array)) {
      array = [array];
    }
    const idx = array.findIndex(x => JSON.stringify(x) === JSON.stringify(value));
    if (idx !== -1) {
      array.splice(idx, 1);
    }
    this.store(raw, array);
  }

  store(raw: string, value: any) {
    let contents = this.localStorage.retrieve(this.key);
    if (!contents) {
      contents = {};
    }
    contents[raw] = value;
    this.localStorage.store(this.key, contents);
  }

  retrieve(raw: string) {
    const contents = this.localStorage.retrieve(this.key);
    if (!contents) {
      return null;
    }
    return contents[raw];
  }

  clear(raw?: string) {
    if (!raw) {
      return this.localStorage.clear(this.key);
    }
    const contents = this.localStorage.retrieve(this.key);
    if (!contents) {
      return this.localStorage.clear(this.key);
    }
    if (contents[raw]) {
      delete contents[raw];
      this.localStorage.store(this.key, contents);
    }
  }

  observe(raw: string): Observable<any> {
    return this.localStorage.observe(this.key)
      .pipe(map(contents => {
        if (!contents) {
          return null;
        }
        return contents[raw];
      }));
  }

  check(): Observable<boolean> {
    return of(true);
  }
}
