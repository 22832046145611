import {Injectable} from '@angular/core';
import {DevCycleClient, initializeDevCycle} from '@devcycle/js-client-sdk';
import {environment} from 'environment';
import {IdentityUser} from 'core';

@Injectable({providedIn: 'root'})
export class FeatureFlagService {
  private devCycleClient: DevCycleClient;

  initFailed = false;

  async init(user?: IdentityUser) {
    const currentSubdomain = window.location.hostname.split('.')[0];
    const devCycleUser = {user_id: user?.id?.toString() || currentSubdomain, email: user?.ssoEmail || user?.email || ''};

    const options: any = {
      disableRealtimeUpdates: true,
      disableAutomaticEventLogging: true,
    };

    try {
      this.devCycleClient = await initializeDevCycle(environment?.devCycleServerKey, devCycleUser, options).onClientInitialized();
    } catch (err) {
      console.error('Error initializing DevCycle:', err);
      this.initFailed = true;
    }
  }

  getVariable(variableName: string, defaultValue: any) {
    if (!this.devCycleClient) {
      return null;
    }

    return this.devCycleClient.variableValue(variableName, defaultValue);
  }
}


// export default new FeatureFlagService();
