import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import RecordRTC from 'recordrtc';
import * as dayjs_ from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {RecordingService} from './recording.service';

const dayjs = dayjs_;
dayjs.extend(duration);

export interface RecordedVideoOutput {
  blob?: Blob;
  file?: File;
  url?: string;
  title?: string;
}

@Injectable({providedIn: 'root'})
export class VideoRecordingService extends RecordingService {
  private interval;
  private startTime;
  private _stream = new Subject<MediaStream>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  private availableRecordingFormats: Array<{ codec: string, mimeType: string, extension: string }> = [
    {
      codec: 'video/webm;codecs=h264',
      mimeType: 'video/webm',
      extension: '.webm'
    },
    {
      codec: 'video/mp4',
      mimeType: 'video/mp4',
      extension: '.mp4'
    },
  ];
  private supportedMimeTypeAndCode = this.availableRecordingFormats[0].codec;
  private supportedMimeType = this.availableRecordingFormats[0].mimeType;
  private recordingFileExtension = this.availableRecordingFormats[0].extension;

  getRecordedUrl(): Observable<string> {
    return this._recordedUrl.asObservable();
  }

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  getRecordedAnalyzerData(): Observable<Uint8Array> {
    return this._recordingAnalyzerData.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  getStream(): Observable<MediaStream> {
    return this._stream.asObservable();
  }

  hasMultipleCameras(): Promise<boolean> {
    return navigator.mediaDevices.enumerateDevices().then(data => {
      console.log(data);
      return data.filter(x => x.kind === 'videoinput').length > 0;
    });
  }

  getDeviceIds(): Promise<Array<MediaDeviceInfo>> {
    return navigator.mediaDevices.enumerateDevices().then(data => {
      return data.filter(x => x.kind === 'videoinput');
    });
  }

  hasPermissions(): Promise<boolean> {
    return navigator.permissions.query(
      {name: 'camera' as PermissionName}
    ).then((status) => {
      return status.state === 'granted';
    });
  }

  askForPermissions(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.startCamera({
        video: {
          facingMode: 'user',
          width: {min: 1024, ideal: 1280, max: 1920},
          height: {min: 576, ideal: 720, max: 1080}
        },
        audio: true
      }).then(
        (stream) => {
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
          stream.stop();
          this.stream = null;
          resolve();
        },
        () => {
          reject();
        });
    });
  }

  startCamera(conf: any): Promise<any> {
    if (this.stream) {
      // It means recording is already started or it is already recording something
      return;
    }

    return new Promise((resolve) => {
      navigator.mediaDevices
        .getUserMedia(conf)
        .then((stream) => {
          this.stream = stream;
          resolve(stream);
        })
        .catch(() => {
          this._recordingFailed.next('');
        });
    });
  }

  startRecording(conf: any): Promise<any> {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');
    return new Promise((resolve) => {
      if (this.stream) {
        this.record();
        this.analyzeAudio();
        resolve(this.stream);
      } else {
        navigator.mediaDevices
          .getUserMedia(conf)
          .then((stream) => {
            this.stream = stream;
            this.record();
            this.analyzeAudio();
            resolve(this.stream);
          })
          .catch(() => {
            this._recordingFailed.next('');
          });
      }
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.handleSupportedMimeType();
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: this.supportedMimeTypeAndCode,
      // frameInterval: 30,
      // frameRate: 30,
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 1000000,
      bitrate: 1000000,
      bitsPerSecond: 1000000
    });
    this.recorder.startRecording();
    this.startTime = dayjs();
    this.interval = setInterval(() => {
      const currentTime = dayjs();
      const diffTime = dayjs.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this._recordingTime.next(time);
      this._stream.next(this.stream);
    }, 500);
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(this.processVideo.bind(this));
    }
  }

  private processVideo(audioVideoWebMURL) {
    let recordedBlob = this.recorder.getBlob();
    recordedBlob = recordedBlob.slice(0, recordedBlob.size, this.supportedMimeType);

    const recordedName = encodeURIComponent(
      'video_' + new Date().getTime() + this.recordingFileExtension
    );
    this._recorded.next({
      blob: recordedBlob,
      url: audioVideoWebMURL,
      title: recordedName,
    });
    this.stopMedia();
  }

  stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
    }

    if (this.stream) {
      this.stream.getAudioTracks().forEach((track) => track.stop());
      this.stream.getVideoTracks().forEach((track) => track.stop());
      this.stream.stop();
      this.stream = null;
    }
  }

  private handleSupportedMimeType() {
    const supportedRecordingFormat = this.availableRecordingFormats.find(x => MediaRecorder.isTypeSupported(x.codec));
    if (supportedRecordingFormat) {
      this.supportedMimeTypeAndCode = supportedRecordingFormat.codec;
      this.supportedMimeType = supportedRecordingFormat.mimeType;
      this.recordingFileExtension = supportedRecordingFormat.extension;
    } else {
      alert('Compatible media recorder type not found');
    }
  }
}
