import {Injectable} from '@angular/core';
import {ApiService} from 'core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  constructor(private apiService: ApiService) {
  }

  login(response: any) {
    return this.apiService.post('auth/google', null, response);
  }
}
