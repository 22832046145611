import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {RecordedVideoOutput, VideoRecordingService} from '../video-recording.service';
import {SubSink} from '../../sub-sink/sub-sink';
import {ResourceBaseCameraEditComponent} from './resource-base-camera-edit.component';

@Component({template: ''})
export abstract class VideoRecordingBaseComponent extends ResourceBaseCameraEditComponent implements AfterViewInit, OnDestroy {
  @Input() isUploadingRecording = false;
  @Output() onStop: EventEmitter<RecordedVideoOutput> = new EventEmitter<RecordedVideoOutput>();
  @Output() onCancel?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('visualizer') canvas: ElementRef;
  @ViewChild('fileVideoInput') fileVideoInput: ElementRef;

  protected cssFeedbackAudioWaveColor: string;

  recordedTime: string;
  isRecording: boolean;

  private canvasContext: CanvasRenderingContext2D;
  private audioWaveColorStyle: string;
  private subSink = new SubSink();

  protected constructor(videoRecordingService: VideoRecordingService,
                        deviceDetectorService: DeviceDetectorService) {
    super(videoRecordingService, deviceDetectorService);
    this.recordedTime = '00:00';
  }

  ngAfterViewInit() {
    this.canvasContext = this.canvas.nativeElement.getContext('2d');
  }

  ngOnDestroy() {
    this.destroy();
    this.subSink?.unsubscribe();
  }

  start() {
    if (this.isMobile) {
      // this.videoRecordingService.stopMedia();
      this.fileVideoInput.nativeElement.click();
      return;
    }

    this.isRecording = true;
    this.videoRecordingService.startRecording(this.videoCameraConfigOptions);

    this.subSink.sink = this.videoRecordingService.recordingFailed().subscribe(() => {
      this.cancelRecording();
    });

    this.subSink.sink = this.videoRecordingService.getRecordedAnalyzerData().subscribe((data: Uint8Array) => {
      this.drawFeedback(data);
    });

    this.subSink.sink = this.videoRecordingService.getRecordedTime().subscribe((data: string) => {
      this.recordedTime = data;
    });
  }

  stop() {
    this.isRecording = false;
    this.videoRecordingService.stopRecording();
    this.subSink.sink = this.videoRecordingService.getRecordedBlob().subscribe((data: RecordedVideoOutput) => {
      this.onStop.emit(data);
    });
  }

  cancelRecording() {
    this.isStreaming = false;
    this.isRecording = false;
    this.cancel();
    this.onCancel?.emit();
  }

  uploadMobileVideo() {
    if (!this.fileVideoInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileVideoInput.nativeElement.files[0];

    this.videoRecordingService.abortRecording();
    this.onStop.emit({
      file: file
    });
  }

  private drawFeedback(dataArray: Uint8Array) {
    if (!this.audioWaveColorStyle) {
      this.audioWaveColorStyle = getComputedStyle(this.canvas.nativeElement).getPropertyValue(this.cssFeedbackAudioWaveColor) || '#ea80ff';
    }

    this.videoRecordingService.drawFeedback(
      this.canvas,
      this.canvasContext,
      dataArray,
      'transparent',
      this.audioWaveColorStyle);
  }
}
