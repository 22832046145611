import {Component, Input} from '@angular/core';

@Component({
  selector: 'bitmark-progress-spinner',
  templateUrl: './progess-spinner.component.html',
  styleUrls: ['./progess-spinner.component.scss']
})
export class ProgessSpinnerComponent {
  @Input() size: number;
}
