import {Component} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {VideoRecordingBaseComponent} from '../../../shared/recording/video-recording-base/video-recording-base.component';
import {VideoRecordingService} from '../../../shared/recording/video-recording.service';

@Component({
  selector: 'bitmark-record-video-edit',
  templateUrl: './record-video-edit.component.html',
  styleUrls: ['./record-video-edit.component.scss']
})
export class RecordVideoEditComponent extends VideoRecordingBaseComponent {
  videoStreamStarted = false;
  initialized = false;

  constructor(videoRecordingService: VideoRecordingService,
              deviceDetectorService: DeviceDetectorService) {
    super(videoRecordingService, deviceDetectorService);
    this.cssFeedbackAudioWaveColor = '--bitmark-record-video-video-recorder-recording-audio-wave-color';
  }

  startVideoStream() {
    if (this.videoStreamStarted) {
      return;
    }
    this.videoStreamStarted = true;
    if (!this.deviceDetectorService.isMobile()) {
      this.videoCameraConfigOptions.audio = true;
    }
    this.initialize().subscribe(() => {
      this.initialized = true;
    });
  }

  cancelRecording() {
    this.initialized = false;
    this.videoStreamStarted = false;
    super.cancelRecording();
  }
}
