import {Component, Input, Output, EventEmitter} from '@angular/core';
import {NgbTimeAdapter} from '@ng-bootstrap/ng-bootstrap';
import {TimeDurationAdapter} from '../adapters/time-duration.adapter';

@Component({
  selector: 'bitmark-duration-editor',
  templateUrl: './duration-editor.component.html',
  styleUrls: ['./duration-editor.component.scss'],
  providers: [
    {provide: NgbTimeAdapter, useClass: TimeDurationAdapter},
  ]
})
export class DurationEditorComponent {
  _ngModel: string;
  @Input() hoursLabel: string;
  @Input() minutesLabel: string;
  @Input()
  set ngModel(value: string) {
    if (!this._ngModel) {
      this._ngModel = value || 'PT1M';
    }
  }
  get ngModel(): string {
    return this._ngModel;
  }

  @Output() ngModelChange = new EventEmitter<string>();

  modelChanged(event) {
    this.ngModelChange.emit(event);
  }
}
