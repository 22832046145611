import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api/api.service';
import {BaseUser, User} from '../account/account.models';
import {environment} from "environment";

@Injectable({providedIn: 'root'})
export class UsersApiService {
  constructor(private apiService: ApiService) {
  }

  getUser(id: number): Observable<User> {
    return this.apiService.get('account/user/{id}', {id});
  }

  getUserByMail(email: string): Observable<User> {
    return this.apiService.get('account/email/{email}', {email});
  }

  getUsersByMails(emails: Array<string>): Observable<Array<User>> {
    const qry = ApiService.buildQuery([{emails: emails}], true);
    return this.apiService.get(`account/emails?${qry}`, null);
  }

  getUsersByIds(ids: Array<string>): Observable<Array<User>> {
    const qry = ApiService.buildQuery([{ids: ids}], true);
    return this.apiService.get(`account/ids?${qry}`, null);
  }

  getUserByResetToken(resetToken: string): Observable<User> {
    return this.apiService.get('account/resetToken/{resetToken}', {resetToken});
  }

  registerUserForced(user: BaseUser): Observable<{ user: User, hasTimelineHistory: boolean }> {
    const bitbookApiEndpoint = environment.name === 'Development'
      ? environment.bitbookApiEndpoint
      : environment.production
        ? `https://app.getmorebrain.com${environment.bitbookApiEndpoint}`
        : `https://cosmic.getmorebrain.com${environment.bitbookApiEndpoint}`;

    return this.apiService.post('account/register-user-forced', {}, Object.assign({
      bitbookApiEndpoint
    }, user));
  }

  clearUserJoinRequests(): Observable<any> {
    return this.apiService.delete('account/training-session-join-requests', {});
  }

  getUserJoinRequests(): Observable<any> {
    return this.apiService.get('account/training-session-join-requests', {});
  }

  createJoinRequestsForParticipants(userEmails: Array<string>, trainingSessionId: string): Observable<any> {
    return this.apiService.post('account/training-session-join-requests', {}, {userEmails, trainingSessionId});
  }

  clearXCourseUserJoinRequests(): Observable<any> {
    return this.apiService.delete('account/course-session-join-requests', {});
  }

  getXCourseUserJoinRequests(): Observable<any> {
    return this.apiService.get('account/course-session-join-requests', {});
  }

  createXCourseJoinRequestsForParticipants(userEmails: Array<string>, courseId: string, courseSessionId: string): Observable<any> {
    return this.apiService.post('account/course-session-join-requests', {}, {userEmails, courseId, courseSessionId});
  }

  createXModuleJoinRequestsForParticipants(userEmails: Array<string>, xModuleId: string): Observable<any> {
    return this.apiService.post('account/course-session-join-requests', {}, {userEmails, xModuleId});
  }

  sendCourseJoinConfirmationEmail(courseData: {
    title: string,
    courseName: string,
    photoUrl: string,
    courseLink: string
  }) {
    return this.apiService.post('account/send-course-join-confirmation-email', {}, courseData);
  }

  sendCourseJoinedByEmail(courseData: {
    title: string,
    courseName: string,
    photoUrl: string,
    courseLink: string,
    organizer: string
  }, emails: Array<string>) {
    return this.apiService.post('account/send-course-joined-by-email', {}, {...courseData, emails});
  }
}
