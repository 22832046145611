import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FocusableOption} from '@angular/cdk/a11y';

@Component({
  selector: 'app-search-suggestion-item',
  templateUrl: './search-suggestion-item.component.html',
  styleUrls: ['./search-suggestion-item.component.scss']
})
export class SearchSuggestionItemComponent implements FocusableOption {
  @Input() suggestion: any;
  @Output() selectSuggestion = new EventEmitter();

  @ViewChild('sgs') sgs;

  focus() {
    this.sgs.nativeElement.focus();
  }

  onSelectSuggestion() {
    this.selectSuggestion.emit(this.suggestion);
  }
}
