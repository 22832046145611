import {Injectable} from '@angular/core';
import {FormatWidth, getLocaleTimeFormat} from '@angular/common';
import * as dayjs_ from 'dayjs';
import duration, {Duration} from 'dayjs/plugin/duration';

const dayjs = dayjs_;
dayjs.extend(duration);

@Injectable({providedIn: 'root'})
export class TimeService {
  is24HourTimeFormat(locale: string, width: FormatWidth): boolean {
    const timeFormat = getLocaleTimeFormat(locale, width);
    return !timeFormat.includes('a');
  }

  parseDuration(value: string): Duration {
    let durationObj;

    const valueInSeconds = parseInt(value?.replace('s', ''), 10);
    if (!isNaN(valueInSeconds)) {
      durationObj = dayjs.duration(valueInSeconds, 'seconds');
    } else {
      durationObj = dayjs.duration(value);
    }

    return durationObj;
  }
}
