import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BitResource, BitResourceType} from '../../../bits.models';
import {ApiService} from '../../../../shared/api/api.service';

@Component({
  selector: 'bitmark-resource-video-link-edit',
  templateUrl: './resource-video-link-edit.component.html',
  styleUrls: ['./resource-video-link-edit.component.scss', '../../../bits.scss']
})
export class ResourceVideoLinkEditComponent {
  @Output() onSave = new EventEmitter<{resource: BitResource | undefined, title: string, description: string}>();

  videoLink: string;
  videoResource: BitResource | undefined;
  private videoTitle: string;
  private videoDescription: string;

  constructor(private ngbActiveModal: NgbActiveModal,
              private apiService: ApiService) {
  }

  onLinkChanged() {
    if (this.videoLink) {
      this.videoResource = undefined;

      this.apiService.post('learning-paths/link-info', null, {link: this.videoLink}).subscribe(result => {
        if (result) {
          this.videoTitle = result.title;
          this.videoDescription = result.description;
        }
        this.videoResource = {
          type: BitResourceType.VideoLink,
          videoLink: {
            url: this.videoLink,
            provider: this.getProvider()
          }
        };
      });
    } else {
      this.videoResource = undefined;
    }
  }

  private getProvider(): string {
    if (this.isVimeoUrl()) {
      return 'vimeo';
    }
    if (this.isYoutubeUrl()) {
      return 'youtube';
    }

    return '';
  }

  private isVimeoUrl(): boolean {
    return this.videoLink.indexOf('vimeo') !== -1;
  }

  private isYoutubeUrl(): boolean {
    return this.videoLink.indexOf('youtube') !== -1 ||
      this.videoLink.indexOf('youtu.be') !== -1;
  }

  saveChanges() {
    this.onSave.emit({resource: this.videoResource, title: this.videoTitle, description: this.videoDescription});
    this.ngbActiveModal.close();
  }

  cancelChanges() {
    this.ngbActiveModal.close();
  }
}
