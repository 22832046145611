// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.button-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #a98ae6;
  background-color: #000000;
  height: 100%;
  z-index: 10001;
}
@media (min-width: 992px) {
  .button-bar {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
