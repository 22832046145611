// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.wrapper > p {
  padding: 0;
  margin: 0;
}

.bit-button {
  justify-content: center !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
