import {Injector} from '@angular/core';
import {Node, mergeAttributes} from '@tiptap/core';
import {AngularNodeViewRenderer} from 'ngx-tiptap';

import {NodeviewMultipleChoiceComponent} from './multiple-choice.component';

const MultipleChoiceComponentExtension = (injector: Injector): Node => {
  return Node.create({
    name: 'multipleChoiceComponent',
    group: 'block',
    inline: false,
    selectable: false,
    atom: true,
    addAttributes() {
      return {
        text: {
          default: '',
        },
      };
    },
    parseHTML() {
      return [{tag: 'angular-component-multiple-choice'}];
    },
    renderHTML({HTMLAttributes}) {
      return ['angular-component-multiple-choice', mergeAttributes(HTMLAttributes)];
    },
    addNodeView() {
      return AngularNodeViewRenderer(NodeviewMultipleChoiceComponent, {injector});
    },
  });
};

export default MultipleChoiceComponentExtension;
