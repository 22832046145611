// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-picker-container {
  border-radius: 10px;
}
.language-picker-container .search-input {
  font-size: 18px;
}
.language-picker-container .language-list {
  max-height: 50vh;
  overflow: auto;
}
.language-picker-container .language-list .language-name, .language-picker-container .language-list .language-category {
  font-family: Inter, sans-serif;
  font-size: 18px;
}
.language-picker-container .language-list .language-english-name {
  color: #9b9b9b;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
