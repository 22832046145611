import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {LearningEventLearningStepBit} from './learning-event-learning-step.models';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';

@Component({
  selector: 'bitmark-learning-event-learning-step',
  templateUrl: './learning-event-learning-step.component.html',
  styleUrls: ['./learning-event-learning-step.component.scss'],
  animations: toggleAnimations
})
export class LearningEventLearningStepComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventLearningStepBit;
  @Output() changed = new EventEmitter<any>();

  isLeadEmpty = false;
  isItemEmpty = false;

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.toggleStateIsDone = this.bit?.answer?.isDone ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.bit.answer?.isDone);
  }
}
