import {Injectable} from '@angular/core';
import {Observable, Subject, Subscriber} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DomUtilsService {
  // findChildWithName(element, pattern) {
  //   if (!element) {
  //     return null;
  //   }
  //
  //   if (element.localName.startsWith(pattern)) {
  //     return element;
  //   }
  //
  //   return this.findChildWithName(element.parentElement, pattern);
  // }

  findAncestorWithClass(element, className) {
    if (!element) {
      return null;
    }
    if (!element.classList) {
      return null;
    }
    if (element.classList.contains(className)) {
      return element;
    }
    return this.findAncestorWithClass(element.parentElement, className);
  }

  findAncestorWithNameStartsWith(element, pattern, breakPattern) {
    if (!element) {
      return null;
    }

    if (element.localName.startsWith(breakPattern)) {
      return null;
    }

    if (element.localName.startsWith(pattern)) {
      return element;
    }

    return this.findAncestorWithNameStartsWith(element.parentElement, pattern, breakPattern);
  }

  findAncestorWithName(element, pattern, breakPattern) {
    if (!element) {
      return null;
    }

    if (element.localName.startsWith(breakPattern)) {
      return null;
    }

    if (element.localName === pattern) {
      return element;
    }

    return this.findAncestorWithNameStartsWith(element.parentElement, pattern, breakPattern);
  }

  findNode(nodes: NodeList, predicate: (n: Node) => boolean) {
    for (let i = 0; i < nodes.length; i++) {
      if (predicate.apply(null, [nodes[i]])) {
        return nodes[i];
      }
    }
    return null;
  }

  bottomScrollDistance(nativeElement: Element): number {
    return nativeElement.scrollHeight - nativeElement.scrollTop - nativeElement.clientHeight;
  }

  isScrolledToBottom(nativeElement: Element, offset = 0): boolean {
    return this.bottomScrollDistance(nativeElement) <= offset;
  }

  isScrolledToTop(nativeElement: Element, offset = 0): boolean {
    return nativeElement.scrollTop <= offset;
  }

  scrollToBottom(nativeElement: Element, offset = 0, behavior?: ScrollBehavior) {
    nativeElement.scrollTo({left: 0, top: nativeElement.scrollHeight - nativeElement.clientHeight - offset, behavior});
  }

  scrollToBottomWithCheck(nativeElement: Element, offset = 0, checkOffset = 0, checkTimeout = 100, behavior?: ScrollBehavior): Observable<boolean> {
    const s = new Subject<boolean>();

    this.scrollToBottomWithCheckRecursive(s, nativeElement, offset, checkOffset, checkTimeout, behavior);

    return s;
  }

  private scrollToBottomWithCheckRecursive(s: Subject<any>, nativeElement: Element, offset: number, checkOffset: number, checkTimeout: number, behavior?: ScrollBehavior) {
    this.scrollToBottom(nativeElement, offset, behavior);

    setTimeout(() => {
      if (!this.isScrolledToBottom(nativeElement, checkOffset)) {
        this.scrollToBottomWithCheckRecursive(s, nativeElement, offset, checkOffset, checkTimeout, behavior);
      } else {
        s.next(null);
        s.complete();
      }
    }, checkTimeout);
  }

  scrollToTop(nativeElement: Element, offset = 0) {
    nativeElement.scrollTo(0, offset);
  }

  scrollToElementId(nativeElement: Element, elementId: string): boolean {
    const unreadEl = document.getElementById(elementId);
    if (unreadEl) {
      const offset = unreadEl.offsetTop;
      nativeElement.scrollTop = offset - 10;
      return true;
    }
    return false;
  }

  scrollElementIntoView(element: string | Element, s: Subject<any> = null, options?: ScrollIntoViewOptions): Observable<any> {
    const el = typeof element === 'string' ? document.querySelector(element) : element;
    s = s || new Subject<boolean>();
    if (el) {
      setTimeout(() => {
        el.scrollIntoView(options);
        s.next(null);
        s.complete();
      }, 500);
    } else {
      setTimeout(() => {
        this.scrollElementIntoView(element, s, options);
      }, 100);
    }
    return s;
  }

  waitForStyleValue(elSelector: string, styleProp: string, styleValue: string): Observable<any> {
    return new Observable(x => {
      this.checkStyleValue(x, document, elSelector, styleProp, styleValue);
    });
  }

  waitForDifferentStyleValue(elSelector: string, styleProp: string, styleValue: string): Observable<any> {
    return new Observable(x => {
      this.checkStyleValue(x, document, elSelector, styleProp, styleValue, true);
    });
  }

  waitForDifferentStyleValueWithParentElement(parentElem: any, elSelector: string, styleProp: string, styleValue: string): Observable<any> {
    return new Observable(x => {
      this.checkStyleValue(x, parentElem, elSelector, styleProp, styleValue, true);
    });
  }

  private checkStyleValue(sub: Subscriber<any>, parentElement: any, elSelector: string, styleProp: string, styleValue: string, isDifferent = false) {
    let isDone = false;
    const el = parentElement.querySelector(elSelector);
    if (el) {
      const computedStyle = window.getComputedStyle(el);
      isDone = isDifferent
        ? computedStyle[styleProp] !== styleValue
        : computedStyle[styleProp] === styleValue;
    }

    if (isDone) {
      sub.next();
      sub.complete();
    } else {
      setTimeout(() => {
        this.checkStyleValue(sub, parentElement, elSelector, styleProp, styleValue, isDifferent);
      }, 200);
    }
  }
}
