import {Mark, mergeAttributes} from '@tiptap/core';
import {MarkedOptions} from '../marked/marked.node';

export const LanguageEm = Mark.create<MarkedOptions>({
  name: 'languageEm',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'em[class="bitmark-text-language-em"]'
      }
    ];
  },

  renderHTML({HTMLAttributes}) {
    const customAttributes = {
      class: 'bitmark-text-language-em'
    };
    return ['em', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
  }
});
