import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Editor} from '@tiptap/core';
import {DropdownItemModel} from '../../../../shared';
import {ReaderTiptapExtensionsService} from '../../../tiptap/reader-tiptap-extensions.service';

@Component({
  selector: 'bitmark-contextual-menu-code',
  templateUrl: './contextual-menu-code.component.html'
})
export class ContextualMenuCodeComponent implements OnInit {
  @Input() editor: Editor | any;
  @Output() onDeleteNode: EventEmitter<string> = new EventEmitter<any>();
  @Output() onLanguageChanged: EventEmitter<string> = new EventEmitter<string>();
  menuActions: Array<DropdownItemModel> = [];

  constructor(private readerTiptapExtensionsService: ReaderTiptapExtensionsService) {
  }

  ngOnInit() {
    this.menuActions = [{
      label: 'Code Coloring',
      handler: this.toggleCodeColoring.bind(this)
    }, {
      label: 'Set Language',
      actions: this.readerTiptapExtensionsService
        .getLowlightInstance()
        .listLanguages()
        .map((l) => {
          return {
            label: l,
            handler: this.setLanguage.bind(this, l)
          };
        })
    }, {isSeparator: true}, {
      label: 'Delete',
      handler: this.deleteNode.bind(this)
    }];
  }

  toggleCodeColoring() {
    (this.editor.commands as any).toggleCodeblockColoring();
  }

  setLanguage(language: string) {
    // (this.editor.commands as any).setCodeblockLanguage(language);
    this.onLanguageChanged.emit(language);
  }

  deleteNode() {
    this.onDeleteNode.emit('codeBlock');
  }
}
