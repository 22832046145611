import {Injectable} from '@angular/core';
import {ApiService} from 'core/api/api.service';
import {environment} from 'environment';
import {WindowService} from 'core';
import script from 'scriptjs';

declare var AppleID: any;

@Injectable({
  providedIn: 'root'
})
export class AppleLoginService {
  private appleScriptUrl = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  private ready: Promise<boolean> = new Promise(resolve => {
    if (typeof window !== 'undefined') {
      script(this.appleScriptUrl, () => resolve(true));
    } else {
      resolve(false);
    }
  });

  constructor(private apiService: ApiService,
              private windowService: WindowService) {
    const hasSocialLogin = ['ef.', 'ef-cosmic.', 'goldstuck.']
      .find(x => window.location.href.indexOf(x) === -1);
    if (hasSocialLogin) {
      this.ready.then(isReady => {
        if (isReady) {
          this.init();
        }
      });
    }
  }

  private init(): void {
    if (typeof AppleID !== 'undefined' && AppleID) {
      AppleID.auth.init({
        clientId: environment.appleClientId,
        scope: 'name email',
        redirectURI: `${this.windowService.getBaseUrl()}/apple-signin`,
        state: 'getmorebrain',
        usePopup: true
      });
    }
  }

  async signIn() {
    try {
      if (typeof AppleID !== 'undefined' && AppleID) {
        return await AppleID.auth.signIn();
      }
    } catch (error) {
      console.log('in catch apple error', error);
    }
  }

  serverLogin(loginData: any) {
    return this.apiService.post('auth/apple', null, loginData);
  }
}
