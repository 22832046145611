// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finished-header {
  height: 64px;
}

.finished-card-content {
  margin: var(--bitmark-app-flashcards-card-size-margin, var(--bitmark-x-size-margin, 0));
  width: var(--bitmark-app-flashcards-card-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-app-flashcards-card-size-height, var(--bitmark-x-size-height, inherit));
  color: var(--bitmark-app-flashcards-label-color, #fff);
}
.finished-card-content .flashcards-finished-right-icon::before {
  content: var(--bitmark-app-flashcards-got-it-right-emoji-string);
  font-size: 90px;
}
.finished-card-content .flashcards-finished-label {
  font-size: 24px;
}

.finished-navigation {
  height: 50px;
}

.flashcards-header:after {
  left: var(--bitmark-app-flashcards-normal-current-pot-position-x, 0%);
}
.flashcards-header .flashcards-right {
  display: flex;
}
.flashcards-header .flashcards-right::after {
  content: var(--bitmark-app-flashcards-got-it-right-emoji-string);
  font-size: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}
.flashcards-header .flashcards-wrong {
  display: flex;
}
.flashcards-header .flashcards-wrong::after {
  content: var(--bitmark-app-flashcards-got-it-wrong-emoji-string);
  font-size: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.jump-animation {
  animation: jump 0.3s ease-in-out 1;
}

@keyframes jump {
  0% {
    transform: translateY(0%);
  }
  30% {
    transform: translateY(-70%);
  }
  50% {
    transform: translateY(10%);
  }
  70% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
