import {Mark, mergeAttributes} from '@tiptap/core';

export interface VarOptions {
  HTMLAttributes: Record<string, any>;
}

export const Var = Mark.create<VarOptions>({
  name: 'var',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      name: {
        default: ''
      }
    };
  },

  parseHTML() {
    return [
      {tag: 'span[class="tiptap-var"]'},
    ];
  },

  renderHTML({HTMLAttributes}) {
    const classAttributes = {
      class: 'tiptap-var'
    };
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, classAttributes), 0];
  }
});
