import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {cloneDeep} from 'lodash';
import {ScormBit} from './scorm.models';
import {BitResource, BitResourceImage, BitType} from '../bits.models';
import {ApiService} from '../../shared/api/api.service';
import {DropdownItemModel, ModalTouchSwipeService, ScormPlayerComponent} from '../../shared';
import {BitbookApiService} from '../../reader/bitbook-api.service';

@Component({
  selector: 'bitmark-scorm',
  templateUrl: './scorm.component.html',
  styleUrls: ['./scorm.component.scss', '../bits.scss']
})
export class ScormComponent implements OnInit {
  @Input() bit?: ScormBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<ScormBit>();

  posterResourceImage: BitResourceImage;

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      this.started = false;
      this.prevScormBit = cloneDeep(this.bit);
      this.apiService.get('scorm/courses', null).subscribe(result => {
        this.courses = result.map(x => ({id: x.id, label: x.title}));
      });
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  get coverImageExists(): boolean {
    return !!this.bit.posterImage;
  }

  started = false;
  courses: Array<DropdownItemModel> = [];
  private prevScormBit?: ScormBit;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private apiService: ApiService,
              private ngbModal: NgbModal,
              private modalTouchSwipeService: ModalTouchSwipeService,
              private bitBookApiService: BitbookApiService) {
  }

  ngOnInit() {
    if (this.bit?.posterImage) {
      this.posterResourceImage = {
        src: this.bit.posterImage
      };
    }
  }

  getScormSourceCourseTitle(): string {
    return this.courses.find(x => x.id === this.bit.scormSource)?.label || '';
  }

  start() {
    if (this.bit?.scormSource && !this.isBeingEditedByUser) {
      const modalRef = this.ngbModal.open(ScormPlayerComponent, {
        windowClass: 'full-screen-modal light-background-modal',
        backdrop: 'static',
        keyboard: true,
        animation: false
      });
      const scormPlayerComponentInstance = modalRef.componentInstance as ScormPlayerComponent;
      scormPlayerComponentInstance.source = this.bit.scormSource;

      this.modalTouchSwipeService.applyTouchSwipe(modalRef);
      this.started = true;

      modalRef.dismissed.subscribe(() => {
        this.started = false;
      });
    }
  }

  removeCoverImage() {
    if (this.bit.posterImage) {
      this.bit.posterImage = null;
      this.posterResourceImage = null;
    }
  }

  uploadCoverImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitBookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        this.bit.posterImage = resourceUrl.url;
        this.posterResourceImage = {
          src: resourceUrl.url
        };
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevScormBit);
    if (this.bit?.posterImage) {
      this.posterResourceImage = {
        src: this.bit.posterImage
      };
    }
    this.editClosed.emit();
  }

  saveChanges() {
    this.saveInlineBit.emit(this.bit);
  }

  protected readonly BitType = BitType;
}
