import {Component, Input, OnInit} from '@angular/core';
import {PageFooterBit, PageFooterSection} from './page-footer.models';
import {BaseBit, BitType} from '../bits.models';

@Component({
  selector: 'bitmark-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  @Input() bit?: PageFooterBit;

  sections: Array<{
    data: PageFooterSection,
    bit: BaseBit
  }> = [];

  ngOnInit() {
    this.sections = this.bit?.sections?.map(this.getBitForInstruction);
  }

  getBitForInstruction(section: PageFooterSection): { data: PageFooterSection, bit: BaseBit } {
    return {
      data: section,
      bit: {...section, type: BitType.PageFooter}
    };
  }
}
