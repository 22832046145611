import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SurveyMatrixBit} from './survey-matrix.models';
import {BitApiWrapper, BitType} from '../bits.models';
import {BitmarkConfig} from '../../bitmark.module';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BitWrapperComponent} from '../../reader';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bitmark-survey-matrix',
  templateUrl: './survey-matrix.component.html',
  styleUrls: ['../bits.scss', 'survey-matrix.component.scss']
})
export class SurveyMatrixComponent implements OnInit {
  private _bit?: SurveyMatrixBit;
  @Input() set bit(value: SurveyMatrixBit) {
    this._bit = value;
    this.computeIsPointerSet();
  }

  get bit(): SurveyMatrixBit {
    return this._bit;
  }

  @Input() bitWrapper?: BitApiWrapper;
  @Input() isMe = false;
  @Input() allowAnswerSubmit = true;
  @Input() peerUser?: any;
  @Output() changed = new EventEmitter<any>();

  readonly BitType = BitType;
  @ViewChild('matrix') matrixElement: ElementRef;
  @ViewChild('pointer') pointerElement: ElementRef;
  isPointerSet = false;
  isEditing = false;
  currentUserDetails: {
    icon: {
      photoUrl: string;
      fullName: string;
    };
    emails: { email: string; ssoEmail: string; };
  };
  tempPositionPointerLeft: string;
  tempPositionPointerTop: string;
  isMobile = false;
  isInModal = false;

  constructor(@Inject('BitmarkConfig') bitmarkConfig: BitmarkConfig,
              private deviceDetectorService: DeviceDetectorService,
              private ngbModal: NgbModal,
              private ngbActiveModal: NgbActiveModal) {
    this.currentUserDetails = {icon: bitmarkConfig.userIcon, emails: bitmarkConfig.userEmails};
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isInModal = this.ngbModal.hasOpenModals();
    if (this.isInModal) {
      this.isEditing = true;
    }

    if (this.isMe && this.peerUser && !this.allowAnswerSubmit) {
      this.currentUserDetails = this.peerUser;
    }

    this.computeIsPointerSet();
  }

  enableEdit() {
    if (this.isMobile) {
      const modalRef = this.ngbModal.open(BitWrapperComponent, {
        windowClass: 'reader-modal',
        modalDialogClass: 'h-100',
        backdrop: 'static',
        backdropClass: 'd-none',
        keyboard: true,
        animation: false
      });
      (modalRef.componentInstance as BitWrapperComponent).bitWrapper = this.bitWrapper;
      (modalRef.componentInstance as BitWrapperComponent).context = 'notebook';
      (modalRef.componentInstance as BitWrapperComponent).fullScreen = true;
      const sub = modalRef.closed.subscribe((newBit: SurveyMatrixBit | undefined) => {
        sub.unsubscribe();
        if (newBit) {
          this.bit.answer = {...newBit.answer};
          this.computeIsPointerSet();
        }
      });
    } else {
      this.isEditing = true;
    }
  }

  save() {
    this.bit.answer = {
      position: {
        pointerLeft: this.tempPositionPointerLeft,
        pointerTop: this.tempPositionPointerTop
      },
      setDate: new Date()
    };
    this.tempPositionPointerLeft = '';
    this.tempPositionPointerTop = '';

    this.isEditing = false;
    this.changed.emit();

    if (this.isInModal) {
      this.ngbActiveModal.close(this.bit);
    }
  }

  cancel() {
    if (this.isInModal) {
      this.ngbActiveModal.close();
    } else {
      this.isEditing = false;
      this.tempPositionPointerLeft = '';
      this.tempPositionPointerTop = '';
      this.computeIsPointerSet();
    }
  }

  setPointer($event: MouseEvent) {
    $event.preventDefault();
    if (!this.isEditing) {
      return;
    }

    const pointerLeftPx = $event.offsetX;
    const pointerTopPx = $event.offsetY;

    const pointerLeftPercentage = pointerLeftPx / this.matrixElement.nativeElement.clientWidth * 100;
    const pointerTopPercentage = pointerTopPx / this.matrixElement.nativeElement.clientHeight * 100;

    this.pointerElement.nativeElement.style.left = pointerLeftPx + 'px';
    this.pointerElement.nativeElement.style.top = pointerTopPx + 'px';

    this.tempPositionPointerLeft = Math.round(pointerLeftPercentage * 100) / 100 + '%';
    this.tempPositionPointerTop = Math.round(pointerTopPercentage * 100) / 100 + '%';

    this.isPointerSet = true;
  }

  private computeIsPointerSet() {
    this.isPointerSet = !!(this.bit.answer?.position?.pointerTop || this.bit.pointerTop);
  }
}
