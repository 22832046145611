import {HttpClient, HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, flatMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ApiRouterService} from './api-router.service';
import {Pagination} from './pagination';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient,
              private apiRouterService: ApiRouterService) {
  }

  static buildQuery(objects: Array<Pagination | any>, joinArrayParams = false) {
    let qry = '';
    objects.filter(o => !!o).forEach(object => {
      let properties = Object.getOwnPropertyNames(object);
      if (object instanceof Pagination) {
        properties = ['page', 'pageSize'];
      }
      properties?.forEach(p => {
        const value: Array<any> = (!Array.isArray(object[p]) ? [object[p]] : object[p]).filter(x => !!x);
        if (joinArrayParams) {
          const k = value.map(v => encodeURIComponent(v)).join(',');
          qry += `&${p}=${k}`;
        } else {
          value?.forEach(v => qry = qry + `&${p}=${encodeURIComponent(v)}`);
        }
      });
    });
    return qry;
  }

  static sanitizeFileName(fileName: string): string {
    const extSep = fileName?.endsWith('.bitmark.pdf') ? fileName.lastIndexOf(".bitmark.pdf") : fileName.lastIndexOf(".");
    const sanitized = fileName
      .replace(/[^a-z0-9]/gi, '_')
      .toLowerCase();
    const ext = extSep !== -1
      ? fileName.substring(extSep + 1)
      : '';
    const trimmed = sanitized
      .substring(0, extSep == -1 ? undefined : extSep)
      .substring(0, 128);
    return trimmed?.length && trimmed?.trim().length ? `${trimmed}.${ext}` : `upload-${trimmed}.${ext}`;
  }

  private httpCall(method: string, routeId: string, params: any, queryParams: any, payload: any, options: any = {}): Observable<any> {
    if (!payload) {
      payload = params;
    }
    const requestOptions = Object.assign({
      body: payload
    }, options);

    const fnCatchError = catchError((error: HttpErrorResponse | any) => {
      return throwError(() => error);
    });

    return this.apiRouterService
      .getRouteById(routeId, params, queryParams)
      .pipe(fnCatchError)
      .pipe(flatMap((url: string) => this.http
        .request(method, url, requestOptions)
        .pipe(fnCatchError)
      ));
  }

  private httpCallWithProgress(method: string, routeId: string, params: any, queryParams: any, payload: any, options: any = {}): Observable<any> {
    if (!payload) {
      payload = params;
    }
    const requestOptions = Object.assign({reportProgress: true}, options);

    const fnCatchError = catchError((error: HttpErrorResponse | any) => {
      return throwError(() => error);
    });

    return this.apiRouterService
      .getRouteById(routeId, params, queryParams)
      .pipe(fnCatchError)
      .pipe(flatMap((url: string) => {
          const req = new HttpRequest(method, url, payload, requestOptions);
          return this.http.request(req).pipe(fnCatchError);
        }
      ));
  }

  get(routeId: string, params: any, queryParams: any = null, options?: any) {
    return this.httpCall('GET', routeId, params, queryParams, null, options);
  }

  getBlob(routeId: string, params: any, options: any = {}) {
    return this.httpCall('GET', routeId, params, null, null, Object.assign({}, {responseType: 'blob'}, options));
  }

  post(routeId: string, params: any, payload: any, options?: any) {
    return this.httpCall('POST', routeId, params, null, payload, options);
  }

  postWithProgress(routeId: string, params: any, payload: any, options?: any) {
    return this.httpCallWithProgress('POST', routeId, params, null, payload, options);
  }

  upload(routeId: string, params: any, file: File, fileKey = 'upload', additionalPayload: any = null, options: any = null, isPut?: boolean): Observable<any> {
    const formData = new FormData();
    formData.append(fileKey, file, file.name);
    if (additionalPayload) {
      Object.keys(additionalPayload)
        .forEach(k => formData.append(k, additionalPayload[k]));
    }
    const method = isPut ? 'put' : 'post';
    return this[method](routeId, params, formData, options);
  }

  uploadWithProgress(routeId: string, params: any, file: File, fileKey = 'upload', additionalPayload: any = null, options: any = null): Observable<any> {
    const formData = new FormData();
    formData.append(fileKey, file, ApiService.sanitizeFileName(file.name));
    if (additionalPayload) {
      Object.keys(additionalPayload)
        .forEach(k => formData.append(k, additionalPayload[k]));
    }
    return this.postWithProgress(routeId, params, formData, options);
  }

  s3Upload(presignedUrl: string, file: File): Observable<string> {
    const fileName = ApiService.sanitizeFileName(file.name);
    return new Observable((x) => {
      this.post('storage/presigned', null, {
        contentType: file.type,
        name: fileName
      }).subscribe(async presignedPostData => {
        const formData = new FormData();
        Object.keys(presignedPostData.fields)
          .forEach(key => formData.append(key, presignedPostData.fields[key]));
        formData.append('file', file, fileName);
        try {
          const uploadResp = await fetch(presignedPostData.url, {
            method: 'POST',
            body: formData
          });
          if (!uploadResp.ok) {
            throw new Error(uploadResp.statusText);
          }
          x.next(presignedPostData.url + '/' + presignedPostData.fields.key);
        } catch (err) {
          x.error(err);
          x.complete();
        }
      }, error => {
        console.error(error);
        x.error(error);
        x.complete();
      });
    });
  }

  put(routeId: string, params: any, payload: any, options?: any) {
    return this.httpCall('PUT', routeId, params, null, payload, options);
  }

  // TODO: refactor to have query params into the regular PUT
  putWithQueryParams(routeId: string, params: any, queryParams: any, payload: any, options?: any) {
    return this.httpCall('PUT', routeId, params, queryParams, payload, options);
  }

  patch(routeId: string, params: any, payload: any) {
    return this.httpCall('PATCH', routeId, params, null, payload, null);
  }

  delete(routeId: string, params: any, payload?: any) {
    return this.httpCall('DELETE', routeId, params, null, payload, null);
  }
}
