import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DebounceService {
  private timerId;

  debounce(delay, fn: () => any) {
    if (this.timerId) {
      return;
    }
    this.timerId = setTimeout(() => {
      fn();
      clearTimeout(this.timerId);
      this.timerId = null;
    }, delay);
  }
}
