class EventEmitter {
  private events: Array<{ key: string, actions: Array<{ handler: string, fn: (args: any) => any }> }>;

  private uuidV4(): string {
    const stringArr = [];
    for (let i = 0; i < 4; i++) {
      const s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(s4);
    }
    return stringArr.join('-');
  }

  constructor() {
    this.events = [];
  }

  on(key, action): string {
    const ev = this.events.find(e => e.key === key);
    const handler = this.uuidV4();
    if (ev) {
      ev.actions.push({handler, fn: action});
    } else {
      this.events.push({
        key, actions: [{handler, fn: action}]
      });
    }
    return handler;
  }

  emit(key, args) {
    const ev = this.events.find(e => e.key === key);
    if (!ev) {
      return;
    }
    ev.actions?.forEach(a => a.fn(args));
  }

  clear(handler: string) {
    if (!handler) {
      return;
    }
    const ev = this.events.find(e => e.actions.map(a => a.handler).includes(handler));
    if (!ev) {
      return;
    }
    ev.actions.splice(ev.actions.findIndex(a => a.handler === handler), 1);
  }
}

const instance = new EventEmitter();
export default instance;
