// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item-image {
  width: 24px;
  margin-right: 4px;
  margin-left: 8px;
  position: relative;
  top: -1px;
}
.dropdown-item-image img {
  max-width: 24px;
}

.dropdown-item-button {
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: left;
}
.dropdown-item-button:disabled {
  cursor: default;
}
.dropdown-item-button:not(:disabled):hover {
  background-color: #a98ae6;
}
.dropdown-item-button .prefix {
  min-width: 25px;
}

.dropdown-item-colors-container {
  display: flex;
}
.dropdown-item-colors-container .dropdown-item-color {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-item-colors-container .dropdown-item-color-selected {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #000;
}

.scrollable {
  max-height: 60vh;
  overflow-y: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
