import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs_ from 'dayjs';
import duration from 'dayjs/plugin/duration';

const dayjs = dayjs_;
dayjs.extend(duration);

@Pipe({name: 'amDuration'})
export class DurationPipe implements PipeTransform {
  transform(value: any, hoursLabel?: string, minutesLabel?: string): string {
    if (!value) {
      return '';
    }

    let durationObj = dayjs.duration(value.toString().replace('s', ''), 'seconds');

    if (isNaN(durationObj.asSeconds())) {
      durationObj = dayjs.duration(value);
    }

    const result = [];

    if (durationObj.asHours() && durationObj.asHours() >= 1) {
      result.push(`${durationObj.format('H')}${hoursLabel || 'h'}`);
    }

    const minutes = durationObj.asMinutes();
    if (minutes % 60) {
      result.push(`${Math.round(minutes % 60)}${minutesLabel || 'min'}`);
    }

    return result.join(' ');
  }
}
