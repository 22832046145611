import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AppConfig} from 'app.config';

@Injectable({providedIn: 'root'})
export class GeneralLayoutService {
  screen_limit = {
    extraLarge: AppConfig.gridBreakpoints.xl
  };
  section_class = {
    hide: 'invisible',
    show: 'col-12 border-0',
    left: 'col-3',
    right: 'offset-3 col-9'
  };
  width = window.innerWidth;
  leftSectionClass = null;
  rightSectionClass = null;
  subjectLeftSectionClass = new Subject();
  subjectRightSectionClass = new Subject();

  constructor() {
    this.showLeftSection();
  }

  onResize(event) {
    if (this.screen_limit.extraLarge > this.width && event.target.innerWidth < this.screen_limit.extraLarge) {
      return;
    }

    this.width = event.target.innerWidth;
    this.showRightSection();
    this.notifySubscribers();
  }

  getLeftSectionClass() {
    return this.leftSectionClass;
  }

  getRightSectionClass() {
    return this.rightSectionClass;
  }

  getLeftSectionClassObservable() {
    return this.subjectLeftSectionClass.asObservable();
  }

  getRightSectionClassObservable() {
    return this.subjectRightSectionClass.asObservable();
  }

  showLeftSection() {
    this.leftSectionClass = this.screen_limit.extraLarge > this.width ? this.section_class.show : this.section_class.left;
    this.rightSectionClass = this.screen_limit.extraLarge > this.width ? this.section_class.hide : this.section_class.right;
    this.notifySubscribers();
  }

  showRightSection() {
    this.leftSectionClass = this.screen_limit.extraLarge > this.width ? this.section_class.hide : this.section_class.left;
    this.rightSectionClass = this.screen_limit.extraLarge > this.width ? this.section_class.show : this.section_class.right;
    this.notifySubscribers();
  }

  toggleSectionClass() {
    if (this.screen_limit.extraLarge < this.width) {
      return;
    }

    const auxClass = this.leftSectionClass;
    this.leftSectionClass = this.rightSectionClass;
    this.rightSectionClass = auxClass;
    this.notifySubscribers();
  }

  private notifySubscribers() {
    this.subjectRightSectionClass.next(this.rightSectionClass);
    setTimeout(() => {
      this.subjectLeftSectionClass.next(this.leftSectionClass);
    });
  }
}
