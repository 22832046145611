import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MultipleChoice1Bit, MultipleChoice1Option} from './multiple-choice-1.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-multiple-choice-1',
  templateUrl: './multiple-choice-1.component.html',
  styleUrls: ['../bits.scss']
})
export class MultipleChoice1Component implements OnInit, OnChanges {
  @Input() bit?: MultipleChoice1Bit;
  @Input() bitId: string;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Input() allowAnswerSubmit = true;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  answersCss = {};

  ngOnInit() {
    this.initializeExample();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.doRefresh();
    }
  }

  setQuizAnswer(selectedChoice: string) {
    this.bit.answer = {choice: selectedChoice};
    this.changed.emit();
  }

  private initializeExample() {
    if (!this.bit.isExample) {
      return;
    }
    const exampleChoice = this.bit.choices.find(x => x.example);
    if (exampleChoice) {
      this.bit.answer = {choice: exampleChoice.choice};

      this.bit.choices?.forEach((c: MultipleChoice1Option, cidx: number) => {
        this.answersCss[cidx] = this.handleFeedback(this.bit?.answer?.choice, c, c.isExample);
      });
    }
  }

  private handleFeedback(response: string, c: MultipleChoice1Option, responseHasFeedback: boolean) {
    return !responseHasFeedback
      ? ''
      : c.isCorrect
        ? 'correct'
        : !c.isCorrect && response === c.choice
          ? 'incorrect'
          : '';
  }

  static evalAnswer(bit: MultipleChoice1Bit): BitEvalResult {
    return {
      totalAnswers: 1,
      answeredAnswers: !!bit.answer?.choice ? 1 : 0,
      notAnsweredAnswers: !bit.answer?.choice ? 1 : 0,
      correctAnswers: bit.answer?.choice && bit.choices.filter(c => c.isCorrect).map(c => c.choice).includes(bit.answer.choice)
        ? 1 : 0,
      incorrectAnswers: bit.answer?.choice && !bit.choices.filter(c => c.isCorrect).map(c => c.choice).includes(bit.answer.choice)
        ? 1 : 0,
    };
  }

  doRefresh() {
    this.bit.choices?.forEach((c: MultipleChoice1Option, cidx: number) => {
      this.answersCss[cidx] = this.handleFeedback(this.bit?.answer?.choice, c, this.hasFeedback);
    });
  }
}
