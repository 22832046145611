import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from 'environment';

@Component({
  selector: 'app-error-pane',
  templateUrl: './error-pane.component.html',
  styleUrls: ['./error-pane.component.scss']
})
export class ErrorPaneComponent implements OnInit {
  @Input() httpError?: HttpErrorResponse;
  @Input() title?: string;
  @Input() description?: string;
  @Input() sendDDNotification = false;

  constructor() {
  }

  ngOnInit() {
    if (this.sendDDNotification && environment.production) {
      console.error(this.httpError || new Error(`${this.title} ${this.description}`));
    }
  }
}
