import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {SubSink} from 'core/sub-sink/sub-sink';

@Component({
  selector: 'app-container',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerComponent implements OnDestroy {
  subSink = new SubSink();

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
