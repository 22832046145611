// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent-mask {
  opacity: 1;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
}

.save-button {
  font-size: 16px;
  display: inline-block;
  width: auto;
  max-width: 400px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
