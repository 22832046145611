import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {PageSubscribeBit} from './page-subscribe.models';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'bitmark-page-subscribe',
  templateUrl: './page-subscribe.component.html',
  styleUrls: ['./page-subscribe.component.scss', '../bits.scss']
})
export class PageSubscribeComponent implements OnInit {
  @Input() bit?: PageSubscribeBit;
  @ViewChild('email') email: ElementRef;
  error = false;
  window: any = window;
  emailPattern = `([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])`;

  form: FormGroup;
  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'email': new FormControl('', [Validators.required])
    });
  }

  subscribe(event: any) {
    const email = this.form['email'] || (this.email && this.email.nativeElement ? this.email.nativeElement.value : '');
    if (!email) {
      return alert('The email is cannot be empty');
    }

    const xhr = new XMLHttpRequest();
    const url = this.bit.subscribeUrl; // Replace with your endpoint URL
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.responseText); // Handle the response here
      }
    };

    var data = JSON.stringify({
      "email": "example@email.com" // Replace with the email you want to send
    });

    xhr.send(data);
  }

  setWideImage(){

  }
}
