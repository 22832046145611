import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {DDService} from '../error-handler/dd-service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private dDService: DDService) {
  }

  handle(params: MissingTranslationHandlerParams) {
    if (params.translateService.store.currentLang && params.key.indexOf('.') !== -1 && params.key.indexOf('.') !== (params.key.length - 1) && params.key.indexOf(' ') === -1) {
      this.dDService.logInfo('Missing translation', {
        key: params.key,
        lang: params.translateService.store.currentLang
      });
      return params.key.split('.')[params.key.split('.').length - 1];
    }
    return params.key;
  }
}
