import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'bitmark-notebook-learning-path-cover',
  templateUrl: './notebook-learning-path-cover.component.html',
})
export class NotebookLearningPathCoverComponent implements OnInit {
  @Input() primaryColor?: string;
  @Input() viewBoxWidth = 140;
  @Input() viewBoxHeight = 200;

  viewBoxProp: string;

  ngOnInit() {
    this.viewBoxProp = `0 0 ${this.viewBoxWidth} ${this.viewBoxHeight}`;
  }
}
