import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, RendererFactory2} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ScrollAntiBlock} from './scroll-anti-block';
import {DomUtilsService} from '../../../../../bitmark/src/lib/shared/dom/dom-utils.service';

@Directive({selector: '[scrollAntiBlock]'})
export class ScrollAntiBlockDirective implements AfterViewInit, OnDestroy {
  @Input() scrollOffset = 3;
  @Input() debounceTime = 50;

  private scrollAntiBlock: ScrollAntiBlock;

  constructor(private elementRef: ElementRef,
              private rendererFactory: RendererFactory2,
              private deviceDetectorService: DeviceDetectorService,
              private domUtils: DomUtilsService) {
  }

  ngAfterViewInit() {
    const renderer = this.rendererFactory.createRenderer(null, null);
    this.scrollAntiBlock = new ScrollAntiBlock(this.elementRef.nativeElement, this.scrollOffset, this.debounceTime, renderer, this.deviceDetectorService, this.domUtils);
    this.scrollAntiBlock.apply();
  }

  ngOnDestroy() {
    if (this.scrollAntiBlock) {
      this.scrollAntiBlock.destroy();
    }
  }
}
