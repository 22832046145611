import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {interval} from 'rxjs';
import {ifvisible} from '@rosskevin/ifvisible';
import * as WebFontLoader from 'webfontloader';
import {BitmarkSettings, RouterExtService} from 'bitmark';
import {FeatureFlagService} from 'core/feature-flag/feature-flag.service';
import {SharedModule} from 'shared/shared.module';
import {
  AccountApiService,
  AppEventsService,
  AppVersion,
  IdentityService,
  PageState,
  InternetConnectionService,
  SubSink,
  UserPresenceService, GaRouterService, DDService
} from 'core';
import {AccountMqService} from 'main/account/account-mq.service';
import {environment} from 'environment';
import {translation as enTranslation} from 'core/translations/i18n/en';
import {translation as deTranslation} from 'core/translations/i18n/de';
import {translation as esTranslation} from 'core/translations/i18n/es';
import {translation as frTranslation} from 'core/translations/i18n/fr';
import {translation as itTranslation} from 'core/translations/i18n/it';
import {translation as roTranslation} from 'core/translations/i18n/ro';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  pageState = new PageState();
  isLoading = false;
  window: any = window;
  nativeBridge = this.window.GetMoreBrain;
  private sub = new SubSink();
  private subUpdates = new SubSink();
  private isOnline = false;

  constructor(private authService: AuthService,
              private accountApiService: AccountApiService,
              private accountMqService: AccountMqService,
              private identityService: IdentityService,
              private userPresenceService: UserPresenceService,
              private internetConnection: InternetConnectionService,
              private translate: TranslateService,
              private appEventsService: AppEventsService,
              private routerExtService: RouterExtService,
              private gaRouterService: GaRouterService,
              private featureFlagService: FeatureFlagService,
              private ddService: DDService
  ) {
    this.routerExtService.init();
    this.gaRouterService.init();

    this.featureFlagService.init(this.identityService.user).then(() => {
      SharedModule.LocalParser.enabled = this.featureFlagService.getVariable('use-local-parser', false) as boolean || false;
      SharedModule.EditorMenusFromTheme.enabled = this.featureFlagService.getVariable('editor-menus-from-theme', false) as boolean || false;
      this.ddService.init(this.featureFlagService.getVariable('rum-on-off', false) as boolean || false,
        this.featureFlagService.getVariable('sample-rate', 0) as number || 0,
        this.featureFlagService.getVariable('default-privacy-level', 'allow') as string || 'mask');
    });

    console.log('GetMoreBrain.Web ' + environment.name);
    translate.setTranslation('en', enTranslation);
    translate.setTranslation('de', deTranslation);
    translate.setTranslation('es', esTranslation);
    translate.setTranslation('it', itTranslation);
    translate.setTranslation('fr', frTranslation);
    translate.setTranslation('ro', roTranslation);
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    WebFontLoader.load({
      active: () => {
        this.pageState.setReady();
      },
      google: {
        families: [
          'Inter:200,300,400,400i,500,600,700',
          'Roboto:300,400,400i,500,700',
          'Roboto+Condensed:300',
          'Open Sans:300,300i,400,400i,500',
        ],
      },
      custom: {
        families: [
          'Calibri',
          'ITC Legacy',
          'Paralucent Text',
          'IBM Plex Serif Regular',
          'Akkurat Std',
          'Stift',
          'AS_Baskerville_Display_Regular',
          'Bitter',
          'Cambria',
          'MinionPro-Regular',
          'Cochin',
          'EFCircularWeb',
          'Lato',
          'Prata',
          'DMSans',
          'PermanentMarker',
          'PlayfairDisplay',
          'NIN3X',
          'UniversLTStd',
          'UniversLTStdCn',
          'Urbanist',
          'Raleway',
          'Quicksand',
          'SquarePeg',
          'Caveat',
          'CormorantGaramond',
          'ES Symbols'
        ],
      },
    });
    // this.swPush.messages.subscribe((message) => {
    //   console.log('PUSH MESSAGE: ', message);
    //   this.registration.showNotification('Notifff', {});
    // });
    // const subdomainCfg = appHost.getSubdomainConfig();
    // if (subdomainCfg.publicShopConfig) {
    //   this.router.navigate(['public', 'shop', ''], {replaceUrl: true});
    // }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.timelineNativePushHandlerService.handleOnPushNotificationOpenApp(JSON.stringify({
    //     url: '/timeline/1'
    //   }))
    // }, 10000);

    // this.userPresenceService.notifyCurrentAvailabilityStatus();
    //this.userPresenceService.startHeartbeat();

    if (this.authService.isAuthenticated() && this.identityService.user) {
      this.accountApiService.getProfile()
        .subscribe((userEntity) => {
          const usr = Object.assign(this.identityService.user);
          this.identityService.user = Object.assign(usr, userEntity);
          const lang = this.identityService.user.language ? this.identityService.user.language : 'en';
          this.translate.use(lang);
          BitmarkSettings.setLocale(lang, this.identityService.user.countryCode);
          this.accountMqService.notifyProfileUpdate();
        }, (err: HttpErrorResponse) => {
          console.error(err);
          this.pageState.setError(`${err.status}: ${err.error.message}`);
        });
    } else {
      this.translate.use('en');
    }

    this.sub.sink = this.appEventsService.onAppLoaded().subscribe(() => {
      // this.userPresenceService.notifyCurrentAvailabilityStatus();
      if (this.nativeBridge && this.nativeBridge.dispatchReadyEvent) {
        this.nativeBridge.dispatchReadyEvent();
      }
      if (this.nativeBridge && this.nativeBridge.popLatestPushNotification) {
        this.nativeBridge.popLatestPushNotification();
      }
    });

    // this.sub.sink = this.appEventsService.onAppUnloaded().subscribe(() => {
    //   this.userPresenceService.notifyAvailabilityStatus(false);
    // });

    this.sub.sink = this.appEventsService.onAppShown().subscribe(() => {
      // this.userPresenceService.notifyCurrentAvailabilityStatus();
      if (this.nativeBridge && this.nativeBridge.dispatchReadyEvent) {
        this.nativeBridge.dispatchReadyEvent();
      }
      if (this.nativeBridge && this.nativeBridge.popLatestPushNotification) {
        this.nativeBridge.popLatestPushNotification();
      }
    });

    // this.sub.sink = this.appEventsService.onAppHidden().subscribe(() => {
    //   this.userPresenceService.notifyAvailabilityStatus(false);
    // });

    this.sub.sink = this.appEventsService.onAppPushNotificationHandled().subscribe(() => this.isLoading = false);

    this.sub.sink = this.internetConnection.createStatusObserver().subscribe(isOnline => {
      if (isOnline) {
        this.appEventsService.notifyAppOnline();
      } else {
        this.appEventsService.notifyAppOffline();
      }
      if (!this.isOnline && isOnline) {
        this.appEventsService.notifyAppBackOnline();
      }
      this.isOnline = isOnline;
    });

    this.subUpdates.sink = interval(30000).subscribe(this.checkForUpdates.bind(this));

    this.linkNativeBridge();

    this.appEventsService.notifyAppLoaded();
    ifvisible.on('blur', () => this.appEventsService.notifyAppHidden());
    ifvisible.on('focus', () => this.appEventsService.notifyAppShown());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.subUpdates.unsubscribe();
  }

  private linkNativeBridge() {
    if (!this.nativeBridge) {
      return;
    }

    this.nativeBridge.popLatestPushNotificationReturn = function (json: string) {
      this.isLoading = true;
      this.ngZone.run(() => this.timelineNativePushHandlerService.handleOnPushNotificationOpenApp(json));
    }.bind(this);

    this.nativeBridge.onPushNotification = function (json: string) {
      this.ngZone.run(() => this.timelineNativePushHandlerService.handleOnPushNotification(json));
    }.bind(this);

    this.nativeBridge.onLink = function (link: string) {
      this.isLoading = true;
      this.ngZone.run(() => this.timelineNativePushHandlerService.handleOnLink(link));
    }.bind(this);

    this.nativeBridge.isReady = function () {
      return this.isAppReady;
    }.bind(this);

    this.nativeBridge.getLoggedInUser = function () {
      const localStorageItem = JSON.parse(window.localStorage.getItem('gmb-cosmic.volatile'));
      const user = localStorageItem && localStorageItem.user;
      const token = window.localStorage.getItem('gmb-cosmic_token');

      return {
        token,
        userId: user && user.id,
      };
    };
  }

  private checkForUpdates() {
    this.appEventsService.checkForUpdates()
      .subscribe((newVersion: AppVersion | null) => {
        if (newVersion) {
          this.subUpdates.unsubscribe();
        }
      });
  }

  @HostListener('window:beforeunload', [])
  appUnloaded() {
    this.appEventsService.notifyAppUnloaded();
  }
}
