import {Injectable} from '@angular/core';
import {Portal} from '@angular/cdk/portal';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BitPortalService {
  private bitPortals: Map<string, BehaviorSubject<Portal<any>>>;

  constructor() {
    this.bitPortals = new Map<string, BehaviorSubject<Portal<any>>>();
  }

  getPortal$(bitId: string): Observable<Portal<any>> {
    if (!this.bitPortals.has(bitId)) {
      const subject = new BehaviorSubject<Portal<any>>(undefined);
      this.bitPortals.set(bitId, subject);
      return subject.asObservable();
    }
    return this.bitPortals.get(bitId).asObservable();
  }

  setPortal(bitId: string, portal: Portal<any>) {
    if (!this.bitPortals.has(bitId)) {
      return;
    }

    this.bitPortals.get(bitId)?.next(portal);
  }

  removePortal(bitId: string) {
    const portal = this.bitPortals.get(bitId);
    if (portal) {
      portal.complete();
      portal.unsubscribe();
    }
    this.bitPortals.delete(bitId);
  }
}
