// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .annotation-container .annotation-body .ProseMirror {
  min-height: 140px;
}
::ng-deep .bit-type-annotation-note .ProseMirror p.is-editor-empty:first-child::before {
  float: none;
  text-align: center;
  position: absolute;
}
::ng-deep .bit-type-annotation-note .annotation-container .title-header .ProseMirror {
  width: 97%;
}
::ng-deep .selected-context-menu .tiptap-bubble-item {
  border: 1px solid #7b7b7b;
  width: 34px;
  border-radius: 5px;
  text-align: center;
  margin: 0 2px;
  background-color: white;
}
::ng-deep .selected-context-menu .menu-bubble-item {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  padding: 2px;
  width: 70px;
  text-align: left;
  padding-left: 7px;
}

.btn-editor-action {
  border: 1px solid #543199;
  background-color: #ffffff;
  border-radius: 5px;
  min-width: 30px;
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 3px;
}
.btn-editor-action.is-active {
  background-color: gold;
}

/**
::ng-deep {
  .reader-text-editor {
    mark {
      background-color: transparent;
      padding: 0;
    }

    .tippy-box {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 5px;
    }

    .tiptap-bubble-menu-list {
      //min-width: 200px;
      border: none;
      box-shadow: none;
    }

    .contextual-menu-absolute {
      text-transform: uppercase;
      color: \$color-white;
      font-weight: 500;
      font-size: 13px;
      font-family: 'Inter', sans-serif;
      right: 0;
      z-index: 100
    }

    #contextual-menu-general {
      top: -50px;
      left: 45px;
      box-shadow: \$reader-dropdown-box-shadow;
      display: none;
      z-index: 1000;
      background-color: white;
      border-radius: 5px;

      .tiptap-bubble-item {
        border: 1px solid #7b7b7b;
      }

      .menu-bubble-item {
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid #7b7b7b;
        padding: 2px;
        width: 70px;
        text-align: left;
        padding-left: 7px;

        &.is-active-bubble {
          border: 2px solid #361f64 !important;
        }
      }

      .tiptap-bubble-item.is-active-bubble {
        border: 2px solid #361f64 !important;
      }
    }
  }
}
**/
::ng-deep .title-header .reader-simple-text-editor .ProseMirror {
  height: auto;
}
::ng-deep .title-header .reader-simple-text-editor p {
  margin: 0;
}
::ng-deep .reader-simple-text-editor .ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}
::ng-deep .reader-simple-text-editor .ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}
@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
::ng-deep .reader-simple-text-editor .ProseMirror-hideselection *::selection {
  background: transparent;
}
::ng-deep .reader-simple-text-editor .ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
::ng-deep .reader-simple-text-editor .ProseMirror-hideselection * {
  caret-color: transparent;
}
::ng-deep .reader-simple-text-editor .ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
::ng-deep .reader-simple-text-editor .tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
}
::ng-deep .reader-text-editor .dropdown-menu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
::ng-deep .d-hidden {
  visibility: hidden;
}
::ng-deep .text-editor-actions .popover-body, ::ng-deep .no-pad-popover .popover-body {
  padding: 0;
}
::ng-deep .text-editor-actions .popover-body label, ::ng-deep .no-pad-popover .popover-body label {
  margin-bottom: 0;
}
::ng-deep .has-focus {
  border-radius: 3px;
  box-shadow: 0 0 0 3px #68cef8;
}
::ng-deep .tippy-content {
  padding: 0;
}
::ng-deep .tippy-content input[type=checkbox] {
  -webkit-appearance: checkbox;
}
::ng-deep .tippy-content .popover-body {
  padding: 0;
}
::ng-deep .tippy-content label {
  margin-bottom: 0;
}
::ng-deep .tiptap-menu-separator {
  border-top: 1px solid #c8c8c8;
}
::ng-deep #tiptap-buble-menu-text-format-item:hover + #tiptap-text-bubble-menu {
  display: block;
}
::ng-deep .tippy-box {
  border-radius: 10px;
  border: none;
  background-color: #fff;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
::ng-deep #tippy-4 .tippy-box {
  padding: 0;
}
::ng-deep #tippy-2 .tippy-box {
  padding: 0;
  box-shadow: none !important;
  background-color: transparent;
  border: none;
}
::ng-deep #tippy-2 .tippy-box .tiptap-menu {
  margin-top: 16px;
  position: absolute;
  right: 0;
}
::ng-deep .tiptap-menu {
  min-width: 200px;
}
::ng-deep .tiptap-bubble-menu-text {
  border-radius: 20px;
}
::ng-deep .tiptap-text-bubble-menu-top {
  height: 40px;
  padding: 6px 4px;
}
::ng-deep .tiptap-text-bubble-menu-top .tiptap-bubble-item {
  width: 34px;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  text-align: center;
  margin: 0 2px;
}
::ng-deep .tiptap-bubble-item-container {
  text-align: center;
}
::ng-deep .tiptap-bubble-item {
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  border-left: 5px solid transparent;
}
::ng-deep .tiptap-bubble-item.tiptap-bubble-item-block {
  display: block;
  width: 120px;
}
::ng-deep .text-decoration-link {
  text-decoration: underline;
  text-decoration-color: #0067ff;
  text-decoration-thickness: 2px;
}
::ng-deep .text-decoration-marked {
  padding: 3px 5px;
  background-color: #a6c4f8;
  border-radius: 5px;
}
::ng-deep .text-decoration-note-highlight {
  padding: 3px 5px;
  background-color: #f6f2ac;
  border-radius: 5px;
}
::ng-deep .text-decoration-inserted {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #97d789;
  text-decoration-thickness: 2px;
}
::ng-deep .text-decoration-deleted {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #dfb5a2;
  text-decoration-thickness: 2px;
}
::ng-deep .color-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}
::ng-deep .lettered-list {
  list-style-type: upper-latin !important;
}
::ng-deep .lettered-list-lower {
  list-style-type: lower-latin !important;
}
::ng-deep .ordered-list-roman {
  list-style-type: upper-roman !important;
}
::ng-deep .ordered-list-roman-lower {
  list-style-type: lower-roman !important;
}
::ng-deep .no-bullet-list {
  list-style-type: none !important;
}
::ng-deep .tiptap-bubble-menu-icon {
  height: 15px;
}
::ng-deep .dropdown__mobile .tiptap-bubble-item {
  width: 34px;
  text-align: center;
  margin: 3px 0;
  font-size: 22px;
}
::ng-deep .dropdown__mobile .tiptap-small-bubble-item {
  border-radius: 5px;
  border: 1px solid #7b7b7b;
}
::ng-deep .dropdown__mobile .menu-bubble-item {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #7b7b7b;
  padding: 2px;
  width: 70px;
  text-align: left;
  padding-left: 7px;
  min-width: 90px;
}
::ng-deep .dropdown__mobile .menu-bubble-item.is-active-bubble {
  border: 2px solid #361f64 !important;
}
::ng-deep .tiptap-bubble-item.is-active-bubble {
  border: 2px solid #361f64 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
